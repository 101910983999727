/*
 * @Author: e “v_caoky@digitalgd.com.cn
 * @Date: 2023-09-06 18:03:08
 * @LastEditors: e “v_caoky@digitalgd.com.cn
 * @LastEditTime: 2023-09-06 19:01:43
 * @FilePath: \VaultekSafe-Admin\src\i18n\zh-CN.ts
 * @Description: 
 */
export default {
    "account": {
        "diSFZHBD": "第三方账户绑定",
        "siDL": "已登录",
        "bangDSFZX": "绑定三方账号",
        "geRXX": "个人信息",
        "zhangX": "账号",
        "yongHM": "用户名",
        "sanFZHXX": "三方账户信息",
        "weiJBD": "立即绑定",
        "weiDL": "未登录",
        "niSTG": "你已通过",
        "shouQ": "授权",
        "wanSSXDLXXJKSWCBD": "完善以下登录信息即可以完成绑定",
        "zhangH": "账户",
        "qingSRZH": "请输入账户",
        "miM": "密码",
        "qingSRMM": "请输入密码",
        "yanZM": "验证码",
        "qingSRYZM": "请输入验证码",
        "deLBBDZH": "登录并绑定账户",
        "yongHXX": "用户信息",
        "huoQ": "获取",
        "cenS": "参数",
        "sanFYYXX": "三方应用信息",
        "dingD": "钉钉",
        "weiX": "微信",
        "bangDCG": "绑定成功",
        "xianDLZBD": "先登录再绑定",
        "huoQTXYZM": "获取图形验证码",
        "deLCG": "登录成功",
        "bangDCGTZZYM": "绑定成功跳转至页面",
        "di": "的",
        "neiBJCRYTHYMYM": "内部集成需要跳回它们页面",
        "bianJ": "编辑",
        "xingM": "姓名",
        "xingMBC": "姓名必填",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "qingSRXM": "请输入姓名",
        "qingSRYHM": "请输入用户名",
        "jiaoS": "角色",
        "qingSRJS": "请输入角色",
        "zuZ": "组织",
        "qingSRZZ": "请输入组织",
        "shouJX": "手机号",
        "qingSRZQSJX": "请输入正确手机号",
        "qingSRSJX": "请输入手机号",
        "youX": "邮箱",
        "youXBSYGYXD": "邮箱不是一个有效的",
        "qingSRYX": "请输入邮箱",
        "baoCCG": "保存成功",
        "tongZMM": "重置密码",
        "jiuMM": "旧密码",
        "qingSRJMM": "请输入旧密码",
        "queRMM": "确认密码",
        "qingSRQRMM": "请输入确认密码",
        "qingZCSRMM": "请再次输入密码",
        "yanZYB": "验证失败",
        "liangCMMSRBYZ": "两次密码输入不一致",
        "gengHTX": "更换头像",
        "zhuCSJ": "注册时间",
        "dianH": "电话",
        "xiuGMM": "修改密码",
        "anQXGDMMKSSZXGAQ": "安全性高的密码可以使帐号更安全",
        "jianYNDJGHMM": "建议您定期更换密码",
        "sheZYGPHZW": "设置一个包含字母",
        "fuXYSZZZSLXJZZCG": "符号或数字中至少两项且长度超过",
        "weiDMM": "位的密码",
        "sheQBBXZ": "社区版不显示",
        "bangDM": "绑定名",
        "bangDSJ": "绑定时间",
        "weiBD": "未绑定",
        "queRJSBDM": "确认解除绑定嘛",
        "jieSBD": "解除绑定",
        "diSFYHBXZ": "第三方用户不显示",
        "shouYST": "首页视图",
        "queD": "确定",
        "diSFZX": "第三方账号",
        "jieBCG": "解绑成功",
        "shangZCG": "上传成功",
        "shangZYB": "上传失败",
        "qingSHZS": "请稍后再试",
        "qingSZZQLSDTP": "请上传正确格式的图片",
        "huoQYHXX": "获取用户信息",
        "huoQBDDSFZX": "获取绑定第三方账号",
        "huoQSYST": "获取首页视图",
        "panDSPS": "判断是否是",
        "yongH": "用户",
        "buSZHQXZDST": "不是则获取选中的视图",
        "xiangQ": "详情",
        "gaoJSB": "告警设备",
        "sheB": "设备",
        "gaoJMC": "告警名称",
        "gaoJSJ": "告警时间",
        "gaoJJB": "告警级别",
        "gaoJSM": "告警说明",
        "gaoJLS": "告警流水",
        "queRBW": "确认标为",
        "weiD": "未读",
        "siD": "已读",
        "biaoWWD": "标为未读",
        "biaoWSD": "标为已读",
        "zhaK": "查看",
        "leiX": "类型",
        "xiaoX": "消息",
        "tongZSJ": "通知时间",
        "zhuangT": "状态",
        "caoZ": "操作",
        "caoZCG": "操作成功",
        "xinC": "新增",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "qingXZLX": "请选择类型",
        "gaoJGZ": "告警规则",
        "qingXZGJGZ": "请选择告警规则",
        "tongZFS": "通知方式",
        "qingXZTZFS": "请选择通知方式",
        "zhanNTZ": "站内通知",
        "jinY": "禁用",
        "qiY": "启用",
        "qingXJY": "请先禁用",
        "zaiSS": "再删除",
        "shanS": "删除",
        "queRSS": "确认删除",
        "caoZYB": "操作失败"
    },
    "DataCollect": {
        "qingSRMC": "请输入名称",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "qingXZTXXY": "请选择通讯协议",
        "qingSR": "请输入",
        "zhuJ": "主机",
        "qingSRZQLSD": "请输入正确格式的",
        "deZ": "地址",
        "qingSRDK": "请输入端口",
        "zhuJDZZS": "之间的正整数",
        "qingSRDD": "请输入端点",
        "qingXZAQCL": "请选择安全策略",
        "qingXZAQMS": "请选择安全模式",
        "qingXZZS": "请选择证书",
        "qingXZQWRZ": "请选择权限认证",
        "qingSRYHM": "请输入用户名",
        "qingSRMM": "请输入密码",
        "tongDMC": "通道名称",
        "tongXXY": "通讯协议",
        "zhuangT": "状态",
        "zhengC": "正常",
        "jinY": "禁用",
        "yunXZT": "运行状态",
        "yunXZ": "运行中",
        "buFCW": "部分错误",
        "cuoW": "错误",
        "shuiM": "说明",
        "caoZ": "操作",
        "xinCTD": "新增通道",
        "xieY": "协议",
        "qiY": "启用",
        "bianJ": "编辑",
        "queR": "确认",
        "caoZCG": "操作成功",
        "shanS": "删除",
        "qingXJYGZJ": "请先禁用该组件",
        "zaiSS": "再删除",
        "gaiCZQHSSXZCJQYDW": "该操作将会删除下属采集器与点位",
        "queDSS": "确定删除",
        "xiaoS": "小时",
        "chaoCDWBYZ": "超出的文本隐藏",
        "yiCYXLXXZ": "溢出用省略号显示",
        "yiCBHX": "溢出不换行",
        "xinC": "新增",
        "qingSRTDMC": "请输入通道名称",
        "zhiC": "支持",
        "yuM": "域名",
        "duanK": "断开",
        "duanD": "端点",
        "anQCL": "安全策略",
        "anQMS": "安全模式",
        "zhengS": "证书",
        "quanWRZ": "权限认证",
        "yongHM": "用户名",
        "miM": "密码",
        "qingSRSM": "请输入说明",
        "quX": "取消",
        "qingSRDWMC": "请输入点位名称",
        "qingXZGTM": "请选择功能码",
        "qingSRDZ": "请输入地址",
        "qingSRJCQSL": "请输入寄存器数量",
        "qingXZSJLX": "请选择数据类型",
        "qingSRSFYZ": "请输入缩放因子",
        "qingXZFWLX": "请选择访问类型",
        "qingXZSPXRSJQZZ": "请选择是否写入数据区长度",
        "qingSRZDYSJQZZ": "请输入自定义数据区长度",
        "qingSRCJPL": "请输入采集频率",
        "yuZZZS": "或者正整数",
        "qingXZSZTD": "请选择所属通道",
        "qingSRCJQMC": "请输入采集器名称",
        "qingSRCJDZ": "请输入从机地址",
        "qingXZCLFS": "请选择处理方式",
        "qingXZSZGDWQH": "请选择单字高低位切换",
        "mingC": "名称",
        "fangWLX": "访问类型",
        "caiJPL": "采集频率",
        "zhiTSBHDSJ": "只推送变化的数据",
        "shouDYC": "手动延迟",
        "fangZSJKGXBJS": "防止数据库更新不及时",
        "piLBJ": "批量编辑",
        "qiangPLXG": "将批量修改",
        "tiaoSJDFWLX": "条数据的访问类型",
        "du": "读",
        "xie": "写",
        "dingY": "订阅",
        "caiJPLW": "采集频率为",
        "shiBZXLXLW": "时不执行轮询任务",
        "tuP": "图片",
        "neiY": "内容",
        "gouX": "勾选",
        "anN": "按钮",
        "xieR": "写入",
        "shi": "是",
        "pi": "否",
        "qingXZ": "请选择",
        "xinCDW": "新增点位",
        "saoM": "扫描",
        "piLCZ": "批量操作",
        "quanX": "全选",
        "dianWMC": "点位名称",
        "siTZ": "已停止",
        "qingXXZ": "请先选择",
        "jiCQSL": "寄存器数量",
        "suoFYZ": "缩放因子",
        "fangZSXGK": "防止刷新过快",
        "gongTM": "功能码",
        "xianJJCQ": "线圈寄存器",
        "liSSRJCQ": "离散输入寄存器",
        "baoCJCQ": "保存寄存器",
        "shuRJCQ": "输入寄存器",
        "qingXZSGTM": "请选择所功能码",
        "shuJLX": "数据类型",
        "xiaoSBLWS": "小数保留位数",
        "qingSRXSBLWS": "请输入小数保留位数",
        "feiBZXYXRPZ": "非标准协议写入配置",
        "shiPXRSJQZZ": "是否写入数据区长度",
        "ziDYSJQZZ": "自定义数据区长度",
        "shiDTZD": "是多余字段",
        "yanS": "但是",
        "banBSSYDLZGZD": "版本上使用到了这个字段",
        "shuJLXZZR": "数据类型长度需",
        "shuZLX": "数组类型",
        "shiJLX": "时间类型",
        "wenBLX": "文本类型",
        "buE": "布尔",
        "tongS": "同上",
        "queRSS": "确认删除",
        "shanSSRYZTSCZ": "删除时需要做同上操作",
        "weiGXWC": "未更新完成",
        "ruYY": "需要用",
        "yuZ": "或者",
        "chuL": "处理",
        "youXCSTSSJ": "有新增时同上数据",
        "shuJY": "数据源",
        "yinZSYJD": "隐藏已有节点",
        "xinCCJQ": "新增采集器",
        "queDQY": "确定启用",
        "queDJY": "确定禁用",
        "qingXJY": "请先禁用",
        "gaiCZQHSSXZDW": "该操作将会删除下属点位",
        "zanMSJ": "暂无数据",
        "quanB": "全部",
        "tongDTZJLYZXSS": "通道跳转进来或者搜索时",
        "shuGJDMQB": "树根节点无全部",
        "moRXZDYG": "默认选中第一个",
        "jiH": "激活",
        "ziJ": "事件",
        "suoZTD": "所属通道",
        "caiJQMC": "采集器名称",
        "congJDZ": "从机地址",
        "guZCL": "故障处理",
        "jiangP": "降频",
        "huL": "忽略",
        "shuangZGDWQH": "双字高低位切换",
        "shanZGDWQH": "单字高低位切换",
        "dangQNCBJ": "当前内存布局",
        "zhiY": "只有",
        "ziJSJLX": "字节数据类型",
        "juY": "具有",
        "chongNCBJ": "种内存布局",
        "qiYZY": "其它只有",
        "liangCNCBJ": "两种内存布局",
        "siSZPZWZ": "以双字配置为准",
        "qingQCSSJPZ": "请求超时时间配置",
        "qingSRQQCSSJPZ": "请输入请求超时时间配置",
        "lianX": "连续",
        "ciYC": "次异常",
        "jiangDLXPLZYYPLD": "降低连接频率至原有频率的",
        "tongSJJBCG": "重试间隔不超过",
        "fenZ": "分钟",
        "guZCLHZDHFZSDLXPL": "故障处理后自动恢复至设定连接频率",
        "fenZYC": "分钟异常",
        "tingZCJSJJRDKZT": "停止采集数据进入断开状态",
        "sheBTXQYHHFCJZT": "设备重新启用后恢复采集状态",
        "huLYC": "忽略异常",
        "baoCYCJPLCSSJW": "保持原采集频率超时时间为",
        "dianWSJL": "点位数据量",
        "cuoJ": "最近",
        "jinR": "今日",
        "jinYZ": "近一周",
        "xiaoXL": "消息量",
        "chuDYS": "处的颜色",
        "queXW": "缺省为",
        "yiCTD": "异常通道",
        "tongDSL": "通道数量",
        "yiCCJQ": "异常采集器",
        "caiJQSL": "采集器数量",
        "yiCDW": "异常点位",
        "caiJDW": "采集点位"
    },
    "device": {
        "xinCBJTC": "新增编辑弹窗",
        "queD": "确定",
        "quX": "取消",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "baiX": "排序",
        "qingSRBX": "请输入排序",
        "shuiM": "说明",
        "appVisible": "APP是否可见",
        "qingSRSM": "请输入说明",
        "biaoSSJ": "表单数据",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "shiJSJ": "提交数据",
        "caoZCG": "操作成功",
        "caoZYB": "操作失败",
        "xianZTC": "显示弹窗",
        "xinC": "新增",
        "shuXJGCDTJ": "树形结构最多添加",
        "ceng": "层",
        "bianJ": "编辑",
        "shengX": "升序",
        "huoQLBSJ": "获取列表数据",
        "guanBTC": "关闭弹窗",
        "jianYXM": "监听项目",
        "chanPFL": "产品分类",
        "xinCHBJTC": "新增和编辑弹窗",
        "shaiX": "筛选",
        "maoS": "描述",
        "caoZ": "操作",
        "xiaoS": "搜索",
        "caoZLAN": "操作栏按钮",
        "bianJFL": "编辑分类",
        "tianJZFL": "添加子分类",
        "xinCZFL": "新增子分类",
        "shanS": "删除",
        "queRSS": "确认删除",
        "tianJCPFL": "添加产品分类",
        "xinCFL": "新增分类",
        "shuaXBLSJ": "刷新表格数据",
        "chuSH": "初始化",
        "sheB": "设备",
        "shouD": "手动",
        "guiZ": "规则",
        "du": "读",
        "xie": "写",
        "shangB": "上报",
        "biaoS": "表单",
        "ziJJB": "事件级别",
        "shiPYB": "是否异步",
        "duXLX": "读写类型",
        "shuJLX": "数据类型",
        "zhuXLY": "属性来源",
        "qingSRBS": "请输入标识",
        "zhiTYSZ": "只能由数字",
        "ziW": "字母",
        "xiaHX": "下划线",
        "zhongHXZC": "中划线组成",
        "qingXZSPYB": "请选择是否异步",
        "shi": "是",
        "pi": "否",
        "shuRCS": "输入参数",
        "peiZBZ": "配置步骤",
        "anXZXHSXBL": "按选择先后顺序排列",
        "shuCCS": "输出参数",
        "jiB": "级别",
        "qingXZJB": "请选择级别",
        "zhiYPZ": "指引配置",
        "cenSXZ": "参数选择",
        "qingXZCS": "请选择参数",
        "ziJZY": "事件指引",
        "qingSRZJZY": "请输入事件指引",
        "zhiYTP": "指引图片",
        "qingSZZYTP": "请上传指引图片",
        "jinRXYBTJ": "进入下一步条件",
        "shouGCF": "手工触发",
        "cenSPP": "参数匹配",
        "qingXZDXLX": "请选择读写类型",
        "ziJ": "字节",
        "laiY": "来源",
        "qingXZLY": "请选择来源",
        "qiYPZ": "其它配置",
        "qingSRZBPZ": "请输入指标配置",
        "zhiBPZ": "指标配置",
        "changJLDYMKYYZBPZZWCFTJ": "场景联动页面可引用指标配置作为触发条件",
        "baoC": "保存",
        "zhuX": "属性",
        "gongT": "功能",
        "biaoQ": "标签",
        "baoCAN": "保存按钮",
        "biaoSSCZ": "标识已存在",
        "qingPZMJX": "请配置枚举项",
        "qingSRYSPZ": "请输入元素配置",
        "peiZCS": "配置参数",
        "qingSR": "请输入",
        "qingXZ": "请选择",
        "qingXZWJLX": "请选择文件类型",
        "shanW": "单位",
        "jingZ": "精度",
        "qingSRJZ": "请输入精度",
        "shiJLS": "时间格式",
        "buEZ": "布尔值",
        "meiJX": "枚举项",
        "cuoTZZ": "最大长度",
        "qingSRCTZZ": "请输入最大长度",
        "yuanSPZ": "元素配置",
        "duiX": "对象",
        "wenJLX": "文件类型",
        "huanCSWSJ": "缓存单位数据",
        "jieGB": "结构体",
        "qingSRPZCS": "请输入配置参数",
        "dangQDCCFSBZCXC": "当前的存储方式不支持新增",
        "dangQDCCFSBZCBJ": "当前的存储方式不支持编辑",
        "puT": "普通",
        "jingG": "警告",
        "jinJ": "紧急",
        "di": "低",
        "tiao": "条",
        "zongH": "总共",
        "xiuGWMXHHTLCPWMX": "修改物模型后会脱离产品物模型",
        "zhaKWMX": "查看物模型",
        "daoC": "导出",
        "wuMXSDSBZYDDGTMS": "物模型是对设备在云端的功能描述",
        "paoKSBDZX": "包括设备的属性",
        "fuWHZJ": "服务和事件",
        "wuLWPSTGDYYCWDMSYYLMSWMX": "物联网平台通过定义一种物的描述语言来描述物模型",
        "chengZW": "称之为",
        "ji": "即",
        "caiY": "采用",
        "luoS": "格式",
        "ninKSGJ": "您可以根据",
        "zuZSBSBDSJ": "组装上报设备的数据",
        "ninKSDCWZWMX": "您可以导出完整物模型",
        "yongXYDYYKF": "用于云端应用开发",
        "wuMX": "物模型",
        "qingXPZWMX": "请先配置物模型",
        "daoRWMX": "导入物模型",
        "daoRDWMXHFGYLDZX": "导入的物模型会覆盖原来的属性",
        "qingJSCZ": "请谨慎操作",
        "daoRFS": "导入方式",
        "kaoBCP": "拷贝产品",
        "xuanZCP": "选择产品",
        "wuMXLX": "物模型类型",
        "aLYWMX": "阿里云物模型",
        "daoRLX": "导入类型",
        "wenJSZ": "文件上传",
        "jueB": "脚本",
        "zaiXBJQZBXWMXJB": "在线编辑器中编写物模型脚本",
        "qingXZDRFS": "请选择导入方式",
        "qingXZCP": "请选择产品",
        "qingXZWMXLX": "请选择物模型类型",
        "qingXZDRLX": "请选择导入类型",
        "qingSZWJ": "请上传文件",
        "qingSRWMX": "请输入物模型",
        "daoRCG": "导入成功",
        "wuMXSJBZQ": "物模型数据不正确",
        "shangZ": "上传",
        "luoSDWMXWJ": "格式的物模型文件",
        "gaiSBSTLCPWMX": "该设备已脱离产品物模型",
        "xiuGCPWMXDGSBMYX": "修改产品物模型对该设备无影响",
        "sheBHMRJCCPDWMX": "设备会默认继承产品的物模型",
        "xiuGSBWMXHQTLCPWMX": "修改设备物模型后将脱离产品物模型",
        "queRTZ": "确认重置",
        "tongZHQSYCPDWMXPZ": "重置后将使用产品的物模型配置",
        "tongZCZ": "重置操作",
        "kuaiSDR": "快速导入",
        "zhuXDY": "属性定义",
        "gongTDY": "功能定义",
        "ziJDY": "事件定义",
        "biaoQDY": "标签定义",
        "tongZWMX": "重置物模型",
        "gengXWMX": "更新物模型",
        "wuMXSJ": "物模型数据",
        "shuJGXHD": "数据更新回调",
        "gengXSJK": "更新数据库",
        "faSZJDCZ": "发送事件等操作",
        "weiCFWMXXG": "未触发物模型修改",
        "baoCWMXSJDFWQ": "保存物模型数据到服务器",
        "leiX": "类型",
        "shuJ": "数据",
        "shanSWMXSJ": "删除物模型数据",
        "shanSDSJ": "删除的数据",
        "chanPSJ": "产品数据",
        "huiD": "回调",
        "tuBSJ": "图表数据",
        "huiZTB": "绘制图表",
        "xianZKQAN": "显示快捷按钮",
        "kuaiQANLB": "快捷按钮列表",
        "jinR": "进入",
        "jinYZ": "近一周",
        "jinYR": "近一月",
        "jinYN": "近一年",
        "chanPSL": "产品数量",
        "sheBSL": "设备实例",
        "dangQZX": "当前在线",
        "jinRSBXXL": "今日设备消息量",
        "sheBXX": "设备信息",
        "sheBFB": "设备分布",
        "zhengC": "正常",
        "jinY": "禁用",
        "zaiX": "在线",
        "liX": "离线",
        "zuoRZX": "昨日在线",
        "dangRSBXXL": "当月设备消息量",
        "zuoR": "昨日",
        "huoQCPSL": "获取产品数量",
        "huoQSBSL": "获取设备数量",
        "huoQZXSL": "获取在线数量",
        "zaiXS": "在线数",
        "shiPPHQX": "是否平滑曲线",
        "guaiDTX": "拐点大小",
        "chuDYS": "处的颜色",
        "queXW": "缺省为",
        "xiaoXL": "消息量",
        "ru": "月",
        "ri": "日",
        "ning": "年",
        "zhengSX": "整数型",
        "zhangZSX": "长整数型",
        "shanJZFDX": "单精度浮点型",
        "shuangJZFDS": "双精度浮点数",
        "ziFC": "字符串",
        "buEX": "布尔型",
        "shiJX": "时间型",
        "meiJ": "枚举",
        "shuZ": "数组",
        "wenJ": "文件",
        "miM": "密码",
        "deLWZ": "地理位置",
        "lianX": "连接",
        "bianM": "编码",
        "erJZ": "二进制",
        "leiXD": "类型的",
        "shiJC": "时间戳",
        "haoM": "毫秒",
        "guJMC": "固件名称",
        "ruanJBB": "软件版本",
        "yaoQYJBB": "要求硬件版本",
        "banBXX": "版本序号",
        "guJLX": "固件类型",
        "suoZCP": "所属产品",
        "qianMFS": "签名方式",
        "chuangJSJ": "创建时间",
        "shengJLW": "升级任务",
        "buZCHBC": "不传参会报错",
        "zanSZXQYZTD": "暂时查询启用状态的",
        "houJHGZQB": "后期会改查全部",
        "shangZWJ": "上传文件",
        "shangZCG": "上传成功",
        "jiTSZ": "系统提示",
        "jiTWZCW": "系统未知错误",
        "qingFKGGLY": "请反馈给管理员",
        "queR": "确认",
        "qingXZSZCP": "请选择所属产品",
        "ruanJBBX": "软件版本号",
        "qingSRBBX": "请输入版本号",
        "yingJBBX": "硬件版本号",
        "qingSRYJBBX": "请输入硬件版本号",
        "qingSRBBXX": "请输入版本序号",
        "qingXZQMFS": "请选择签名方式",
        "qianM": "签名",
        "qingSRBDWJJXQMJMHDZ": "请输入本地文件进行签名加密后的值",
        "qingSRQM": "请输入签名",
        "guJSZ": "固件上传",
        "qiTPZ": "其他配置",
        "queRSSM": "确认删除吗",
        "tianJ": "添加",
        "qianMBYZ": "签名不一致",
        "qingJZWJSPSZZQ": "请检查文件是否上传正确",
        "banBXXSCZ": "版本序号已存在",
        "qingSRRJBBX": "请输入软件版本号",
        "queDPLTS": "确定批量重试",
        "piLTS": "批量重试",
        "dengDSJ": "等待升级",
        "shengJZ": "升级中",
        "shengJWC": "升级完成",
        "shengJYB": "升级失败",
        "siTZ": "已停止",
        "sheBMC": "设备名称",
        "wanCSJ": "完成时间",
        "jinZ": "进度",
        "zhuangT": "状态",
        "zhaK": "查看",
        "tongS": "重试",
        "queRTS": "确认重试",
        "yiBYY": "失败原因",
        "linWMC": "任务名称",
        "tuiSFS": "推送方式",
        "sheBLQ": "设备拉取",
        "pingSTS": "平台推送",
        "wanCBL": "完成比例",
        "kaiSSJ": "开始时间",
        "xiangQ": "详情",
        "linW": "任务",
        "qingSRLWMC": "请输入任务名称",
        "qingXZTSFS": "请选择推送方式",
        "xiangYCSSJ": "响应超时时间",
        "qingSRXYCSSJ": "请输入响应超时时间",
        "miao": "秒",
        "shengJCSSJ": "升级超时时间",
        "qingSRSJCSSJ": "请输入升级超时时间",
        "shengJSB": "升级设备",
        "suoYSB": "所有设备",
        "xuanZSB": "选择设备",
        "qingXZSB": "请选择设备",
        "quanX": "全选",
        "guJBB": "固件版本",
        "zhuCSJ": "注册时间",
        "bangDSB": "绑定设备",
        "bangDZSB": "绑定子设备",
        "qingXZRYBDDSB": "请选择需要绑定的设备",
        "dianWYS": "点位映射",
        "piLYS": "批量映射",
        "baoCBYY": "保存并应用",
        "caiJQ": "采集器",
        "bianYWGDLDZSWLSB": "边缘网关代理的真实物理设备",
        "qingXZCJQ": "请选择采集器",
        "qingXZDW": "请选择点位",
        "siBD": "已绑定",
        "weiBD": "未绑定",
        "jieB": "解绑",
        "queRJB": "确认解绑",
        "zanMSJ": "暂无数据",
        "qingPZWMX": "请配置物模型",
        "tongD": "通道",
        "dianW": "点位",
        "huoQTDLB": "获取通道列表",
        "jieBYS": "解绑映射",
        "xuanZJB": "选择解绑",
        "wangGSB": "网关设备",
        "baoCCG": "保存成功",
        "caiJQDDWMCYZXMCYZSQZDYSBD": "采集器的点位名称与属性名称一致时将自动映射绑定",
        "youDGCJQDWMCYZXMCYZSSD": "有多个采集器点位名称与属性名称一致时以第",
        "geCJQDDWSJJXBD": "个采集器的点位数据进行绑定",
        "yuanSJ": "源数据",
        "jiaRYZ": "加入右侧",
        "queDSS": "确定删除",
        "zanMDYZXDYS": "暂无对应属性的映射",
        "xinCBBD": "新增并绑定",
        "bangD": "绑定",
        "queDJBM": "确定解绑吗",
        "piLJS": "批量解除",
        "queRJBM": "确认解绑吗",
        "qingGXRYJBDSJ": "请勾选需要解绑的数据",
        "jiBXX": "基本信息",
        "fanH": "返回",
        "qingSRSBMC": "请输入设备名称",
        "chanPMC": "产品名称",
        "qingXZCPMC": "请选择产品名称",
        "huoQCPLB": "获取产品列表",
        "huoQYSSJ": "获取映射数据",
        "heBWMX": "合并物模型",
        "shanSWMX": "删除物模型",
        "shanSHJB": "删除后解绑",
        "genJCP": "根据产品",
        "huoQDYYSLB": "获取对应映射列表",
        "chanP": "产品",
        "bangDFSB": "绑定父设备",
        "shuJCJZPZDZSWLSB": "数据采集中配置的真实物理设备",
        "siZD": "已诊断",
        "ge": "个",
        "lianXZT": "连接状态",
        "xiaoXTX": "消息通信",
        "chuLSJBGXDQK": "处理数据不更新的情况",
        "quanWYZ": "权限验证",
        "jieM": "解码",
        "qingQ": "请求",
        "xiangY": "响应",
        "xiaXXX": "下行消息",
        "shangXXX": "上行消息",
        "cenSMC": "参数名称",
        "zhi": "值",
        "duQZX": "读取属性",
        "xiuGZX": "修改属性",
        "diaoYGT": "调用功能",
        "qingXZZX": "请选择属性",
        "qingSRZ": "请输入值",
        "qingXZGT": "请选择功能",
        "faS": "发送",
        "cenSLB": "参数列表",
        "qingSRCSLB": "请输入参数列表",
        "diaoS": "调试",
        "riZ": "日志",
        "shangXXXZDZ": "上行消息诊断中",
        "xiaXXXZDZ": "下行消息诊断中",
        "xiaXXXTXYC": "下行消息通信异常",
        "xiaXXXTXZC": "下行消息通信正常",
        "shangXXXTXYC": "上行消息通信异常",
        "shangXXXTXZC": "上行消息通信正常",
        "sheBZD": "设备诊断",
        "zhenDJY": "诊断建议",
        "suoYZDYMYCYSBRWSX": "所有诊断均无异常但设备仍未上线",
        "qingJZSXNY": "请检查以下内容",
        "lianXXX": "连接信息",
        "lianXDZ": "连接地址",
        "wangGXX": "网关信息",
        "fuSB": "父设备",
        "wangLXX": "网络信息",
        "wangLZJ": "网络组件",
        "zhenDWLZJPZSPZQ": "诊断网络组件配置是否正确",
        "peiZCWQDZSBLXYB": "配置错误将导致设备连接失败",
        "yiC": "异常",
        "wangLZJSJY": "网络组件已禁用",
        "qingX": "请先",
        "queRQY": "确认启用",
        "qiY": "启用",
        "qingJZFWQDKSPKF": "请检查服务器端口是否开放",
        "ruWKF": "如未开放",
        "qingKFHCSTXLX": "请开放后尝试重新连接",
        "qingJZFWQFHCL": "请检查服务器防火策略",
        "ruYKQFHQ": "如有开启防火墙",
        "qingGBFHQYDZFHQCLHTS": "请关闭防火墙或调整防火墙策略后重试",
        "qingQFSCW": "请求发生错误",
        "sheBBH": "设备不含",
        "sheBXRWG": "设备接入网关",
        "zhenDSBXRWGZTSPZC": "诊断设备接入网关状态是否正常",
        "wangGPZSPZQ": "网关配置是否正确",
        "jinYZTQDZLXYB": "禁用状态将导致连接失败",
        "keTCZYC": "可能存在异常",
        "qing": "请",
        "peiZ": "配置",
        "renGJZ": "人工检查",
        "wangGPZSPSCXZQ": "网关配置是否已填写正确",
        "ruoNQDGXMRZDK": "若您确定该项无需诊断可",
        "queRHL": "确认忽略",
        "huL": "忽略",
        "sheBXRWGSJY": "设备接入网关已禁用",
        "wangGFSB": "网关父设备",
        "zhenDWGFSBZTSPZC": "诊断网关父设备状态是否正常",
        "jinYYLXQDZLXYB": "禁用或离线将导致连接失败",
        "weiBDFSB": "未绑定父设备",
        "fuSBHTS": "父设备后重试",
        "wangGFSBSJY": "网关父设备已禁用",
        "wangGFSBSLX": "网关父设备已离线",
        "qingXBZWGSBGZ": "请先排查网关设备故障",
        "chanPZT": "产品状态",
        "zhenDCPZTSPZC": "诊断产品状态是否正常",
        "jinYZTQDZSBLXYB": "禁用状态将导致设备连接失败",
        "chanPSJY": "产品已禁用",
        "sheBZT": "设备状态",
        "zhenDSBZTSPZC": "诊断设备状态是否正常",
        "sheBSJY": "设备已禁用",
        "chanPRZPZ": "产品认证配置",
        "zhenDCP": "诊断产品",
        "renZPZSPZQ": "认证配置是否正确",
        "cuoWDPZQDZLXYB": "错误的配置将导致连接失败",
        "zhengZZDZ": "正在诊断中",
        "qingGJSBXRPZRY": "请根据设备接入配置需要",
        "chenX": "填写",
        "peiZSPSCXZQ": "配置是否已填写正确",
        "sheBRZPZ": "设备认证配置",
        "zhenDSB": "诊断设备",
        "shiPSPZ": "是否已配置",
        "weiPZQDZLXYB": "未配置将导致连接失败",
        "qingJZSBYXZTSPZC": "请检查设备运行状态是否正常",
        "qingJZSBWLSPCT": "请检查设备网络是否畅通",
        "bingQBSBSLXDSXDZZY": "并确保设备已连接到以下地址之一",
        "bingQBSBSLXD": "并确保设备已连接到",
        "qingGJ": "请根据",
        "sheBXRPZ": "设备接入配置",
        "zhong": "中",
        "xinX": "信息",
        "linYSBYTSJ": "任意上报一条数据",
        "qingLJGLYSG": "请联系管理员提供",
        "bingGJ": "并根据",
        "xinXLYSBYTSJ": "信息任意上报一条数据",
        "ruYSFYPSZDFSYTXXTZDBPS": "需要三方云平台主动发送一条消息通知到本平台",
        "chuFSBZTWZX": "触发设备状态为在线",
        "qingJZSFPSPZXSPCXZQ": "请检查三方平台配置项是否填写正确",
        "weiKF": "未开发",
        "lianXXQ": "连接详情",
        "yiJXF": "一键修复",
        "tongXZD": "重新诊断",
        "qingJZPZXSPCXZQ": "请检查配置项是否填写正确",
        "zhenDXSM": "诊断项说明",
        "xieKDZ": "接口地址",
        "tongZ": "重置",
        "yu": "域",
        "jiQ": "集群",
        "hongXPZ": "共享配置",
        "duWPZ": "独立配置",
        "deZ": "地址",
        "gongW": "公网",
        "jieD": "节点",
        "jieDMC": "节点名称",
        "quXG": "去修改",
        "queRMW": "确认无误",
        "zhenDGSBSZCPSPSPZ": "诊断该设备所属产品是否已配置",
        "sheBXR": "设备接入",
        "fangS": "方式",
        "weiPZQDZSBLXYB": "未配置将导致设备连接失败",
        "xiuG": "修改",
        "faXLXWT": "发现连接问题",
        "lianXZTZC": "连接状态正常",
        "qingCLLXYC": "请处理连接异常",
        "xianZKDSXXTX": "现在可调试消息通信",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "queRJY": "确认禁用",
        "zhiX": "执行",
        "qingK": "清空",
        "zhiXJL": "执行结果",
        "sheBGTSJCL": "设备功能数据处理",
        "genJSJLX": "根据数据类型",
        "fuCSZ": "赋初始值",
        "jingJMSXCSZZCSRKDFSLR": "精简模式下参数只支持输入框的方式录入",
        "qingAZ": "请按照",
        "luoSSR": "格式输入",
        "gaiZDWBCZD": "该字段为必填字段",
        "shuRLX": "输入类型",
        "qingPZDYCPD": "请配置对应产品的",
        "wuMXZXGT": "物模型属性功能",
        "jingJMS": "精简模式",
        "gaoJMS": "高级模式",
        "queRQYSB": "确认启用设备",
        "qiYSB": "启用设备",
        "queRDKLX": "确认断开连接",
        "duanKLX": "断开连接",
        "gaiSBDZXZTYFSB": "该设备的在线状态与父设备",
        "baoCYZ": "保持一致",
        "gaiSBZXZTYSBZSYXZTJD": "该设备在线状态由设备自身运行状态决定",
        "buJCFSB": "不继承父设备",
        "diZXZT": "的在线状态",
        "shiLXX": "实例信息",
        "yunXZT": "运行状态",
        "sheBGT": "设备功能",
        "riZGL": "日志管理",
        "shuJJS": "数据解析",
        "chanPLXWWGDQKXCXZCMK": "产品类型为网关的情况下才显示此模块",
        "ziSB": "子设备",
        "bianYDYS": "边缘端映射",
        "queRTXYYGPZ": "确认重新应用该配置",
        "yingYPZ": "应用配置",
        "xiuGPZHRTXYYHCTSX": "修改配置后需重新应用后才能生效",
        "queRHFMRPZ": "确认恢复默认配置",
        "huiFMR": "恢复默认",
        "gaiSBSDBJGPZXX": "该设备单独编辑过配置信息",
        "dianJCQHFCMRDPZXX": "点击此将恢复成默认的配置信息",
        "youXZ": "有效值",
        "huiFMRPZCG": "恢复默认配置成功",
        "bianJPZ": "编辑配置",
        "guanJXX": "关系信息",
        "guanLSBYQTYWDGLGJ": "管理设备与其他业务的关联关系",
        "guanJLYXGJPZ": "关系来源于关系配置",
        "bianJBQ": "编辑标签",
        "chenZ": "填值",
        "shanSZ": "删除值",
        "sheBLX": "设备类型",
        "lianXXY": "连接协议",
        "xiaoXXY": "消息协议",
        "cuoHSXSJ": "最后上线时间",
        "shiJ": "时间",
        "neiY": "内容",
        "xiangXXX": "详细信息",
        "dangQSJJSNYSTLCPYX": "当前数据解析内容已脱离产品影响",
        "houQJCCPSJJSNY": "后将继承产品数据解析内容",
        "dangQSJJSNYJCZCP": "当前数据解析内容继承自产品",
        "houQTLCPYX": "后将脱离产品影响",
        "jueBYY": "脚本语言",
        "qingDJSFXGZY": "请点击上方修改字样",
        "yongSBJJB": "用以编辑脚本",
        "muNSR": "模拟输入",
        "erJZSJS": "二进制数据以",
        "kaiTDSLJZSR": "开头的十六进制输入",
        "ziFCSJSRYSZFC": "字符串数据输入原始字符串",
        "yunXJL": "运行结果",
        "ruSRJBHMNSJHZDJ": "需输入脚本和模拟数据后再点击",
        "qingXDS": "请先调试",
        "jieMHS": "解码函数",
        "yuanSBW": "原始报文",
        "zhuanW": "转为",
        "shiTGCFFHQ": "时通过此方法获取",
        "shiQBL": "提取变量",
        "wenZZX": "温度属性",
        "shiZZX": "湿度属性",
        "huoQ": "获取",
        "huoQSBJSGZ": "获取设备解析规则",
        "baoCSBJSGZ": "保存设备解析规则",
        "guanB": "关闭",
        "qingSRZJMC": "请输入事件名称",
        "tongJZJ": "统计周期",
        "shiJZ": "实际值",
        "anFZTJ": "按分钟统计",
        "anXSTJ": "按小时统计",
        "anTTJ": "按天统计",
        "anZTJ": "按周统计",
        "anRTJ": "按月统计",
        "tongJGZ": "统计规则",
        "pingYZ": "平均值",
        "cuoTZ": "最大值",
        "cuoXZ": "最小值",
        "zongS": "总数",
        "liB": "列表",
        "tuB": "图表",
        "guiJ": "轨迹",
        "kaiSDH": "开始动画",
        "tingZDH": "停止动画",
        "ziDYZX": "自定义属性",
        "ziFCNYZC": "字符串内容转成",
        "chuFDJ": "触发点击",
        "yiS": "移除",
        "jieSSJ": "结束时间",
        "gengXSJ": "更新时间",
        "zhiB": "指标",
        "sheZZXZSB": "设置属性至设备",
        "huoQCXZXZ": "获取最新属性值",
        "bianJZB": "编辑指标",
        "changJLDYMKYYZBPZCFTJ": "场景联动页面可引用指标配置触发条件",
        "xuanZ": "选中",
        "shuR": "输入",
        "zhiBZ": "指标值",
        "dangSJLYWSBS": "当数据来源为设备时",
        "chenXDZQXFDSB": "填写的值将下发到设备",
        "gaiZDSBCZD": "该字段是必填字段",
        "yuMW": "域名为",
        "buZCFW": "不支持访问",
        "gaiTPMFFW": "该图片无法访问",
        "dangQFZCBF": "当前仅支持播放",
        "luoSDSP": "格式的视频",
        "xuanZSGCPSKDCQXZSBDXXSJ": "选择单个产品时可导出其下属设备的详细数据",
        "buXZCPSDCSYSBDJCSJ": "不选择产品时导出所有设备的基础数据",
        "wenJLS": "文件格式",
        "qingXZWJLS": "请选择文件格式",
        "daoR": "导入",
        "shangYB": "上一步",
        "xiaYB": "下一步",
        "buZ": "步骤",
        "zhiLSB": "直连设备",
        "wangGLX": "网关类型",
        "xieRFS": "接入方式",
        "wangGZSB": "网关子设备",
        "fenL": "分类",
        "suoZZZ": "所属组织",
        "quanB": "全部",
        "weiJH": "未激活",
        "chanPJL": "产品系列",
        "kaiQDY": "开启订阅",
        "siQYDSBBTSS": "已启用的设备不能删除",
        "piLDCSB": "批量导出设备",
        "piLDRSB": "批量导入设备",
        "qiYQBSB": "启用全部设备",
        "queRQYQBSB": "确认启用全部设备",
        "tongBSBZT": "同步设备状态",
        "piLSSSB": "批量删除设备",
        "siQYDSBMFSS": "已启用的设备无法删除",
        "queRSSXZDJYZTSB": "确认删除选中的禁用状态设备",
        "jiHXZSB": "激活选中设备",
        "queRJHXZSB": "确认激活选中设备",
        "piLJYSB": "批量禁用设备",
        "queRJYXZSB": "确认禁用选中设备",
        "zhaZXD": "查在线的",
        "zhaQB": "查全部",
        "tongB": "同步",
        "zhengZQYQBSB": "正在启用全部设备",
        "zhengZTBSBZT": "正在同步设备状态",
        "chengG": "成功",
        "qiYYB": "启用失败",
        "shiLXXYMZDPZXWWS": "实例信息页面中的配置项未完善",
        "wanC": "完成",
        "qingQTLTJCS": "请求头里添加参数",
        "weiSJS": "位随机数",
        "taiXXZWSZ": "大小写字母数字",
        "qiaHDSQ": "客户端时区",
        "qingSRYWYZSZYZ": "请输入英文或者数字或者",
        "yuZ": "或者",
        "cuoDSR": "最多输入",
        "ruoBCX": "若不填写",
        "jiTQZDSCWY": "系统将自动生成唯一",
        "zhiTXZ": "只能选择",
        "zhuangTDCP": "状态的产品",
        "qingXZZTW": "请选择状态为",
        "diCP": "的产品",
        "tongF": "重复",
        "huo": "和",
        "chuL": "处理",
        "chuLMWXH": "处理无限循环",
        "dangQBJD": "当前编辑的",
        "xiaoXZY": "消息指引",
        "qingSRXXZY": "请输入消息指引",
        "xiaoXSZ": "消息提示",
        "tuP": "图片",
        "qingSZTP": "请上传图片",
        "ziJLX": "事件类型",
        "qingXZZJLX": "请选择事件类型",
        "kaiSZJ": "开始事件",
        "jinXZZJ": "进行中事件",
        "jieSZJ": "结束事件",
        "caoZJL": "操作结果",
        "yiB": "失败",
        "qingXZCZJL": "请选择操作结果",
        "tanCXG": "弹窗相关",
        "xinCCZZY": "新增操作指引",
        "bianJCZZY": "编辑操作指引",
        "xuX": "序号",
        "gouX": "勾选",
        "peiZXX": "配置信息",
        "peiZXRFS": "配置接入方式",
        "bianJPZXX": "编辑配置信息",
        "qiH": "切换",
        "xiuGCGSX": "修改成功刷新",
        "huoQCPJSGZ": "获取产品解析规则",
        "baoCCPJSGZ": "保存产品解析规则",
        "xieY": "协议",
        "zhaXTJ": "查询条件",
        "shiJSBSJ": "提交设备数据",
        "fanHWBZJRYDSJ": "返回外部组件需要的数据",
        "qingXZXRFS": "请选择接入方式",
        "daKBQXC": "打开标签新增",
        "yongSSGSBXRTL": "用以提供设备接入能力",
        "qingLJGLYPZCPXRFS": "请联系管理员配置产品接入方式",
        "gengH": "更换",
        "xianZ": "显示",
        "wenJNY": "文件内容",
        "zanMLXXX": "暂无连接信息",
        "ciPZLZXCPXRFSSXZDXY": "此配置来自于产品接入方式所选择的协议",
        "cunCCL": "存储策略",
        "ruoXGCCCL": "若修改存储策略",
        "ruYSDZSJQY": "需要手动做数据迁移",
        "pingSZTXSCXCCCLZDSJ": "平台只能搜索最新存储策略中的数据",
        "wuMXCLFS": "物模型处理方式",
        "cunCSJ": "存储数据",
        "daKSBTC": "打开设备弹窗",
        "baoCYDYSJ": "保存引导页数据",
        "xianZYDY": "显示引导页",
        "woZDL": "我知道了",
        "buZSZ": "不再提示",
        "shangXX": "上下行",
        "zhiL": "示例",
        "huoQSXXSJ": "获取上下行数据",
        "shangX": "上行",
        "xiaX": "下行",
        "zhaXXRFS": "查询接入方式",
        "fenZ": "分组",
        "zhaXXYXX": "查询协议信息",
        "huoQXYLXMC": "获取协议类型名称",
        "zhaXBCSJXX": "查询保存数据信息",
        "liuZSMS": "流传输模式",
        "chuSW": "初始为",
        "muS": "模式",
        "qiHXRFSZHHQSPXZYD": "切换接入方式之后获取是否显示引导",
        "youSBXR": "有设备接入",
        "chanPYWMX": "产品有物模型",
        "sheBXRMY": "设备接入没有",
        "quCPWMX": "取产品物模型",
        "sheBXRYWMX": "设备接入有物模型",
        "chanPMY": "产品没有",
        "quSBXRDWMX": "取设备接入的物模型",
        "piZQKZFC": "否则取空字符串",
        "buTW": "不能为",
        "gengXXZSB": "更新选择设备",
        "gengXCPPZXX": "更新产品配置信息",
        "baoCSBXR": "保存设备接入",
        "gaiCPSPYWMX": "该产品是否有物模型",
        "youZTCJXCL": "有则弹窗进行处理",
        "erCQRSPFGWMX": "二次确认是否覆盖物模型",
        "tiaoZWMXBQ": "跳转物模型标签",
        "chanPXYSBSLSBTGHXRFS": "产品下有设备实例时不能更换接入方式",
        "tingYCPHCKGHXRFS": "停用产品后才可更换接入方式",
        "xuanZCLFS": "选择处理方式",
        "pingS": "平台",
        "zhongSYSJ": "中已有数据",
        "qingXZCLFS": "请选择处理方式",
        "moRCYFGDFSCLGT": "默认采用覆盖的方式处理功能",
        "biaoQXDSJ": "标签下的数据",
        "chuLFS": "处理方式",
        "quJJ": "取交集",
        "fuBLBSYZDZX": "仅保留标识一致的属性",
        "quBJ": "取并集",
        "baoLPS": "保留平台",
        "zhaJZDSYZX": "插件中的所有属性",
        "fuBLPSZDZX": "仅保留平台中的属性",
        "fuG": "覆盖",
        "fuBLZJZDZX": "仅保留插件中的属性",
        "chanPWMX": "产品物模型",
        "jiaoJ": "交集",
        "bingJ": "并且",
        "jiaoJCLHS": "交集处理函数",
        "zhiBLLZZJZDZX": "只保留来自插件中的属性",
        "zhaJWMX": "插件物模型",
        "bingJHSCL": "并集函数处理",
        "tongFS": "重复时",
        "zhiBLLZZJZD": "只保留来自插件中的",
        "tiaoZX": "条属性",
        "yinDYSJ": "引导页数据",
        "peiZWMX": "配置物模型",
        "peiZCPWMX": "配置产品物模型",
        "shiXSBZYDDGTMS": "实现设备在云端的功能描述",
        "qiYCP": "启用产品",
        "qiYCPH": "启用产品后",
        "keZCPXXCSB": "可在产品下新增设备",
        "tianJSB": "添加设备",
        "bingLXDPS": "并连接到平台",
        "chenXPZ": "填写配置",
        "chenXSBXRSRDPZCS": "填写设备接入所需的配置参数",
        "queDYYPZ": "确定应用配置",
        "qingXQYCP": "请先启用产品",
        "jinYCP": "禁用产品",
        "zhaXSBSL": "查询设备数量",
        "shiPXZSJJSMK": "是否显示数据解析模块",
        "xiangQYTZDSBY": "详情页跳转到设备页",
        "chanPBCCGHDSZK": "产品保存成功后的提示框",
        "chanPCJCG": "产品创建成功",
        "zhaKXQ": "查看详情",
        "xieXLTJCZ": "接下来推荐操作",
        "peiZCPXRFS": "配置产品接入方式",
        "dianJJBCPDZKAN": "点击具体产品的查看按钮",
        "ye": "页",
        "bingCZSBMPSMXZPPDXRFS": "并参照设备铭牌说明选择匹配的接入方式",
        "tianJCSSB": "添加测试设备",
        "jinRSBLB": "进入设备列表",
        "tianJSGSB": "添加单个设备",
        "yongXYZCPMXSPPZZQ": "用于验证产品模型是否配置正确",
        "gongTDS": "功能调试",
        "dianJZKJBSB": "点击查看具体设备",
        "duiTJDCSSBJXGTDS": "对添加的测试设备进行功能调试",
        "yanZTPLXDPS": "验证能否连接到平台",
        "sheBGTSPPZZQ": "设备功能是否配置正确",
        "piLTJSB": "批量添加设备",
        "jinRSBLBYM": "进入设备列表页面",
        "dianJPLDRSB": "点击批量导入设备",
        "piLTJTYCPXDSB": "批量添加同一产品下的设备",
        "tanCGB": "弹窗关闭",
        "weiXR": "未接入",
        "biaoLSJ": "表格数据",
        "siQYDCPBTSS": "已启用的产品不能删除",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "sheZDRDCPZTWWFB": "设置导入的产品状态为未发布",
        "shuaXSJ": "刷新数据",
        "jiangZZTYXNXZSYWB": "强制在同一行内显示所有文本",
        "zhiDWBJSYZZY": "直到文本结束或者遭遇",
        "biaoQDXCHX": "标签对象才换行",
        "chaoCBFYZ": "超出部分隐藏",
        "yinZBFSXLXDT": "隐藏部分以省略号代替",
        "bianJCP": "编辑产品",
        "qingXZCPFL": "请选择产品分类",
        "sheBBDCZZY": "设备绑定操作指引",
        "sheBBDZYTP": "设备开门图片",
        "miMYSSL": "密码钥匙数量",
        "qingSRMMYSSL": "请输入密码钥匙数量",
        "zhiWYSSL": "指纹钥匙数量",
        "qingSRZWYSSLSL": "请输入指纹钥匙数量数量",
        "yueSSL": "钥匙数量",
        "jianWSL": "键位数量",
        "qingSRJWSLSL": "请输入键位数量数量",
        "zhiLWLWPSDSB": "直连物联网平台的设备",
        "taiGZZSBYPSJXTXDSB": "能挂载子设备与平台进行通信的设备",
        "zuoWWGDZSB": "作为网关的子设备",
        "youWGDLLXDWLWPS": "有网关代理连接到物联网平台",
        "jiaoY": "校验",
        "jiaoYSPXZSBLX": "校验是否选择设备类型",
        "qingXZSBLX": "请选择设备类型",
        "weiZF": "位字符",
        "zhaXCPFL": "查询产品分类",
        "chuLCPFL": "处理产品分类",
        "shiJBSSJ": "提交表单数据",
        "miMYSSLBTWK": "密码钥匙数量不能为空",
        "zhiWYSSLBTWK": "指纹钥匙数量不能为空",
        "yueSSLBTWK": "钥匙数量不能为空",
        "jianWSLBTWK": "键位数量不能为空",
        "zhiYXX": "指引信息",
        "chanPFLBXZKZFC": "产品分类不选传空字符串",
        "yunXZTCRYSJ": "运行状态处需要数据",
        "buM": "部门",
        "sheBXQ": "设备详情",
        "ceS": "测试",
        "dianL": "电量",
        "buZX": "不在线",
        "menZT": "门状态",
        "kaiQ": "开启",
        "wenZ": "温度",
        "shiZ": "湿度",
        "liangZ": "亮度",
        "yinL": "音量",
        "guan": "关",
        "gao": "高",
        "kaiS": "开锁",
        "kai": "开",
        "sheBGL": "设备管理",
        "yueSGL": "钥匙管理",
        "fenXSB": "分享设备",
        "shengJ": "升级",
        "fenXGL": "分享管理",
        "sheBSZ": "设备设置",
        "sheBSJ": "设备时间",
        "jieSPZ": "解锁配置",
        "baoJSZ": "报警设置",
        "fangJCSZ": "防劫持设置",
        "dingYCS": "订阅超时",
        "huoQYTLSJL": "获取一条历史记录",
        "xieZXDSB": "写属性到设备",
        "zhuXZ": "属性值",
        "juBCSCZWMX": "具体参数参照物模型",
        "xuanZFS": "选择方式",
        "qingXZFS": "请选择方式",
        "guanJ": "关系",
        "qingXZGJ": "请选择关系",
        "qingXZBQ": "请选择标签",
        "bianL": "变量",
        "shouCCZBQSJ": "首次操作标签数据",
        "huiXWT": "回显问题",
        "ziDY": "自定义",
        "ziDYXZDQCPXDLYSB": "自定义选择当前产品下的任意设备",
        "anGJ": "按关系",
        "xuanZYCFSBJYXTGJDSB": "选择与触发设备具有相同关系的设备",
        "anBQ": "按标签",
        "anBQXZCPXJYTDBQDSB": "按标签选择产品下具有特定标签的设备",
        "anBL": "按变量",
        "weiSLBLZDSB": "为上游变量值的设备",
        "panDXTCPCYABL": "判断相同产品才有按变量",
        "qiHSB": "切换设备",
        "bianLXZ": "变量选择",
        "wei": "为",
        "biaoQXZ": "标签选择",
        "ziCFP": "资产分配",
        "piLCZ": "批量操作",
        "shiPPLJSBD": "是否批量解除绑定",
        "piLJB": "批量解绑",
        "piLBJ": "批量编辑",
        "ziCQW": "资产权限",
        "shiPJSBD": "是否解除绑定",
        "jieSBD": "解除绑定",
        "huoQQWSJZD": "获取权限数据字典",
        "huoQQWMC": "获取权限名称",
        "quXQX": "取消全选",
        "quNSJ": "去掉数据",
        "huoQBZLSJ": "获取并整理数据",
        "zhengLCSBHQSJ": "整理参数并获取数据",
        "sheBZCFPTCCZLX": "设备资产分配弹窗操作类型",
        "shouDDJZCFPAN": "手动点击资产分配按钮",
        "chanPZCFPH": "产品资产分配后",
        "ziDTCSBZCFP": "自动弹出设备资产分配",
        "qingGXRYBJDSJ": "请勾选需要编辑的数据",
        "tag": "标签",
        "batchEnable": "批量启用",
        "state": "状态",
        "qingXZZT": "请选择状态"
    },
    "Log": {
        "yhdl": "用户登录",
        "htdl": "后台登录",
        "lianX": "连接",
        "quanWYZ": "权限验证",
        "jieM": "解码",
        "bianM": "编码",
        "qingQ": "请求",
        "xiangY": "响应",
        "xiaXXX": "下行消息",
        "shangXXX": "上行消息",
        "leiX": "类型",
        "shiJ": "时间",
        "neiY": "内容",
        "caoZ": "操作",
        "zhaK": "查看",
        "xiangXXX": "详细信息",
        "xiangQ": "详情",
        "qingQFF": "请求方法",
        "dongZ": "动作",
        "leiM": "类名",
        "fangFM": "方法名",
        "qingQSJ": "请求时间",
        "qingQHS": "请求耗时",
        "qingQT": "请求头",
        "qingQCS": "请求参数",
        "yiCXX": "异常信息",
        "zanMSJ": "暂无数据",
        "guanB": "关闭",
        "qingQLJ": "请求路径",
        "shuiM": "说明",
        "qingQYH": "请求用户",
        "xiaoS": "搜索",
        "fangWRZ": "访问日志",
        "jiTRZ": "系统日志",
        "mingC": "名称",
        "riZJB": "日志级别",
        "riZNY": "日志内容",
        "fuWM": "服务名",
        "chuangJSJ": "创建时间",
        "sheBRZ": "设备日志",
        "weiSBRZ": "为设备日志",
        "weiGJRZ": "为告警日志",
        "zhaXCS": "查询参数",
        "xuX": "序号",
        "sheBMC": "设备名称",
        "caoZLX": "操作类型",
        "caoZSJ": "操作时间",
        "caoZR": "操作人",
        "gaoJNY": "告警内容",
        "gaoJSJ": "告警时间",
        "tongZJL": "通知记录",
        "faSSJ": "发送时间",
        "zhuangT": "状态",
        "chengG": "成功",
        "yiB": "失败",
        "zhaKCWXX": "查看错误信息",
        "cuoWXX": "错误信息",
        "zhaKXQ": "查看详情",
        "xiangQXX": "详情信息",
        "gaoJMC": "告警名称",
        "dangQGJJLXX": "当前告警记录信息",
        "gaoJJLDXQ": "告警记录的详情",
        "huoQXQLB": "获取详情列表",
        "genJ": "根据",
        "chuSHSJ": "初始化数据",
        "gaoJSB": "告警设备",
        "guanBMTTC": "关闭模态弹窗",
        "queD": "确定",
        "quX": "取消",
        "sheB": "设备",
        "gaoJJB": "告警级别",
        "gaoJSM": "告警说明",
        "gaoJLS": "告警流水",
        "quanB": "全部",
        "chanP": "产品",
        "zuZ": "组织",
        "qiT": "其他",
        "jiLLB": "记录列表",
        "chuLSJ": "处理时间",
        "chuLLX": "处理类型",
        "jiT": "系统",
        "renG": "人工",
        "gaoJCL": "告警处理",
        "guanBTC": "关闭弹窗",
        "chuLJL": "处理记录",
        "qingSRCLJL": "请输入处理结果",
        "caoZCG": "操作成功",
        "caoZYB": "操作失败",
        "cuoJGJSJ": "最近告警时间",
        "gaoJZ": "告警中",
        "moGJ": "无告警",
        "chanPMC": "产品名称",
        "zuZMC": "组织名称",
        "chuLZHD": "处理之后的",
        "mo": "无",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "gaoJRZ": "告警日志",
        "guanBGJRZ": "关闭告警日志",
        "guanBCLJL": "关闭处理记录",
        "beiA": "备案",
        "yu": "渝",
        "bei": "备",
        "xiao": "号",
        "yuY": "语言",
        "zhongW": "中文",
        "yingW": "英文",
        "zhangX": "账号",
        "qingSRZX": "请输入账号",
        "miM": "密码",
        "qingSRMM": "请输入密码",
        "yanZM": "验证码",
        "qingSRYZM": "请输入验证码",
        "jiZW": "记住我",
        "deL": "登录",
        "meiYZX": "没有账号",
        "qingN": "请您",
        "xiaZ": "下载",
        "zhuC": "注册",
        "qiuDQXLZKSHTPJT": "团队全新力作可视化大屏系统",
        "benY": "体验",
        "xianZ": "显示",
        "diXZEWM": "的下载二维码",
        "huanYSY": "欢迎使用",
        "erWM": "二维码",
        "quDLLQD": "取得浏览器的",
        "ziFC": "字符串",
        "panDSP": "判断是否",
        "liuLQ": "浏览器",
        "chuangJYG": "创建一个",
        "shiL": "实例",
        "jiaMFF": "加密方法",
        "sheZGY": "设置公钥",
        "jiaM": "加密",
        "jiaMHDSJ": "加密后的数据"
    },
    "edge": {
        "xinC": "新增",
        "daoR": "导入",
        "sheBLX": "设备类型",
        "chanPMC": "产品名称",
        "sheBMC": "设备名称",
        "zhuCSJ": "注册时间",
        "zhuangT": "状态",
        "jinY": "禁用",
        "liX": "离线",
        "zaiX": "在线",
        "chanPFL": "产品分类",
        "xieRFS": "接入方式",
        "zhiLSB": "直连设备",
        "wangGZSB": "网关子设备",
        "wangGSB": "网关设备",
        "shuiM": "说明",
        "caoZ": "操作",
        "zhaK": "查看",
        "bianJ": "编辑",
        "qiY": "启用",
        "queR": "确认",
        "caoZCG": "操作成功",
        "caoZYB": "操作失败",
        "yuanCQZ": "远程控制",
        "tongZMM": "重置密码",
        "queRTZMMW": "确认重置密码为",
        "shanS": "删除",
        "siQYDSBBTSS": "已启用的设备不能删除",
        "queRSS": "确认删除",
        "qiT": "其他",
        "qingSRYWYZSZYZ": "请输入英文或者数字或者",
        "yuZ": "或者",
        "cuoDSR": "最多输入",
        "geZF": "个字符",
        "ruoBCX": "若不填写",
        "jiTQZDSCWY": "系统将自动生成唯一",
        "qingSR": "请输入",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "qingXZSZCP": "请选择所属产品",
        "suoZCP": "所属产品",
        "zhiTXZ": "只能选择",
        "zhengC": "正常",
        "zhuangTDCP": "状态的产品",
        "qingSRSM": "请输入说明",
        "tongF": "重复",
        "tongXXY": "通信协议",
        "suoZBYWG": "所属边缘网关",
        "xieR": "接入",
        "chuangJSJ": "创建时间",
        "xiaF": "下发",
        "qingXQY": "请先启用",
        "zaiXF": "再下发",
        "qingXJY": "请先禁用",
        "zaiSS": "再删除",
        "xiaFSB": "下发设备",
        "liXSBMFJXSBMBXF": "离线设备无法进行设备模板下发",
        "qingXZSB": "请选择设备",
        "xiaFJL": "下发结果",
        "chengG": "成功",
        "yiB": "失败",
        "xiaFYBYY": "下发失败原因",
        "xiaZ": "下载",
        "xiaFSBSL": "下发设备数量",
        "siXFSL": "已下发数量",
        "chuangJYZDKXZLX": "创建隐藏的可下载链接",
        "ziFCNYZC": "字符串内容转成",
        "deZ": "地址",
        "chuFDJ": "触发点击",
        "yiS": "移除"
    },
    "home": {
        "jiCTJ": "基础统计",
        "xiangQ": "详情",
        "shiYL": "使用率",
        "neiC": "内存",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "wuLWYD": "物联网引导",
        "yunWYD": "运维引导",
        "sheBXRTJBZ": "设备接入推荐步骤",
        "buTDSBYWTXXYDBT": "不同的设备因为通信协议的不同",
        "cunZXRBZDCY": "存在接入步骤的差异",
        "yunWGLTJBZ": "运维管理推荐步骤",
        "qingGJYWRYDXSBZJXXZXCZ": "请根据业务需要对下述步骤进行选择性操作",
        "anNQWQZ": "按钮权限控制",
        "shuJ": "数据",
        "chuangJCP": "创建产品",
        "chuangJSB": "创建设备",
        "guiZYQ": "规则引擎",
        "chanPSSBDJH": "产品是设备的集合",
        "tongCZYZJYXTGTDSB": "通常指一组具有相同功能的设备",
        "wuLSBBXTGCPJXXRFSPZ": "物联设备必须通过产品进行接入方式配置",
        "peiZCPXRFS": "配置产品接入方式",
        "tongGCPDTYLXDSBJXTYDXRFSPZ": "通过产品对同一类型的设备进行统一的接入方式配置",
        "qingCZSBMPSMXZPPDXRFS": "请参照设备铭牌说明选择匹配的接入方式",
        "tianJCSSB": "添加测试设备",
        "tianJSGSB": "添加单个设备",
        "yongXYZCPMXSPPZZQ": "用于验证产品模型是否配置正确",
        "gongTDS": "功能调试",
        "duiTJDCSSBJXGTDS": "对添加的测试设备进行功能调试",
        "yanZTPLXDPS": "验证能否连接到平台",
        "sheBGTSPPZZQ": "设备功能是否配置正确",
        "piLTJSB": "批量添加设备",
        "piLTJTYCPXDSB": "批量添加同一产品下的设备",
        "yunWGLYD": "运维管理引导",
        "sheBXRPZ": "设备接入配置",
        "riZBZ": "日志排查",
        "shiSJQ": "实时监控",
        "xieYGL": "协议管理",
        "genJYWRQZDYKFDYDCP": "根据业务需求自定义开发对应的产品",
        "sheBMX": "设备模型",
        "xieRXY": "接入协议",
        "bingSZDPS": "并上传到平台",
        "zhengSGL": "证书管理",
        "tongYWHPSNDZS": "统一维护平台内的证书",
        "yongXSJTXJM": "用于数据通信加密",
        "wangLZJ": "网络组件",
        "genJBTDZSLXPZPSDCWLZJXGCS": "根据不同的传输类型配置平台底层网络组件相关参数",
        "sheBXRWG": "设备接入网关",
        "genJBTDZSLX": "根据不同的传输类型",
        "guanLXXXY": "关联消息协议",
        "peiZSBXRWGXGCS": "配置设备接入网关相关参数",
        "riZGL": "日志管理",
        "jianQJTRZ": "监控系统日志",
        "jiSCLJTYC": "及时处理系统异常",
        "sheBTJ": "设备统计",
        "chanPSL": "产品数量",
        "sheBSL": "设备数量",
        "youCPCSQWZHQSJ": "有产品菜单权限则获取数据",
        "youSBCSQWZHQSJ": "有设备菜单权限则获取数据",
        "xuanZSB": "选择设备",
        "tanCQZ": "弹窗控制",
        "qingXZSB": "请选择设备",
        "sheB": "设备",
        "sheBMC": "设备名称",
        "chanPMC": "产品名称",
        "zhuCSJ": "注册时间",
        "zhuangT": "状态",
        "zaiX": "在线",
        "liX": "离线",
        "xuanZCP": "选择产品",
        "chanP": "产品",
        "gaiZDSBCZD": "该字段是必填字段",
        "qingXZSYST": "请选择首页视图",
        "queD": "确定",
        "chaoGMRZHGLST": "超管默认综合管理视图",
        "pingSJGT": "平台架构图",
        "tiaoZYM": "跳转页面",
        "jiBXX": "基本信息",
        "huoQXZDST": "获取选择的视图",
        "panDSPS": "判断是否是",
        "yongH": "用户",
        "buSZHQXZDST": "不是则获取选中的视图",
        "tuiJBZMX": "推荐步骤每项",
        "chanPLBLDMX": "产品列表里的每项",
        "jiCNY": "基础内容",
        "jiTMC": "系统名称",
        "qingSRJTMC": "请输入系统名称",
        "zhuTS": "主题色",
        "boS": "白色",
        "heiS": "黑色",
        "gaoD": "高德",
        "peiZHPSKDYGDDT": "配置后平台可调用高德地图",
        "fuW": "服务",
        "qingSRGD": "请输入高德",
        "jiTHSFWD": "系统后台访问的",
        "qingSR": "请输入",
        "jiT": "系统",
        "dianJXG": "点击修改",
        "tuiJCC": "推荐尺寸",
        "zhiC": "支持",
        "liuLQYQ": "浏览器页签",
        "liuLQ": "浏览器",
        "yeZXZDTPYS": "页中显示的图片元素",
        "luoS": "格式",
        "deLBJT": "登录背景图",
        "siNDTP": "以内的图片",
        "jianYCC": "建议尺寸",
        "biaoSSJ": "表单数据",
        "cuoDKSR": "最多可输入",
        "wei": "位",
        "qingXZZTS": "请选择主题色",
        "shiJSJ": "提交数据",
        "luoSJY": "格式校验",
        "qingSZ": "请上传",
        "luoSDTP": "格式的图片",
        "tuPTXBXXX": "图片大小必须小于",
        "shangZGBZJ": "上传改变事件",
        "liuLQYQSZZQ": "浏览器页签上传之前",
        "liuLQYQFSGB": "浏览器页签发生改变",
        "beiJTPSZZQ": "背景图片上传之前",
        "beiJTPFSGB": "背景图片发生改变",
        "gunDZ": "滚动槽",
        "gunDTHK": "滚动条滑块",
        "buZC": "不支持",
        "geRZX": "个人中心",
        "xiuGMM": "修改密码",
        "tongZDY": "通知订阅",
        "zhaK": "查看",
        "wuLW": "物联网",
        "shouY": "首页",
        "tongZGL": "通知管理",
        "tongZPZ": "通知配置",
        "tongBYH": "同步用户",
        "tongZJL": "通知记录",
        "diaoS": "调试",
        "daoC": "导出",
        "daoR": "导入",
        "shanS": "删除",
        "bianJ": "编辑",
        "xinC": "新增",
        "tongZMB": "通知模板",
        "sheBGL": "设备管理",
        "yiBP": "仪表盘",
        "qi": "启",
        "jinY": "禁用",
        "chanPFL": "产品分类",
        "yunWGL": "运维管理",
        "liuMBFW": "流媒体服务",
        "yuanCSJ": "远程升级",
        "jianXZC": "间接支持",
        "shuJCJ": "数据采集",
        "tongDGL": "通道管理",
        "qiY": "启用",
        "caiJQ": "采集器",
        "gaoJZX": "告警中心",
        "jiCPZ": "基础配置",
        "baoC": "保存",
        "gaoJPZ": "告警配置",
        "shouDCF": "手动触发",
        "gaoJJL": "告警记录",
        "gaoJCL": "告警处理",
        "beiXSC": "北向输出",
        "aLY": "阿里云",
        "guiZBB": "规则编排",
        "changJLD": "场景联动",
        "bianYWG": "边缘网关",
        "wangGSB": "网关设备",
        "yuanCQZ": "远程控制",
        "tongZMM": "重置密码",
        "ziYK": "资源库",
        "xiaF": "下发",
        "shiPZX": "视频中心",
        "shiPSB": "视频设备",
        "fenPZZ": "分屏展示",
        "guoBJL": "国标级联",
        "tuiS": "推送",
        "xuanZTD": "选择通道",
        "jiTGL": "系统管理",
        "yongHGL": "用户管理",
        "zuZGL": "组织管理",
        "ziCJB": "资产解绑",
        "bangDYH": "绑定用户",
        "ziCFP": "资产分配",
        "jiaoSGL": "角色管理",
        "caiSGL": "菜单管理",
        "quanWGL": "权限管理",
        "guanJPZ": "关系配置",
        "shuJYGL": "数据源管理",
        "guanL": "管理",
        "peiZ": "配置",
        "yingYGL": "应用管理",
        "wuLQ": "物联卡",
        "wuLQGL": "物联卡管理",
        "tongB": "同步",
        "jiH": "激活",
        "bangD": "绑定",
        "chongZGL": "充值管理",
        "chongZ": "充值",
        "pingSXR": "平台接入",
        "caoZJL": "操作记录",
        "chuSHSJSJBS": "初始化数据提交表单",
        "benDDZ": "本地地址",
        "benDDK": "本地端口",
        "gongWDZ": "公网地址",
        "gongWDK": "公网端口",
        "jiBXXBS": "基本信息表单",
        "deLBJ": "登录背景",
        "tuPSZBS": "图片上传表单",
        "neiZJSSJ": "内置角色数据",
        "sheBXRG": "设备接入岗",
        "gaiJSFZSBXRMKDWHGL": "该角色负责设备接入模块的维护管理",
        "zhengC": "正常",
        "yunWGLG": "运维管理岗",
        "gaiJSFZJTYWMKDWHGL": "该角色负责系统运维模块的维护管理",
        "zongHGLG": "综合管理岗",
        "gaiJSFZJTYWHSBXRMKDWHGL": "该角色负责系统运维和设备接入模块的维护管理",
        "quanBSJ": "全部数据",
        "ziQCJD": "自己创建的",
        "suoZZZ": "所在组织",
        "suoZZZJXJZZ": "所在组织及下级组织",
        "ciCSZCSJQWQZ": "此菜单支持数据权限控制",
        "ciCSBZCSJQWQZ": "此菜单不支持数据权限控制",
        "jiTCSH": "系统初始化",
        "peiZPSMC": "配置平台名称",
        "zhuTSDJBXX": "主题色等基本信息",
        "caiSCSH": "菜单初始化",
        "chuSHCSSJ": "初始化菜单数据",
        "jiaoSCSH": "角色初始化",
        "chuSHNZJSYQWSJ": "初始化内置角色与权限数据",
        "chuSHSJ": "初始化数据",
        "chuSHSBXRZLSJ": "初始化设备接入示例数据",
        "moRDKDYGCSCS": "默认打开第一个初始菜单",
        "shiJJCBS": "提交基础表单",
        "tiaoZSY": "跳转首页",
        "shiJSYSJ": "提交所有数据",
        "dangQSJSPCGSJ": "当前数据是否成功提交",
        "baoCCG": "保存成功",
        "jiLCSHSJ": "记录初始化数据",
        "panDSPSYPZ": "判断是否已有配置",
        "chuSSJSJBS": "初始数据提交表单",
        "chuSSJ": "初始数据",
        "quX": "取消",
        "chuSHSJPK": "初始化数据包括",
        "leiXSBXRWG": "类型设备接入网关",
        "guanFXY": "官方协议",
        "bangDDFWQSDWQDZ": "绑定到服务器上的网卡地址",
        "bangDDSYWQ": "绑定到所有网卡",
        "duiWSGFWDDZNWHJSCXFWQDNW": "对外提供访问的地址内网环境时填写服务器的内网",
        "deZ": "地址",
        "qingSRGWDZ": "请输入公网地址",
        "jianYZDDKDQQ": "监听指定端口的请求",
        "qingXZBDDK": "请选择本地端口",
        "duiWSGFWDDK": "对外提供访问的端口",
        "qingSRGWDK": "请输入公网端口",
        "chuSHSJZT": "初始化数据状态",
        "chuSHTCBSSJ": "初始化弹窗表单数据",
        "jiaoYGWDZ": "校验官网地址",
        "qingSRZQDGWDZ": "请输入正确的公网地址",
        "jiaoYSZ": "校验数字",
        "diZZS": "的正整数",
        "qingXZBDDZ": "请选择本地地址",
        "chuSSJTCDJZJ": "初始数据弹窗点击事件",
        "biaoSQXZJ": "表单取消事件",
        "shiJCSHSJ": "提交初始化数据",
        "zhaXDKSJ": "查询端口数据",
        "shiJCSSJBS": "提交初始数据表单",
        "xinCWLZJ": "新增网络组件",
        "baoCXY": "保存协议",
        "xinCSBXRWG": "新增设备接入网关",
        "xinCCP": "新增产品",
        "xinCSB": "新增设备",
        "chuSH": "初始化",
        "geCS": "个菜单",
        "chuSHHDCSKZ": "初始化后的菜单可在",
        "yeMJXWHGL": "页面进行维护管理",
        "huoQCSSJ": "获取菜单数据",
        "huoQDQJTQWXX": "获取当前系统权限信息",
        "guoLCS": "过滤菜单",
        "jiSCSSL": "计算菜单数量",
        "chuSHCS": "初始化菜单",
        "yongHZX": "用户中心",
        "jiaoSGXSJ": "角色勾选数据",
        "huoQJSXZSJ": "获取角色选择数据",
        "genJCSZJS": "根据菜单找角色",
        "tianJGJS": "添加该角色",
        "gengXQW": "更新权限",
        "baoCJS": "保存角色"
    },
    "iframe": {},
    "init-home": {
        "jiCNY": "基础内容",
        "jiTMC": "系统名称",
        "qingSRJTMC": "请输入系统名称",
        "zhuTS": "主题色",
        "boS": "白色",
        "heiS": "黑色",
        "gaoD": "高德",
        "peiZHPSKDYGDDT": "配置后平台可调用高德地图",
        "fuW": "服务",
        "qingSRGD": "请输入高德",
        "jiTHSFWD": "系统后台访问的",
        "qingSR": "请输入",
        "jiT": "系统",
        "dianJXG": "点击修改",
        "tuiJCC": "推荐尺寸",
        "zhiC": "支持",
        "liuLQYQ": "浏览器页签",
        "liuLQ": "浏览器",
        "yeZXZDTPYS": "页中显示的图片元素",
        "luoS": "格式",
        "deLBJT": "登录背景图",
        "siNDTP": "以内的图片",
        "jianYCC": "建议尺寸",
        "biaoSSJ": "表单数据",
        "cuoDKSR": "最多可输入",
        "wei": "位",
        "qingXZZTS": "请选择主题色",
        "shiJSJ": "提交数据",
        "luoSJY": "格式校验",
        "qingSZ": "请上传",
        "luoSDTP": "格式的图片",
        "tuPTXBXXX": "图片大小必须小于",
        "shangZGBZJ": "上传改变事件",
        "liuLQYQSZZQ": "浏览器页签上传之前",
        "liuLQYQFSGB": "浏览器页签发生改变",
        "beiJTPSZZQ": "背景图片上传之前",
        "beiJTPFSGB": "背景图片发生改变",
        "gunDZ": "滚动槽",
        "gunDTHK": "滚动条滑块",
        "buZC": "不支持",
        "geRZX": "个人中心",
        "xiuGMM": "修改密码",
        "tongZDY": "通知订阅",
        "zhaK": "查看",
        "wuLW": "物联网",
        "shouY": "首页",
        "tongZGL": "通知管理",
        "tongZPZ": "通知配置",
        "tongBYH": "同步用户",
        "tongZJL": "通知记录",
        "diaoS": "调试",
        "daoC": "导出",
        "daoR": "导入",
        "shanS": "删除",
        "bianJ": "编辑",
        "xinC": "新增",
        "tongZMB": "通知模板",
        "sheBGL": "设备管理",
        "yiBP": "仪表盘",
        "chanP": "产品",
        "qi": "启",
        "jinY": "禁用",
        "sheB": "设备",
        "chanPFL": "产品分类",
        "yunWGL": "运维管理",
        "sheBXRWG": "设备接入网关",
        "xieYGL": "协议管理",
        "riZGL": "日志管理",
        "wangLZJ": "网络组件",
        "zhengSGL": "证书管理",
        "liuMBFW": "流媒体服务",
        "yuanCSJ": "远程升级",
        "jianXZC": "间接支持",
        "shuJCJ": "数据采集",
        "tongDGL": "通道管理",
        "qiY": "启用",
        "caiJQ": "采集器",
        "gaoJZX": "告警中心",
        "jiCPZ": "基础配置",
        "baoC": "保存",
        "gaoJPZ": "告警配置",
        "shouDCF": "手动触发",
        "gaoJJL": "告警记录",
        "gaoJCL": "告警处理",
        "beiXSC": "北向输出",
        "aLY": "阿里云",
        "guiZYQ": "规则引擎",
        "guiZBB": "规则编排",
        "changJLD": "场景联动",
        "bianYWG": "边缘网关",
        "wangGSB": "网关设备",
        "yuanCQZ": "远程控制",
        "tongZMM": "重置密码",
        "ziYK": "资源库",
        "xiaF": "下发",
        "shiPZX": "视频中心",
        "shiPSB": "视频设备",
        "fenPZZ": "分屏展示",
        "guoBJL": "国标级联",
        "tuiS": "推送",
        "xuanZTD": "选择通道",
        "jiTGL": "系统管理",
        "yongHGL": "用户管理",
        "zuZGL": "组织管理",
        "ziCJB": "资产解绑",
        "bangDYH": "绑定用户",
        "ziCFP": "资产分配",
        "jiaoSGL": "角色管理",
        "caiSGL": "菜单管理",
        "quanWGL": "权限管理",
        "guanJPZ": "关系配置",
        "shuJYGL": "数据源管理",
        "guanL": "管理",
        "peiZ": "配置",
        "yingYGL": "应用管理",
        "wuLQ": "物联卡",
        "wuLQGL": "物联卡管理",
        "tongB": "同步",
        "jiH": "激活",
        "bangD": "绑定",
        "chongZGL": "充值管理",
        "chongZ": "充值",
        "pingSXR": "平台接入",
        "caoZJL": "操作记录",
        "chuSHSJSJBS": "初始化数据提交表单",
        "benDDZ": "本地地址",
        "benDDK": "本地端口",
        "gongWDZ": "公网地址",
        "gongWDK": "公网端口",
        "jiBXXBS": "基本信息表单",
        "deLBJ": "登录背景",
        "tuPSZBS": "图片上传表单",
        "neiZJSSJ": "内置角色数据",
        "sheBXRG": "设备接入岗",
        "gaiJSFZSBXRMKDWHGL": "该角色负责设备接入模块的维护管理",
        "zhengC": "正常",
        "yunWGLG": "运维管理岗",
        "gaiJSFZJTYWMKDWHGL": "该角色负责系统运维模块的维护管理",
        "zongHGLG": "综合管理岗",
        "gaiJSFZJTYWHSBXRMKDWHGL": "该角色负责系统运维和设备接入模块的维护管理",
        "quanBSJ": "全部数据",
        "ziQCJD": "自己创建的",
        "suoZZZ": "所在组织",
        "suoZZZJXJZZ": "所在组织及下级组织",
        "ciCSZCSJQWQZ": "此菜单支持数据权限控制",
        "ciCSBZCSJQWQZ": "此菜单不支持数据权限控制",
        "jiTCSH": "系统初始化",
        "jiBXX": "基本信息",
        "peiZPSMC": "配置平台名称",
        "zhuTSDJBXX": "主题色等基本信息",
        "caiSCSH": "菜单初始化",
        "chuSHCSSJ": "初始化菜单数据",
        "jiaoSCSH": "角色初始化",
        "chuSHNZJSYQWSJ": "初始化内置角色与权限数据",
        "chuSHSJ": "初始化数据",
        "chuSHSBXRZLSJ": "初始化设备接入示例数据",
        "queD": "确定",
        "moRDKDYGCSCS": "默认打开第一个初始菜单",
        "shiJJCBS": "提交基础表单",
        "tiaoZSY": "跳转首页",
        "shiJSYSJ": "提交所有数据",
        "dangQSJSPCGSJ": "当前数据是否成功提交",
        "baoCCG": "保存成功",
        "jiLCSHSJ": "记录初始化数据",
        "panDSPSYPZ": "判断是否已有配置",
        "chuSSJSJBS": "初始数据提交表单",
        "chuSSJ": "初始数据",
        "quX": "取消",
        "chuSHSJPK": "初始化数据包括",
        "leiXSBXRWG": "类型设备接入网关",
        "guanFXY": "官方协议",
        "bangDDFWQSDWQDZ": "绑定到服务器上的网卡地址",
        "bangDDSYWQ": "绑定到所有网卡",
        "duiWSGFWDDZNWHJSCXFWQDNW": "对外提供访问的地址内网环境时填写服务器的内网",
        "deZ": "地址",
        "qingSRGWDZ": "请输入公网地址",
        "jianYZDDKDQQ": "监听指定端口的请求",
        "qingXZBDDK": "请选择本地端口",
        "duiWSGFWDDK": "对外提供访问的端口",
        "qingSRGWDK": "请输入公网端口",
        "chuSHSJZT": "初始化数据状态",
        "chuSHTCBSSJ": "初始化弹窗表单数据",
        "jiaoYGWDZ": "校验官网地址",
        "qingSRZQDGWDZ": "请输入正确的公网地址",
        "jiaoYSZ": "校验数字",
        "diZZS": "的正整数",
        "qingXZBDDZ": "请选择本地地址",
        "chuSSJTCDJZJ": "初始数据弹窗点击事件",
        "biaoSQXZJ": "表单取消事件",
        "shiJCSHSJ": "提交初始化数据",
        "zhaXDKSJ": "查询端口数据",
        "shiJCSSJBS": "提交初始数据表单",
        "xinCWLZJ": "新增网络组件",
        "baoCXY": "保存协议",
        "xinCSBXRWG": "新增设备接入网关",
        "xinCCP": "新增产品",
        "xinCSB": "新增设备",
        "chuSH": "初始化",
        "geCS": "个菜单",
        "chuSHHDCSKZ": "初始化后的菜单可在",
        "yeMJXWHGL": "页面进行维护管理",
        "huoQCSSJ": "获取菜单数据",
        "huoQDQJTQWXX": "获取当前系统权限信息",
        "guoLCS": "过滤菜单",
        "jiSCSSL": "计算菜单数量",
        "chuSHCS": "初始化菜单",
        "yongHZX": "用户中心",
        "jiaoSGXSJ": "角色勾选数据",
        "huoQJSXZSJ": "获取角色选择数据",
        "genJCSZJS": "根据菜单找角色",
        "tianJGJS": "添加该角色",
        "gengXQW": "更新权限",
        "baoCJS": "保存角色"
    },
    "iot-card": {
        "bangDSB": "绑定设备",
        "xuanZSB": "选择设备",
        "queD": "确定",
        "quX": "取消",
        "sheBMC": "设备名称",
        "zhuCSJ": "注册时间",
        "zhuangT": "状态",
        "jinY": "禁用",
        "liX": "离线",
        "zaiX": "在线",
        "caoZCG": "操作成功",
        "wuLQZK": "物联卡查看",
        "xinC": "新增",
        "bianJ": "编辑",
        "jiBXX": "基本信息",
        "qiaX": "卡号",
        "pingSLX": "平台类型",
        "pingSMC": "平台名称",
        "yunYS": "运营商",
        "leiX": "类型",
        "jiHRJ": "激活日期",
        "gengXSJ": "更新时间",
        "zongLL": "总流量",
        "shiYLL": "使用流量",
        "shengTLL": "剩余流量",
        "shuiM": "说明",
        "liuLTJ": "流量统计",
        "shuJTJ": "数据统计",
        "zuoRLLXH": "昨日流量消耗",
        "dangRLLXH": "当月流量消耗",
        "benNLLXH": "本年流量消耗",
        "zuoR": "昨日",
        "jinYZ": "近一周",
        "jinYR": "近一月",
        "jinYN": "近一年",
        "bianJGBTC": "编辑关闭弹窗",
        "jiaZBL": "加载表格",
        "zhaXJR": "查询今日",
        "dangR": "当月",
        "benNSJ": "本年数据",
        "daoR": "导入",
        "daoC": "导出",
        "wenJLS": "文件格式",
        "qingXZWJLS": "请选择文件格式",
        "wuLQGL": "物联卡管理",
        "pingSDX": "平台对接",
        "qingXZPSDX": "请选择平台对接",
        "wenJSZ": "文件上传",
        "xiaZMB": "下载模板",
        "siWC": "已完成",
        "zongSL": "总数量",
        "yiB": "失败",
        "guanB": "关闭",
        "daoRCG": "导入成功",
        "daoRYB": "导入失败",
        "wuLQDRMB": "物联卡导入模板",
        "piLCZ": "批量操作",
        "piLDC": "批量导出",
        "piLDR": "批量导入",
        "queRJHM": "确认激活吗",
        "piLJH": "批量激活",
        "queRTYM": "确认停用吗",
        "piLTY": "批量停用",
        "queRFJM": "确认复机吗",
        "piLFJ": "批量复机",
        "queRTBZTM": "确认同步状态吗",
        "tongBZT": "同步状态",
        "queRSSM": "确认删除吗",
        "piLSS": "批量删除",
        "zongH": "总共",
        "ningQ": "年卡",
        "jiQ": "季卡",
        "ruQ": "月卡",
        "qiT": "其他",
        "jiH": "激活",
        "weiJH": "未激活",
        "tingJ": "停机",
        "qiY": "启用",
        "caoZ": "操作",
        "jieBSB": "解绑设备",
        "queRJBSB": "确认解绑设备",
        "fuJ": "复机",
        "tingY": "停用",
        "queRJH": "确认激活",
        "queRFJ": "确认复机",
        "queRTY": "确认停用",
        "shanS": "删除",
        "queRSS": "确认删除",
        "caoZYB": "操作失败",
        "zhaK": "查看",
        "chuLQYZT": "处理其它状态",
        "bangDSBGBCK": "绑定设备关闭窗口",
        "qingXZSJ": "请选择数据",
        "fuZCTYGYYSXJCS": "仅支持同一个运营商下且最少",
        "tiaoSJ": "条数据",
        "cuoD": "最多",
        "tongBZTCG": "同步状态成功",
        "qingSRQX": "请输入卡号",
        "qiaDWYSBXM": "卡的唯一识别号码",
        "qingSR": "请输入",
        "qingXZYYS": "请选择运营商",
        "qingXZLX": "请选择类型",
        "qingSRSM": "请输入说明",
        "qingSRSRZQD": "请输入输入正确的",
        "cuoDSR": "最多输入",
        "geZF": "个字符",
        "jinXZ": "进行中",
        "zuJSXBDDZ": "组件双向绑定的值",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "tuBYS": "图表颜色",
        "shiPZZ": "是否展示",
        "zhou": "轴",
        "tuBSJ": "图表数据",
        "huiZTB": "绘制图表",
        "liuLXH": "流量消耗",
        "xianZKQAN": "显示快捷按钮",
        "kuaiQANLB": "快捷按钮列表",
        "jinR": "今日",
        "wuLQ": "物联卡",
        "yiBP": "仪表盘",
        "liuLSY": "流量使用",
        "kaiSSJ": "开始时间",
        "jieSSJ": "结束时间",
        "yiD": "移动",
        "dianX": "电信",
        "lianT": "联通",
        "zhiFFS": "支付方式",
        "zhiFBSJWZZF": "支付宝手机网站支付",
        "zhiFBWYJSDZZF": "支付宝网页及时到账支付",
        "weiXGZXZF": "微信公众号支付",
        "weiXSMZF": "微信扫码支付",
        "shouY": "首页",
        "wuLQYD": "物联卡引导",
        "jiCTJ": "基础统计",
        "xiangQ": "详情",
        "zuoRLLTJ": "昨日流量统计",
        "pingSJGT": "平台架构图",
        "caiSQW": "菜单权限",
        "anNQW": "按钮权限",
        "caoZJL": "操作记录",
        "zhengC": "正常",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "huoQZRLLXH": "获取昨日流量消耗",
        "huoQCJ": "获取最近",
        "tianLLXHTJTSJ": "天流量消耗统计图数据",
        "huoQWLQZTSJ": "获取物联卡状态数据",
        "cuoJ": "最近",
        "tianLLXHTJT": "天流量消耗统计图",
        "sheSZZZKZ": "设单柱状置宽度",
        "sheZZZDBJXN": "设置柱状的背景虚拟",
        "wuLQBT": "物联卡饼图",
        "shiPQYFZBQTDCL": "是否启用防止标签重叠策略",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "qingSRMM": "请输入密码",
        "xieKDZ": "接口地址",
        "qingSRXKDZ": "请输入接口地址",
        "yongH": "用户",
        "qingSRYH": "请输入用户",
        "miM": "密码",
        "chuangJZ": "创建者",
        "qingSRCJZ": "请输入创建者",
        "baoC": "保存",
        "cuoDKSR": "最多可输入",
        "baoCCG": "保存成功",
        "zhongGYDWLQTLKFPS": "中国移动物联卡能力开放平台",
        "gaiS": "概述",
        "pingSDXTG": "平台对接通过",
        "diFSYSFJTJXSJDX": "的方式与三方系统进行数据对接",
        "weiWLQDGLSGSJJHZC": "为物联卡的管理提供数据交互支持",
        "peiZSM": "配置说明",
        "diSFYYWYBS": "第三方应用唯一标识",
        "zhongGYDWLWQWGLYZ": "中国移动物联网全网管理员在",
        "taiLKFPSSFPBZZGJQQH": "能力开放平台上分配并展示给集团客户",
        "huoQLJ": "获取路径",
        "zhongYWLQTLKFPS": "中移物联卡能力开放平台",
        "geRZX": "个人中心",
        "qiaHXX": "客户信息",
        "xieRXX": "接入信息",
        "xieRBY": "接入秘钥",
        "yaoZGYDWLWSG": "由中国移动物联网提供",
        "jiQQHC": "集团客户从",
        "taiLKFPS": "能力开放平台",
        "huoQ": "获取",
        "houZQGJSJQKCX": "后缀请根据实际情况填写",
        "zhiL": "示例",
        "lianXGLPS": "连接管理平台",
        "lianXGLPSYHDWYBS": "连接管理平台用户的唯一标识",
        "yongXSFSB": "用于身份识别",
        "taiLKF": "能力开放",
        "wangGZXGL": "网关账号管理",
        "jingJMZHDMM": "经加密之后的密码",
        "weiYBY": "唯一秘钥",
        "yanFZL": "雁飞智连",
        "pingS": "平台",
        "woDYY": "我的应用",
        "yingYLB": "应用列表",
        "xieKCSZD": "接口参数中的",
        "queR": "确认",
        "qingXJYZSS": "请先禁用再删除",
        "chongZJE": "充值金额",
        "zhangH": "账户",
        "dingSX": "订单号",
        "zhiF": "支付",
        "dingSSJ": "订单时间",
        "chongZGL": "充值管理",
        "chongZ": "充值",
        "benPSFSGCZRK": "本平台仅提供充值入口",
        "juBCZJLRSYYSDCZJLWZ": "具体充值结果需以运营商的充值结果为准",
        "chongZGBTC": "充值关闭弹窗",
        "zhaKXQTCGB": "查看详情弹窗关闭",
        "zanZZCYD": "暂只支持移动",
        "qingSRZH": "请输入账户",
        "zhuJDJE": "之间的金额",
        "qingXZZFFS": "请选择支付方式",
        "qingSRCZJE": "请输入充值金额",
        "zhuJDSZ": "之间的数字",
        "jiaoFYB": "缴费失败",
        "caoZGXPP": "操作过于频繁",
        "qingSHZS": "请稍后再试",
        "caoZLX": "操作类型",
        "caoZSJ": "操作时间",
        "caoZR": "操作人"
    },
    "link": {
        "jiBXX": "基本信息",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "shuiM": "说明",
        "qingSRSM": "请输入说明",
        "baoC": "保存",
        "peiZGL": "配置概览",
        "xieRFS": "接入方式",
        "xiaoXXY": "消息协议",
        "sheBXRZY": "设备接入指引",
        "peiZ": "配置",
        "tongD": "通道",
        "chuangJ": "创建",
        "sheBXRWG": "设备接入网关",
        "chuangJCP": "创建产品",
        "bingXZXRFSW": "并选中接入方式为",
        "tianJSB": "添加设备",
        "shanDWMYGSBJXSJDBD": "单独为每一个设备进行数据点绑定",
        "caoZCG": "操作成功",
        "tongG": "通过",
        "pingSD": "平台的",
        "tuiSFWJXSJXR": "推送服务进行数据接入",
        "xieKDZ": "接口地址",
        "qingSR": "请输入",
        "caoZZY": "操作指引",
        "duanCJCP": "端创建产品",
        "sheB": "设备",
        "siJYGDSFYY": "以及一个第三方应用",
        "duanPZCP": "端配置产品",
        "fenZJDY": "分组级订阅",
        "dingYF": "订阅方",
        "deZQCX": "地址请填写",
        "duanCJLXW": "端创建类型为",
        "diSBXRWG": "的设备接入网关",
        "xuanZXRFSW": "选中接入方式为",
        "chenX": "填写",
        "pingSZDCP": "平台中的产品",
        "duanTJSB": "端添加设备",
        "weiMYSSBSZWYD": "为每一台设备设置唯一的",
        "ruY": "需与",
        "pingSZCXDZYZ": "平台中填写的值一致",
        "sheBXRWGPZSM": "设备接入网关配置说明",
        "qingQ": "请将",
        "di": "的",
        "pingS": "平台",
        "yingYGLZD": "应用管理中的",
        "huo": "和",
        "fuZDDQYM": "复制到当前页面",
        "qiTSM": "其他说明",
        "zai": "在",
        "duanQYSBS": "端启用设备时",
        "ruo": "若",
        "pingSMYYZDYDSB": "平台没有与之对应的设备",
        "zeQZ": "则将在",
        "duanZDCJXSB": "端自动创建新设备",
        "zhiTXZ": "只能选择",
        "tongXFSDXY": "通信方式的协议",
        "xinC": "新增",
        "zanMSJ": "暂无数据",
        "chuangJLXW": "创建类型为",
        "xuanZHRCX": "选中后需填写",
        "ma": "码",
        "pingSMYDYDSB": "平台没有对应的设备",
        "qiangHTG": "将会通过",
        "pingSSGD": "平台提供的",
        "xieYZDCJ": "协议自动创建",
        "shangYB": "上一步",
        "xiaYB": "下一步",
        "xieRPZ": "接入配置",
        "wanC": "完成",
        "qingXZXXXY": "请选择消息协议",
        "tongBWLWPSSBSJD": "同步物联网平台设备数据到",
        "qingSRTZ": "请输入通知",
        "tongZ": "通知",
        "ziDY": "自定义",
        "keYXYZQQSPLZ": "可用于验证请求是否来自",
        "duanSCDXXJM": "端生成的消息加密",
        "bingPZ": "并配置",
        "tuiS": "推送",
        "leiXDSBXRWG": "类型的设备接入网关",
        "duanDCP": "端的产品",
        "zaiSBSLYMWMYSSBSZWYD": "在设备实例页面为每一台设备设置唯一的",
        "pingSZDZYZ": "平台中的值一致",
        "tuiSPZSM": "推送配置说明",
        "tuiSPZLJ": "推送配置路径",
        "yingYKF": "应用开发",
        "shuJTS": "数据推送",
        "cenS": "参数",
        "shiLMC": "实例名称",
        "tuiSSLDMC": "推送实例的名称",
        "tuiSDZ": "推送地址",
        "yongXXS": "用于接收",
        "tuiSSBSJDDZWLWPSDZ": "推送设备数据的地址物联网平台地址",
        "xiaoXJM": "消息加密",
        "caiY": "采用",
        "jiaMSFDTSDSJJXSJJM": "加密算法对推送的数据进行数据加密",
        "weiJMBY": "为加密秘钥",
        "pingSZJBCPD": "平台中具体产品的",
        "shuJTSPZZSZD": "数据推送配置中设置的",
        "shuJTSPZLXXJM": "数据推送配置了消息加密",
        "ciCCX": "此处填写",
        "duanSJTSPZZSZD": "端数据推送配置中设置的",
        "xuanZYSBTXDWLZJ": "选择与设备通信的网络组件",
        "wangLZJ": "网络组件",
        "qingXZWLZJ": "请选择网络组件",
        "peiZSBXLCS": "配置设备信令参数",
        "yu": "或",
        "qingXZJQ": "请选择集群",
        "jiQ": "集群",
        "hongXPZ": "共享配置",
        "jiQXSYJDHYTYPZ": "集群下所有节点共用同一配置",
        "duWPZ": "独立配置",
        "jiQXBTJDSYBTPZ": "集群下不同节点使用不同配置",
        "deZ": "地址",
        "qingXZ": "请选择",
        "qingXZDK": "请选择端口",
        "gongW": "公网",
        "qingSRZQD": "请输入正确的",
        "shuRDK": "输入端口",
        "qingSRDK": "请输入端口",
        "peiZXX": "配置信息",
        "shanS": "删除",
        "jieDMC": "节点名称",
        "qingXZJDMC": "请选择节点名称",
        "daoFWQSDWQDZ": "到服务器上的网卡地址",
        "bangDDSYWQ": "绑定到所有网卡",
        "qingSRGW": "请输入公网",
        "jianYZDDKDQQ": "监听指定端口的请求",
        "xinLPZ": "信令配置",
        "qingXCYWSPZ": "请新增或完善配置",
        "jiaoYWTGDZK": "校验未通过的展开",
        "shiYXZDXXXY": "使用选择的消息协议",
        "duiWLZJTXSJJXBJM": "对网络组件通信数据进行编解码",
        "renZDCZ": "认证等操作",
        "xinX": "信息",
        "shangX": "上行",
        "xiaX": "下行",
        "fenZ": "分组",
        "xuanZZJ": "选择插件",
        "zhaJ": "插件",
        "banBX": "版本号",
        "tongYPZ": "通用配置",
        "shuR": "输入",
        "xuanZ": "选择",
        "qing": "请",
        "qingXZZJ": "请选择插件",
        "bianJ": "编辑",
        "huiXBSZ": "回显表单值",
        "xieR": "接入",
        "keSRSBMRJWLXJKSYPSZSSJ": "可以让设备无需建立连接就可以与平台传输数据",
        "zaiYHYDCZDPDQKX": "在允许一定程度丢包的情况下",
        "shiGQLHJJSDLX": "提供轻量化且简单的连接",
        "fuWSYCMXLXD": "服务是一种面向连接的",
        "keKD": "可靠的",
        "jiXZJLDZSCTXXY": "基于字节流的传输层通信协议",
        "sheBKTG": "设备可通过",
        "fuWYPSJXZLX": "服务与平台进行长链接",
        "shiSGXZTBFSXX": "实时更新状态并发送消息",
        "keZDYDCNCPGZ": "可自定义多种粘拆包规则",
        "chuLZSGCZKTFSDNCPWT": "处理传输过程中可能发生的粘拆包问题",
        "shiYCZSG": "是一种在单个",
        "lianXSJXQSGTXDXY": "连接上进行全双工通信的协议",
        "yuanHFWDZDXQHDTSSJ": "允许服务端主动向客户端推送数据",
        "sheBTG": "设备通过",
        "juKSFBDYXX": "且可以发布订阅消息",
        "shi": "时",
        "biaoZXJXFB": "标准下基于发布",
        "dingYFSDXXXY": "订阅范式的消息协议",
        "juYQL": "具有轻量",
        "jianS": "简单",
        "kaiFHYXSXDTD": "开放和易于实现的特点",
        "pingSSYZDD": "平台使用指定的",
        "xieRQTYCPS": "接入其他远程平台",
        "dingYXX": "订阅消息",
        "yiKTJYHMHMMJY": "也可添加用户名和密码校验",
        "keSZCTXXZZ": "可设置最大消息长度",
        "keTYSZHXDDYQZ": "可统一设置共享的订阅前缀",
        "fuWSYGJSDQQ": "服务是一个简单的请求",
        "xiangYDJX": "响应的基于",
        "diMZTXY": "的无状态协议",
        "fuWYPSJXLHDDLXTX": "服务与平台进行灵活的短链接通信",
        "fuZCSBHPSZJSDSDQQ": "仅支持设备和平台之间单对单的请求",
        "xiangYMS": "响应模式",
        "shiG": "提供",
        "diFWD": "的服务端",
        "siGSBSZLXDFSXRPS": "以供设备以长链接的方式接入平台",
        "sheBSYWYD": "设备使用唯一的",
        "shiZDZYSLDNCKJHYWDJSTLSGDYCJX": "是针对只有少量的内存空间和有限的计算能力提供的一种基于",
        "diXY": "的协议",
        "bianXDGHYWLSWDSBYPSTX": "便于低功耗或网络受限的设备与平台通信",
        "shangXX": "上下行",
        "zhiL": "示例",
        "fanH": "返回",
        "houJYH": "后期优化",
        "shiPLSBXR": "视频类设备接入",
        "yunPSXR": "云平台接入",
        "tongDLSBXR": "通道类设备接入",
        "guanFXR": "官方接入",
        "ziDYSBXR": "自定义设备接入",
        "zhaJSBXR": "插件设备接入",
        "kuaiSTJXRWG": "快速添加接入网关",
        "jianZSPBCPSY": "检查是否被产品使用",
        "xieY": "协议",
        "wangGLX": "网关类型",
        "zhuangT": "状态",
        "jinY": "禁用",
        "zhengC": "正常",
        "caoZ": "操作",
        "qiY": "启用",
        "queR": "确认",
        "qingXJY": "请先禁用",
        "zaiSS": "再删除",
        "queRSS": "确认删除",
        "xiaoS": "小时",
        "chaoCDWBYZ": "超出的文本隐藏",
        "yiCYXLXXZ": "溢出用省略号显示",
        "yiCBHX": "溢出不换行",
        "shangZWJ": "上传文件",
        "shangZCG": "上传成功",
        "qingSZ": "请上传",
        "luoSDWJ": "格式的文件",
        "zhengSBZ": "证书标准",
        "zhengSMC": "证书名称",
        "qingSRZSMC": "请输入证书名称",
        "zhengSWJ": "证书文件",
        "qingSRZSWJ": "请输入证书文件",
        "zhengSSY": "证书私钥",
        "qingSRZSSY": "请输入证书私钥",
        "gaiS": "概述",
        "zhengSYSXLDSZZSBFJG": "证书由受信任的数字证书颁发机构",
        "zaiYZFWQSFHBF": "在验证服务器身份后颁发",
        "juYFWQSFYZHSJZSJMGT": "具有服务器身份验证和数据传输加密功能",
        "baoZSBYPSJDSJZSAQ": "保障设备与平台间的数据传输安全",
        "peiZHKBWLZJYY": "配置后可被网络组件引用",
        "peiZSM": "配置说明",
        "ninKSSYWBBJGJDK": "您可以使用文本编辑工具打开",
        "luoSDZSWJ": "格式的证书文件",
        "fuZQZDNYBNTDGWBK": "复制其中的内容并粘贴到该文本框",
        "yuZSJGWBKXDSZ": "或者单击该文本框下的上传",
        "bingXZCCZBDJSJDZSWJ": "并选择存储在本地计算机的证书文件",
        "qiangWJNYSZDWBK": "将文件内容上传到文本框",
        "chenXZSSYNYD": "填写证书私钥内容的",
        "bianM": "编码",
        "luoSDZSSYWJ": "格式的证书私钥文件",
        "yuZSJGWBKXDSZBXZCCZBDJSJDZSSYWJ": "或者单击该文本框下的上传并选择存储在本地计算机的证书私钥文件",
        "qingXZZSBZ": "请选择证书标准",
        "qingSRYSZWJ": "请输入或上传文件",
        "guoJBZ": "国际标准",
        "zhaK": "查看",
        "queD": "确定",
        "quX": "取消",
        "shiYLQS": "使用率趋势",
        "cuoJ": "最近",
        "jinR": "今日",
        "jinYZ": "近一周",
        "neiCSYLQS": "内存使用率趋势",
        "wangLLL": "网络流量",
        "ru": "如",
        "ri": "日",
        "ning": "年",
        "shiYL": "使用率",
        "neiC": "内存",
        "zong": "总",
        "ciPTY": "磁盘占用",
        "zongCPTX": "总磁盘大小",
        "jiTNC": "系统内存",
        "zhaJLX": "插件类型",
        "zhaJMC": "插件名称",
        "caoZYB": "操作失败",
        "ruoBCX": "若不填写",
        "jiTQZDSCWY": "系统将自动生成唯一",
        "wenJ": "文件",
        "qingSZWJ": "请上传文件",
        "banB": "版本",
        "xinCJY": "新增校验",
        "tongF": "重复",
        "luoSYQ": "格式要求",
        "wenJM": "文件名",
        "jiTSZ": "系统提示",
        "jiTWZCW": "系统未知错误",
        "qingFKGGLY": "请反馈给管理员",
        "wuGL": "物管理",
        "leiX": "类型",
        "shangZ": "上传",
        "pao": "包",
        "qingXZLX": "请选择类型",
        "wenJDZ": "文件地址",
        "qingSRWJDZ": "请输入文件地址",
        "buCL": "不处理",
        "fenJF": "分隔符",
        "ziDYJB": "自定义脚本",
        "guDZZ": "固定长度",
        "zhangZZD": "长度字段",
        "taiD": "大端",
        "xiaoD": "小端",
        "deZYZYM": "地址或者域名",
        "cuoTKSR": "最大可输入",
        "qingXZBDDZ": "请选择本地地址",
        "qingXZBDDK": "请选择本地端口",
        "qingSRGWDZ": "请输入公网地址",
        "qingSRGWDK": "请输入公网端口",
        "zhuJDZZS": "之间的正整数",
        "qingSRYCDZ": "请输入远程地址",
        "qingSRZQLSDYMY": "请输入正确格式的域名或",
        "shuRYCDK": "输入远程端口",
        "qingSRYHM": "请输入用户名",
        "qingSRMM": "请输入密码",
        "qingSRCTXXZZ": "请输入最大消息长度",
        "qingXZZS": "请选择证书",
        "qingSRSYBM": "请输入私钥别名",
        "qingXZNCPGZ": "请选择粘拆包规则",
        "qingSRFJF": "请输入分隔符",
        "qingXZJBYY": "请选择脚本语言",
        "qingSRJB": "请输入脚本",
        "qingSRZZZ": "请输入长度值",
        "qingXZZZ": "请选择长度",
        "benDDZ": "本地地址",
        "bangDDFWQSDWQDZ": "绑定到服务器上的网卡地址",
        "benDDK": "本地端口",
        "gongWDZ": "公网地址",
        "duiWSGFWDDZ": "对外提供访问的地址",
        "neiWHJSCXFWQDNW": "内网环境时填写服务器的内网",
        "gongWDK": "公网端口",
        "duiWSGFWDDK": "对外提供访问的端口",
        "yuanCDZ": "远程地址",
        "yuanCDK": "远程端口",
        "qingSRYCDK": "请输入远程端口",
        "yongHM": "用户名",
        "miM": "密码",
        "dingYQZ": "订阅前缀",
        "dangLXDFWW": "当连接的服务为",
        "keTRYSYHXDDYQZ": "可能需要使用共享的订阅前缀",
        "qingSRDYQZ": "请输入订阅前缀",
        "cuoTXXZZ": "最大消息长度",
        "shanCSFXXDCTZZ": "单次收发消息的最大长度",
        "shanW": "单位",
        "ziJ": "字节",
        "sheZGTKTHYXXT": "设置过大可能会影响性能",
        "kaiQ": "开启",
        "pi": "否",
        "zhengS": "证书",
        "siYBM": "私钥别名",
        "nianCPGZ": "粘拆包规则",
        "chuL": "处理",
        "nianCPDFS": "粘拆包的方式",
        "jueBYY": "脚本语言",
        "jieSJB": "解析脚本",
        "zhangZZ": "长度值",
        "zhangZ": "长度",
        "pianYL": "偏移量",
        "qingSRPYL": "请输入偏移量",
        "taiXD": "大小端",
        "qingXZTXD": "请选择大小端",
        "zhiBL": "只保留",
        "paoH": "包含",
        "diSJ": "的数据",
        "shuJ": "数据",
        "fangZBJS": "防止编辑时",
        "biaoSZDBWS": "表单字段不完善",
        "daoZSR": "导致输入",
        "xuanZKXCXSZBD": "选择框新出现时找不到",
        "gengXDK": "更新端口",
        "bianLSJGXDYDBDDK": "遍历数据更新对应的本地端口",
        "xiangQ": "详情",
        "qingXJYGZJ": "请先禁用该组件",
        "yuanC": "远程",
        "queDYSSM": "确定要删除吗",
        "qingXZSB": "请选择设备",
        "piLDRSB": "批量导入设备",
        "piLSSSB": "批量删除设备",
        "siQYDSBMFSS": "已启用的设备无法删除",
        "queRSSXZDJYZTSB": "确认删除选中的禁用状态设备",
        "xuX": "序号",
        "sheP": "设配",
        "shePMC": "设配名称",
        "chanPMC": "产品名称",
        "bangDYH": "绑定用户",
        "siJH": "已激活",
        "weiJH": "未激活",
        "chuangJSJ": "创建时间",
        "daoR": "导入",
        "luoSWJ": "格式文件",
        "daoRSJ": "导入数据",
        "daoRCG": "导入成功",
        "daoRYB": "导入失败",
        "qingTS": "请重试",
        "luoS": "格式"
    },
    "linkDeviceList": {
        "xinC": "新增",
        "shanS": "删除",
        "queDYSSM": "确定要删除吗",
        "qingXZSB": "请选择设备",
        "caoZCG": "操作成功",
        "piLDRSB": "批量导入设备",
        "piLSSSB": "批量删除设备",
        "siQYDSBMFSS": "已启用的设备无法删除",
        "queRSSXZDJYZTSB": "确认删除选中的禁用状态设备",
        "xuX": "序号",
        "sheP": "设配",
        "shePMC": "设配名称",
        "chanPMC": "产品名称",
        "bangDYH": "绑定用户",
        "zhuangT": "状态",
        "siJH": "已激活",
        "weiJH": "未激活",
        "deZ": "地址",
        "chuangJSJ": "创建时间",
        "caoZ": "操作",
        "caoZYB": "操作失败",
        "xiaoS": "搜索",
        "daoR": "导入",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "daoRSJ": "导入数据",
        "daoRCG": "导入成功",
        "daoRYB": "导入失败",
        "qingTS": "请重试",
        "luoS": "格式"
    },
    "media": {
        "guoBJL": "国标级联",
        "bangDTD": "绑定通道",
        "quX": "取消",
        "queD": "确定",
        "tongDLB": "通道列表",
        "sheBMC": "设备名称",
        "tongDMC": "通道名称",
        "anZDZ": "安装地址",
        "changS": "厂商",
        "zhuangT": "状态",
        "siLX": "已连接",
        "weiLX": "未连接",
        "xiaoS": "搜索",
        "qingGXSJ": "请勾选数据",
        "caoZCG": "操作成功",
        "caoZYB": "操作失败",
        "queRJB": "确认解绑",
        "piLJB": "批量解绑",
        "guoBJLY": "国标级联有",
        "wei": "为",
        "weiLCLS": "位两种格式",
        "zaiDQYMXGBHXGSPSB": "在当前页面修改不会修改视频设备",
        "tongDYMZDGB": "通道页面中的国标",
        "bianJGB": "编辑国标",
        "qingSRGB": "请输入国标",
        "gaiGB": "该国标",
        "zaiTYSBXSCZ": "在同一设备下已存在",
        "baoC": "保存",
        "guoB": "国标",
        "biaoTSYLZZ": "表头单元格插槽",
        "zaiXZT": "在线状态",
        "caoZ": "操作",
        "biaoLCZAN": "表格操作按钮",
        "biaoLSJX": "表格数据项",
        "biaoLZZLX": "表格展示类型",
        "jieB": "解绑",
        "qingXXZRYJBDTDLB": "请先选择需要解绑的通道列表",
        "yanZ": "验证",
        "shiPCZ": "是否存在",
        "xinC": "新增",
        "tongDSL": "通道数量",
        "mingC": "名称",
        "shangJ": "上级",
        "deZ": "地址",
        "zhengC": "正常",
        "jinY": "禁用",
        "jiLZT": "级联状态",
        "chuLBLSJ": "处理表格数据",
        "anN": "按钮",
        "bianJ": "编辑",
        "xuanZTD": "选择通道",
        "tuiS": "推送",
        "jinYZTXBKTS": "禁用状态下不可推送",
        "qiY": "启用",
        "queR": "确认",
        "shanS": "删除",
        "qingXJY": "请先禁用",
        "zaiSS": "再删除",
        "queRSS": "确认删除",
        "chengG": "成功",
        "yiB": "失败",
        "tuiSYB": "推送失败",
        "xiaZ": "下载",
        "tuiSTDSL": "推送通道数量",
        "siTSTDSL": "已推送通道数量",
        "guoBJLXC": "国标级联新增",
        "jiBXX": "基本信息",
        "qingSRMC": "请输入名称",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "daiLSPL": "代理视频流",
        "qingXZDLSPL": "请选择代理视频流",
        "xinLFWPZ": "信令服务配置",
        "qingXZJQJD": "请选择集群节点",
        "jiQJD": "集群节点",
        "shiYCJQJDJLDSJPS": "使用此集群节点级联到上级平台",
        "xinLMC": "信令名称",
        "qingSRXLMC": "请输入信令名称",
        "qingSRSJ": "请输入上级",
        "yu": "域",
        "qingSRSJPS": "请输入上级平台",
        "qingSR": "请输入",
        "qingSRDK": "请输入端口",
        "benD": "本地",
        "qingSRWGZD": "请输入网关侧的",
        "wangGZD": "网关侧的",
        "qingXZ": "请选择",
        "benDDZ": "本地地址",
        "shiYZDDWQHDKJXQQ": "使用指定的网卡和端口进行请求",
        "qingXZDK": "请选择端口",
        "yuanCDZ": "远程地址",
        "zhuanSXY": "传输协议",
        "qingXZZSXY": "请选择传输协议",
        "yongH": "用户",
        "qingSRYH": "请输入用户",
        "xieRMM": "接入密码",
        "qingSRXRMM": "请输入接入密码",
        "qingSRCS": "请输入厂商",
        "xingX": "型号",
        "qingSRXX": "请输入型号",
        "banBX": "版本号",
        "qingSRBBX": "请输入版本号",
        "xinTZJ": "心跳周期",
        "miao": "秒",
        "qingSRXTZJ": "请输入心跳周期",
        "zhuCJJ": "注册间隔",
        "qingSRZCJJ": "请输入注册间隔",
        "gaiS": "概述",
        "peiZGBJL": "配置国标级联",
        "pingSKSQSJXRDZSDSXTHXGDSFDYBF": "平台可以将已经接入到自身的摄像头共享给第三方调用播放",
        "zhu": "注",
        "gaiPZZYXQBPSXSJLZDSFPS": "该配置只用于将本平台向上级联至第三方平台",
        "ruRDSFPSXSJLZBPS": "如需第三方平台向上级联至本平台",
        "qingZ": "请在",
        "shiPSB": "视频设备",
        "yeMXCSBSXZ": "页面新增设备时选择",
        "xieRFS": "接入方式",
        "peiZSM": "配置说明",
        "siXPZSMSQBPSSJJLD": "以下配置说明以将本平台数据级联到",
        "pingSWL": "平台为例",
        "qingCXDSFPSZPZD": "请填写第三方平台中配置的",
        "qingCXBDD": "请填写本地的",
        "deZYZXBM": "地址由中心编码",
        "xingYBM": "行业编码",
        "leiXBM": "类型编码",
        "huoXX": "和序号",
        "siGMDH": "四个码段共",
        "weiS": "位十",
        "jinZSZZFGC": "进制数字字符构成",
        "xiangXGZQCX": "详细规则请参见",
        "zhongBL": "中附录",
        "buF": "部分",
        "zhiDDWQHDK": "指定的网卡和端口",
        "ruYYWQLJJTYWRY": "如有疑问请联系系统运维人员",
        "buFPSYJXYHHXRMMDTSRZ": "部分平台有基于用户和接入密码的特殊认证",
        "tongCQKX": "通常情况下",
        "qingCX": "请填写",
        "zhi": "至",
        "ruYSJPSSZDXRMMYZ": "需与上级平台设置的接入密码一致",
        "yongXSFRZ": "用于身份认证",
        "benPSQS": "本平台将以",
        "sheB": "设备",
        "diSFJLDSJPS": "的身份级联到上级平台",
        "qingSZBPSZSJPSZXZDCS": "请设置本平台在上级平台中显示的厂商",
        "ruYSJPSSZDXTZJBCYZ": "需与上级平台设置的心跳周期保持一致",
        "tongCMR": "通常默认",
        "ruo": "若",
        "daiLTGZCFSJS": "代理通过注册方式校时",
        "qiZCJJSJYSZWXX": "其注册间隔时间宜设置为小于",
        "daiLY": "代理与",
        "fuWQCX": "服务器出现",
        "wu": "误",
        "chaiSJGDYXSJ": "差所经过的运行时间",
        "biaoSSJ": "表单提交",
        "siXZD": "以下字段",
        "shiJSRSQD": "提交时需提取到",
        "ziDDZ": "字段当中",
        "huoQJQJD": "获取集群节点",
        "huoQDK": "获取端口",
        "huoQXQ": "获取详情",
        "ziDYZ": "字段验证",
        "ciCBDDS": "此处绑定的是",
        "duanK": "端口",
        "qingSRZQD": "请输入正确的",
        "duanKQSR": "端口请输入",
        "zhuJDZZS": "之间的正整数",
        "jinR": "今日",
        "jinYZ": "近一周",
        "jinYR": "近一月",
        "jinYN": "近一年",
        "zanMSJ": "暂无数据",
        "tuBSJ": "图表数据",
        "tongJSJWZ": "统计时间维度",
        "huiZTB": "绘制图表",
        "boFSL": "播放数量",
        "renC": "人次",
        "guaiDTX": "拐点大小",
        "chuDYS": "处的颜色",
        "queXW": "缺省为",
        "sheBSL": "设备数量",
        "luXSL": "录像数量",
        "boFZSL": "播放中数量",
        "dangQZZBFDTDSLZH": "当前正在播放的通道数量之和",
        "zaiX": "在线",
        "liX": "离线",
        "tongD": "通道",
        "luX": "录像",
        "zongSZ": "总时长",
        "boFZ": "播放中",
        "boFRS": "播放人数",
        "huoQBFSL": "获取播放数量",
        "xieRFSW": "接入方式为",
        "shi": "时",
        "buZCXC": "不支持新增",
        "huiF": "回放",
        "boFBYQWQZ": "播放不要权限控制",
        "boF": "播放",
        "zuoZS": "左侧树",
        "kaiSLX": "开始录像",
        "benDCC": "本地存储",
        "cunCZSBBD": "存储在设备本地",
        "yunDCC": "云端存储",
        "cunCZFWQZ": "存储在服务器中",
        "qingQLXZ": "请求录像中",
        "tingZLX": "停止录像",
        "shuaX": "刷新",
        "tongZQDKZB": "重置将断开直播",
        "keTHYXQTBFZ": "可能会影响其他播放者",
        "tongZ": "重置",
        "boFQ": "播放器",
        "shiPDZ": "视频地址",
        "shiPLX": "视频类型",
        "meiBKSBF": "媒体开始播放",
        "luXZT": "录像状态",
        "zhaXLXZT": "查询录像状态",
        "dianJQZAN": "点击控制按钮",
        "qiangZLX": "控制类型",
        "zhiK": "置空",
        "jiXHBFQ": "即销毁播放器",
        "tanC": "弹窗",
        "yongXXC": "用于新增",
        "xiuGSJ": "修改数据",
        "ruoBCX": "若不填写",
        "jiTQZDSCWY": "系统将自动生成唯一",
        "qingSRTD": "请输入通道",
        "qingSRTDMC": "请输入通道名称",
        "qingSRCSMC": "请输入厂商名称",
        "qingSRSPDZ": "请输入视频地址",
        "buTCJD": "不同厂家的",
        "guDDZGZBT": "固定地址规则不同",
        "qingADYCJDGZCX": "请按对应厂家的规则填写",
        "yongHM": "用户名",
        "qingSRYHM": "请输入用户名",
        "miM": "密码",
        "qingSRMM": "请输入密码",
        "qingSRAZDZ": "请输入安装地址",
        "yunSLX": "云台类型",
        "weiZ": "未知",
        "qiuB": "球体",
        "panQB": "半球体",
        "guDQJ": "固定枪机",
        "yaoQQJ": "遥控枪机",
        "qingXZYSLX": "请选择云台类型",
        "shuiM": "说明",
        "ziDYZSPCZ": "字段验证是否存在",
        "feiBC": "非必填",
        "meiYSR": "没有输入",
        "buJY": "不校验",
        "bianJSBJYWYX": "编辑时不校验唯一性",
        "gai": "该",
        "siCZ": "已存在",
        "jiaoYSPDZ": "校验视频地址",
        "qingSRZQDSPDZ": "请输入正确的视频地址",
        "shiJ": "提交",
        "meiYSX": "没有生效",
        "siXZDFBSSC": "以下字段非表单所填",
        "tongZZDRSDZK": "重置字段需手动置空",
        "qingSRMLMC": "请输入目录名称",
        "huoQSBXQ": "获取设备详情",
        "guDDZ": "固定地址",
        "beiD": "被动",
        "chanPMC": "产品名称",
        "zhaKTD": "查看通道",
        "zhaK": "查看",
        "gengXTD": "更新通道",
        "guDDZMFGXTD": "固定地址无法更新通道",
        "sheBSLX": "设备已离线",
        "sheBSJY": "设备已禁用",
        "tongDGXCG": "通道更新成功",
        "zaiXSBMFSS": "在线设备无法删除",
        "qiaPBZZZKAN": "卡片不展示查看按钮",
        "shiPTBZJ": "视频图标组件",
        "shiYX": "时有效",
        "weiXZ": "未下载",
        "xiaZZ": "下载中",
        "siXZ": "已下载",
        "shangZYDRYYDSJ": "上传云端需要一定时间",
        "qingSHZKYDSJ": "请稍后查看云端数据",
        "jinXTZ": "进行跳转",
        "jinXXZ": "进行下载",
        "jinZT": "进度条",
        "yunD": "云端",
        "leiX": "类型",
        "zanT": "暂停",
        "xiaZLXWJ": "下载录像文件",
        "xiaZDYD": "下载到云端",
        "boFZT": "播放状态",
        "tingZ": "停止",
        "boFWC": "播放完成",
        "dangQBFSPBS": "当前播放视频标识",
        "shiPJSBF": "是否结束播放",
        "zhaXBDSP": "查询本地视频",
        "panDSPSXZYDSP": "判断是否已下载云端视频",
        "zhaXYDSP": "查询云端视频",
        "boFJZTDJ": "播放进度条点击",
        "riLCZ": "日历操作",
        "boFQSJKZ": "播放器时间刻度",
        "huoQXZDTKSSJC": "获取选中当天开始时间戳",
        "huoQXZDTJSSJC": "获取选中当天结束时间戳",
        "zhaZXYGSP": "查找下一个视频",
        "shiPWCHYG": "是否为最后一个",
        "boFDYG": "播放第一个",
        "shiPCBDTZDYDBF": "是否从本地跳转到云端播放",
        "huoQTZBFD": "获取跳转播放段",
        "boFPD": "播放片段",
        "meiYKBFDSPZY": "没有可播放的视频资源",
        "benDTZYDYSMZY": "本地跳转云端但是无资源",
        "shaDMY": "啥都没有",
        "shiPSBXC": "视频设备新增",
        "qingXZXRFS": "请选择接入方式",
        "cuoDSR": "最多输入",
        "qingSRYWYZSZYZ": "请输入英文或者数字或者",
        "yuZ": "或者",
        "qingSRSBMC": "请输入设备名称",
        "suoZCP": "所属产品",
        "qingXZSZCP": "请选择所属产品",
        "liuZSMS": "流传输模式",
        "qingXZLZSMS": "请选择流传输模式",
        "sheBCS": "设备厂商",
        "qingSRSBCS": "请输入设备厂商",
        "sheBXX": "设备型号",
        "qingSRSBXX": "请输入设备型号",
        "guJBB": "固件版本",
        "qingSRGJBB": "请输入固件版本",
        "qingSRSM": "请输入说明",
        "shiPSBTG": "视频设备通过",
        "xieRPSZBFW": "接入平台整体分为",
        "paoKPSDPZHSBDPZ": "包括平台端配置和设备端配置",
        "buTDSBDPZDLJYYMCZCY": "不同的设备端配置的路径或页面存在差异",
        "yanPZXJBTTXY": "但配置项基本大同小异",
        "pingSDPZ": "平台端配置",
        "sheBWYBS": "设备唯一标识",
        "qingCXSBDPZDSBBX": "请填写设备端配置的设备编号",
        "zhiTXZXRFSW": "只能选择接入方式为",
        "diCP": "的产品",
        "ruoDQMDYCP": "若当前无对应产品",
        "keDJYZKSTJAN": "可点击右侧快速添加按钮",
        "chenXCPMCHXZ": "填写产品名称和选择",
        "leiXDWGWCCPCJ": "类型的网关完成产品创建",
        "peiZXRMM": "配置接入密码",
        "sheBDPZDMMRYGMMYZ": "设备端配置的密码需与该密码一致",
        "gaiZDKZCP": "该字段可在产品",
        "sheBXRYMJXTYPZ": "设备接入页面进行统一配置",
        "peiZHSYSBQJCCPPZ": "配置后所有设备将继承产品配置",
        "sheBSDXGHQTLJCGJ": "设备单独修改后将脱离继承关系",
        "sheBDPZ": "设备端配置",
        "geGCJ": "各个厂家",
        "buTSBXXDSBDPZYMBJCZCY": "不同设备型号的设备端配置页面布局存在差异",
        "ciCSTHSXTWLZWXRPZZL": "此处以大华摄像头为例作为接入配置示例",
        "fuWQBX": "服务器编号",
        "fuWQBXCRGSBSZCP": "服务器编号填入该设备所属产品",
        "xieRFSYM": "接入方式页面",
        "lianXXX": "连接信息",
        "di": "的",
        "yuTCW": "域通常为",
        "fuWQBXDQ": "服务器编号的前",
        "fuWQ": "服务器",
        "duanKCRGSBSZCP": "端口填入该设备所属产品",
        "xieRFSYMZ": "接入方式页面中",
        "sheBBX": "设备编号",
        "sheBBXWSBWYXBS": "设备编号为设备唯一性标识",
        "wuLWPSDSBXRMYJYGZD": "物联网平台的设备接入没有校验该字段",
        "shuRLYSZYBYXSBXRPS": "输入任意数字均不影响设备接入平台",
        "zhuCMM": "注册密码",
        "chenRGSBSZCP": "填入该设备所属产品",
        "peiZ": "配置",
        "chuDXRMM": "处的接入密码",
        "qiTZD": "其他字段",
        "buYXSBXRPS": "不影响设备接入平台",
        "keBCSBCSHZ": "可保持设备初始化值",
        "guDDZXRPSFW": "固定地址接入平台分为",
        "bu": "步",
        "tianJSPSB": "添加视频设备",
        "tianJSPXDTDDZ": "添加视频下的通道地址",
        "dangQYMWXCSPSB": "当前页面为新增视频设备",
        "xinCWCHDJSBD": "新增完成后点击设备的",
        "tianJTD": "添加通道",
        "jiTQZDSCWYBS": "系统将自动生成唯一标识",
        "zhiTXZXRFSWGDDZDCP": "只能选择接入方式为固定地址的产品",
        "chenXCPMCHXZGDDZLXDWGWCCPCJ": "填写产品名称和选择固定地址类型的网关完成产品创建",
        "bianJZD": "编辑字段",
        "huoQSZCP": "获取所属产品",
        "xinCCP": "新增产品",
        "baoCCG": "保存成功",
        "kuaiSTJ": "快速添加",
        "qingSRCPMC": "请输入产品名称",
        "xieRWG": "接入网关",
        "qingXZXRWG": "请选择接入网关",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "qingX": "请先",
        "tianJ": "添加",
        "huoQXRWG": "获取接入网关",
        "dianJXRWG": "点击接入网关",
        "huoQDYPZ": "获取对应配置",
        "tianJXRWG": "添加接入网关",
        "jiCTJ": "基础统计",
        "xiangQ": "详情",
        "shiPZXYD": "视频中心引导",
        "sheBXRTJBZ": "设备接入推荐步骤",
        "buTDSBYWTXXYDBT": "不同的设备因为通信协议的不同",
        "cunZXRBZDCY": "存在接入步骤的差异",
        "xuanZSB": "选择设备",
        "quanWQZ": "权限控制",
        "fenPZZ": "分屏展示",
        "genJSPSBDZSXY": "根据视频设备的传输协议",
        "zaiSCJDCPXTJDYDSB": "在已创建的产品下添加对应的设备",
        "zhaKSBXDTDSJ": "查看设备下的通道数据",
        "keSJXZB": "可以进行直播",
        "luZDCZ": "录制等操作",
        "duiDGTDDSPLSJJXFPZZ": "对多个通道的视频流数据进行分屏展示",
        "qingXZSB": "请选择设备",
        "huoQSPLX": "获取视频链接",
        "dianJZZSXT": "点击左侧摄像头",
        "boFDYSP": "播放对应视频",
        "dianJJD": "点击节点",
        "shiPWZJD": "是否为子节点",
        "huoQSBLB": "获取设备列表",
        "gengXSJ": "更新数据",
        "huoQZJD": "获取子节点",
        "yiBJZZJDSJ": "异步加载子节点数据",
        "liuMBMC": "流媒体名称",
        "qingSRLMBMC": "请输入流媒体名称",
        "cuoTKSR": "最大可输入",
        "fuWS": "服务商",
        "qingXZFWS": "请选择服务商",
        "miY": "密钥",
        "qingSRMY": "请输入密钥",
        "deZYZYM": "地址或者域名",
        "diaoYLMBXKSQQDFWDZ": "调用流媒体接口时请求的服务地址",
        "qingSRSRDK": "请输入输入端口",
        "shiPSBQLTSDG": "视频设备将流推送到该",
        "deZX": "地址下",
        "buFSBFZC": "部分设备仅支持",
        "jianYQSY": "建议全是用",
        "qingSRQSDK": "请输入起始端口",
        "qiSDK": "起始端口",
        "qingSRZZDK": "请输入终止端口",
        "zhongZDK": "终止端口",
        "dongTDK": "动态端口",
        "zhengCDLMBBTSS": "正常的流媒体不能删除",
        "chaoCDWBYZ": "超出的文本隐藏",
        "yiCYXLXXZ": "溢出用省略号显示",
        "yiCBHX": "溢出不换行"
    },
    "my": {
        "wangZ": "网站",
        "dianH": "电话",
        "youX": "邮箱",
        "deZ": "地址",
        "zhou": "州",
        "shi": "是",
        "jieD": "街道",
        "luo": "路",
        "xiao": "号",
        "benGCZYDYSWBGS": "本公司专业的一些文本介绍",
        "fangJCGJTZPZ": "防劫持告警通知配置",
        "ziDSX": "自动提醒",
        "kai": "开",
        "guan": "关",
        "shiXFS": "提醒方式",
        "shouJ": "手机",
        "sheBGJTZPZ": "设备告警通知配置",
        "xinC": "新增",
        "xiuG": "修改",
        "zanM": "暂无",
        "gaoJTZPZ": "告警通知配置",
        "qingXZGJFS": "请选择告警方式",
        "qingSRDHXM": "请输入电话号码",
        "qingSRYX": "请输入邮箱",
        "sheZCG": "设置成功",
        "guanLSB": "管理设备",
        "xinCSB": "新增设备",
        "yiSSB": "移除设备",
        "xuanZD": "选择的",
        "qingCSXZYSSB": "请最少选择一台设备",
        "shiPSTJJFZ": "是否是添加进分组",
        "fenZ": "分钟",
        "liB": "列表",
        "caoZCG": "操作成功",
        "zuoS": "左手",
        "youS": "右手",
        "taiMZ": "大拇指",
        "shiZ": "湿度",
        "zhongZ": "中指",
        "moMZ": "无名指",
        "xiaoMZ": "小拇指",
        "jinY": "禁用",
        "qiY": "启用",
        "zhiW": "指纹",
        "luRZW": "录入指纹",
        "xuanZZW": "选择指纹",
        "baoC": "保存",
        "shiPXC": "是否新增",
        "dingYH": "订阅后",
        "fanHDXX": "返回的信息",
        "zhiWSZ": "指纹上传",
        "zhiWLX": "指纹类型",
        "luR": "录入",
        "xuanZ": "选择",
        "zhiWXB": "指纹下标",
        "muZ": "拇指",
        "zhiWMB": "指纹模板",
        "gengX": "更新",
        "shanS": "删除",
        "tongS": "重设",
        "shanSSY": "删除所有",
        "sheB": "设备",
        "chanP": "产品",
        "zhiWSZMB": "指纹上传模板",
        "diaoYSZZWMBXKFHD": "调用上传指纹模版接口返回的",
        "zhiWSZYB": "指纹上传失败",
        "zhiWSZCG": "指纹上传成功",
        "tongZ": "重置",
        "kaiQDY": "开启订阅",
        "shangZZWMB": "上传指纹模板",
        "huoQZWWJLB": "获取指纹文件列表",
        "qingXXZZW": "请先选择指纹",
        "miaoN": "秒内",
        "meiYFHSJCGB": "没有返回数据测关闭",
        "meiSJFH": "没数据返回",
        "tianJYB": "添加失败",
        "qingJZSB": "请检查设备",
        "yongHM": "用户名",
        "miM": "密码",
        "weiSZMM": "位数字密码",
        "shiSZ": "是数字",
        "buSSZ": "不是数字",
        "gengXZWLB": "更新指纹列表",
        "xianPDYMYSRYHMGMM": "先判断有没有输入用户名跟密码",
        "qingSRYHMHMM": "请输入用户名和密码",
        "miMZZRYW": "密码长度需要为",
        "weiSZ": "位数字",
        "yiBL": "失败了",
        "fenXSB": "分享设备",
        "sheBXX": "设备信息",
        "sheBMC": "设备名称",
        "xingX": "型号",
        "xuanZYH": "选择用户",
        "qingSRDLYH": "请输入登录用户",
        "sheBGL": "设备管理",
        "huoQYHFZ": "获取用户分组",
        "qingXXZYH": "请先选择用户",
        "qingXZYFXDQW": "请选择要分享的权限",
        "sheBJL": "设备记录",
        "beiFXZ": "被分享者",
        "fenXDQW": "分享的权限",
        "fenXCG": "分享成功",
        "shengJ": "升级",
        "shiQ": "时区",
        "shiJ": "时间",
        "chanPX": "产品号",
        "jiTBB": "系统版本",
        "panDGJSPRYSJ": "判断固件是否需要升级",
        "jiTBBXXZML": "系统版本信息怎么来",
        "xieSD": "写死的",
        "haiSZGXKRLRYSJDSHFHDM": "还是这个接口如果需要升级的时候返回的吗",
        "gengXCG": "更新成功",
        "guJSJSPRY": "固件升级是否需要",
        "jianYJZD": "监听进度的",
        "guJSJCG": "固件升级成功",
        "sheBSZ": "设备设置",
        "jieSPZ": "解锁配置",
        "baoJSZ": "报警设置",
        "fangJCSZ": "防劫持设置",
        "muS": "模式",
        "jieSKG": "解锁开关",
        "linSMM": "临时密码",
        "duoTJSPZ": "多重解锁配置",
        "duoTJSKG": "多重解锁开关",
        "duoTJSMM": "多重解锁密码",
        "duoTZW": "多重指纹",
        "diDLGJ": "低电量告警",
        "fangQGJ": "防撬告警",
        "menWGGJ": "门未关告警",
        "quLGJ": "驱离告警",
        "jieCGJ": "劫持告警",
        "zhaZCSB": "查找此设备",
        "daKHQFCSY": "打开后将发出声音",
        "chiX": "持续",
        "miao": "秒",
        "wenZXWGJSZ": "温度下限告警设置",
        "wenZSWGJSZ": "温度上限告警设置",
        "shiZXWGJSZ": "湿度下限告警设置",
        "shiZSWGJSZ": "湿度上限告警设置",
        "miMYS": "密码钥匙",
        "zhiWYS": "指纹钥匙",
        "di": "低",
        "zhong": "中",
        "gao": "高",
        "huoQYHLB": "获取用户列表",
        "xieZXDSB": "写属性到设备",
        "zhuXZ": "属性值",
        "juBCSCZWMX": "具体参数参照物模型",
        "sheBYHJL": "设备用户记录",
        "shiPSFJCYH": "是否是防劫持用户",
        "yueSFL": "钥匙分类",
        "chuangJLSMM": "创建临时密码",
        "huoQ": "获取",
        "youXJ": "有效期",
        "shiYCS": "使用次数",
        "ci": "次",
        "queDSSLSMM": "确定删除临时密码",
        "queD": "确定",
        "quX": "取消",
        "shanSLSMM": "删除临时密码",
        "miMGL": "密码管理",
        "guanLY": "管理员",
        "puT": "普通",
        "pi": "否",
        "buRY": "不需要",
        "ruY": "需要",
        "queDSSM": "确定删除吗",
        "queDSZZWMBM": "确定上传指纹模板吗",
        "shangZ": "上传",
        "shangZZWMBM": "上传指纹模板吗",
        "siSZZW": "已上传指纹",
        "xinCMM": "新增密码",
        "xinCZWYS": "新增指纹钥匙",
        "chuangJXYH": "创建新用户",
        "qingSRYHM": "请输入用户名",
        "queR": "确认",
        "zhiWWJ": "指纹文件",
        "tanC": "弹窗",
        "miMTC": "密码弹窗",
        "zhiWTC": "指纹弹窗",
        "lianSMM": "零时密码",
        "xinCZW": "新增指纹",
        "zhiWMBTC": "指纹模板弹窗",
        "siJYLSMM": "已经有零时密码",
        "zhaKLSMM": "查看零时密码",
        "yaoGJ": "要更加",
        "xuanZDYHQK": "选择的用户情况",
        "tanCXCZW": "弹窗新增指纹",
        "chuangJYH": "创建用户",
        "daKTC": "打开弹窗",
        "miMGLXZR": "密码管理选择人",
        "zhiWBYDZSXMZ": "指纹秘钥的左手小拇指",
        "queDSZZWMB": "确定上传指纹模板",
        "zheLRYKQ": "这里需要开启",
        "huoQMM": "获取密码",
        "baoCLSMM": "保存零时密码",
        "queSLSMM": "缺少零时密码",
        "queSYXSJ": "缺少有效时间",
        "queSYXCS": "缺少有效次数",
        "linSMMCJCG": "临时密码创建成功",
        "linSMMCJYB": "临时密码创建失败",
        "linSMMSSCG": "临时密码删除成功",
        "qingKDSQ": "清空定时器",
        "sheBXQ": "设备详情",
        "ceS": "测试",
        "dianL": "电量",
        "buZX": "不在线",
        "zaiX": "在线",
        "menZT": "门状态",
        "guanB": "关闭",
        "kaiQ": "开启",
        "wenZ": "温度",
        "liangZ": "亮度",
        "yinL": "音量",
        "kaiS": "开锁",
        "yueSGL": "钥匙管理",
        "fenXGL": "分享管理",
        "sheBSJ": "设备时间",
        "dingYCS": "订阅超时",
        "huoQYTLSJL": "获取一条历史记录",
        "sheBRZ": "设备日志",
        "weiSBRZ": "为设备日志",
        "weiGJRZ": "为告警日志",
        "zhaXCS": "查询参数",
        "xuX": "序号",
        "caoZLX": "操作类型",
        "neiY": "内容",
        "caoZSJ": "操作时间",
        "caoZR": "操作人",
        "gaoJNY": "告警内容",
        "gaoJSJ": "告警时间",
        "qingSRMC": "请输入名称",
        "xinCFZ": "新增分组",
        "xiuGMC": "修改名称",
        "xiuGFZMC": "修改分组名称",
        "chengG": "成功",
        "fenZMC": "分组名称",
        "xuanZCPXX": "选择产品型号",
        "qingDKSB": "请打开设备",
        "qiangSBZXHXTJMS": "将设备置于共享添加模式",
        "xiaYB": "下一步",
        "qingZSBSLRXFDHXPDM": "请在设备上录入下方的共享配对码",
        "youXSJ": "有效时间",
        "siYX": "已失效",
        "fanH": "返回",
        "peiDCG": "配对成功",
        "qingSRSBMC": "请输入设备名称",
        "chanPJT": "产品系统",
        "hongXTJ": "共享添加",
        "huoQSYCPFL": "获取所有产品分类",
        "genJCPFLHQCP": "根据产品分类获取产品",
        "dangQCPFLXMCP": "当前产品分类下无产品",
        "qingHYGCPFL": "请换一个产品分类",
        "huoQHXM": "获取共享码",
        "dangQDLYHM": "当前登录用户名",
        "fenXM": "分享码",
        "sheBFZ": "设备分组",
        "fanHSBLB": "返回设备列表",
        "chanPJL": "产品系列",
        "gaoJRZ": "告警日志",
        "tongMM": "重命名",
        "queDSSSBM": "确定删除设备吗",
        "shanSSB": "删除设备",
        "queDSSFZM": "确定删除分组吗",
        "shanSFZ": "删除分组",
        "hong": "共",
        "siSB": "台设备",
        "riZ": "日志",
        "zhiFFS": "支付方式",
        "zhengC": "正常",
        "huoQLB": "获取列表",
        "sheBBZX": "设备不在线",
        "dianJFZ": "点击分组",
        "shanSCG": "删除成功",
        "dingSX": "订单号",
        "qiaHZH": "客户账户",
        "shiPKP": "是否开票",
        "dingSJE": "订单金额",
        "dingSZT": "订单状态",
        "huiYJB": "会员级别",
        "jiaoYFS": "交易方式",
        "chuangJSJ": "创建时间",
        "shengTYXJ": "剩余有效期",
        "xiaoS": "搜索",
        "qiaHLB": "客户列表",
        "shiYXY": "使用协议",
        "benBFBRJ": "本版发布日期",
        "ning": "年",
        "ru": "月",
        "ri": "日",
        "shengXRJ": "生效日期",
        "yinSBHXYDXYNYYJFSG": "隐私保护协议的协议内容由甲方提供",
        "queRDC": "确认导出",
        "daoC": "导出",
        "queRSS": "确认删除",
        "yongH": "用户",
        "mingC": "名称",
        "shangZSJ": "上传时间",
        "caoZ": "操作",
        "caoZYB": "操作失败",
        "woFXD": "我分享的",
        "liX": "离线",
        "fenXYHLB": "分享用户列表",
        "queDSSFXM": "确定删除分享吗",
        "shanSFX": "删除分享",
        "fenXGWD": "分享给我的",
        "sheBLB": "设备列表",
        "sheBTP": "设备图片",
        "zhuangT": "状态",
        "yongHMC": "用户名称",
        "quXFX": "取消分享",
        "fenXQXCG": "分享取消成功",
        "yongHZCDXYNYYJFSG": "用户注册的协议内容由甲方提供"
    },
    "user": {
        "shiYXY": "使用协议",
        "benBFBRJ": "本版发布日期",
        "ning": "年",
        "ru": "月",
        "ri": "日",
        "shengXRJ": "生效日期",
        "yongHZCDXYNYYJFSG": "用户注册的协议内容由甲方提供",
        "bangD": "绑定",
        "tanCXG": "弹窗相关",
        "caoZCG": "操作成功",
        "qingXZYBDDYH": "请选择要绑定的用户",
        "xingM": "姓名",
        "yongHM": "用户名",
        "shouDXZGB": "手动选择改变",
        "fenYH": "分页会",
        "quXXZ": "取消选择",
        "bangDYH": "绑定用户",
        "shiPJSBD": "是否解除绑定",
        "piLJB": "批量解绑",
        "zhengC": "正常",
        "jinY": "禁用",
        "zhuangT": "状态",
        "caoZ": "操作",
        "xiaoSCS": "搜索参数",
        "biaoL": "表格",
        "biaoLSL": "表格实例",
        "qingGXRYJBDSJ": "请勾选需要解绑的数据",
        "shuaXLB": "刷新列表",
        "beiA": "备案",
        "yu": "渝",
        "bei": "备",
        "xiao": "号",
        "yuY": "语言",
        "zhongW": "中文",
        "yingW": "英文",
        "zhangX": "账号",
        "qingSRZX": "请输入账号",
        "miM": "密码",
        "qingSRMM": "请输入密码",
        "yanZM": "验证码",
        "qingSRYZM": "请输入验证码",
        "jiZW": "记住我",
        "deL": "登录",
        "meiYZX": "没有账号",
        "qingN": "请您",
        "xiaZ": "下载",
        "zhuC": "注册",
        "qiuDQXLZKSHTPJT": "团队全新力作可视化大屏系统",
        "benY": "体验",
        "xianZ": "显示",
        "diXZEWM": "的下载二维码",
        "huanYSY": "欢迎使用",
        "erWM": "二维码",
        "quDLLQD": "取得浏览器的",
        "ziFC": "字符串",
        "panDSP": "判断是否",
        "liuLQ": "浏览器",
        "chuangJYG": "创建一个",
        "shiL": "实例",
        "jiaMFF": "加密方法",
        "sheZGY": "设置公钥",
        "jiaM": "加密",
        "jiaMHDSJ": "加密后的数据"
    },
    "Northbound": {
        "aLYWLWPS": "阿里云物联网平台",
        "gaiS": "概述",
        "zaiTDCJX": "在特定场景下",
        "sheBMFZXXRALYWLWPSS": "设备无法直接接入阿里云物联网平台时",
        "ninKXQSBXRWLWPS": "您可先将设备接入物联网平台",
        "zaiSYALY": "再使用阿里云",
        "yunYDX": "云云对接",
        "kuaiSGJQXFW": "快速构建桥接服务",
        "taJWLWPSYALYWLWPSDSXSJTD": "搭建物联网平台与阿里云物联网平台的双向数据通道",
        "peiZSM": "配置说明",
        "fuWDZ": "服务地址",
        "aLYNBGMSJQSZDWYBX": "阿里云内部给每台机器设置的唯一编号",
        "qingGJGMDALYFWQDZJXXZ": "请根据购买的阿里云服务器地址进行选择",
        "huoQLJ": "获取路径",
        "yongXCXTZFSDYYFWF": "用于程序通知方式调用云服务费",
        "diYHBSHBYHQLJ": "的用户标识和秘钥获取路径",
        "aLYGLQZS": "阿里云管理控制台",
        "yongHTX": "用户头像",
        "guanL": "管理",
        "zhaK": "查看",
        "wangQCP": "网桥产品",
        "wuLWPSDXALYWLWPS": "物联网平台对于阿里云物联网平台",
        "shiYGWGSB": "是一个网关设备",
        "ruYYSDALYWLWPSDJBCP": "需要映射到阿里云物联网平台的具体产品",
        "chanPYS": "产品映射",
        "qiangALYWLWPSZDCPSLYWLWPSDCPSLJXGL": "将阿里云物联网平台中的产品实例与物联网平台的产品实例进行关联",
        "guanLHRYJRGCPXDMYGSBDSLXXY": "关联后需要进入该产品下的每一个设备的实例信息页",
        "chenRDYDALYWLWPSSBD": "填入对应的阿里云物联网平台设备的",
        "jinXYDYBD": "进行一对一绑定",
        "jiBXX": "基本信息",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "cuoDSR": "最多输入",
        "geZF": "个字符",
        "qingXZFWDZ": "请选择服务地址",
        "shiL": "实例",
        "aLYWLWPSZDSL": "阿里云物联网平台中的实例",
        "meiYZBC": "没有则不填",
        "qingSRSL": "请输入实例",
        "qingSR": "请输入",
        "yongXCXTZFSDYYFW": "用于程序通知方式调用云服务",
        "diYHBS": "的用户标识",
        "diBYBS": "的秘钥标识",
        "qingXZWQCP": "请选择网桥产品",
        "wuLWPSDYDALYCP": "物联网平台对应的阿里云产品",
        "aLYCP": "阿里云产品",
        "qingXZALYCP": "请选择阿里云产品",
        "pingSCP": "平台产品",
        "qingXZPSCP": "请选择平台产品",
        "tianJ": "添加",
        "shuiM": "说明",
        "qingSRSM": "请输入说明",
        "baoC": "保存",
        "caoZCG": "操作成功",
        "xinC": "新增",
        "zhuangT": "状态",
        "zhengC": "正常",
        "jinY": "禁用",
        "caoZ": "操作",
        "bianJ": "编辑",
        "qiY": "启用",
        "queR": "确认",
        "caoZYB": "操作失败",
        "shanS": "删除",
        "qingXJYGSJ": "请先禁用该数据",
        "zaiSS": "再删除",
        "queRSS": "确认删除",
        "qingXZ": "请选择",
        "shi": "是",
        "pi": "否",
        "cenSMC": "参数名称",
        "leiX": "类型",
        "zhi": "值",
        "zhiLLX": "指令类型",
        "qingXZZLLX": "请选择指令类型",
        "duQZX": "读取属性",
        "xiuGZX": "修改属性",
        "diaoYGT": "调用功能",
        "zhuX": "属性",
        "qingXZZX": "请选择属性",
        "qingSRZ": "请输入值",
        "gongT": "功能",
        "qingXZGT": "请选择功能",
        "cenSLB": "参数列表",
        "qingSRCSLB": "请输入参数列表",
        "xiaoZZTJJKFPS": "小度智能家居开放平台",
        "zhiCJJCJXDYDQZ": "支持家居场景下的云端控制",
        "gaiYMZYQPSDCPY": "该页面主要将平台的产品与",
        "zhiCYYQZDCPJXYS": "支持语音控制的产品进行映射",
        "siDTXZPSQZBPSSBDMD": "以到达小度平台控制本平台设备的目的",
        "caoZBZ": "操作步骤",
        "zaiMZXZJTPSCJJT": "在百度小度技能平台创建技能",
        "bingSQ": "并授权",
        "wanCWLWPSY": "完成物联网平台与",
        "diGL": "的关联",
        "shouQDZ": "授权地址",
        "wuLWPSDDLDZ": "物联网平台的登录地址",
        "zhuYRYW": "注意需要为",
        "qingFZBCX": "请复制并填写",
        "qingCXJTGL": "请填写系统管理",
        "yingYGLZD": "应用管理中的",
        "huiDDZ": "回调地址",
        "qingFZ": "请复制",
        "pingSZDZ": "平台中的值",
        "chenXDJTGL": "填写到系统管理",
        "yingYGLZ": "应用管理中",
        "zhong": "中",
        "deZ": "地址",
        "qingFZJTGL": "请复制系统管理",
        "chenXD": "填写到",
        "pingS": "平台",
        "deLWLWPS": "登录物联网平台",
        "jinXPSNCPY": "进行平台内产品与",
        "chanPDSJYS": "产品的数据映射",
        "zhiTJJYHTGWLWPSZDYH": "智能家居用户通过物联网平台中的用户",
        "deLXZ": "登录小度",
        "huoQPSNDQYHDSZSB": "获取平台内当前用户的所属设备",
        "huoQHJKJXYYQZ": "获取后即可进行语音控制",
        "sheBLX": "设备类型",
        "wei": "为",
        "pingSNDDBZGF": "平台拟定的标准规范",
        "sheBLXQJD": "设备类型将决定",
        "dongZYS": "动作映射",
        "dongZ": "动作",
        "diXLXX": "的下拉选项",
        "siJ": "以及",
        "zhuXYS": "属性映射",
        "chanP": "产品",
        "qingXZCP": "请选择产品",
        "qingXZSBLX": "请选择设备类型",
        "pingSNDDGF": "平台拟定的规范",
        "chanPMC": "产品名称",
        "qingXZDZ": "请选择动作",
        "pingSNDDSBLXJYDXGDZ": "平台拟定的设备类型具有的相关动作",
        "qingXZCZ": "请选择操作",
        "yangSWLWPSZSXCPJBDDZ": "映射物联网平台中所选产品具备的动作",
        "xiaFZL": "下发指令",
        "huoQLSSJ": "获取历史数据",
        "xinCDZ": "新增动作",
        "pingSZX": "平台属性",
        "qingXZPSZX": "请选择平台属性",
        "xinCZX": "新增属性",
        "zhaXCPLB": "查询产品列表",
        "quNTFD": "去掉重复的"
    },
    "notice": {
        "diaoS": "调试",
        "quX": "取消",
        "queD": "确定",
        "tongZMB": "通知模板",
        "gaiZDWBCZD": "该字段为必填字段",
        "qingXZTZMB": "请选择通知模板",
        "bianL": "变量",
        "huoQTZMB": "获取通知模板",
        "huoQMBXQ": "获取模板详情",
        "dianHZDJY": "电话字段校验",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "qingSRYXXM": "请输入有效号码",
        "mingC": "名称",
        "zhi": "值",
        "biaoSSJ": "表单提交",
        "shiJ": "时间",
        "caoZCG": "操作成功",
        "qingQTKBJBL": "请求头可编辑表格",
        "tianJ": "添加",
        "caoZ": "操作",
        "aLYGLQZS": "阿里云管理控制台",
        "gaiS": "概述",
        "tongZPZKSJHTZPZWGJXXTZSGZC": "通知配置可以结合通知配置为告警消息通知提供支撑",
        "yiKSYXJTZQTZDYMKDDY": "也可以用于系统中其他自定义模块的调用",
        "tongZPZSM": "通知配置说明",
        "aLYNBGMSJQSZDWYBX": "阿里云内部给每台机器设置的唯一编号",
        "qingGJGMDALYFWQDZJXCX": "请根据购买的阿里云服务器地址进行填写",
        "aLYDYHKYQDZBDZ": "阿里云地域和可用区对照表地址",
        "yongXCXTZFSDYYFWF": "用于程序通知方式调用云服务费",
        "diYHBSHBY": "的用户标识和秘钥",
        "huoQLJ": "获取路径",
        "yongHTX": "用户头像",
        "guanL": "管理",
        "zhaK": "查看",
        "aLYFWDYYDYD": "阿里云服务地域与对应的",
        "qingGJGMDALYFWQDYJXXZ": "请根据购买的阿里云服务器地域进行选择",
        "diYHBSHBYHQLJ": "的用户标识和秘钥获取路径",
        "yaMSGLQZS": "亚马孙管理控制台",
        "yongHDPZ": "用户的凭证",
        "sheZDZ": "设置地址",
        "yongHDBY": "用户的秘钥",
        "dingDKFPS": "钉钉开放平台",
        "qiYNBYYDWYSFBS": "企业内部应用的唯一身份标识",
        "zaiDDKFZHSCJQYNBYYH": "在钉钉开发者后台创建企业内部应用后",
        "jiTHZDSCYD": "系统会自动生成一对",
        "huo": "和",
        "yingYKF": "应用开发",
        "yingYXX": "应用信息",
        "dingDYYDYDDYMY": "钉钉应用对应的调用密钥",
        "zaiDDQNMCJYGDDQZDYJQRDHCSWYD": "在钉钉群内每创建一个钉钉群自定义机器人都会产生唯一的",
        "deZ": "地址",
        "dingDZMQHD": "钉钉桌面客户端",
        "qunSZ": "群设置",
        "zhiTQCS": "智能群助手",
        "jiQRXX": "机器人信息",
        "deLDDZMQHD": "登录钉钉桌面客户端",
        "jinRQSZ": "进入群设置",
        "dianJZTQCS": "点击智能群助手",
        "zhaKJQRXX": "查看机器人信息",
        "fuWQDZ": "服务器地址",
        "xiaLKXZGNCYDYXFWPZ": "下拉可选择国内常用的邮箱服务配置",
        "yiZCSDSRQTDZ": "也支持手动输入其他地址",
        "jiTSY": "系统使用",
        "xieY": "协议",
        "yuanHDZYJQHDXZFWQSDYJ": "允许电子邮件客户端下载服务器上的邮件",
        "yanSNZDZYJQHDDCZ": "但是您在电子邮件客户端的操作",
        "ru": "如",
        "yiDYJ": "移动邮件",
        "biaoJSDD": "标记已读等",
        "zheSBHFKDFWQS": "这时不会反馈到服务器上",
        "faJR": "发件人",
        "yongXFSYJS": "用于发送邮件时",
        "xinXDXZ": "信息的显示",
        "yongHM": "用户名",
        "yongGZXJXFSYJ": "用该账号进行发送邮件",
        "miM": "密码",
        "yongXZXSFRZ": "用于账号身份认证",
        "renZTGHKTGGZXJXFSYJ": "认证通过后可通过该账号进行发送邮件",
        "shiYGXS": "是一个接收",
        "qingQD": "请求的",
        "benPSMRZZC": "本平台默认只支持",
        "qingQ": "请求",
        "shiXL": "实现了",
        "diDSFJTKSJXG": "的第三方系统可以基于该",
        "dingYBPSJTXX": "订阅本平台系统信息",
        "benPSAPZBTDDZJJLTSDZDDDZ": "本平台按配置把特定的事件结果推送到指定的地址",
        "bianXJTZHXCL": "便于系统做后续处理",
        "qingQT": "请求头",
        "zhiCGJJTSGDXKSZBTDQQT": "支持根据系统提供的接口设置不同的请求头",
        "weiXGZPS": "微信公众平台",
        "weiXFWXDWYZZBX": "微信服务号的唯一专属编号",
        "guanLHS": "管理后台",
        "sheZYKF": "设置与开发",
        "jiBPZ": "基本配置",
        "gongZXKFZSFDMM": "公众号开发者身份的密码",
        "qiYWXGLHS": "企业微信管理后台",
        "qiYXDWYZZBX": "企业号的唯一专属编号",
        "qiYWX": "企业微信",
        "woDQY": "我的企业",
        "qiY": "企业",
        "yingYDWY": "应用的唯一",
        "yiGQYWXZKSYDG": "一个企业微信中可以有多个",
        "yingYYXCX": "应用与小程序",
        "ziJYY": "自建应用",
        "zhongHQ": "中获取",
        "tongZPZXQ": "通知配置详情",
        "tongZFS": "通知方式",
        "qingXZTZFS": "请选择通知方式",
        "qingSRMC": "请输入名称",
        "leiX": "类型",
        "dingD": "钉钉",
        "qingSR": "请输入",
        "weiX": "微信",
        "youJ": "邮件",
        "qingSRFWQDZ": "请输入服务器地址",
        "kaiQ": "开启",
        "qingSRFJR": "请输入发件人",
        "qingSRYHM": "请输入用户名",
        "qingSRMM": "请输入密码",
        "yuY": "语音",
        "duanX": "短信",
        "qingXZ": "请选择",
        "shuiM": "说明",
        "qingSRSM": "请输入说明",
        "baoC": "保存",
        "xiaoXLX": "消息类型",
        "dingDXX": "钉钉消息",
        "qunJQRXX": "群机器人消息",
        "genJTZFSZZDYDZD": "根据通知方式展示对应的字段",
        "yanZGZ": "验证规则",
        "qingXZLX": "请选择类型",
        "aLYYY": "阿里云语音",
        "ruYSYGHFD": "需要是一个合法的",
        "qingSRBY": "请输入秘钥",
        "tongZFSGB": "通知方式改变",
        "tongZLXGB": "通知类型改变",
        "tongZZDZ": "重置字段值",
        "baoCCG": "保存成功",
        "shuJLY": "数据来源",
        "gongHY": "公共云",
        "zhongGDQ": "中国地区",
        "paoHZGXH": "包含中国香港",
        "zhongGAM": "中国澳门",
        "buPHZGSW": "不包含中国台湾",
        "huaB": "华北",
        "jingD": "青岛",
        "beiJ": "北京",
        "zhangJK": "张家口",
        "xuHGT": "呼和浩特",
        "wuLCB": "乌兰察布",
        "huaD": "华东",
        "kangZ": "杭州",
        "shangH": "上海",
        "nanJ": "南京",
        "benDDY": "本地地域",
        "fuZ": "福州",
        "huaN": "华南",
        "shenH": "深圳",
        "heY": "硅谷",
        "yanZ": "广州",
        "xiN": "悉尼",
        "chengD": "成都",
        "zhongGXH": "中国香港",
        "qiTGJHDQ": "其他国家和地区",
        "xinJP": "新加坡",
        "aoTLY": "澳大利亚",
        "maLXY": "马来西亚",
        "jiLP": "吉隆坡",
        "yinZNXY": "印度尼西亚",
        "yaJT": "雅加达",
        "feiLB": "菲律宾",
        "maNL": "马尼拉",
        "taiG": "泰国",
        "manY": "曼谷",
        "yinZ": "印度",
        "mengM": "孟买",
        "riB": "日本",
        "dongJ": "东京",
        "hanG": "韩国",
        "shouE": "首尔",
        "meiG": "美国",
        "fuJNY": "弗吉尼亚",
        "deG": "德国",
        "faLKF": "法兰克福",
        "yingG": "英国",
        "lunZ": "伦敦",
        "aLQ": "阿联酋",
        "diB": "迪拜",
        "tongZPZ": "通知配置",
        "xinC": "新增",
        "daoR": "导入",
        "queRDC": "确认导出",
        "daoC": "导出",
        "peiZMC": "配置名称",
        "xiaoS": "搜索",
        "genJTZFSZZDY": "根据通知方式展示对应",
        "tongZFSZDZZDYWZ": "通知方式字段展示对应文字",
        "genJLXZZDYWA": "根据类型展示对应文案",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "tongZPZSJ": "通知配置数据",
        "bianJ": "编辑",
        "shanS": "删除",
        "queRSS": "确认删除",
        "caoZYB": "操作失败",
        "qiT": "其他",
        "tongBYH": "同步用户",
        "tongZJL": "通知记录",
        "faSSJ": "发送时间",
        "zhuangT": "状态",
        "chengG": "成功",
        "yiB": "失败",
        "zhaKCWXX": "查看错误信息",
        "cuoWXX": "错误信息",
        "zhaKXQ": "查看详情",
        "xiangQXX": "详情信息",
        "qingSRBMMC": "请输入部门名称",
        "ziDBD": "自动绑定",
        "di": "第",
        "tiao": "条",
        "zongH": "总共",
        "bangDYH": "绑定用户",
        "yongH": "用户",
        "qingXZYH": "请选择用户",
        "zuoZBM": "左侧部门",
        "huoQBM": "获取部门",
        "buMDJ": "部门点击",
        "youZBL": "右侧表格",
        "dingDYHM": "钉钉用户名",
        "bangDZT": "绑定状态",
        "bangD": "绑定",
        "jieB": "解绑",
        "queRJB": "确认解绑",
        "huoQDD": "获取钉钉",
        "weiXBMYH": "微信部门用户",
        "huoQSJBDDYH": "获取已经绑定的用户",
        "huoQSYYHWBDDYH": "获取所有用户未绑定的用户",
        "chuLLBSJ": "处理列表数据",
        "weiBDDYH": "未绑定的用户",
        "bangDDYH": "绑定的用户",
        "siBD": "已绑定",
        "weiBD": "未绑定",
        "qianDFY": "前端分页",
        "yongHXLSX": "用户下拉筛选",
        "bangDSJ": "绑定提交",
        "qiYXX": "企业消息",
        "fuWXXX": "服务号消息",
        "aLYDX": "阿里云短信",
        "ziDGJYS": "字段关系映射",
        "peiZ": "配置",
        "muB": "模板",
        "dingDJQR": "钉钉机器人",
        "yuYTZLX": "语音通知类型",
        "yuYTZ": "语音通知",
        "yuYYZM": "语音验证码",
        "qingXZTZPZ": "请选择通知配置",
        "sheZMRPZ": "设置默认配置",
        "buJXX": "附件信息",
        "shanSBJ": "删除附件",
        "tianJBJ": "添加附件",
        "buJBS": "附件标识",
        "qingXZSXBM": "请选择收信部门",
        "qingXZBQTS": "请选择标签推送",
        "qingXZSXR": "请选择收信人",
        "muBNY": "模板内容",
        "bianLLB": "变量列表",
        "mo": "无",
        "biaoSJY": "表单校验",
        "shouDTJJY": "手动添加校验",
        "gaiZDSBCZD": "该字段是必填字段",
        "ziFC": "字符串",
        "shuZ": "数字",
        "luoSW": "格式为",
        "daiBSZBLDXSWS": "代表数字保留的小数位数",
        "dang": "当",
        "shi": "时",
        "daiBLSWZS": "代表格式为整数",
        "luoS": "格式",
        "aLYDXFWPS": "阿里云短信服务平台",
        "tongZMBJHTZPZWGJXXTZSGZC": "通知模板结合通知配置为告警消息通知提供支撑",
        "tongZMBZTDYTYLXDTZPZFW": "通知模板只能调用同一类型的通知配置服务",
        "shiYALYDXSRXZALYDXFWPSCJDXMB": "使用阿里云短信时需先在阿里云短信服务平台创建短信模板",
        "muBPZSM": "模板配置说明",
        "bangDPZ": "绑定配置",
        "shiYGDDTZPZFSCTZMB": "使用固定的通知配置发送此通知模板",
        "aLYDXPSZDYDMBMC": "阿里云短信平台自定义的模板名称",
        "shouXR": "收信人",
        "dangQFZCGNSJX": "当前仅支持国内手机号",
        "ciCRBC": "此处若不填",
        "zeZMBDSHPZGJTZSSDCX": "则在模板调试和配置告警通知时手动填写",
        "qianM": "签名",
        "yongXDXNYQMXXXZ": "用于短信内容签名信息显示",
        "ruZALYDXJXPZ": "需在阿里云短信进行配置",
        "bianLZX": "变量属性",
        "ruYZDQYMSDSZYALYDXMBZYYDBL": "需要在当前页面手动设置与阿里云短信模板中一样的变量",
        "piZHDZFSYC": "否则会导致发送异常",
        "aLYYYFWPS": "阿里云语音服务平台",
        "shiYALYYYSRXZALYYYFWPSCJYYMB": "使用阿里云语音时需先在阿里云语音服务平台创建语音模板",
        "aLYYYTZLX": "阿里云语音通知类型",
        "dangLXWYZMLXSKPZBL": "当类型为验证码类型时可配置变量",
        "aLYYYDMYTYYTZFPDWY": "阿里云语音对每一条语音通知分配的唯一",
        "biaoS": "标识",
        "beiJXM": "被叫号码",
        "ruoNSYDYYTZWJWGHMSWX": "若您使用的语音通知文件为公共模式外呼",
        "zeGCSZBC": "则该参数值不填",
        "ruoNSYDYYTZWJWZZMSWX": "若您使用的语音通知文件为专属模式外呼",
        "zeBXZRSGMDXM": "则必须传入已购买的号码",
        "fuZCYGXM": "仅支持一个号码",
        "beiJXX": "被叫显号",
        "yongHXJXMXZ": "用户呼叫号码显示",
        "biXSZALYGMDXM": "必须是在阿里云购买的号码",
        "boFCS": "播放次数",
        "cuoDKBF": "最多可播放",
        "ci": "次",
        "fuDTZLXWYZMLXSKJXPZ": "仅当通知类型为验证码类型时可进行配置",
        "bianLBSRYALYMBZDBSYZ": "变量标识需要阿里云模板中的标识一致",
        "zhiCCXDBLDDTMB": "支持填写带变量的动态模板",
        "bianLCXGFZL": "变量填写规范示例",
        "chenXDTCSH": "填写动态参数后",
        "keDBLDMC": "可对变量的名称",
        "luoSJXPZ": "格式进行配置",
        "siBGJTZSCX": "以便告警通知时填写",
        "dingDGLHS": "钉钉管理后台",
        "shiYDDXXTZSRZDDKFPSZCJHDYDYY": "使用钉钉消息通知时需在钉钉开放平台中创建好对应的应用",
        "yingYWYBS": "应用唯一标识",
        "zhaKYY": "查看应用",
        "shouXBM": "收信部门",
        "ruoBCXSXR": "若不填写收信人",
        "tongXL": "通讯录",
        "zhaKYH": "查看用户",
        "bianJBM": "编辑部门",
        "shiYDDQJQRXXTZSRZDDKFPSZCJHDYDJQR": "使用钉钉群机器人消息通知时需在钉钉开放平台中创建好对应的机器人",
        "zaiDDDQHDZDYDQZBDZTJQR": "再到钉钉客户端在对应的群中绑定智能机器人",
        "muQZC": "目前支持",
        "chong": "种",
        "biaoT": "标题",
        "fuWQDZZCZDYSR": "服务器地址支持自定义输入",
        "zhiCSRBL": "支持输入变量",
        "bianLLS": "变量格式",
        "shouJR": "收件人",
        "zhiCLRDGYXDZ": "支持录入多个邮箱地址",
        "keCXBLCS": "可填写变量参数",
        "qingQB": "请求体",
        "qingQBZDSJLZXFSTZSZDDSYBL": "请求体中的数据来自于发送通知时指定的所有变量",
        "yiKTGZDYDFSJXBLPZ": "也可通过自定义的方式进行变量配置",
        "shiY": "使用",
        "tongZS": "通知时",
        "jiTHQGZJTGNZDD": "系统会将该事件通过您指定的",
        "si": "以",
        "fangSFS": "方式发送",
        "yongHBQ": "用户标签",
        "siBQDWZTZGBQXSYYH": "以标签的维度通知该标签下所有用户",
        "xiaoXMB": "消息模板",
        "weiXGZXZPZDXXMB": "微信公众号中配置的消息模板",
        "muBTZLX": "模板跳转链接",
        "dianJXXZHJXYMTZ": "点击消息之后进行页面跳转",
        "tiaoZXCX": "跳转小程序",
        "dianJXXZHDKDYDXCX": "点击消息之后打开对应的小程序",
        "tiaoZXCXJBLJ": "跳转小程序具体路径",
        "dianJXXZHTZDXCXDJBYM": "点击消息之后跳转到小程序的具体页面",
        "yingYGL": "应用管理",
        "yingY": "应用",
        "biaoQTS": "标签推送",
        "xieSTZD": "接收通知的",
        "chongFS": "种方式",
        "geZDRZCYMDMYCX": "个字段若在此页面都没有填写",
        "zeZMBDSHPZGJTZSRYSDCX": "则在模板调试和配置告警通知时需要手动填写",
        "chengYXX": "成员信息",
        "zhaKCYZX": "查看成员账号",
        "shouXZZ": "收信组织",
        "buMXX": "部门信息",
        "zhaKBM": "查看部门",
        "tongZMBXQ": "通知模板详情",
        "shiYGDDTZPZLFSCTZMB": "使用固定的通知配置来发送此通知模板",
        "qingXZBDPZ": "请选择绑定配置",
        "ruLBCXGZD": "如果不填写该字段",
        "qiangZSYCMBFSTZSJXZD": "将在使用此模板发送通知时进行指定",
        "qingXZXXLX": "请选择消息类型",
        "qingSRBT": "请输入标题",
        "tuPLX": "图片链接",
        "qingSRTPLX": "请输入图片链接",
        "neiYLX": "内容链接",
        "qingSRNYLX": "请输入内容链接",
        "benQYWXDBQ": "本企业微信的标签",
        "liB": "列表",
        "cuoDZC": "最多支持",
        "ge": "个",
        "youJBT": "邮件标题",
        "duoGSJRYHXFJ": "多个收件人用换行分隔",
        "cuoTZC": "最大支持",
        "geXM": "个号码",
        "qingSRSJRYX": "请输入收件人邮箱",
        "buJZSRWJMCQZFSYJSJXWJSZ": "附件只输入文件名称将在发送邮件时进行文件上传",
        "yuYYZMLXKPZBL": "语音验证码类型可配置变量",
        "bingJZZCSZHYWZW": "并且只支持数字和英文字母",
        "aLYNBFPDWY": "阿里云内部分配的唯一",
        "qingSRMB": "请输入模板",
        "fuZCZGTLXM": "仅支持中国大陆号码",
        "qingSRBJXM": "请输入被叫号码",
        "biXSSGMDXM": "必须是已购买的号码",
        "yongXXJXMXZ": "用于呼叫号码显示",
        "qingSRBJXX": "请输入被叫显号",
        "yuYWJDBFCS": "语音文件的播放次数",
        "qingSRBFCS": "请输入播放次数",
        "yuYYZMNYSRK": "语音验证码内容输入框",
        "yongXXRYYYZMBL": "用于渲染验语音证码变量",
        "neiYZDBLQYXALYYYYZM": "内容中的变量将用于阿里云语音验证码",
        "qingXZMB": "请选择模板",
        "qingSRSXR": "请输入收信人",
        "qingXZQM": "请选择签名",
        "dianHXM": "电话号码",
        "moR": "默认",
        "ziDY": "自定义",
        "faSDNY": "发送的内容",
        "zhiCLRBL": "支持录入变量",
        "zhiL": "示例",
        "zunJD": "尊敬的",
        "youSBCFGJ": "有设备触发告警",
        "qingZYCL": "请注意处理",
        "youJLSCW": "邮件格式错误",
        "fuZC": "仅支持",
        "qingSRQM": "请输入签名",
        "gaiZDBSYXDSJX": "该字段不是有效的手机号",
        "qingSRMBNY": "请输入模板内容",
        "ziDSQBL": "字段提取变量",
        "qiangRYSQBLDZDZPXWYGZFC": "将需要提取变量的字段值拼接为一个字符串",
        "yongXTYSQBL": "用于统一提取变量",
        "genJZDSRNY": "根据字段输入内容",
        "shiQBL": "提取变量",
        "siJCZDBL": "已经存在的变量",
        "zhengZSQ": "正则提取",
        "liMDZ": "里面的值",
        "xiaoXLXXZGB": "消息类型选择改变",
        "huoQXQ": "获取详情",
        "aLYYYMBNYZDCYBM": "阿里云语音模板内容字段采用别名",
        "huoQBDPZ": "获取绑定配置",
        "peiZXZGB": "配置选择改变",
        "huoQALMB": "获取阿里模板",
        "duanXMBGB": "短信模板改变",
        "huoQQM": "获取签名",
        "bianLLBCZ": "变量列表存在",
        "juCZKZ": "且存在空值",
        "youJMYPZZD": "邮件没有配置字段",
        "ziDYQTLXD": "字段与其他类型的",
        "ziDTM": "字段重名",
        "yanYYBRYBCYZ": "但语音不需要必填验证",
        "quBM": "取别名",
        "yanZTGZH": "验证通过之后",
        "fuZH": "赋值回",
        "ziD": "字段",
        "bingSSBMZD": "并删除别名字段",
        "muBMC": "模板名称",
        "tongZMBSJ": "通知模板数据",
        "bangDPZLX": "绑定配置类型"
    },
    "oauth": {
        "deL": "登录",
        "shouQDL": "授权登录",
        "ninZZSQDL": "您正在授权登录",
        "qiangHDSXQW": "将获得以下权限",
        "guanL": "关联",
        "zhangX": "账号",
        "huoQNDGRXX": "获取您的个人信息",
        "tongYSQ": "同意授权",
        "qiHZX": "切换账号",
        "yongHM": "用户名",
        "qingSRYHM": "请输入用户名",
        "miM": "密码",
        "qingSRMM": "请输入密码",
        "qingSRYZM": "请输入验证码",
        "shouQ": "授权",
        "huoQYZMPZ": "获取验证码配置",
        "weiDL": "未登录",
        "shiPZ": "是否走",
        "huoQ": "获取",
        "zhongDPZXX": "中的配置信息",
        "huoQYHXX": "获取用户信息"
    },
    "rule-engine": {
        "gaoJJBPZ": "告警级别配置",
        "jiB": "级别",
        "baoC": "保存",
        "gongTSM": "功能说明",
        "gaoJJBYXMSGJDYTCZ": "告警级别用于描述告警的严重程度",
        "qingGJYWGLFSJXZDY": "请根据业务管理方式进行自定义",
        "gaoJJBQHZGJPZZBYY": "告警级别将会在告警配置中被引用",
        "cuoDKPZ": "最多可配置",
        "geJB": "个级别",
        "gaoJJB": "告警级别",
        "shuJLZ": "数据流转",
        "caoZCG": "操作成功",
        "gaoJSJSC": "告警数据输出",
        "qiangGJSJSCDQTDSFJT": "将告警数据输出到其他第三方系统",
        "deZ": "地址",
        "zhuangT": "状态",
        "gaoJCLJLSR": "告警处理结果输入",
        "xieSDSFJTCLDGJJL": "接收第三方系统处理的告警结果",
        "gongTTZ": "功能图示",
        "pingSZCQGJSJSCD": "平台支持将告警数据输出到",
        "diSFJTKDY": "第三方系统可订阅",
        "zhongDGJSJ": "中的告警数据",
        "jinXYWCL": "进行业务处理",
        "shuCCS": "输出参数",
        "zhiL": "示例",
        "pingSZCDY": "平台支持订阅",
        "zhongGJCLSJ": "中告警处理数据",
        "bingGXGJJLZT": "并更新告警记录状态",
        "dingYCS": "订阅参数",
        "gaoJPZMC": "告警配置名称",
        "tuiSDGJPZMC": "推送的告警配置名称",
        "yinGGJ": "烟感告警",
        "gaoJPZ": "告警配置",
        "tuiSDGJPZ": "推送的告警配置",
        "gaoJSJ": "告警时间",
        "gaoJWYXBS": "告警唯一性标识",
        "gaoJJL": "告警记录",
        "gaoJJLDWYBS": "告警记录的唯一标识",
        "keGJC": "可根据此",
        "chuLGJ": "处理告警",
        "gaoJMBLX": "告警目标类型",
        "gaoJSZDYWLX": "告警所属的业务类型",
        "juBYCP": "具体有产品",
        "sheB": "设备",
        "buM": "部门",
        "qiT": "其他",
        "chanP": "产品",
        "gaoJMB": "告警目标",
        "gaoJMBWYXBS": "告警目标唯一性标识",
        "gaoJMBMC": "告警目标名称",
        "gaoJMBSLMC": "告警目标实例名称",
        "haiKYG": "海康烟感",
        "gaoJCFSJ": "告警触发时间",
        "gaoJYLX": "告警源类型",
        "chuFGJDYLX": "触发告警的源类型",
        "dangQZY": "当前只有",
        "gaoJY": "告警源",
        "chuFGJDY": "触发告警的源",
        "ruSB": "如设备",
        "gaoJYMC": "告警源名称",
        "chuFGJDYMC": "触发告警的源名称",
        "ruSBMC": "如设备名称",
        "louYG": "楼烟感",
        "gaoJYTCZZB": "告警严重程度指标",
        "gaoJSM": "告警说明",
        "gaoJGZSM": "告警规则说明",
        "louYGTYGJGZSZ": "楼烟感统一告警规则设置",
        "shi": "时",
        "chuLSJ": "处理时间",
        "gaoJCLSJ": "告警处理时间",
        "buCSMRWXXCLSJ": "不填是默认为消息处理时间",
        "chuLSM": "处理说明",
        "gaoJCLNYXXMSSM": "告警处理内容详细描述说明",
        "siLJDSFRYJXGJCL": "已联系第三方人员进行告警处理",
        "xianGJSHF": "现告警已恢复",
        "chuLLX": "处理类型",
        "zhiC": "支持",
        "chuLHDZT": "处理后的状态",
        "mingC": "名称",
        "biaoS": "标识",
        "leiX": "类型",
        "shuiM": "说明",
        "zhiLZ": "示例值",
        "biC": "必填",
        "sheBWZGG": "设备温度过高",
        "wenZXCSB": "温度探测设备",
        "bianJ": "编辑",
        "quX": "取消",
        "queD": "确定",
        "cuoDSR": "最多输入",
        "geZF": "个字符",
        "qingSR": "请输入",
        "qiY": "其它",
        "dingY": "订阅",
        "tuiS": "推送",
        "jinY": "禁用",
        "zhengC": "正常",
        "xinC": "新增",
        "guanLCJLD": "关联场景联动",
        "zuZ": "组织",
        "caoZ": "操作",
        "shouDCF": "手动触发",
        "weiQY": "未启用",
        "buTSDCF": "不能手动触发",
        "queDSDCF": "确定手动触发",
        "caoZYB": "操作失败",
        "jinYGJBHYXGLDCJZT": "禁用告警不会影响关联的场景状态",
        "queDYJYM": "确定要禁用吗",
        "queRQY": "确认启用",
        "shanS": "删除",
        "qingXJYGGJ": "请先禁用该告警",
        "zaiSS": "再删除",
        "queRSS": "确认删除",
        "qingSRMC": "请输入名称",
        "qingXZLX": "请选择类型",
        "qingXZJB": "请选择级别",
        "cuoDKSR": "最多可输入",
        "qingPZGLDCJLD": "请配置关联的场景联动",
        "jiCPZ": "基础配置",
        "qingXBCJCPZ": "请先保存基础配置",
        "shiYXDSFPSXWLWPSXFZLQZSB": "适用于第三方平台向物联网平台下发指令控制设备",
        "dingSCF": "定时触发",
        "shiYXDJZXGDLW": "适用于定期执行固定任务",
        "sheBCF": "设备触发",
        "shiYXSBSJYXWMJCFTJS": "适用于设备数据或行为满足触发条件时",
        "zhiXZDDDZ": "执行指定的动作",
        "jieB": "解绑",
        "queDJB": "确定解绑",
        "chuFFS": "触发方式",
        "quXXZZJ": "取消选择事件",
        "baoCXZGLCJ": "保存选中关联场景",
        "qingXZZSYTSJ": "请选择至少一条数据",
        "gaoJMC": "告警名称",
        "zhaK": "查看",
        "dangQGJJLXX": "当前告警记录信息",
        "gaoJJLDXQ": "告警记录的详情",
        "huoQXQLB": "获取详情列表",
        "genJ": "根据",
        "chuSHSJ": "初始化数据",
        "gaoJSB": "告警设备",
        "guanBMTTC": "关闭模态弹窗",
        "xiangQ": "详情",
        "gaoJLS": "告警流水",
        "quanB": "全部",
        "jiLLB": "记录列表",
        "jiT": "系统",
        "renG": "人工",
        "gaoJCL": "告警处理",
        "guanBTC": "关闭弹窗",
        "chuLJL": "处理记录",
        "qingSRCLJL": "请输入处理结果",
        "cuoJGJSJ": "最近告警时间",
        "gaoJZ": "告警中",
        "moGJ": "无告警",
        "chanPMC": "产品名称",
        "sheBMC": "设备名称",
        "zuZMC": "组织名称",
        "chuLZHD": "处理之后的",
        "mo": "无",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "gaoJRZ": "告警日志",
        "guanBGJRZ": "关闭告警日志",
        "guanBCLJL": "关闭处理记录",
        "tuBSJ": "图表数据",
        "huiZTB": "绘制图表",
        "cuoXGJ": "最新告警",
        "xianZKQAN": "显示快捷按钮",
        "kuaiQANLB": "快捷按钮列表",
        "jinR": "今日",
        "jinYZ": "近一周",
        "jinYR": "近一月",
        "jinYN": "近一年",
        "jinRGJ": "今日告警",
        "gaoJTJ": "告警统计",
        "gaoJBM": "告警排名",
        "dangRGJ": "当月告警",
        "gaoJS": "告警数",
        "chuDYS": "处的颜色",
        "queXW": "缺省为",
        "chuSHZXTJ": "初始化查询条件",
        "ru": "月",
        "ri": "日",
        "ning": "年",
        "gaoJQS": "告警趋势",
        "wangLQQ": "网络请求",
        "queR": "确认",
        "qingXJYZSS": "请先禁用再删除",
        "shuaXSJ": "刷新数据",
        "qingSRSM": "请输入说明",
        "weiPZGZDBTQY": "未配置规则的不能启用",
        "qingXJYGCJ": "请先禁用该场景",
        "queRSDCF": "确认手动触发",
        "chuFLX": "触发类型",
        "yanCZX": "延迟执行",
        "qingSRSJ": "请输入时间",
        "miao": "秒",
        "fen": "分",
        "xiaoS": "小时",
        "hou": "后",
        "zhiXHXDZ": "执行后续动作",
        "cenSMC": "参数名称",
        "zhi": "值",
        "qingXZ": "请选择",
        "shouDSR": "手动输入",
        "neiZCS": "内置参数",
        "dongZLX": "动作类型",
        "qingXZDZLX": "请选择动作类型",
        "gongTDY": "功能调用",
        "qingXZGT": "请选择功能",
        "duQZX": "读取属性",
        "qingXZDQZX": "请选择读取属性",
        "qingXZZX": "请选择属性",
        "sheZZX": "设置属性",
        "qingSRGTZ": "请输入功能值",
        "qingXZSZZX": "请选择设置属性",
        "zhuXZ": "属性值",
        "sheBLX": "设备离线",
        "chuangJSJ": "创建时间",
        "liX": "离线",
        "zaiX": "在线",
        "xuanZFS": "选择方式",
        "qingXZFS": "请选择方式",
        "guanJ": "关系",
        "qingXZGJ": "请选择关系",
        "qingXZBQ": "请选择标签",
        "bianL": "变量",
        "qingXZCS": "请选择参数",
        "shouCCZBQSJ": "首次操作标签数据",
        "huiXWT": "回显问题",
        "ziDY": "自定义",
        "ziDYXZDQCPXDLYSB": "自定义选择当前产品下的任意设备",
        "anGJ": "按关系",
        "xuanZYCFSBJYXTGJDSB": "选择与触发设备具有相同关系的设备",
        "anBQ": "按标签",
        "anBQXZCPXJYTDBQDSB": "按标签选择产品下具有特定标签的设备",
        "anBL": "按变量",
        "xuanZSB": "选择设备",
        "weiSLBLZDSB": "为上游变量值的设备",
        "panDXTCPCYABL": "判断相同产品才有按变量",
        "biaoQ": "标签",
        "qiHSB": "切换设备",
        "bianLXZ": "变量选择",
        "bingJ": "并且",
        "yuZ": "或者",
        "wei": "为",
        "qingXZSB": "请选择设备",
        "biaoQXZ": "标签选择",
        "pi": "否",
        "zhiXDZ": "执行动作",
        "xuanZCP": "选择产品",
        "shangYB": "上一步",
        "xiaYB": "下一步",
        "zhuXGT": "属性功能",
        "sheZGT": "设置功能",
        "xuanZQBS": "选择器标识",
        "chuFSB": "触发设备",
        "zhiX": "执行",
        "duQ": "读取",
        "sheZ": "设置",
        "qingXZCP": "请选择产品",
        "xieRFS": "接入方式",
        "weiXR": "未接入",
        "panDSPWDYC": "判断是否为第一次",
        "wangGLX": "网关类型",
        "zhiLSB": "直连设备",
        "wangGZSB": "网关子设备",
        "wangGSB": "网关设备",
        "fenL": "分类",
        "suoZZZ": "所属组织",
        "chuanX": "串行",
        "anSXYCZXDZ": "按顺序依次执行动作",
        "shiYXJXDZSCCS": "适用于基于动作输出参数",
        "panDSPZXHXDZDCJ": "判断是否执行后续动作的场景",
        "bingX": "并行",
        "tongSZXSYDZ": "同时执行所有动作",
        "shiYXBRYGZZXDZXHSXHJLDCJ": "适用于不需要关注执行动作先后顺序和结果的场景",
        "jiaoYDQZXDZDSBYZCPSPSS": "校验当前执行动作的设备或者产品是否删除",
        "chanPSSS": "产品已删除",
        "zhiXZYGSB": "只选中一个设备",
        "siSBWMXWZ": "以设备物模型为准",
        "meiYGSBBSS": "某一个设备被删除",
        "ruLSABL": "如果是按变量",
        "jiaoYSYGSBSCDCP": "校验上一个设备输出的产品",
        "panDZ": "判断值",
        "jiaoYDQZXDZDTZPZ": "校验当前执行动作的通知配置",
        "xiaoXMBSPSS": "消息模板是否删除",
        "tongZPZ": "通知配置",
        "tongZMB": "通知模板",
        "panDCSSPFSBH": "判断参数是否发生变化",
        "weiXYH": "微信用户",
        "dingDYH": "钉钉用户",
        "huoQPSYH": "获取平台用户",
        "pingSYH": "平台用户",
        "guanJYH": "关系用户",
        "sheBSC": "设备输出",
        "bingJYZ": "并且有值",
        "dingYSYG": "订阅上一个",
        "caoZF": "操作符",
        "cenSZ": "参数值",
        "tiaoJZ": "条件值",
        "moRSDSRXL": "默认手动输入下拉",
        "chuL": "处理",
        "ruLCSLXWFSBH": "如果参数类型未发生变化",
        "zeBXGCZFSJZ": "则不修改操作符以及值",
        "xiuGCZF": "修改操作符",
        "ruLSCDZ": "如果上次的值",
        "zai": "在",
        "zhong": "中",
        "zeBB": "则不变",
        "fenZ": "分组",
        "gaiSJSFSBG": "该数据已发生变更",
        "qingTXPZ": "请重新配置",
        "qingXZCZF": "请选择操作符",
        "qingXZYSRCSZ": "请选择或输入参数值",
        "shuJFSBH": "数据发生变化",
        "manJTJHQCF": "满足条件后将触发",
        "guanLCCJDGJ": "关联此场景的告警",
        "manJTJHQJS": "满足条件后将解除",
        "tongG": "通过",
        "qunJQRXX": "群机器人消息",
        "faS": "发送",
        "dingD": "钉钉",
        "xiang": "向",
        "weiX": "微信",
        "youJ": "邮件",
        "yuY": "语音",
        "duanX": "短信",
        "di": "的",
        "yu": "与",
        "juYXT": "具有相同",
        "sheBD": "设备的",
        "dianJPZZXDZ": "点击配置执行动作",
        "manJCTJHZXHXDZ": "满足此条件后执行后续动作",
        "tianJGLTJ": "添加过滤条件",
        "faBXX": "发布消息",
        "qingKSYGCXZXDZZD": "清空上一个串行执行动作中的",
        "huo": "和",
        "tianJZXDZ": "添加执行动作",
        "zhiXBJZXDSJ": "直接编辑执行栋数据",
        "peiZSBDYGT": "配置设备调用功能",
        "sheZZXGZ": "设置属性规则",
        "xiaoXTZ": "消息通知",
        "peiZXZDYHFYJ": "配置向指定用户发邮件",
        "duanXDTZ": "短信等通知",
        "dengDYDSJH": "等待一段时间后",
        "zaiZXHXDZ": "再执行后续动作",
        "chuFGJ": "触发告警",
        "peiZCFGJGZ": "配置触发告警规则",
        "ruPH": "需配合",
        "shiY": "使用",
        "jieSGJ": "解除告警",
        "peiZJSGJGZ": "配置解除告警规则",
        "tongZFS": "通知方式",
        "muBBL": "模板变量",
        "yingY": "应用",
        "qingXZTZFS": "请选择通知方式",
        "qingXZTZPZ": "请选择通知配置",
        "qingXZTZMB": "请选择通知模板",
        "zanMMBBL": "暂无模板变量",
        "cuoD": "最多",
        "qingSRSJR": "请输入收件人",
        "qingSRZQDYXDZ": "请输入正确的邮箱地址",
        "qingSRZQDSJXM": "请输入正确的手机号码",
        "qingSZWJ": "请上传文件",
        "shangZBJ": "上传附件",
        "qingSZZQLSTP": "请上传正确格式图片",
        "tuPTXBXXX": "图片大小必须小于",
        "qingXZZZ": "请选择组织",
        "guDYX": "固定邮箱",
        "guDXM": "固定号码",
        "qingXZSXR": "请选择收信人",
        "qingSRSJRYX": "请输入收件人邮箱",
        "duoGSJRYHXFJ": "多个收件人用换行分隔",
        "qingSRGDXM": "请输入固定号码",
        "guanLGJSL": "关联告警数量",
        "shuJHX": "数据回显",
        "qingAZ": "请按照",
        "luoSSR": "格式输入",
        "shiPWZBZ": "是否为指标值",
        "huiX": "回显",
        "chuLZBZHX": "处理指标值回显",
        "zhiBZ": "指标值",
        "kaiQFD": "开启防抖",
        "guanBFD": "关闭防抖",
        "miaoNFS": "秒内发送",
        "ciJSSS": "次及以上时",
        "diYC": "第一次",
        "cuoHYC": "最后一次",
        "dang": "当",
        "piZ": "否则",
        "gaiCZQQKQYSYPZTJ": "该操作将清空其它所有否则条件",
        "zhiSPZYGZXDZ": "至少配置一个执行动作",
        "huoQDYZBZ": "获取对应指标值",
        "huanCZBZ": "缓存指标值",
        "leiXFSBH": "类型发生变化",
        "chuFTJ": "触发条件",
        "kai": "开",
        "guan": "关",
        "anZ": "按周",
        "anR": "按月",
        "biaoTS": "表达式",
        "zhouJZX": "周期执行",
        "zhiXYC": "执行一次",
        "mei": "每",
        "qingSRZQD": "请输入正确的",
        "pinLSWQH": "频率单位切换",
        "xingJY": "星期一",
        "xingJE": "星期二",
        "xingJS": "星期四",
        "xingJW": "星期五",
        "xingJL": "星期六",
        "xingJR": "星期日",
        "biaoZQ": "表示前",
        "lianX": "连续",
        "buLX": "不连续",
        "zhiSSWLX": "至少三位连续",
        "xiao": "号",
        "meiT": "每天",
        "meiZ": "每周",
        "meiR": "每月",
        "deng": "等",
        "tian": "天",
        "ci": "次",
        "quanX": "全选",
        "dianJPZSBCF": "点击配置设备触发",
        "chuFGZ": "触发规则",
        "tuoZCS": "拓展参数",
        "siSB": "台设备",
        "suoYD": "所有的",
        "shangX": "上线",
        "zhuXSB": "属性上报",
        "shiPXZFSWSB": "是否选择方式为设备",
        "qingXZBM": "请选择部门",
        "xuanZFSWSBJFXZYGSBS": "选择方式为设备且仅选中一个设备时",
        "wuMXQGSB": "物模型取该设备",
        "panDCPSPSS": "判断产品是否删除",
        "huoQDQCPWMX": "获取当前产品物模型",
        "panDSBSPSS": "判断设备是否删除",
        "panDWMX": "判断物模型",
        "chanPXDSYSB": "产品下的所有设备",
        "anZZ": "按组织",
        "xuanZCPXGZXJBZZDSB": "选择产品下归属于具体组织的设备",
        "qingPZSBCFGZ": "请配置设备触发规则",
        "panDCP": "判断产品",
        "panDSBYZZZ": "判断设备或者组织",
        "panDZX": "判断属性",
        "ziJ": "事件",
        "gongT": "功能",
        "dingSDYSXGT": "定时调用所选功能",
        "huoQDQXZGTZX": "获取当前选择功能属性",
        "baiX": "排序",
        "dingSDQSXZXZ": "定时读取所选属性值",
        "geZX": "个属性",
        "qingXZZJ": "请选择事件",
        "sheBSX": "设备上线",
        "shangB": "上报",
        "ziJSB": "事件上报",
        "xiuGZX": "修改属性",
        "qingSRXGZ": "请输入修改值",
        "dingSDYSXZX": "定时调用所选属性",
        "xiuG": "修改",
        "qingXZZXZ": "请选择属性值",
        "qingSRZXZ": "请输入属性值",
        "qingXZCFFS": "请选择触发方式",
        "qingPZDSCFGZ": "请配置定时触发规则",
        "dianJPZDSCF": "点击配置定时触发",
        "faB": "发布",
        "chuFLXW": "触发类型为",
        "shiBTWK": "时不能为空",
        "sheBCFPZ": "设备触发配置",
        "zhiXDZMYGCS": "执行动作没有该参数",
        "fangDPZ": "防抖配置",
        "tuoZXX": "拓展信息",
        "dingSCFPZ": "定时触发配置",
        "shouDSRZ": "手动输入值",
        "gongTDYSSY": "功能调用时使用",
        "duQZXSSY": "读取属性时使用",
        "duQZXSW": "读取属性时为",
        "shuZ": "数组",
        "sheZZXSW": "设置属性时为",
        "laiYW": "来源为",
        "zhiXQLXW": "执行器类型为",
        "zhongD": "中的",
        "yongXSS": "用于删除",
        "jieGYTYZXTJXT": "结构与通用查询条件相同",
        "tiaoJSJLZXK": "条件数据来自接口",
        "genJCFQJSCZCDTJL": "根据触发器解析出支持的条件列",
        "dongZFZ": "动作分支",
        "yongXQDCCYSXRSJ": "用于前端存储一些渲染数据"
    },
    "system": {
        "jiCCS": "集成菜单",
        "qingXZJCJT": "请选择集成系统",
        "dangQJCCS": "当前集成菜单",
        "tanCXG": "弹窗相关",
        "caoZCG": "操作成功",
        "qingGXPZCS": "请勾选配置菜单",
        "qingXZSZJT": "请选择所属系统",
        "yuJCJTGLDCS": "与集成系统关联的菜单",
        "huoQDQYHKFWCS": "获取当前用户可访问菜单",
        "huoQJCJTXX": "获取集成系统选项",
        "shuXZZJ": "树选中事件",
        "guoLJD": "过滤节点",
        "moRDSFJD": "默认带上父节点",
        "buFHTJDZXKN": "不符合条件的直接砍掉",
        "fuHTJDBL": "符合条件的保留",
        "bingJRYDGCLQZJD": "并且需要递归处理其子节点",
        "xinC": "新增",
        "yingYGLQDGYYJTDDLJHWYCDL": "应用管理将多个应用系统的登录简化为一次登录",
        "shiXDCFW": "实现多处访问",
        "jiZGQDYWCJ": "集中管控的业务场景",
        "leiX": "类型",
        "shuiM": "说明",
        "neiBDWYY": "内部独立应用",
        "weiXWZYY": "微信网站应用",
        "neiBJCYY": "内部集成应用",
        "dingDQYNBYY": "钉钉企业内部应用",
        "diSFYY": "第三方应用",
        "mingC": "名称",
        "zhuangT": "状态",
        "zhengC": "正常",
        "jinY": "禁用",
        "caoZ": "操作",
        "bianJ": "编辑",
        "qiY": "启用",
        "queR": "确认",
        "shanS": "删除",
        "qingXJYZSS": "请先禁用再删除",
        "queRSS": "确认删除",
        "qiT": "其他",
        "youJCCSQW": "有集成菜单权限",
        "you": "有",
        "caoZQW": "操作权限",
        "fuQ": "赋权",
        "zhaK": "查看",
        "qiTBWK": "其他不为空",
        "gaiS": "概述",
        "neiBDWYYSYXQ": "内部独立应用适用于将",
        "guanFKF": "官方开发",
        "diQTYYY": "的其他应用与",
        "wuLWPSXHJC": "物联网平台相互集成",
        "liRQKSHPSJCZWLWPS": "例如将可视化平台集成至物联网平台",
        "yuZQWLWPSJCZKSHPS": "或者将物联网平台集成至可视化平台",
        "siSXDCFW": "以实现多处访问",
        "neiBDWYYD": "内部独立应用的",
        "houDFW": "后端服务",
        "xiangH": "相互",
        "duWYX": "独立运行",
        "huBYX": "互不影响",
        "xieRFSSM": "接入方式说明",
        "yeMJC": "页面集成",
        "jiCQTYYD": "集成其他应用的",
        "qianDYM": "前端页面",
        "zhiWLWPSZ": "至物联网平台中",
        "weiSXYYYWLWPSSJHLHT": "为实现应用与物联网平台数据互联互通",
        "tongCHRYPZ": "通常还需要配置",
        "fuW": "服务",
        "qiaHD": "客户端",
        "wuLWPS": "物联网平台",
        "qingQ": "请求",
        "qiTYY": "其他应用",
        "diXK": "的接口",
        "siSXQWLWPSJCZQTYYJT": "以实现将物联网平台集成至其他应用系统",
        "ruRSX": "如需实现",
        "deLHKSFW": "登录后可以访问",
        "yeM": "页面",
        "haiRYPZSDDL": "还需要配置单点登录",
        "waiBYY": "外部应用",
        "shiXWLWPSDFWDYTL": "实现物联网平台的服务调用能力",
        "tongCHRYPZYMJC": "通常还需要配置页面集成",
        "peiZ": "配置",
        "fuWH": "服务后",
        "jiTQ": "系统将",
        "ziDCJ": "自动创建",
        "duiYD": "对应的",
        "yongH": "用户",
        "yongHD": "用户的",
        "zhangX": "账号",
        "miM": "密码",
        "fenBDY": "分别对应",
        "diSFYH": "第三方用户",
        "keDYD": "可调用的",
        "zaiQYYGLQPD": "在其应用管理卡片的",
        "jinX": "进行",
        "ziDYPZ": "自定义配置",
        "shanDDL": "单点登录",
        "tongG": "通过",
        "diSFPSZX": "第三方平台账号",
        "deLDWLWPS": "登录到物联网平台",
        "neiBJCYYSYXQ": "内部集成应用适用于将",
        "houDFWZTYGHJYX": "后端服务在同一个环境运行",
        "jiCHJTDBQXCDYDYYGLCS": "集成后系统顶部将新增对应的应用管理菜单",
        "quQQQTYYDXK": "去请求其他应用的接口",
        "siSXQWLWPSJCZQTYY": "以实现将物联网平台集成至其他应用",
        "dingDKFPS": "钉钉开放平台",
        "dingDQYNBYYSYXTGDDDL": "钉钉企业内部应用适用于通过钉钉登录",
        "tongGDDZXDLDWLWPS": "通过钉钉账号登录到物联网平台",
        "weiXKFPS": "微信开放平台",
        "weiXWZYYSYXTGWXSQDL": "微信网站应用适用于通过微信授权登录",
        "tongGWXZXDLDWLWPS": "通过微信账号登录到物联网平台",
        "diSFYYSYX": "第三方应用适用于",
        "yu": "与",
        "liRQGCYWGLJTJCZWLWPS": "例如将公司业务管理系统集成至物联网平台",
        "yuZQWLWPSJCZYWGLJT": "或者将物联网平台集成至业务管理系统",
        "haiRYPZ": "还需要配置",
        "fuWPZ": "服务配置",
        "qingQWLWPSXK": "请求物联网平台接口",
        "shiX": "实现",
        "diFWDYTL": "的服务调用能力",
        "qingSRMC": "请输入名称",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "yingY": "应用",
        "qingXZYY": "请选择应用",
        "xieRFS": "接入方式",
        "qingXZXRFS": "请选择接入方式",
        "qingSRXRDZ": "请输入接入地址",
        "xieRDZ": "接入地址",
        "chenXFWQYPSDDZ": "填写访问其它平台的地址",
        "luoYFS": "路由方式",
        "qingXZLYFS": "请选择路由方式",
        "cenS": "参数",
        "ziDYCS": "自定义参数",
        "luoS": "格式",
        "yongHM": "用户名",
        "qingSRXKDZ": "请输入接口地址",
        "xieKDZ": "接口地址",
        "fangW": "访问",
        "fuWDDZ": "服务的地址",
        "qingSRSQDZ": "请输入授权地址",
        "shouQDZ": "授权地址",
        "renZSQDZ": "认证授权地址",
        "qingSR": "请输入",
        "deZ": "地址",
        "sheZ": "设置",
        "lianPDDZ": "令牌的地址",
        "huiDDZ": "回调地址",
        "shouQWCHTZDJBYMDHDDZ": "授权完成后跳转到具体页面的回调地址",
        "qingSRHDDZ": "请输入回调地址",
        "diSFYYWYBS": "第三方应用唯一标识",
        "diSFYYWYBSDMY": "第三方应用唯一标识的密钥",
        "renZFS": "认证方式",
        "jiBRZ": "基本认证",
        "renZ": "认证",
        "qingQFS": "请求方式",
        "qingXZQQFS": "请选择请求方式",
        "qingQB": "请求体",
        "qingQT": "请求头",
        "yingYWYBS": "应用唯一标识",
        "yingYWYBSDBY": "应用唯一标识的秘钥",
        "qingSRYHM": "请输入用户名",
        "qingSRMM": "请输入密码",
        "qingSRQQT": "请输入请求头",
        "genJBTYYDDYGF": "根据不同应用的调用规范",
        "ziDYQQTNY": "自定义请求头内容",
        "qingSRCS": "请输入参数",
        "diSFYYWYBSPPDBY": "第三方应用唯一标识匹配的秘钥",
        "qingXZJS": "请选择角色",
        "jiaoS": "角色",
        "weiYYYHFPJS": "为应用用户分配角色",
        "genJBDDJS": "根据绑定的角色",
        "jinXJTCSFQ": "进行系统菜单赋权",
        "zuZ": "组织",
        "weiYYYHFPSZZZ": "为应用用户分配所属组织",
        "genJBDDZZ": "根据绑定的组织",
        "jinXSJJL": "进行数据隔离",
        "qingXZZZ": "请选择组织",
        "shouQHZDTZDYMDZ": "授权后自动跳转的页面地址",
        "boMS": "白名单",
        "duoGDZHCFJ": "多个地址回车分隔",
        "buCMRYKFW": "不填默认均可访问",
        "qingXZRZFS": "请选择认证方式",
        "wenZYHFWYYCXDQW": "限制用户访问应用程序的权限",
        "dianJSZTP": "点击上传图片",
        "yongHXXDZ": "用户信息地址",
        "qingSRYHXXDZ": "请输入用户信息地址",
        "qingSRYH": "请输入用户",
        "biaoTSCSQJLZHQDSFPSYHDWYBS": "表达式从授权结果中获取第三方平台用户的唯一标识",
        "shuRCYHXXXKFHSJZDYH": "输入从用户信息接口返回数据中的用户",
        "ziD": "字段",
        "zhiL": "示例",
        "shuRCYHXXXKFHSJZDYHMZD": "输入从用户信息接口返回数据中的用户名字段",
        "touX": "头像",
        "shuRCYHXXXKFHSJZDYHTXZD": "输入从用户信息接口返回数据中的用户头像字段",
        "feiDSFYY": "非第三方应用",
        "feiDD": "非钉钉",
        "feiWX": "非微信",
        "dingD": "钉钉",
        "weiX": "微信",
        "yingYDWYBSDBY": "应用的唯一标识的秘钥",
        "ziDCJYH": "自动创建用户",
        "kaiQH": "开启后",
        "diSFYHDYCSQDLJTS": "第三方用户第一次授权登录系统时",
        "moRJRSQBDYM": "无需进入授权绑定页面",
        "jiTMRCJYGXYHYZBD": "系统默认创建一个新用户与之绑定",
        "yongHMQZ": "用户名前缀",
        "qingSRYHMQZ": "请输入用户名前缀",
        "moRMM": "默认密码",
        "qingSRMRMM": "请输入默认密码",
        "cuoSSR": "最少输入",
        "qingSRSM": "请输入说明",
        "baoC": "保存",
        "chuSHBS": "初始化表单",
        "qingQCS": "请求参数",
        "keXZ": "可选值",
        "shouQXX": "授权信息",
        "jiBXX": "基本信息",
        "xinX": "信息",
        "tongDXDZ": "重定向地址",
        "qiaHDMY": "客户端密钥",
        "zhuXM": "属性名",
        "miY": "密钥",
        "tongDX": "重定向",
        "jiaoSLB": "角色列表",
        "buMLB": "部门列表",
        "shiPQY": "是否启用",
        "tongYSDDLJC": "统一单点登陆集成",
        "shanDDLPZ": "单点登录配置",
        "yongHXXXK": "用户信息接口",
        "yongHZXZDXX": "用户属性字段信息",
        "youX": "邮箱",
        "dianH": "电话",
        "weiXSDDLPZ": "微信单点登录配置",
        "dingDSDDLPZ": "钉钉单点登录配置",
        "shiPZDCJPSYH": "是否自动创建平台用户",
        "qianZ": "前缀",
        "ziDCJPSYHSJSLB": "自动创建平台用户时角色列表",
        "ziDCJPSYHSBMLB": "自动创建平台用户时部门列表",
        "xieRFSJX": "接入方式镜像",
        "tiDMBSY": "折叠面板使用",
        "zuZLB": "组织列表",
        "qingQTHCSBCYZ": "请求头和参数必填验证",
        "qingSRWZDQQT": "请输入完整的请求头",
        "qingSRWZDQQCS": "请输入完整的请求参数",
        "xieRFSDXX": "接入方式的选项",
        "xinCS": "新增时",
        "qiHYYLX": "切换应用类型",
        "qingKGYZDDZ": "清空公用字段的值",
        "houDFHD": "后端返回的",
        "huo": "和",
        "zhong": "中",
        "zhuanW": "转为",
        "zhaXXQ": "查询详情",
        "fuZCSZD": "赋值初始字段",
        "jieJBJGBXRFSBCDWT": "解决编辑改变接入方式报错的问题",
        "huoQJSLB": "获取角色列表",
        "huoQZZLB": "获取组织列表",
        "tianJJS": "添加角色",
        "shanSDTDCS": "删除多余的参数",
        "zhiXZL": "只选择了",
        "fuWHSDDL": "服务和单点登录",
        "yuZZXZLSDDL": "或者只选择了单点登录",
        "peiZSDDLRTSPZ": "配置单点登录需同时配置",
        "qiaFD": "客服端",
        "duWYY": "独立应用",
        "ruGW": "需改为",
        "zhuanGHD": "传给后端",
        "baoCCG": "保存成功",
        "ruLCXJYCGJZYBLBZ": "如果此项校验成功且在失败列表中",
        "zeCCLBZYS": "则从此列表中移除",
        "fanZZJS": "反之则加上",
        "qingSZ": "请上传",
        "luoSDTP": "格式的图片",
        "tuPTXBXXX": "图片大小必须小于",
        "shangZYB": "上传失败",
        "qingSHZS": "请稍后再试",
        "yanZ": "验证",
        "heFX": "合法性",
        "buSZQD": "不是正确的",
        "gaiZDWBCZD": "该字段为必填字段",
        "ciCBLRYYZ": "此处表格需要验证",
        "weiZDYZMFTG": "为字段验证无法通过",
        "gaiW": "改为",
        "meiYXDCL": "每一项都填了",
        "yuZMXDMYC": "或者没项都没有填",
        "yunWYZTG": "均为验证通过",
        "piZYWWTG": "否则均为未通过",
        "ruLSXCXCZ": "如果是新增行操作",
        "ruoXCHHCXXYY": "若新增后会出现新一页",
        "zeTZDCXDYY": "则跳转到最新的一页",
        "ruLSSSXCZ": "如果是删除行操作",
        "ruoSSDXSBYDCHYX": "若删除的行是本页的最后一行",
        "juBYBSDYY": "且本页不是第一页",
        "zeTZDSYY": "则跳转到上一页",
        "renZPZ": "认证配置",
        "qianMFS": "签名方式",
        "jiTMC": "系统名称",
        "qingSRJTMC": "请输入系统名称",
        "zhuTS": "主题色",
        "boS": "白色",
        "heiS": "黑色",
        "gaoD": "高德",
        "peiZHPSKDYGDDT": "配置后平台可调用高德地图",
        "qingSRGD": "请输入高德",
        "jiTHSFWD": "系统后台访问的",
        "qianDSZFWQ": "前端所在服务器",
        "qianDBLDFWDK": "前端暴露的服务端口",
        "jiT": "系统",
        "dianJXG": "点击修改",
        "tuiJCC": "推荐尺寸",
        "zhiC": "支持",
        "liuLQYQ": "浏览器页签",
        "liuLQ": "浏览器",
        "yeZXZDTPYS": "页中显示的图片元素",
        "deLBJT": "登录背景图",
        "siNDTP": "以内的图片",
        "jianYCC": "建议尺寸",
        "anZPYSRSDDZ": "安装包或输入商店地址",
        "shangZ": "上传",
        "banBX": "版本号",
        "shangDDZ": "商店地址",
        "luoSDWJ": "格式的文件",
        "shangZCG": "上传成功",
        "jiTSZ": "系统提示",
        "jiTWZCW": "系统未知错误",
        "qingFKGGLY": "请反馈给管理员",
        "mingCBC": "名称必填",
        "qingXZZTS": "请选择主题色",
        "jiaZZT": "加载状态",
        "beiJTJZZT": "背景图加载状态",
        "yeQJZZT": "页签加载状态",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "luoSJY": "格式校验",
        "liuLQYQLSJY": "浏览器页签格式校验",
        "shangZGBZJ": "上传改变事件",
        "beiJSZGBZJ": "背景上传改变事件",
        "beiJTSZYB": "背景图上传失败",
        "liuLQYQSZGBZJ": "浏览器页签上传改变事件",
        "liuLQYQSZYB": "浏览器页签上传失败",
        "jiBXXBS": "基本信息表单",
        "tuPSZBS": "图片上传表单",
        "queRDC": "确认导出",
        "queD": "确定",
        "quX": "取消",
        "daoC": "导出",
        "xuX": "序号",
        "qiaHZH": "客户账户",
        "lianJDZ": "联系地址",
        "chuangJSJ": "创建时间",
        "gengXSJ": "更新时间",
        "xiaoS": "搜索",
        "qiaHLB": "客户列表",
        "qingXZLX": "请选择类型",
        "yuZ": "或者",
        "lianXDZ": "链接地址",
        "guanLDZ": "管理地址",
        "qingSRGLDZ": "请输入管理地址",
        "qingSRLXDZ": "请输入链接地址",
        "xuNY": "虚拟域",
        "qingSRXNY": "请输入虚拟域",
        "bianJSJY": "编辑数据源",
        "xinCSJY": "新增数据源",
        "qingSRZQD": "请输入正确的",
        "guanLDZJY": "管理地址校验",
        "qingSRZQDGLDZ": "请输入正确的管理地址",
        "lianXDZJY": "链接地址校验",
        "qingSRZQDLXDZ": "请输入正确的链接地址",
        "zanBZCGLGT": "暂不支持管理功能",
        "guanL": "管理",
        "qingXQYSJY": "请先启用数据源",
        "queDY": "确定要",
        "ma": "吗",
        "qingXJY": "请先禁用",
        "zaiSS": "再删除",
        "biaoLSL": "表格实例",
        "daKBJTC": "打开编辑弹窗",
        "shuaXLB": "刷新列表",
        "shiY": "使用",
        "yongXJJYBJZSJHBZKDWT": "用于解决异步加载数据后不展开的问题",
        "qingSRLX": "请输入类型",
        "qingXZSPBTWK": "请选择是否不能为空",
        "shi": "是",
        "pi": "否",
        "xinCX": "新增行",
        "buTSSZKT": "不能以数字开头",
        "mingCZTYSZ": "名称只能由数字",
        "ziW": "字母",
        "xiaHX": "下划线",
        "zhongHXZC": "中划线组成",
        "liM": "列名",
        "zhangZ": "长度",
        "jingZ": "精度",
        "buTWK": "不能为空",
        "qingPZSJYZD": "请配置数据源字段",
        "shuJKZD": "数据库字段",
        "bangD": "绑定",
        "zhiTFPY": "只能分配有",
        "hongX": "共享",
        "quanWDZCSJ": "权限的资产数据",
        "piLPZ": "批量配置",
        "kai": "开",
        "guan": "关",
        "ziCQW": "资产权限",
        "ziCZXCS": "资产咨询次数",
        "chanPFPHZDJRDSBZC": "产品分配后自动进入的设备资产",
        "diYCRYDSCP": "第一次需要带上产品",
        "zhaX": "查询",
        "qingXGXSJ": "请先勾选数据",
        "baoCS": "保存时",
        "guoLMYDQW": "过滤没有的权限",
        "fenPCPZCH": "分配产品资产后",
        "jinRSBZCFP": "进入设备资产分配",
        "xuanZXD": "选中项的",
        "jiuXZXD": "旧选中项的",
        "xuanZX": "选中项",
        "liBDQKB": "列表的浅拷贝",
        "qiYPLSZ": "启用批量设置",
        "qiangSGXDQWHPLSZDQWJXHB": "将已勾选的权限和批量设置的权限进行合并",
        "bingYZQKXDQWJXBD": "并与自己可选的权限进行比对",
        "quJJZWDQXZDQW": "取交集作为当前选中的权限",
        "jinYSDGX": "禁用单独勾选",
        "quXPLSZ": "取消批量设置",
        "fangKZQQWDGXWZ": "放开自己权限的勾选限制",
        "zhaKWBX": "查看为必选",
        "quXGXSCF": "取消勾选时触发",
        "naDQXXZDXD": "拿到取消选中的项的",
        "qiangQXGXDXDQWTZ": "将取消勾选的项的权限重置",
        "liBQXQBXZ": "列表取消全部选择",
        "xuanZ": "选中",
        "ruoGXDKXQWZMYFXQW": "若该项的可选权限中没有分享权限",
        "zeBZCLHCZ": "则不支持任何操作",
        "gaiZCBZCHX": "该资产不支持共享",
        "quXQX": "取消全选",
        "huoQBZLSJ": "获取并整理数据",
        "ziCQWBX": "资产权限排序",
        "ziCBX": "资产排序",
        "chanPDZTJXZHCL": "产品的状态进行转换处理",
        "zhengLCSBHQSJ": "整理参数并获取数据",
        "feiSB": "非设备",
        "chanP": "产品",
        "buCZ": "不存在",
        "youQTZXCZ": "有其他查询操作",
        "sheBYMSDDJZCFP": "设备页面手动点击资产分配",
        "yunSSCPDRD": "均删除产品带入的",
        "shangJZZ": "上级组织",
        "qingXZSJZZ": "请选择上级组织",
        "baiX": "排序",
        "qingSRBX": "请输入排序",
        "zaiGDDSZTG": "在给定的树中通过",
        "piP": "匹配",
        "qiangCSXDSYJDJY": "将此树下的所有节点禁用",
        "biaoSXG": "表单相关",
        "xinCZZZ": "新增子组织",
        "shangJZZXZGB": "上级组织选择改变",
        "dangQBJDZZBX": "当前编辑的组织排序",
        "weiXZSJZZDCTBX": "为选择上级组织的最大排序",
        "ruSJZZMYZZZ": "如上级组织没有自组织",
        "zeMRW": "则默认为",
        "qingSRZZMC": "请输入组织名称",
        "queDYSSM": "确定要删除吗",
        "zanMSJ": "暂无数据",
        "bianJTC": "编辑弹窗",
        "xiaoSNY": "搜索内容",
        "shuJJZZT": "数据加载状态",
        "yuanSJ": "源数据",
        "shuJD": "数据的",
        "banB": "版本",
        "zhanZDSJ": "展示的数据",
        "dangQXZDX": "当前选中的项",
        "baoCYSJ": "报存源数据",
        "qiangSXJGZHW": "将树形结构转换为",
        "jieG": "结构",
        "diYCBYJXGL": "第一次不用进行过滤",
        "qiangSXSZZHW": "将树形数组转化为",
        "xingS": "形式",
        "siBSXSCZ": "以便筛选时操作",
        "shanSBM": "删除部门",
        "tanC": "弹窗",
        "jiSMRBXZ": "计算默认排序值",
        "weiZLBZCTDBXZ": "为子列表中最大的排序值",
        "shiPJXFPCPXDJBSB": "是否继续分配产品下的具体设备",
        "ziCFP": "资产分配",
        "piLCZ": "批量操作",
        "shiPPLJSBD": "是否批量解除绑定",
        "piLJB": "批量解绑",
        "piLBJ": "批量编辑",
        "shiPJSBD": "是否解除绑定",
        "suoZCP": "所属产品",
        "zhuCSJ": "注册时间",
        "liX": "离线",
        "zaiX": "在线",
        "jieSBD": "解除绑定",
        "huoQQWSJZD": "获取权限数据字典",
        "huoQQWMC": "获取权限名称",
        "quNSJ": "去掉数据",
        "sheBZCFPTCCZLX": "设备资产分配弹窗操作类型",
        "shouDDJZCFPAN": "手动点击资产分配按钮",
        "chanPZCFPH": "产品资产分配后",
        "ziDTCSBZCFP": "自动弹出设备资产分配",
        "qingGXRYBJDSJ": "请勾选需要编辑的数据",
        "qingGXRYJBDSJ": "请勾选需要解绑的数据",
        "sheB": "设备",
        "qingXZYBDDYH": "请选择要绑定的用户",
        "xingM": "姓名",
        "shouDXZGB": "手动选择改变",
        "fenYH": "分页会",
        "quXXZ": "取消选择",
        "bangDYH": "绑定用户",
        "xiaoSCS": "搜索参数",
        "biaoL": "表格",
        "jiCXX": "基础信息",
        "qingPZ": "请配置",
        "peiZCG": "配置成功",
        "daoJSJ": "到期时间",
        "bianM": "编码",
        "qingSRBM": "请输入编码",
        "quanW": "权限",
        "qingXZQW": "请选择权限",
        "gengX": "更新",
        "caiSTB": "菜单图标",
        "buXZ": "不显示",
        "ruYGL": "需要过滤",
        "qingSRQWMC": "请输入权限名称",
        "quanWMC": "权限名称",
        "quanWCZ": "权限操作",
        "huoQQWLB": "获取权限列表",
        "quanX": "全选",
        "shanX": "单选",
        "qingSZTB": "请上传图标",
        "dianJXZTB": "点击选择图标",
        "yeMDZ": "页面地址",
        "qingSRYMDZ": "请输入页面地址",
        "ziF": "字符",
        "qingZQCXDZ": "请正确填写地址",
        "si": "以",
        "kaiT": "开头",
        "qingSRTX": "请输入大于",
        "diZS": "的整数",
        "quanWPZ": "权限配置",
        "shuJQWQZ": "数据权限控制",
        "ciCSYMSJSDYDZCLX": "此菜单页面数据所对应的资产类型",
        "buZC": "不支持",
        "jianXQZ": "间接控制",
        "ciCSNDSJJXQTCSDSJQWQZ": "此菜单内的数据基于其他菜单的数据权限控制",
        "qingXZZCLX": "请选择资产类型",
        "qingXZGLCS": "请选择关联菜单",
        "luoY": "路由",
        "biaoS": "标识",
        "guanLCS": "关联菜单",
        "ziCLX": "资产类型",
        "yuanBD": "原本的",
        "huoQCSXQ": "获取菜单详情",
        "huoQGLCS": "获取关联菜单",
        "huoQZCLX": "获取资产类型",
        "bianJSBJYYBDBM": "编辑时不校验原本的编码",
        "gaiBMTF": "该编码重复",
        "xinCHSXYM": "新增后刷新页面",
        "bianJZBRY": "编辑则不需要",
        "caoZYB": "操作失败",
        "qingXXCCSJBXX": "请先新增菜单基本信息",
        "caiSDJBXX": "菜单的基本信息",
        "qiZPKLBLDSJ": "其中包括了表格的数据",
        "biaoLXG": "表格相关",
        "anNGL": "按钮管理",
        "caiSPZ": "菜单配置",
        "xinCZCS": "新增子菜单",
        "shiPSSGCS": "是否删除该菜单",
        "guoLFJCDCS": "过滤非集成的菜单",
        "tiaoZZXQY": "跳转至详情页",
        "shanJKQHCSWXZ": "单击可切换菜单未选中",
        "xuanZZT": "选中状态",
        "caoZFJCSS": "操作父级菜单时",
        "duiYZCSZTQMRYQTB": "对应子菜单状态将默认与其同步",
        "keSSDCZDZ": "可以单独操作调整",
        "zhiCTZCSDZZZSX": "支持拖拽菜单调整展示顺序",
        "baoCHDQJTCSSJQBFG": "保存后当前系统菜单数据将被覆盖",
        "queRCZ": "确认操作",
        "biaoJ": "标记",
        "tuoZCG": "拖拽成功",
        "fuZC": "仅支持",
        "jiCS": "级菜单",
        "tuoZCGSGX": "拖拽成功时更新",
        "chuSHCS": "初始化菜单",
        "buYKL": "不要克隆",
        "tongGYY": "通过引用",
        "chuL": "处理",
        "chuLBX": "处理排序",
        "genJQWGLCS": "根据权限过滤菜单",
        "heBSZ": "合并数组",
        "heBCS": "合并菜单",
        "moRCS": "默认菜单",
        "dangQJTCS": "当前系统菜单",
        "heBHDCS": "合并后的菜单",
        "jiLJTXZ": "记录系统选中",
        "fangZTF": "防止重复",
        "jiTSJXZD": "系统已经选中的",
        "buTZC": "不能再从",
        "zhongTJ": "中添加",
        "moRCSSJZXJTCS": "默认菜单以及查询系统菜单",
        "jiTXZD": "系统选中的",
        "ruYWY": "需要唯一",
        "zhaZFJ": "查找父级",
        "ziJ": "子级",
        "dangQWZDCS": "当前完整的菜单",
        "dangQDJD": "当前点击的",
        "dangQ": "当前",
        "diQBFJ": "的全部父级",
        "diQBZJ": "的全部子级",
        "xuanZGT": "选择功能",
        "ziJMRCS": "事件默认参数",
        "chuLHD": "处理后的",
        "tuoZGT": "拖拽功能",
        "zhi": "值",
        "tuoZH": "拖拽后",
        "zhaZCSCJ": "查找最深层级",
        "ruYDMZDBS": "需与代码中的标识",
        "yiZ": "一致",
        "qingSRBS": "请输入标识",
        "caoZQWLB": "操作权限列表",
        "tianJ": "添加",
        "jiaoYBSSPKY": "校验标识是否可用",
        "caoZLX": "操作类型",
        "dangSSDGHWBYDCHYXS": "当删除的刚好为本页的最后一项时",
        "fanHDSYY": "返回到上一页",
        "dangTJDXRYXJYYCTXZS": "当添加的项需要新加一页才能显示时",
        "tiaoZDCHYY": "跳转到最后一页",
        "daoR": "导入",
        "daoRSJ": "导入数据",
        "daoRCG": "导入成功",
        "daoRYB": "导入失败",
        "qingTS": "请重试",
        "daoCSJ": "导出数据",
        "quanWSJ": "权限数据",
        "daoCCG": "导出成功",
        "daoCCW": "导出错误",
        "xiuGZT": "修改状态",
        "qingQSJLX": "请求数据类型",
        "xiangYSJLX": "响应数据类型",
        "xieKMS": "接口描述",
        "qingQZL": "请求示例",
        "xiangYZT": "响应状态",
        "xiangYCS": "响应参数",
        "cenSM": "参数名",
        "cenSSM": "参数说明",
        "qingQLX": "请求类型",
        "shiPBX": "是否必须",
        "cenSLX": "参数类型",
        "buS": "不是",
        "zhongDLDHZBJXJS": "中的类的话则不进行解析",
        "zhiXJS": "直接结束",
        "zhuangTM": "状态码",
        "cenSMC": "参数名称",
        "faS": "发送",
        "gaiZDSBCZD": "该字段是必填字段",
        "queDSS": "确定删除",
        "cenSZ": "参数值",
        "ruLYHMCXCSJY": "如果用户没填写参数且有",
        "diQKX": "的情况下",
        "geiYHZZQQZL": "给用户展示请求示例",
        "huoQMRCS": "获取默认参数",
        "chanSBHD": "产生变化的",
        "xiang": "项",
        "dangQJDBLSJ": "当前节点表格数据",
        "dangQBL": "当前表格",
        "yuanYXZD": "原有选中的",
        "shuDQBLZW": "除当前表格之外",
        "gouXSDSJ": "勾选上的数据",
        "quXXZDSJX": "取消选择的数据项",
        "xinCXZDX": "新增选择的项",
        "huanCDQBLHQTBLGBDSJ": "缓存当前表格和其他表格改变的数据",
        "xinCXZ": "新增选中",
        "quXXZDSJ": "取消选中的数据",
        "yiSD": "移除的",
        "xinXZD": "新选中的",
        "ciSS": "此时是",
        "qingXZ": "请选择",
        "xieK": "接口",
        "diSFXRSM": "第三方接入说明",
        "diSFPSXKQQJXSJQMDYFS": "第三方平台接口请求基于数据签名调用方式",
        "shiYQMLJYQHDQQDWZXSJHFX": "使用签名来校验客户端请求的完整性以及合法性",
        "ninKSCKRXWD": "您可以参看如下文档",
        "laiGC": "来构造",
        "xieKSDYDYDDSFPSXK": "接口以调用对应的第三方平台接口",
        "qianMZLSM": "签名示例说明",
        "liangCFS": "两种方式",
        "faQQQDQMXXDRYFDQQTZ": "发起请求的签名信息都需要放到请求头中",
        "erBSQQB": "而不是请求体",
        "qianMGZ": "签名规则",
        "zhuY": "注意",
        "qianMSJCYFWQSJBTXCWFZSS": "签名时间戳与服务器时间不能相差五分钟以上",
        "piZFWQQJJBCQQ": "否则服务器将拒绝本次请求",
        "qiangCS": "将参数",
        "an": "按",
        "baiXDD": "排序得到",
        "shiYPXSJCSJMYDD": "使用拼接时间戳以及密钥得到",
        "deiD": "得到",
        "qingQTZL": "请求头示例",
        "xiangYJLZL": "响应结果示例",
        "zhiLSJ": "示例数据",
        "zhiLSJLX": "示例数据类型",
        "fuWQYQLC": "服务器验签流程",
        "yanQSM": "验签说明",
        "shiYHQMXTDSF": "使用和签名相同的算法",
        "buRYDXYJLBX": "不需要对响应结果排序",
        "xieRSM": "接入说明",
        "pingS": "平台",
        "jiX": "基于",
        "banBKF": "版本开发",
        "yiL": "依赖",
        "qiangSX": "将以下",
        "yiLJRD": "依赖加入到",
        "wenJZ": "文件中",
        "qiaHDDCSHHQQFS": "客户端的初始化和请求方式",
        "qianMZLSJC": "签名示例时间戳",
        "fuWDXYJL": "服务端响应结果",
        "xiangYT": "响应头",
        "yanQTG": "验签通过",
        "fuWQD": "服务器的",
        "fangWBY": "访问秘钥",
        "ruoLXBW": "若类型不为",
        "genJBTLXTJDDBTDGLSZ": "根据不同类型添加得到不同的过滤数组",
        "shouY": "首页",
        "guoLTZZDXK": "过滤能展示的接口",
        "muS": "模式",
        "wei": "位",
        "shiBRYGL": "时不需要过滤",
        "guoLSZ": "过滤数组",
        "wenD": "文档",
        "fanH": "返回",
        "diaoS": "调试",
        "shiPZZ": "是否展示",
        "qiangDXXSDSJZHWBLRYDXS": "将对象形式的数据转换为表格需要的形式",
        "duiYYJ": "对应一级",
        "xiangGDL": "相关的类",
        "youZGXDX": "右侧勾选的项",
        "youZYBGXDX": "右侧原本勾选的项",
        "gouXFSBHDX": "勾选发生变化的项",
        "siDYD": "以对应的",
        "zuoW": "作为",
        "youZ": "右侧",
        "yingYGL": "应用管理",
        "zhaKCS": "查看菜单",
        "diSFSY": "第三方首页",
        "qiangDYSBZHWBLSJFH": "将对应实体转换为表格数据返回",
        "shiBL": "实体类",
        "shiBLMC": "实体类名称",
        "qiangZDXXSZZHW": "将字段信息数组转换为",
        "daiM": "代码",
        "duiYDSYSBL": "对应的所有实体类",
        "ziDXXSZ": "字段信息数组",
        "zhuanHSZ": "转换深度",
        "peiZJTZC": "配置系统支持",
        "fuQDFW": "赋权的范围",
        "guanLF": "关联方",
        "qingXZGLF": "请选择关联方",
        "beiGLF": "被关联方",
        "qingXZBGLF": "请选择被关联方",
        "yanZBS": "验证标识",
        "biaoSZTYSZ": "标识只能由数字",
        "yanZBSWYX": "验证标识唯一性",
        "qingSRJSMC": "请输入角色名称",
        "qingZSXZYX": "请至少选择一项",
        "biaoT": "表头",
        "caiSQW": "菜单权限",
        "shuJQW": "数据权限",
        "gouXLYSJQWYTKDZQCJDSJQW": "勾选任意数据权限均能看到自己创建的数据权限",
        "piLSZ": "批量设置",
        "biaoLNY": "表格内容",
        "buZCSJQWPZ": "不支持数据权限配置",
        "moRKZKQBSJ": "默认可查看全部数据",
        "quanBSJ": "全部数据",
        "suoZZZJXJZZ": "所在组织及下级组织",
        "suoZZZ": "所在组织",
        "ziQCJD": "自己创建的",
        "tongZPLSZ": "重置批量设置",
        "xiaMWBLNYBF": "下面为表格内容部分",
        "biaoLSJDPPHBB": "表格数据的扁平化版本",
        "qianKL": "浅克隆",
        "fangBJXDBLSJJXCZ": "方便进行对表格数据进行操作",
        "jianYQWDXGQK": "监听权限的修改情况",
        "chanSXGHFKGFZJ": "产生修改后反馈给父组件",
        "shenKLBLSJDPPB": "深克隆表格数据的扁平版",
        "yinWHZYSGD": "因为会做一些改动",
        "gaiGDZYXFKGFZJ": "该改动只用于反馈给父组件",
        "benZJMRBH": "本组件无需变化",
        "fangKGRZXSJXXDY": "放开个人中心以及消息订阅",
        "ruLGXZCSZSJQW": "如果该项支持设置数据权限",
        "zeDQJXSJQWDYS": "则对其进行数据权限的映射",
        "jieSHSSYXYSDYZX": "结束后删除用于映射的源属性",
        "tongSQSYXPQXZTDBJ": "同时清除用于半全选状态的标记",
        "moRXZGRZXXGSZ": "默认选中个人中心相关设置",
        "biaoLSJPPH": "表格数据扁平化",
        "diYLXZDX": "第一列选中的项",
        "xuanZDXZHFZJ": "选中的项传回父组件",
        "panDSQX": "判断是全选",
        "panQX": "半全选",
        "caiSQWGBZJ": "菜单权限改变事件",
        "chuFDX": "触发的项",
        "shiPGBDYDCZQW": "是否改变对应的操作权限",
        "panDSPRYDZCSJCZQWJXXZ": "判断是否需要对子菜单及操作权限进行选择",
        "gengXXZZT": "更新选中状态",
        "gengXSJQW": "更新数据权限",
        "ruLDQSJQWSYZ": "如果当前数据权限已有值",
        "juCSQWMYBXZYBPX": "且菜单权限没有被选中或被半选",
        "zeQKDYDSJQW": "则清空对应的数据权限",
        "ruLDQSJQWMYZ": "如果当前数据权限没有值",
        "juCSQWYBXZYZSBPX": "且菜单权限有被选中或者是被半选",
        "zeQSJQWBWMRZ": "则将数据权限变为默认值",
        "gengXSCJDDZT": "更新上层节点的状态",
        "zhaoDDYDFJD": "找到对应的父节点",
        "panDGFJDDXZZTW": "判断该父节点的选中状态为",
        "quanXZ": "全选中",
        "buFXZ": "部分选中",
        "weiXZ": "未选中",
        "ruoGFJDBSGJD": "若该父节点不是根节点",
        "tongFCCZSCLQDGFJDDFJDZT": "重复此操作以此来确定该父节点的父节点状态",
        "gaiBTBJDZT": "改变头部节点状态",
        "gengXQW": "更新权限",
        "caoZQWGBZJ": "操作权限改变事件",
        "qiangSXJGDBLSJBPWYGPTSZ": "将树形结构的表格数据拍扁为一个普通数组",
        "shuJQWHC": "数据权限回填",
        "genJSYQW": "根据所有权限",
        "qu": "取",
        "bingJSJ": "并集数据",
        "sheZZJDDZT": "设置子节点的状态",
        "genJ": "根据",
        "di": "的",
        "zhuX": "属性",
        "panDDYDQXZT": "判断对应的全选状态",
        "touBQXBSY": "头部全选不适用",
        "muBDX": "目标对象",
        "muBZX": "目标属性",
        "ruLZXXYPQX": "如果子选项有半全选",
        "zeDQJDZXWPQX": "则当前节点直接为半全选",
        "quanWFP": "权限分配",
        "yongHGL": "用户管理",
        "jieB": "解绑",
        "queRJB": "确认解绑",
        "qingGXSJ": "请勾选数据",
        "shuaXBL": "刷新表格",
        "qingSRXM": "请输入姓名",
        "queRMM": "确认密码",
        "qingZCSRMM": "请再次输入密码",
        "shouJX": "手机号",
        "qingSRZQDSJX": "请输入正确的手机号",
        "qingSRSJX": "请输入手机号",
        "qingSRZQDYX": "请输入正确的邮箱",
        "qingSRYX": "请输入邮箱",
        "tongZMM": "重置密码",
        "miMBTSX": "密码不能少于",
        "miMBXPHTXXYWHSZ": "密码必须包含大小写英文和数字",
        "weiDMM": "位的密码",
        "liangCMMSRBYZ": "两次密码输入不一致",
        "yongHLX": "用户类型"
    },
    "valueAdded": {
        "daoC": "导出",
        "shi": "是",
        "pi": "否",
        "xuX": "序号",
        "yongHM": "用户名",
        "chanPMC": "产品名称",
        "shePSS": "设配台数",
        "shiPZDDY": "是否自动订阅",
        "shengXSJ": "生效时间",
        "guoJSJ": "过期时间",
        "xiaoS": "小时",
        "huiYQY": "会员权益",
        "xinCYHQ": "新增优惠券",
        "quX": "取消",
        "queD": "确定",
        "jiTZDSC": "系统自动生成",
        "kaiSJSSJSJ": "开始结束时间时间",
        "qingXZKSSJ": "请选择开始时间",
        "zengSJE": "赠送金额",
        "yuan": "元",
        "qingSRZSJE": "请输入赠送金额",
        "qingSR": "请输入",
        "youHQSL": "优惠券数量",
        "zhang": "张",
        "qingSRYHQSL": "请输入优惠券数量",
        "youXJ": "有效期",
        "qingXZYXJ": "请选择有效期",
        "lingQHJS": "领取后计算",
        "genSHD": "跟随活动",
        "tian": "天",
        "tanCXG": "弹窗相关",
        "caoZCG": "操作成功",
        "xinC": "新增",
        "shanS": "删除",
        "queDYSSM": "确定要删除吗",
        "tingZ": "停止",
        "kaiSSJ": "开始时间",
        "jieSSJ": "结束时间",
        "lingQHYXJ": "领取后有效期",
        "siLQSJ": "已领取数据",
        "siSYSL": "已使用数量",
        "zhuangT": "状态",
        "daiQD": "待启动",
        "jinXZ": "进行中",
        "siTZ": "已停止",
        "siWC": "已完成",
        "chuangJSJ": "创建时间",
        "chuangJR": "创建人",
        "cuoHGXSJ": "最后更新时间",
        "cuoHGXR": "最后更新人",
        "caoZ": "操作",
        "caoZYB": "操作失败",
        "daoR": "导入",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "daoRSJ": "导入数据",
        "daoRCG": "导入成功",
        "daoRYB": "导入失败",
        "qingTS": "请重试",
        "luoS": "格式",
        "dingSXX": "订单信息",
        "dingSS": "订单数",
        "jinE": "金额",
        "dingS": "订单",
        "zong": "总",
        "daiFK": "待付款",
        "siFK": "已付款",
        "siGJ": "已过期",
        "zhiFCS": "支付超时",
        "dingSZX": "订单查询",
        "sanFDS": "三方订单",
        "chanP": "产品",
        "zhiFJD": "支付渠道",
        "zhiFLX": "支付类型",
        "zhiFZT": "支付状态",
        "zhiFSJ": "支付时间",
        "dingYFWGJSJ": "订阅服务过期时间",
        "shiPKJFP": "是否开具发票",
        "geiDDRJ": "给定的日期",
        "dangQSJ": "当前时间",
        "jiSSJC": "计算时间差",
        "shanW": "单位",
        "haoM": "毫秒",
        "qiangSJCZHWLXZHYDJ": "将时间差转换为离现在还有多久",
        "tianS": "天数",
        "xiaoSS": "小时数",
        "fenZS": "分钟数",
        "miaoS": "秒数",
        "shuCJL": "输出结果",
        "fenZ": "分组",
        "miao": "秒",
        "huiYDS": "会员订单",
        "gengXCPDJ": "更新产品定价",
        "qingSRCPMC": "请输入产品名称",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "qingSRXM": "请输入姓名",
        "chanPTP": "产品图片",
        "qingSZCPTP": "请上传产品图片",
        "jiFGZ": "计费规则",
        "banB": "版本",
        "shuL": "数量",
        "shanJ": "单价",
        "ru": "月",
        "ban": "版",
        "meiCJYS": "每增加一台",
        "chanPMS": "产品描述",
        "shuRNYHAHCJTJCPMS": "输入内容后按回车键添加产品描述",
        "queRSS": "确认删除",
        "qiSJFSL": "起始计费数量",
        "jieSJFSL": "结束计费数量",
        "chanPMSBTWK": "产品描述不能为空",
        "qingSRNY": "请输入内容",
        "xiangS": "向上",
        "xiangX": "向下",
        "liSJL": "历史记录",
        "guanB": "关闭",
        "caoZSJ": "操作时间",
        "caoZR": "操作人",
        "bianGNY": "变更内容",
        "xiuG": "修改",
        "bianGLS": "变更历史",
        "zhaX": "查询",
        "baoC": "保存",
        "quanWMC": "权限名称",
        "shiPSFX": "是否收费项",
        "shiPSF": "是否收费",
        "qingXZQWX": "请选择权限项",
        "baoCCG": "保存成功"
    },

    "gjsj": {
        "banBWJXQ": "版本文件详情",
        "shiYCP": "适用产品",
        "linWMC": "任务名称",
        "gengXDJ": "更新等级",
        "gengXNY": "更新内容",
        "gengXFW": "更新范围",
        "banBYJPZ": "版本硬件配置",
        "ju": "且",
        "guJBB": "固件版本",
        "yu": "或",
        "gengXSL": "更新数量",
        "jiHGXSBSL": "计划更新设备数量",
        "siGXSBSL": "已更新设备数量",
        "gengXPZ": "更新配置",
        "sheB": "设备",
        "fenZMYHCZS": "分钟无用户操作时",
        "ziDGX": "自动更新",
        "sheBDDSJ": "设备当地时间",
        "shi": "时",
        "jinY": "禁用",
        "qiY": "启用",
        "xiangQ": "详情",
        "linWX": "任务号",
        "zhuangT": "状态",
        "jiHGXSBS": "计划更新设备数",
        "caoZSJ": "操作时间",
        "caoZR": "操作人",
        "caoZ": "操作",
        "chanPXZ": "产品选择",
        "ziDGXPZ": "自动更新配置",
        "guJLXXZ": "固件类型选择",
        "ruanJTBB": "软件大版本",
        "banB": "版本",
        "banBZCDX": "版本支持多选",
        "yingJPZZCDX": "硬件配置支持多选",
        "xinCGJSJ": "新增固件升级",
        "yongHZDGX": "用户主动更新",
        "jinJGX": "紧急更新",
        "shengJ": "升级",
        "sheBKXSJ": "设备空闲时间",
        "fenZS": "分钟数",
        "biCBTWK": "必填不能为空",
        "sheZTBBSJQK": "设置大版本数据情况",
        "zhiTXZCXBB": "只能选择最新版本",
        "huoQBBPZLMDYJZCBB": "获取版本配置里面的硬件支持版本",
        "cuoSYTNY": "最少一条内容",
        "zuiDNR": "最多两条内容",
        "qingXZZDGXPZ": "请选择自动更新配置",
        "gengXNYPD": "更新内容判断",
        "qingXWSGXNY": "请先完善更新内容",
        "juGJLXBTXT": "且固件类型不能相同"
    },
    "bbbz": {
        "banBWJXQ": "版本文件详情",
        "shiYCP": "适用产品",
        "banBLX": "版本类型",
        "dangQCXZBB": "当前最新子版本",
        "zhiCYJBB": "支持硬件版本",
        "caoZR": "操作人",
        "caoZSJ": "操作时间",
        "liSZBB": "历史子版本",
        "bianJ": "编辑",
        "xiangQ": "详情",
        "banBWJM": "版本文件名",
        "chanPMC": "产品名称",
        "ruanJTBB": "软件大版本",
        "cuoXZBB": "最新子版本",
        "zhiCYJPZ": "支持硬件配置",
        "caoZ": "操作",
        "chanP": "产品",
        "chanPXZ": "产品选择",
        "leiX": "类型",
        "shengJGJBB": "升级固件版本",
        "xiuGLSRJBB": "修改历史软件版本",
        "cengJZZBB": "子版本",
        "ziBBX": "子版本号",
        "ziBBWJ": "子版本文件",
        "xinCBBWJ": "新增版本文件",
        "bianJDSHY": "编辑的时候用",
        "ziBBD": "子版本的",
        "ziBBWJDQM": "子版本文件的签名",
        "ziBBWJDTX": "子版本文件的大小",
        "zhiCDYJBBLB": "支持的硬件版本列表",
        "guJLX": "固件类型",
        "biCBTWK": "必填不能为空",
        "ziBBXBC": "子版本号必填",
        "ziBBWJBZ": "子版本文件必传",
        "zhiCYJBBBX": "支持硬件版本必选",
        "bianJYM": "编辑页面",
        "bianJBBWJ": "编辑版本文件",
        "xiangQYM": "详情页面",
        "genJCPGBBLXYZTBB": "根据产品跟版本类型或者大版本",
        "huoQBBPZLMDYJZCBB": "获取版本配置里面的硬件支持版本",
    },
    "bbsj": {
        "qiY": "启用",
        "tingY": "停用",
        "bianJ": "编辑",
        "jinY": "禁用",
        "chanPMC": "产品名称",
        "peiZLX": "固件类型",
        "banBLX": "版本类型",
        "yingJPZ": "版本号",
        "zhuangT": "状态",
        "caoZSJ": "操作时间",
        "caoZR": "操作人",
        "caoZ": "操作",
        "chanP": "产品",
        "chanPXZ": "产品选择",
        "leiX": "类型",
        "guJLX": "固件类型",
        "xinCYJPZ": "新增硬件配置",
        "siYYJPZ": "已有版本号",
        "xinCBBPZ": "新增版本配置",
        "ruanJ": "软件",
        "yingJ": "硬件",
        "biCBTWK": "必填不能为空",
        "bianJBBPZ": "编辑版本配置",
        "huoQBBPZLMDYJZCBB": "获取版本配置里面的硬件支持版本"
    },
    "AMapComponent": {
        "qingPZGDDT": "请配置高德地图",
        "luoX": "路线",
        "dangQHJBZC": "当前环境不支持",
        "jiSSZ": "计算速度",
        "shiPZDXZ": "是否置顶显示",
        "duiYDGJ": "对应的轨迹",
        "weiZ": "为真",
        "sheZ": "设置",
        "wei": "为",
        "xianCCT": "现存最大",
        "weiJ": "为假",
        "gouCCSZ": "构造参数中",
        "diFHZ": "的返回值"
    },
    "BadgeStatus": {
        "gaoJYS": "告警颜色",
        "zheGZBXPPXLZFCZDYG": "这个值必须匹配下列字符串中的一个",
        "ziDY": "自定义",
        "zhiYS": "值颜色"
    },
    "BatchDropdown": {
        "tongX": "重选",
        "piLCZ": "批量操作",
        "ruYXZBLSJ": "需要选择表格数据",
        "houCFDZJ": "后触发的事件"
    },
    "CardBox": {
        "tuP": "图片",
        "neiY": "内容",
        "gouX": "勾选",
        "zhuangT": "状态",
        "anN": "按钮",
        "zhengC": "正常"
    },
    "Empty": {
        "zanMSJ": "暂无数据"
    },
    "FileFormat": {
        "qingXZWJLS": "请选择文件格式",
        "ziDQY": "自动启用",
        "zuJSXBDDZ": "组件双向绑定的值"
    },
    "Form": {
        "qingXZWJLS": "请选择文件格式",
        "ziDQY": "自动启用",
        "zuJSXBDDZ": "组件双向绑定的值",
        "biaoSSJ": "表单数据",
        "biaoS": "表单",
        "genJZRDBS": "根据传入的表单",
        "shengCBS": "生成表单",
        "chuLMRZSJYSZ": "处理默认值以及原始值",
        "dangQZZ": "当前值在",
        "zhong": "中",
        "chuL": "处理",
        "qingQ": "请求",
        "tongZBS": "重置表单",
        "yanZBSJBS": "验证并提交表单",
        "gaiBSGZ": "改变单个值",
        "xiuGZGBSZ": "修改整个表单值",
        "zuJMC": "组件名称",
        "zuJ": "组件",
        "neiZZX": "内置查询",
        "huiFG": "会覆盖",
        "yinZ": "隐藏",
        "zhiBHJRD": "值不会进入到",
        "biaoSYZY": "表单隐藏域",
        "cuoDSR": "最多输入",
        "geZF": "个字符",
        "shuR": "输入",
        "xuanZ": "选择",
        "qing": "请",
        "qingSRYWYZSZYZ": "请输入英文或者数字或者",
        "yuZ": "或者",
        "cuoXFSR": "最小仅输入",
        "cuoTKSR": "最大可输入",
        "weiZF": "位字符"
    },
    "index.ts": {
        "xianZ": "显示",
        "haiS": "还是",
        "yongXBTFBL": "用于不同分辨率",
        "fenBL": "分辨率",
        "dingBZBZZ": "顶部左边插槽",
        "qiaPNY": "卡片内容",
        "fenY": "分页",
        "qiHQPHBL": "切换卡片和表格",
        "shuaXSJ": "刷新数据",
        "muSQH": "模式切换",
        "jianYKZ": "监听宽度",
        "jiSXZQPGS": "计算显示卡片个数",
        "qingQSJ": "请求数据",
        "panDRLSCHYYJCHYYWK": "判断如果是最后一页且最后一页为空",
        "jiuTZDQYY": "就跳转到前一页",
        "chuSH": "初始化",
        "daoCFF": "导出方法",
        "dingBYBZZ": "顶部右边插槽",
        "siXZ": "已选择",
        "xiang": "项",
        "quXXZ": "取消选择",
        "di": "第",
        "tiao": "条",
        "zongH": "总共"
    },
    "FRuleEditor": {
        "sheZZXGZ": "设置属性规则",
        "zhuXFZ": "属性赋值",
        "qingDSFGZSYDZXJXFZ": "请对上方规则使用的属性进行赋值",
        "faSSJ": "发送数据",
        "tianJTM": "添加条目",
        "yunXJL": "运行结果",
        "kaiSYX": "开始运行",
        "tingZYX": "停止运行",
        "qingK": "清空",
        "zhuX": "属性",
        "dangQZ": "当前值",
        "shangYZ": "上一值",
        "qingBJGZ": "请编辑规则",
        "qingXSRBS": "请先输入标识",
        "xiaoSGJZ": "搜索关键字",
        "qingXZSYZ": "请选择使用值",
        "shiSZWKSHQSYYXZBQ": "实时值为空时获取上一有效值补齐",
        "shiSZBWKZSYSSZ": "实时值不为空则使用实时值",
        "shiSZ": "实时值",
        "shiSZDSYYXZ": "实时值的上一有效值",
        "tianJ": "添加",
        "shuJLX": "数据类型",
        "shiPZD": "是否只读",
        "keXSZFW": "可写数值范围"
    },
    "GeoComponent": {
        "zuoBDSQZJ": "坐标点拾取组件",
        "deLWZ": "地理位置",
        "queR": "确认",
        "quX": "取消",
        "shouDSRDZBD": "手动输入的坐标点",
        "jingWZZFC": "经纬度字符串",
        "deTTC": "地图弹窗",
        "deTSQDZBD": "地图拾取的坐标点",
        "deTJWZ": "地图经纬度",
        "deTCSH": "地图初始化",
        "deTDJ": "地图点击",
        "xuanZXSJL": "选择搜索结果"
    },
    "InputSelect": {
        "qingXZSW": "请选择单位"
    },
    "JIndicators": {
        "qingSR": "请输入",
        "qingXZ": "请选择",
        "fanW": "范围"
    },
    "Layout": {
        "paoH": "包含",
        "cenS": "参数",
        "qingS": "清除",
        "biaoJSD": "标记已读",
        "weiDXX": "未读消息",
        "dangQBJWSD": "当前标记为已读",
        "zhaKGD": "查看更多",
        "yinZYQXGDT": "隐藏或取消滚动条",
        "geRZX": "个人中心",
        "tuiCDL": "退出登录"
    },
    "Metadata": {
        "peiZYS": "配置元素",
        "shuiM": "说明",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "qingSRSM": "请输入说明",
        "qingSR": "请输入",
        "shi": "是",
        "pi": "否",
        "cunCPZ": "存储配置",
        "meiJXPZ": "枚举项配置",
        "xinCMJX": "新增枚举型",
        "peiZCS": "配置参数",
        "meiJZ": "枚举值",
        "qingSRMJZ": "请输入枚举值",
        "zhiTYSZ": "只能由数字",
        "ziW": "字母",
        "xiaHX": "下划线",
        "zhongHXZC": "中划线组成",
        "biaoS": "标识",
        "qingSRBS": "请输入标识",
        "meiJMC": "枚举名称",
        "qingSRMJMC": "请输入枚举名称",
        "mingC": "名称",
        "qingSRMC": "请输入名称",
        "tianJCS": "添加参数",
        "zhiBZ": "指标值",
        "qingSRZBZ": "请输入指标值",
        "tianJZB": "添加指标",
        "guiZPZ": "规则配置",
        "congK": "窗口",
        "qingXZCK": "请选择窗口",
        "juHHS": "聚合函数",
        "qingXZJHHS": "请选择聚合函数",
        "qingSRCKZZ": "请输入窗口长度",
        "qingSRBZ": "请输入步长",
        "shiJCK": "时间窗口",
        "ciSCK": "次数窗口",
        "congKZZ": "窗口长度",
        "miao": "秒",
        "ci": "次",
        "buZ": "步长"
    },
    "MonacoEditor": {
        "daiMBJQ": "代码编辑器",
        "zhuTS": "主题色",
        "moRGL": "默认高亮",
        "heiS": "黑色",
        "gaoLHS": "高亮黑色",
        "daiMLSH": "代码格式化",
        "guangBWZZRNY": "光标位置插入内容",
        "zhuQHQGBWZ": "之前获取光标位置",
        "zhuHGBWZGB": "之后光标位置改变",
        "sheZGBWZW": "设置光标位置为",
        "zhuQDWZ": "之前的位置"
    },
    "NormalUpload": {
        "wenJSZ": "文件上传",
        "xiaZMB": "下载模板",
        "jinXZ": "进行中",
        "siWC": "已完成",
        "zongSL": "总数量",
        "zuJSXBDDZ": "组件双向绑定的值",
        "sheBDRMB": "设备导入模板",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "yiB": "失败",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "qingXSZWJ": "请先上传文件"
    },
    "Upload": {
        "wenJSZ": "文件上传",
        "xiaZMB": "下载模板",
        "jinXZ": "进行中",
        "siWC": "已完成",
        "zongSL": "总数量",
        "zuJSXBDDZ": "组件双向绑定的值",
        "sheBDRMB": "设备导入模板",
        "qingSZ": "请上传",
        "luoSWJ": "格式文件",
        "yiB": "失败",
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员",
        "qingXSZWJ": "请先上传文件",
        "dianJXG": "点击修改",
        "shangZYB": "上传失败",
        "qingSZZQLSDTP": "请上传正确格式的图片",
        "tuPTXBXXX": "图片大小必须小于"
    },
    "PermissionButton": {
        "zanMQW": "暂无权限",
        "qingLJGLY": "请联系管理员"
    },
    "Player": {
        "shiPBF": "视频播放",
        "boF": "播放",
        "zanT": "暂停",
        "zanTZT": "暂停状态",
        "shiPBFGJL": "视频播放工具栏",
        "fenPZJ": "分屏组件",
        "gongJL": "工具栏",
        "shanP": "单屏",
        "siFP": "四分屏",
        "jiuFP": "九分屏",
        "quanP": "全屏",
        "keBCFPPZJL": "可保存分屏配置记录",
        "fenPMC": "分屏名称",
        "qingSRMC": "请输入名称",
        "cuoDKSR": "最多可输入",
        "geZF": "个字符",
        "baoC": "保存",
        "zanMSJ": "暂无数据",
        "queRSS": "确认删除",
        "queR": "确认",
        "quX": "取消",
        "boFQ": "播放器",
        "shuaX": "刷新",
        "qiangZQ": "控制器",
        "dangQXZBFSP": "当前选中播发视频",
        "dangQCZDZ": "当前操作动作",
        "fenPSL": "分屏数量",
        "shiPCK": "视频窗口",
        "dangQXZDCK": "当前选中的窗口",
        "liSJL": "历史记录",
        "zhanZBCFC": "展示保存浮窗",
        "baoCBS": "保存表单",
        "quanPYS": "全屏元素",
        "shuaXSP": "刷新视频",
        "shiPLXBH": "视频链接变化",
        "gengXBFNY": "更新播放内容",
        "dangQWZWFPCHYW": "当前位置为分屏最后一位",
        "dianJFPLSJL": "点击分屏历史记录",
        "huoQLSFP": "获取历史分屏",
        "shanSLSFP": "删除历史分屏",
        "baoCFP": "保存分屏",
        "baoCCG": "保存成功",
        "baoCYB": "保存失败",
        "chuSH": "初始化",
        "gaiBFPSL": "改变分屏数量",
        "quanPCZ": "全屏操作",
        "dianJQZAN": "点击控制按钮",
        "qiangZLX": "控制类型"
    },
    "RadioCard": {
        "shanXQP": "单选卡片",
        "shuiP": "水平",
        "zhuiZ": "垂直",
        "shiPYXGYS": "是否有小勾样式"
    },
    "RowPagination": {
        "biaoLXXC": "表格行新增",
        "jianSFYZJ": "简单分页组件"
    },
    "Search": {
        "zuJ": "组件",
        "ruYJH": "需要结合",
        "shiY": "使用",
        "zhuX": "属于",
        "mingC": "名称",
        "shuiM": "说明",
        "leiX": "类型",
        "moRZ": "默认值",
        "zhaXXLLB": "查询下拉列表",
        "zhaXMS": "查询模式",
        "zhaXZJWY": "查询组件唯一",
        "zhaXHDZJ": "查询回调事件",
        "yongLTMMZXZDZ": "用来重命名查询字段值",
        "zhaXZZJLX": "查询值组件类型",
        "huo": "和",
        "zuJXLZ": "组件下拉值",
        "qiangZZXZDXLMRZ": "控制查询字段下拉默认值",
        "moRW": "默认为",
        "jiMC": "即名称",
        "zhaXTJ": "查询条件",
        "chuLSGZX": "处理单个查询",
        "zhi": "值",
        "jiCYF": "基础用法",
        "zhongPH": "中包含",
        "zhuXCHCXZZXXLZ": "属性才会出现在查询下拉中",
        "diZYZX": "的作用在于",
        "paoC": "抛出",
        "huiGJ": "会根据",
        "xiuGSJZ": "修改数据中",
        "diZ": "的值",
        "diZYZXSZZXTJ": "的作用在于设置查询条件",
        "xiangGTJCK": "相关条件参考",
        "zhongD": "中的",
        "shiJ": "提交",
        "moR": "默认",
        "yuZ": "或者",
        "bingJ": "并且",
        "paoH": "包含",
        "buPH": "不包含",
        "buZX": "不属于"
    },
    "Table": {
        "xianZ": "显示",
        "haiS": "还是",
        "yongXBTFBL": "用于不同分辨率",
        "fenBL": "分辨率",
        "dingBZBZZ": "顶部左边插槽",
        "qiaPNY": "卡片内容",
        "fenY": "分页",
        "qiHQPHBL": "切换卡片和表格",
        "shuaXSJ": "刷新数据",
        "muSQH": "模式切换",
        "jianYKZ": "监听宽度",
        "jiSXZQPGS": "计算显示卡片个数",
        "qingQSJ": "请求数据",
        "panDRLSCHYYJCHYYWK": "判断如果是最后一页且最后一页为空",
        "jiuTZDQYY": "就跳转到前一页",
        "chuSH": "初始化",
        "daoCFF": "导出方法",
        "dingBYBZZ": "顶部右边插槽",
        "siXZ": "已选择",
        "xiang": "项",
        "quXXZ": "取消选择",
        "di": "第",
        "tiao": "条",
        "zongH": "总共"
    },
    "TitleComponent": {},
    "ValueItem": {
        "cenSLXSRZJ": "参数类型输入组件",
        "qingSRLX": "请输入链接",
        "daiMBJQTC": "代码编辑器弹窗",
        "bianJ": "编辑",
        "queR": "确认",
        "quX": "取消",
        "zuJSXBDDZ": "组件双向绑定的值",
        "zuJLX": "组件类型",
        "xiaLXZKXLSJ": "下拉选择框下拉数据",
        "wenJSZ": "文件上传"
    }
}
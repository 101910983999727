/*
 * @Author: e “v_caoky@digitalgd.com.cn
 * @Date: 2023-09-06 18:03:08
 * @LastEditors: e “v_caoky@digitalgd.com.cn
 * @LastEditTime: 2023-09-06 19:01:43
 * @FilePath: \VaultekSafe-Admin\src\i18n\zh-CN.ts
 * @Description: 
 */
export default {
    "account": {
        "diSFZHBD": "Third party account binding",
        "siDL": "Logged in",
        "bangDSFZX": "Bind third-party accounts",
        "geRXX": "personal information",
        "zhangX": "Account",
        "yongHM": "User name",
        "sanFZHXX": "Third party account information",
        "weiJBD": "Bind Now",
        "weiDL": "Not logged in",
        "niSTG": "You have passed",
        "shouQ": "authorization",
        "wanSSXDLXXJKSWCBD": "Complete the following login information to complete the binding",
        "zhangH": "account",
        "qingSRZH": "Please enter your account",
        "miM": "password",
        "qingSRMM": "Please enter password",
        "yanZM": "Verification code",
        "qingSRYZM": "Please enter the verification code",
        "deLBBDZH": "Login and bind account",
        "yongHXX": "User Information",
        "huoQ": "obtain",
        "cenS": "parameter",
        "sanFYYXX": "Third party application information",
        "dingD": "DingTalk",
        "weiX": "WeChat",
        "bangDCG": "Binding successful",
        "xianDLZBD": "Login first and then bind",
        "huoQTXYZM": "Obtain graphic verification code",
        "deLCG": "Login successful",
        "bangDCGTZZYM": "Successfully bound and redirected to the page",
        "di": "of",
        "neiBJCRYTHYMYM": "Internal integration requires jumping back to their pages",
        "bianJ": "Edit",
        "xingM": "Name",
        "xingMBC": "Name is required",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "qingSRXM": "Please enter your name",
        "qingSRYHM": "enter one User name",
        "jiaoS": "role",
        "qingSRJS": "Please enter the role",
        "zuZ": "organization",
        "qingSRZZ": "Please enter the organization",
        "shouJX": "Mobile phone number",
        "qingSRZQSJX": "Please enter the correct phone number",
        "qingSRSJX": "Please enter your phone number",
        "youX": "Mailbox",
        "youXBSYGYXD": "The email is not a valid one",
        "qingSRYX": "Please enter your email address",
        "baoCCG": "Save successful",
        "tongZMM": "reset password",
        "jiuMM": "Old password",
        "qingSRJMM": "Please enter your old password",
        "queRMM": "Confirm password",
        "qingSRQRMM": "Please enter a confirmation password",
        "qingZCSRMM": "Please enter your password again",
        "yanZYB": "Verification failed",
        "liangCMMSRBYZ": "The two password inputs are inconsistent",
        "gengHTX": "Change avatar",
        "zhuCSJ": "Registration time",
        "dianH": "telephone",
        "xiuGMM": "Change password",
        "anQXGDMMKSSZXGAQ": "A password with high security can make the account more secure",
        "jianYNDJGHMM": "We suggest that you regularly change your password",
        "sheZYGPHZW": "Set a containing letter",
        "fuXYSZZZSLXJZZCG": "At least two symbols or numbers with a length exceeding",
        "weiDMM": "Password for bit",
        "sheQBBXZ": "Community version not displayed",
        "bangDM": "Binding Name",
        "bangDSJ": "Binding time",
        "weiBD": "Unbound",
        "queRJSBDM": "Are you sure to unbind",
        "jieSBD": "Unbind",
        "diSFYHBXZ": "Third party Users do not display",
        "shouYST": "Home page view",
        "queD": "Confirm",
        "diSFZX": "Third party account",
        "jieBCG": "Unbind successful",
        "shangZCG": "Upload successful",
        "shangZYB": "Upload failed",
        "qingSHZS": "Please try again later",
        "qingSZZQLSDTP": "Please upload images in the correct format",
        "huoQYHXX": "Obtain User information",
        "huoQBDDSFZX": "Get bound third-party accounts",
        "huoQSYST": "Get homepage view",
        "panDSPS": "Determine if it is",
        "yongH": "User",
        "buSZHQXZDST": "If not, obtain the selected view",
        "xiangQ": "Details",
        "gaoJSB": "Alarm equipment",
        "sheB": "Equipment",
        "gaoJMC": "Alarm name",
        "gaoJSJ": "Alarm time",
        "gaoJJB": "Alarm level",
        "gaoJSM": "Alarm Description",
        "gaoJLS": "Alarm flow",
        "queRBW": "Confirmed as",
        "weiD": "Unread",
        "siD": "Read",
        "biaoWWD": "Mark as unread",
        "biaoWSD": "Mark as read",
        "zhaK": "check",
        "leiX": "Type",
        "xiaoX": "news",
        "tongZSJ": "Notification time",
        "zhuangT": "State",
        "caoZ": "Operation",
        "caoZCG": "Operation successful",
        "xinC": "Create",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "qingXZLX": "Please select the type",
        "gaoJGZ": "Alarm rules",
        "qingXZGJGZ": "Please select an alarm rule",
        "tongZFS": "Notification method",
        "qingXZTZFS": "Please choose the notification method",
        "zhanNTZ": "On site notifications",
        "jinY": "Disabled",
        "qiY": "Enable",
        "qingXJY": "Please disable first",
        "zaiSS": "Delete again",
        "shanS": "Delete",
        "queRSS": "confirm deletion",
        "caoZYB": "operation failed"
    },
    "DataCollect": {
        "qingSRMC": "Please enter a name",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "qingXZTXXY": "Please select a communication protocol",
        "qingSR": "Please enter",
        "zhuJ": "main engine",
        "qingSRZQLSD": "Please enter the correct format",
        "deZ": "address",
        "qingSRDK": "Please enter the port",
        "zhuJDZZS": "Positive integers between",
        "qingSRDD": "Please enter endpoint",
        "qingXZAQCL": "Please select a security policy",
        "qingXZAQMS": "Please select safe mode",
        "qingXZZS": "Please select a certificate",
        "qingXZQWRZ": "Please select permission authentication",
        "qingSRYHM": "enter one User name",
        "qingSRMM": "Please enter password",
        "tongDMC": "Channel name",
        "tongXXY": "Communication protocol",
        "zhuangT": "State",
        "zhengC": "Normal",
        "jinY": "Disabled",
        "yunXZT": "running state",
        "yunXZ": "Running",
        "buFCW": "Partial errors",
        "cuoW": "error",
        "shuiM": "Explain",
        "caoZ": "Operation",
        "xinCTD": "New channel added",
        "xieY": "protocol",
        "qiY": "Enable",
        "bianJ": "Edit",
        "queR": "Confirm ",
        "caoZCG": "Operation successful",
        "shanS": "Delete",
        "qingXJYGZJ": "Please disable this component first",
        "zaiSS": "Delete again",
        "gaiCZQHSSXZCJQYDW": "This operation will delete the subordinate collectors and points",
        "queDSS": "Confirm deletion",
        "xiaoS": "hour",
        "chaoCDWBYZ": "Exceeding text hiding",
        "yiCYXLXXZ": "Overflow displayed with ellipses",
        "yiCBHX": "Overflow without line breaks",
        "xinC": "Create",
        "qingSRTDMC": "Please enter the channel name",
        "zhiC": "support",
        "yuM": "domain name",
        "duanK": "Disconnect",
        "duanD": "Endpoint",
        "anQCL": "security policy",
        "anQMS": "safe mode",
        "zhengS": "certificate",
        "quanWRZ": "Permission authentication",
        "yongHM": "User name",
        "miM": "password",
        "qingSRSM": "Please enter a description",
        "quX": "Cancel",
        "qingSRDWMC": "Please enter the location name",
        "qingXZGTM": "Please select the function code",
        "qingSRDZ": "Please enter the address",
        "qingSRJCQSL": "Please enter the number of registers",
        "qingXZSJLX": "Please select a data type",
        "qingSRSFYZ": "Please enter the scaling factor",
        "qingXZFWLX": "Please select the access type",
        "qingXZSPXRSJQZZ": "Please choose whether to write the length of the data area",
        "qingSRZDYSJQZZ": "Please enter the length of the custom data area",
        "qingSRCJPL": "Please enter the collection frequency",
        "yuZZZS": "Or positive integers",
        "qingXZSZTD": "Please select the channel you belong to",
        "qingSRCJQMC": "Please enter the collector name",
        "qingSRCJDZ": "Please enter the slave address",
        "qingXZCLFS": "Please choose the processing method",
        "qingXZSZGDWQH": "Please choose to switch between high and low positions of a single word",
        "mingC": "Name",
        "fangWLX": "Access type",
        "caiJPL": "Collection frequency",
        "zhiTSBHDSJ": "Only push changing data",
        "shouDYC": "Manual delay",
        "fangZSJKGXBJS": "Prevent untimely database updates",
        "piLBJ": "Batch editing",
        "qiangPLXG": "Batch modification",
        "tiaoSJDFWLX": "Access type of data",
        "du": "read",
        "xie": "write",
        "dingY": "subscription",
        "caiJPLW": "The collection frequency is",
        "shiBZXLXLW": "When not executing polling tasks",
        "tuP": "Picture",
        "neiY": "content",
        "gouX": "Check the box",
        "anN": "button",
        "xieR": "write",
        "shi": "yes",
        "pi": "no",
        "qingXZ": "Please select",
        "xinCDW": "Add new locations",
        "saoM": "scanning",
        "piLCZ": "Batch operations",
        "quanX": "Select All",
        "dianWMC": "Point name",
        "siTZ": "Stopped",
        "qingXXZ": "Please select first",
        "jiCQSL": "Number of registers",
        "suoFYZ": "Scale factor",
        "fangZSXGK": "Prevent refresh from being too fast",
        "gongTM": "Function code",
        "xianJJCQ": "Coil register",
        "liSSRJCQ": "Discrete input register",
        "baoCJCQ": "Save Register",
        "shuRJCQ": "Input register",
        "qingXZSGTM": "Please select the desired function code",
        "shuJLX": "Data type",
        "xiaoSBLWS": "Decimal Places",
        "qingSRXSBLWS": "Please enter the number of decimal places to be retained",
        "feiBZXYXRPZ": "Non standard protocol write configuration",
        "shiPXRSJQZZ": "Whether to write the length of the data area",
        "ziDYSJQZZ": "Custom data area length",
        "shiDTZD": "It's an extra field",
        "yanS": "however",
        "banBSSYDLZGZD": "This field is used in the version",
        "shuJLXZZR": "The length of the data type needs to be",
        "shuZLX": "Array type",
        "shiJLX": "Time type",
        "wenBLX": "Text type",
        "buE": "Boolean",
        "tongS": "Same as above",
        "queRSS": "confirm deletion",
        "shanSSRYZTSCZ": "The same operation is required for deletion",
        "weiGXWC": "Not updated completed",
        "ruYY": "Need to use",
        "yuZ": "perhaps",
        "chuL": "handle",
        "youXCSTSSJ": "When there are new additions, the same data applies",
        "shuJY": "data source",
        "yinZSYJD": "Hide existing nodes",
        "xinCCJQ": "New collector added",
        "queDQY": "Confirm Enable",
        "queDJY": "Confirm disabled",
        "qingXJY": "Please disable first",
        "gaiCZQHSSXZDW": "This operation will delete subordinate points",
        "zanMSJ": "There is currently no data available",
        "quanB": "whole",
        "tongDTZJLYZXSS": "When the channel jumps in or searches",
        "shuGJDMQB": "Root node not all",
        "moRXZDYG": "By default, the first one is selected",
        "jiH": "activation",
        "ziJ": "event",
        "suoZTD": "Belonging channel",
        "caiJQMC": "Collector Name",
        "congJDZ": "Slave address",
        "guZCL": "Fault handling",
        "jiangP": "Frequency reduction",
        "huL": "ignore",
        "shuangZGDWQH": "Double word high low switching",
        "shanZGDWQH": "Single word high low switching",
        "dangQNCBJ": "Current memory layout",
        "zhiY": "only",
        "ziJSJLX": "Byte data type",
        "juY": "have",
        "chongNCBJ": "Kind of memory layout",
        "qiYZY": "Other only",
        "liangCNCBJ": "Two memory layouts",
        "siSZPZWZ": "According to the double word configuration",
        "qingQCSSJPZ": "Request timeout configuration",
        "qingSRQQCSSJPZ": "Please enter the request timeout time configuration",
        "lianX": "continuity",
        "ciYC": "Secondary anomaly",
        "jiangDLXPLZYYPLD": "Reduce the connection frequency to the original frequency",
        "tongSJJBCG": "The retry interval should not exceed",
        "fenZ": "minute",
        "guZCLHZDHFZSDLXPL": "Automatically restore to the set connection frequency after troubleshooting",
        "fenZYC": "Minute anomaly",
        "tingZCJSJJRDKZT": "Stopping data collection and entering a disconnected state",
        "sheBTXQYHHFCJZT": "Restore collection status after device reactivation",
        "huLYC": "Ignoring exceptions",
        "baoCYCJPLCSSJW": "Keep the original collection frequency timeout time as",
        "dianWSJL": "Point data volume",
        "cuoJ": "recently",
        "jinR": "today",
        "jinYZ": "Last week",
        "xiaoXL": "Message volume",
        "chuDYS": "Color at",
        "queXW": "Default to",
        "yiCTD": "Abnormal channel",
        "tongDSL": "Number of channels",
        "yiCCJQ": "Exception collector",
        "caiJQSL": "Number of collectors",
        "yiCDW": "Abnormal points",
        "caiJDW": "Collection point location"
    },
    "device": {
        "xinCBJTC": "Add editing pop-up window",
        "queD": "Confirm",
        "quX": "Cancel",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "baiX": "Sort",
        "qingSRBX": "Please enter sorting",
        "shuiM": "Explain",
        "appVisible": "Is visible to APP",
        "qingSRSM": "Please enter a description",
        "biaoSSJ": "Form Data",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "shiJSJ": "Submit data",
        "caoZCG": "Operation successful",
        "caoZYB": "operation failed",
        "xianZTC": "Show pop ups",
        "xinC": "Create",
        "shuXJGCDTJ": "Tree structure can be added at most",
        "ceng": "layer",
        "bianJ": "Edit",
        "shengX": "Ascending order",
        "huoQLBSJ": "Get List Data",
        "guanBTC": "Close pop-up window",
        "jianYXM": "Monitoring project",
        "chanPFL": "Product classification",
        "xinCHBJTC": "Add and edit pop ups",
        "shaiX": "screen",
        "maoS": "describe",
        "caoZ": "Operation",
        "xiaoS": "search",
        "caoZLAN": "Action bar buttons",
        "bianJFL": "Edit Classification",
        "tianJZFL": "Add subcategories",
        "xinCZFL": "Add subcategories",
        "shanS": "Delete",
        "queRSS": "confirm deletion",
        "tianJCPFL": "Add product category",
        "xinCFL": "Add a new category",
        "shuaXBLSJ": "Refresh Table Data",
        "chuSH": "initialization",
        "sheB": "Equipment",
        "shouD": "Manual",
        "guiZ": "rule",
        "du": "read",
        "xie": "write",
        "shangB": "Reporting",
        "biaoS": "Form",
        "ziJJB": "Event level",
        "shiPYB": "Is it asynchronous",
        "duXLX": "Reading and writing types",
        "shuJLX": "Data type",
        "zhuXLY": "Attribute Source",
        "qingSRBS": "Please enter identification",
        "zhiTYSZ": "Can only be represented by numbers",
        "ziW": "letter",
        "xiaHX": "Underline",
        "zhongHXZC": "Middle line composition",
        "qingXZSPYB": "Please choose whether to proceed asynchronously",
        "shi": "Yes",
        "pi": "no",
        "shuRCS": "input parameter",
        "peiZBZ": "Configuration steps",
        "anXZXHSXBL": "Arrange in order of selection",
        "shuCCS": "Output parameters",
        "jiB": "level",
        "qingXZJB": "Please select a level",
        "zhiYPZ": "Guidance configuration",
        "cenSXZ": "Parameter selection",
        "qingXZCS": "Please select parameters",
        "ziJZY": "Event guidance",
        "qingSRZJZY": "Please enter event guidelines",
        "zhiYTP": "Guide image",
        "qingSZZYTP": "Please upload a guide image",
        "jinRXYBTJ": "Enter the next step conditions",
        "shouGCF": "Manual triggering",
        "cenSPP": "Parameter matching",
        "qingXZDXLX": "Please select the read/write type",
        "ziJ": "byte",
        "laiY": "source",
        "qingXZLY": "Please select the source",
        "qiYPZ": "Other configurations",
        "qingSRZBPZ": "Please enter indicator configuration",
        "zhiBPZ": "Indicator configuration",
        "changJLDYMKYYZBPZZWCFTJ": "The scene linkage page can reference indicator configurations as triggering conditions",
        "baoC": "Save",
        "zhuX": "attribute",
        "gongT": "function",
        "biaoQ": "label",
        "baoCAN": "Save button",
        "biaoSSCZ": "Identification already exists",
        "qingPZMJX": "Please configure enumeration items",
        "qingSRYSPZ": "Please enter element configuration",
        "peiZCS": "configuration parameter",
        "qingSR": "Please enter",
        "qingXZ": "Please select",
        "qingXZWJLX": "Please select a file type",
        "shanW": "unit",
        "jingZ": "accuracy",
        "qingSRJZ": "Please enter accuracy",
        "shiJLS": "Time format",
        "buEZ": "Boolean value",
        "meiJX": "Enumeration item",
        "cuoTZZ": "Maximum length",
        "qingSRCTZZ": "Please enter the maximum length",
        "yuanSPZ": "Element configuration",
        "duiX": "object",
        "wenJLX": "file type",
        "huanCSWSJ": "Cache unit data",
        "jieGB": "structural morphology",
        "qingSRPZCS": "Please enter configuration parameters",
        "dangQDCCFSBZCXC": "The current storage method does not support adding new ones",
        "dangQDCCFSBZCBJ": "The current storage method does not support editing",
        "puT": "ordinary",
        "jingG": "warning",
        "jinJ": "urgent",
        "di": "low",
        "tiao": "strip",
        "zongH": "altogether",
        "xiuGWMXHHTLCPWMX": "After modifying the object model, it will detach from the product object model",
        "zhaKWMX": "View object models",
        "daoC": "Export",
        "wuMXSDSBZYDDGTMS": "The object model is a description of the functionality of a device in the cloud",
        "paoKSBDZX": "Including device properties",
        "fuWHZJ": "Services and events",
        "wuLWPSTGDYYCWDMSYYLMSWMX": "IoT platforms describe object models by defining a description language for objects",
        "chengZW": "Called it",
        "ji": "Namely",
        "caiY": "adopt",
        "luoS": "format",
        "ninKSGJ": "You can refer to",
        "zuZSBSBDSJ": "Assembling and reporting equipment data",
        "ninKSDCWZWMX": "You can export the complete object model",
        "yongXYDYYKF": "Used for cloud application development",
        "wuMX": "Physical model",
        "qingXPZWMX": "Please configure the object model first",
        "daoRWMX": "Import object model",
        "daoRDWMXHFGYLDZX": "The imported object model will overwrite the original attributes",
        "qingJSCZ": "Please operate with caution",
        "daoRFS": "Import method",
        "kaoBCP": "Copying products",
        "xuanZCP": "Select product",
        "wuMXLX": "Object model type",
        "aLYWMX": "Alibaba Cloud IoT Model",
        "daoRLX": "Import Type",
        "wenJSZ": "File upload",
        "jueB": "script",
        "zaiXBJQZBXWMXJB": "Writing object model scripts in an online editor",
        "qingXZDRFS": "Please choose the import method",
        "qingXZCP": "Please select a product",
        "qingXZWMXLX": "Please select the type of object model",
        "qingXZDRLX": "Please select the import type",
        "qingSZWJ": "Please upload the file",
        "qingSRWMX": "Please input the object model",
        "daoRCG": "Import successful",
        "wuMXSJBZQ": "The physical model data is incorrect",
        "shangZ": "upload",
        "luoSDWMXWJ": "Format of object model file",
        "gaiSBSTLCPWMX": "The device has detached from the product model",
        "xiuGCPWMXDGSBMYX": "Modifying the product model has no impact on the device",
        "sheBHMRJCCPDWMX": "The device will default to inheriting the product's physical model",
        "xiuGSBWMXHQTLCPWMX": "After modifying the equipment model, it will detach from the product model",
        "queRTZ": "Confirm reset",
        "tongZHQSYCPDWMXPZ": "After resetting, the product's physical model configuration will be used",
        "tongZCZ": "Reset operation",
        "kuaiSDR": "Quick import",
        "zhuXDY": "Attribute Definition",
        "gongTDY": "Function definition",
        "ziJDY": "Event Definition",
        "biaoQDY": "Label Definition",
        "tongZWMX": "Reset object model",
        "gengXWMX": "Update the physical model",
        "wuMXSJ": "Physical model data",
        "shuJGXHD": "Data update callback",
        "gengXSJK": "Update database",
        "faSZJDCZ": "Send events and other operations",
        "weiCFWMXXG": "Untriggered object model modification",
        "baoCWMXSJDFWQ": "Save object model data to the server",
        "leiX": "Type",
        "shuJ": "data",
        "shanSWMXSJ": "Delete object model data",
        "shanSDSJ": "Data deleted",
        "chanPSJ": "Product data",
        "huiD": "Callback",
        "tuBSJ": "Chart data",
        "huiZTB": "Draw a chart",
        "xianZKQAN": "Show shortcut buttons",
        "kuaiQANLB": "List of shortcut buttons",
        "jinR": "enter",
        "jinYZ": "Last week",
        "jinYR": "In the past month",
        "jinYN": "In the past year",
        "chanPSL": "Product quantity",
        "sheBSL": "Device instance",
        "dangQZX": "Currently online",
        "jinRSBXXL": "Today's device message volume",
        "sheBXX": "Device information",
        "sheBFB": "Equipment distribution",
        "zhengC": "Normal",
        "jinY": "Disabled",
        "zaiX": "Online",
        "liX": "Offline",
        "zuoRZX": "Online yesterday",
        "dangRSBXXL": "Monthly device message volume",
        "zuoR": "yesterday",
        "huoQCPSL": "Obtain product quantity",
        "huoQSBSL": "Obtain the number of devices",
        "huoQZXSL": "Obtain online quantity",
        "zaiXS": "Online quantity",
        "shiPPHQX": "Smooth curve or not",
        "guaiDTX": "Inflection point size",
        "chuDYS": "Color at",
        "queXW": "Default to",
        "xiaoXL": "Message volume",
        "ru": "month",
        "ri": "day",
        "ning": "year",
        "zhengSX": "Integer type",
        "zhangZSX": "Long integer type",
        "shanJZFDX": "Single precision floating-point type",
        "shuangJZFDS": "Double precision floating-point number",
        "ziFC": "character string",
        "buEX": "Boolean type",
        "shiJX": "Time based",
        "meiJ": "enumeration",
        "shuZ": "array",
        "wenJ": "file",
        "miM": "password",
        "deLWZ": "geographical position",
        "lianX": "connect",
        "bianM": "coding",
        "erJZ": "Binary",
        "leiXD": "Type",
        "shiJC": "time stamp",
        "haoM": "millisecond",
        "guJMC": "Firmware Name",
        "ruanJBB": "Software version",
        "yaoQYJBB": "Require hardware version",
        "banBXX": "Version number",
        "guJLX": "Firmware type",
        "suoZCP": "Belonging products",
        "qianMFS": "Signature method",
        "chuangJSJ": "Creation time",
        "shengJLW": "Upgrade task",
        "buZCHBC": "No error message for attending the conference",
        "zanSZXQYZTD": "Temporarily query enabled status",
        "houJHGZQB": "We will update and check everything later on",
        "shangZWJ": "Upload",
        "shangZCG": "Upload successful",
        "jiTSZ": "System prompt",
        "jiTWZCW": "Unknown system error",
        "qingFKGGLY": "Please provide feedback to the administrator",
        "queR": "Confirm ",
        "qingXZSZCP": "Please select the product you belong to",
        "ruanJBBX": "Software version number",
        "qingSRBBX": "Please enter the version number",
        "yingJBBX": "Hardware version number",
        "qingSRYJBBX": "Please enter the hardware version number",
        "qingSRBBXX": "Please enter the version number",
        "qingXZQMFS": "Please choose a signature method",
        "qianM": "autograph",
        "qingSRBDWJJXQMJMHDZ": "Please enter the value after signing and encrypting the local file",
        "qingSRQM": "Please enter your signature",
        "guJSZ": "Firmware upload",
        "qiTPZ": "Other configurations",
        "queRSSM": "Are you sure to delete it",
        "tianJ": "Add",
        "qianMBYZ": "Inconsistent signature",
        "qingJZWJSPSZZQ": "Please check if the file is uploaded correctly",
        "banBXXSCZ": "Version number already exists",
        "qingSRRJBBX": "Please enter the software version number",
        "queDPLTS": "Confirm batch retry",
        "piLTS": "Batch retry",
        "dengDSJ": "Waiting for upgrade",
        "shengJZ": "Upgrading",
        "shengJWC": "Upgrade completed",
        "shengJYB": "Upgrade failed",
        "siTZ": "Stopped",
        "sheBMC": "Device Name",
        "wanCSJ": "Completion time",
        "jinZ": "schedule",
        "zhuangT": "State",
        "zhaK": "check",
        "tongS": "retry",
        "queRTS": "Confirm retry",
        "yiBYY": "Reason for failure",
        "linWMC": "Task Name",
        "tuiSFS": "Push method",
        "sheBLQ": "Equipment pull",
        "pingSTS": "Platform push",
        "wanCBL": "Completion ratio",
        "kaiSSJ": "start time",
        "xiangQ": "Details",
        "linW": "task",
        "qingSRLWMC": "Please enter the task name",
        "qingXZTSFS": "Please select the push method",
        "xiangYCSSJ": "Response timeout",
        "qingSRXYCSSJ": "Please enter the response timeout time",
        "miao": "second",
        "shengJCSSJ": "Upgrade timeout",
        "qingSRSJCSSJ": "Please enter the upgrade timeout period",
        "shengJSB": "Upgrade device",
        "suoYSB": "All devices",
        "xuanZSB": "Select device",
        "qingXZSB": "Please select a device",
        "quanX": "Select All",
        "guJBB": "Firmware version",
        "zhuCSJ": "Registration time",
        "bangDSB": "Binding devices",
        "bangDZSB": "Bind sub devices",
        "qingXZRYBDDSB": "Please select the device that needs to be bound",
        "dianWYS": "Point mapping",
        "piLYS": "Batch mapping",
        "baoCBYY": "Save and Apply",
        "caiJQ": "collector",
        "bianYWGDLDZSWLSB": "Real physical devices for edge gateway proxies",
        "qingXZCJQ": "Please select a collector",
        "qingXZDW": "Please select a location",
        "siBD": "Bound",
        "weiBD": "Unbound",
        "jieB": "Unbind",
        "queRJB": "Confirm unbinding",
        "zanMSJ": "There is currently no data available",
        "qingPZWMX": "Please configure the object model",
        "tongD": "passageway",
        "dianW": "Point position",
        "huoQTDLB": "Get channel list",
        "jieBYS": "Unbind mapping",
        "xuanZJB": "Choose unbind",
        "wangGSB": "Gateway device",
        "baoCCG": "Save successful",
        "caiJQDDWMCYZXMCYZSQZDYSBD": "When the point name of the collector matches the attribute name, it will be automatically mapped and bound",
        "youDGCJQDWMCYZXMCYZSSD": "When there are multiple collector point names that match the attribute names, use the first",
        "geCJQDDWSJJXBD": "Bind point data from multiple collectors",
        "yuanSJ": "Source data",
        "jiaRYZ": "Join on the right side",
        "queDSS": "Confirm deletion",
        "zanMDYZXDYS": "There is currently no mapping for the corresponding attribute",
        "xinCBBD": "Add and bind",
        "bangD": "binding",
        "queDJBM": "Are you sure to unbind it",
        "piLJS": "Batch release",
        "queRJBM": "Are you sure to unbind it",
        "qingGXRYJBDSJ": "Please check the data that needs to be unbound",
        "jiBXX": "Basic information",
        "fanH": "return",
        "qingSRSBMC": "Please enter the device name",
        "chanPMC": "Product Name",
        "qingXZCPMC": "Please select a product name",
        "huoQCPLB": "Get product list",
        "huoQYSSJ": "Get mapping data",
        "heBWMX": "Merge object model",
        "shanSWMX": "Delete object model",
        "shanSHJB": "Unbind after deletion",
        "genJCP": "Based on the product",
        "huoQDYYSLB": "Get the corresponding mapping list",
        "chanP": "Product",
        "bangDFSB": "Bind parent device",
        "shuJCJZPZDZSWLSB": "Real physical devices configured in data collection",
        "siZD": "Diagnosed",
        "ge": "individual",
        "lianXZT": "Connection status",
        "xiaoXTX": "Message communication",
        "chuLSJBGXDQK": "Handling situations where data is not updated",
        "quanWYZ": "Permission verification",
        "jieM": "decoding",
        "qingQ": "request",
        "xiangY": "response",
        "xiaXXX": "Downward message",
        "shangXXX": "Uplink message",
        "cenSMC": "Parameter Name",
        "zhi": "value",
        "duQZX": "read attribute",
        "xiuGZX": "modify attribute",
        "diaoYGT": "Call function",
        "qingXZZX": "Please select attributes",
        "qingSRZ": "Please enter a value",
        "qingXZGT": "Please select the function",
        "faS": "send",
        "cenSLB": "parameter list",
        "qingSRCSLB": "Please enter a parameter list",
        "diaoS": "debugging",
        "riZ": "journal",
        "shangXXXZDZ": "Diagnosis of uplink messages",
        "xiaXXXZDZ": "Downward message diagnosis in progress",
        "xiaXXXTXYC": "Abnormal downlink message communication",
        "xiaXXXTXZC": "Downlink message communication is normal",
        "shangXXXTXYC": "Abnormal uplink message communication",
        "shangXXXTXZC": "Uplink message communication is normal",
        "sheBZD": "Equipment diagnosis",
        "zhenDJY": "Diagnostic recommendations",
        "suoYZDYMYCYSBRWSX": "All diagnoses are normal, but the device has not been launched yet",
        "qingJZSXNY": "Please check the following content",
        "lianXXX": "Connection information",
        "lianXDZ": "Connection address",
        "wangGXX": "Gateway information",
        "fuSB": "Parent device",
        "wangLXX": "network information",
        "wangLZJ": "Network components",
        "zhenDWLZJPZSPZQ": "Diagnose whether the network component configuration is correct",
        "peiZCWQDZSBLXYB": "Configuration errors will result in device connection failure",
        "yiC": "abnormal",
        "wangLZJSJY": "Network component disabled",
        "qingX": "Please proceed first",
        "queRQY": "Confirm activation",
        "qiY": "Enable",
        "qingJZFWQDKSPKF": "Please check if the server port is open",
        "ruWKF": "If not opened",
        "qingKFHCSTXLX": "Please try reconnecting after opening",
        "qingJZFWQFHCL": "Please check the server's fire prevention strategy",
        "ruYKQFHQ": "If a firewall is enabled",
        "qingGBFHQYDZFHQCLHTS": "Please close the firewall or adjust the firewall policy before trying again",
        "qingQFSCW": "Request has encountered an error",
        "sheBBH": "The device does not include",
        "sheBXRWG": "Device access gateway",
        "zhenDSBXRWGZTSPZC": "Is the status of the diagnostic device accessing the gateway normal",
        "wangGPZSPZQ": "Is the gateway configuration correct",
        "jinYZTQDZLXYB": "Disabled state will cause connection failure",
        "keTCZYC": "Possible abnormalities",
        "qing": "please",
        "peiZ": "allocation",
        "renGJZ": "Manual inspection",
        "wangGPZSPSCXZQ": "Has the gateway configuration been filled in correctly",
        "ruoNQDGXMRZDK": "If you are certain that no diagnosis is required for this item",
        "queRHL": "Confirm ignore",
        "huL": "ignore",
        "sheBXRWGSJY": "Device access gateway disabled",
        "wangGFSB": "Gateway parent device",
        "zhenDWGFSBZTSPZC": "Diagnose whether the parent device status of the gateway is normal",
        "jinYYLXQDZLXYB": "Disabled or offline will cause connection failure",
        "weiBDFSB": "Unbound parent device",
        "fuSBHTS": "Try again after parent device",
        "wangGFSBSJY": "Gateway parent device disabled",
        "wangGFSBSLX": "Gateway parent device is offline",
        "qingXBZWGSBGZ": "Please troubleshoot the gateway device first",
        "chanPZT": "Product status",
        "zhenDCPZTSPZC": "Diagnose whether the product status is normal",
        "jinYZTQDZSBLXYB": "Disabled state will cause device connection failure",
        "chanPSJY": "The product has been disabled",
        "sheBZT": "Device status",
        "zhenDSBZTSPZC": "Diagnose whether the device status is normal",
        "sheBSJY": "Device disabled",
        "chanPRZPZ": "Product certification configuration",
        "zhenDCP": "Diagnostic products",
        "renZPZSPZQ": "Is the authentication configuration correct",
        "cuoWDPZQDZLXYB": "Incorrect configuration will result in connection failure",
        "zhengZZDZ": "Diagnosing in progress",
        "qingGJSBXRPZRY": "Please configure according to device access requirements",
        "chenX": "fill in",
        "peiZSPSCXZQ": "Has the configuration been filled in correctly",
        "sheBRZPZ": "Device authentication configuration",
        "zhenDSB": "Diagnostic equipment",
        "shiPSPZ": "Has it been configured",
        "weiPZQDZLXYB": "Not configured will result in connection failure",
        "qingJZSBYXZTSPZC": "Please check if the device is running properly",
        "qingJZSBWLSPCT": "Please check if the device network is unobstructed",
        "bingQBSBSLXDSXDZZY": "And ensure that the device is connected to one of the following addresses",
        "bingQBSBSLXD": "And ensure that the device is connected to",
        "qingGJ": "Please refer to",
        "sheBXRPZ": "Device access configuration",
        "zhong": "in",
        "xinX": "information",
        "linYSBYTSJ": "Report any data",
        "qingLJGLYSG": "Please contact the administrator to provide",
        "bingGJ": "And according to",
        "xinXLYSBYTSJ": "Report any data as needed",
        "ruYSFYPSZDFSYTXXTZDBPS": "Require third-party cloud platforms to proactively send a message notification to this platform",
        "chuFSBZTWZX": "Trigger device status to online",
        "qingJZSFPSPZXSPCXZQ": "Please check if the configuration items for the third-party platform are filled in correctly",
        "weiKF": "uncultivated",
        "lianXXQ": "Connection Details",
        "yiJXF": "One click repair",
        "tongXZD": "Re diagnosis",
        "qingJZPZXSPCXZQ": "Please check if the configuration items are filled in correctly",
        "zhenDXSM": "Diagnostic Item Description",
        "xieKDZ": "Interface address",
        "tongZ": "Reset",
        "yu": "field",
        "jiQ": "colony",
        "hongXPZ": "Shared Configuration",
        "duWPZ": "Independent configuration",
        "deZ": "address",
        "gongW": "Public network",
        "jieD": "node",
        "jieDMC": "Node Name",
        "quXG": "Go and modify it",
        "queRMW": "Confirmed to be correct",
        "zhenDGSBSZCPSPSPZ": "Diagnose whether the product to which the device belongs has been configured",
        "sheBXR": "Device access",
        "fangS": "mode",
        "weiPZQDZSBLXYB": "Not configured will result in device connection failure",
        "xiuG": "modify",
        "faXLXWT": "Discovered connection issues",
        "lianXZTZC": "Connection status is normal",
        "qingCLLXYC": "Please handle connection exceptions",
        "xianZKDSXXTX": "Now message communication can be debugged",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "queRJY": "Confirm disabled",
        "zhiX": "implement",
        "qingK": "empty",
        "zhiXJL": "results of enforcement",
        "sheBGTSJCL": "Equipment function data processing",
        "genJSJLX": "According to data type",
        "fuCSZ": "Assign initial value",
        "jingJMSXCSZZCSRKDFSLR": "In simplified mode, parameters can only be entered through input boxes",
        "qingAZ": "Please follow",
        "luoSSR": "Format input",
        "gaiZDWBCZD": "This field is mandatory",
        "shuRLX": "Input type",
        "qingPZDYCPD": "Please configure the corresponding product's",
        "wuMXZXGT": "Object Model Attribute Function",
        "jingJMS": "Streamlining mode",
        "gaoJMS": "Advanced Mode",
        "queRQYSB": "Confirm device activation",
        "qiYSB": "Enable device",
        "queRDKLX": "Confirm disconnection",
        "duanKLX": "Disconnect",
        "gaiSBDZXZTYFSB": "The online status of this device is different from that of the parent device",
        "baoCYZ": "Maintain consistency",
        "gaiSBZXZTYSBZSYXZTJD": "The online status of the device is determined by its own operating status",
        "buJCFSB": "Do not inherit parent device",
        "diZXZT": "Online status of",
        "shiLXX": "Instance information",
        "yunXZT": "running state",
        "sheBGT": "Equipment functions",
        "riZGL": "Log management",
        "shuJJS": "Data parsing",
        "chanPLXWWGDQKXCXZCMK": "This module is only displayed when the product type is gateway",
        "ziSB": "Sub devices",
        "bianYDYS": "Edge mapping",
        "queRTXYYGPZ": "Confirm reapplying the configuration",
        "yingYPZ": "Application configuration",
        "xiuGPZHRTXYYHCTSX": "After modifying the configuration, it needs to be reapplied before it can take effect",
        "queRHFMRPZ": "Confirm restoring default configuration",
        "huiFMR": "Restore default",
        "gaiSBSDBJGPZXX": "This device has individually edited configuration information",
        "dianJCQHFCMRDPZXX": "Clicking this will restore the default configuration information",
        "youXZ": "Effective value",
        "huiFMRPZCG": "Successfully restored default configuration",
        "bianJPZ": "Edit Configuration",
        "guanJXX": "Relationship information",
        "guanLSBYQTYWDGLGJ": "Manage the correlation between devices and other businesses",
        "guanJLYXGJPZ": "Relationships originate from relationship configuration",
        "bianJBQ": "Edit tags",
        "chenZ": "Fill in values",
        "shanSZ": "Delete value",
        "sheBLX": "Equipment type",
        "lianXXY": "Connection Protocol",
        "xiaoXXY": "Message Protocol",
        "cuoHSXSJ": "Last online time",
        "shiJ": "time",
        "neiY": "content",
        "xiangXXX": "detailed information",
        "dangQSJJSNYSTLCPYX": "The current data parsing content has detached from the impact of the product",
        "houQJCCPSJJSNY": "Afterwards, the product data parsing content will be inherited",
        "dangQSJJSNYJCZCP": "The current data parsing content is inherited from the product",
        "houQTLCPYX": "Afterwards, it will be detached from the impact of the product",
        "jueBYY": "scripting language",
        "qingDJSFXGZY": "Please click on the above to modify the text",
        "yongSBJJB": "Used to edit scripts",
        "muNSR": "Analog input",
        "erJZSJS": "Binary data with",
        "kaiTDSLJZSR": "Hexadecimal input at the beginning",
        "ziFCSJSRYSZFC": "Input string data into the original string",
        "yunXJL": "Running results",
        "ruSRJBHMNSJHZDJ": "Script and simulation data need to be entered before clicking",
        "qingXDS": "Please debug first",
        "jieMHS": "Decoding function",
        "yuanSBW": "Original message",
        "zhuanW": "Convert to",
        "shiTGCFFHQ": "Obtain through this method",
        "shiQBL": "Extract variables",
        "wenZZX": "Temperature attribute",
        "shiZZX": "Humidity attribute",
        "huoQ": "obtain",
        "huoQSBJSGZ": "Get device parsing rules",
        "baoCSBJSGZ": "Save device parsing rules",
        "guanB": "close",
        "qingSRZJMC": "Please enter the event name",
        "tongJZJ": "Statistical cycle",
        "shiJZ": "actual value",
        "anFZTJ": "Count by minute",
        "anXSTJ": "Hourly statistics",
        "anTTJ": "Statistics by Day",
        "anZTJ": "Weekly statistics",
        "anRTJ": "Monthly statistics",
        "tongJGZ": "Statistical rules",
        "pingYZ": "average value",
        "cuoTZ": "Maximum value",
        "cuoXZ": "minimum value",
        "zongS": "Total number",
        "liB": "list",
        "tuB": "chart",
        "guiJ": "trajectory",
        "kaiSDH": "Start animation",
        "tingZDH": "Stop animation",
        "ziDYZX": "Custom Properties",
        "ziFCNYZC": "Convert string content to",
        "chuFDJ": "Trigger Click",
        "yiS": "remove",
        "jieSSJ": "End time",
        "gengXSJ": "Update time",
        "zhiB": "index",
        "sheZZXZSB": "Set properties to device",
        "huoQCXZXZ": "Get the latest attribute values",
        "bianJZB": "Edit metrics",
        "changJLDYMKYYZBPZCFTJ": "Scenario linkage page can reference indicator configuration trigger conditions",
        "xuanZ": "Select",
        "shuR": "input",
        "zhiBZ": "Indicator value",
        "dangSJLYWSBS": "When the data source is a device",
        "chenXDZQXFDSB": "",
        "gaiZDSBCZD": "This field is mandatory",
        "yuMW": "The domain name is",
        "buZCFW": "Access not supported",
        "gaiTPMFFW": "This image cannot be accessed",
        "dangQFZCBF": "Currently, only playback is supported",
        "luoSDSP": "Format video",
        "xuanZSGCPSKDCQXZSBDXXSJ": "When selecting a single product, detailed data of its subordinate devices can be exported",
        "buXZCPSDCSYSBDJCSJ": "Export basic data of all devices without selecting products",
        "wenJLS": "file format",
        "qingXZWJLS": "Please select a file format",
        "daoR": "Import",
        "shangYB": "Previous step",
        "xiaYB": "Next step",
        "buZ": "step",
        "zhiLSB": "Direct connected devices",
        "wangGLX": "Gateway Type",
        "xieRFS": "Access method",
        "wangGZSB": "Gateway sub equipment",
        "fenL": "classification",
        "suoZZZ": "Affiliated organization",
        "quanB": "whole",
        "weiJH": "not active",
        "chanPJL": "Product series",
        "kaiQDY": "Enable subscription",
        "siQYDSBBTSS": "Devices that have been enabled cannot be deleted",
        "piLDCSB": "Batch export devices",
        "piLDRSB": "Batch import of devices",
        "qiYQBSB": "Enable all devices",
        "queRQYQBSB": "Confirm enabling all devices",
        "tongBSBZT": "Synchronize device status",
        "piLSSSB": "Batch deletion of devices",
        "siQYDSBMFSS": "Devices that have been enabled cannot be deleted",
        "queRSSXZDJYZTSB": "Confirm deletion of selected disabled devices",
        "jiHXZSB": "Activate selected device",
        "queRJHXZSB": "Confirm activation of selected devices",
        "piLJYSB": "Batch disable devices",
        "queRJYXZSB": "Confirm disabled the selected device",
        "zhaZXD": "Check online",
        "zhaQB": "Check All",
        "tongB": "synchronization",
        "zhengZQYQBSB": "Enabling all devices",
        "zhengZTBSBZT": "Synchronizing device status",
        "chengG": "success",
        "qiYYB": "Enabling failed",
        "shiLXXYMZDPZXWWS": "The configuration items in the instance information page are not complete",
        "wanC": "complete",
        "qingQTLTJCS": "Request to add parameters to the header",
        "weiSJS": "Bit random number",
        "taiXXZWSZ": "Uppercase and lowercase letters and numbers",
        "qiaHDSQ": "Client Time Zone",
        "qingSRYWYZSZYZ": "Please enter English or numbers or",
        "yuZ": "perhaps",
        "cuoDSR": "Maximum Input",
        "ruoBCX": "If not filled in",
        "jiTQZDSCWY": "The system will automatically generate a unique",
        "zhiTXZ": "Can only choose",
        "zhuangTDCP": "Products with status",
        "qingXZZTW": "Please select a status of",
        "diCP": "Product",
        "tongF": "repeat",
        "huo": "and",
        "chuL": "handle",
        "chuLMWXH": "Handling infinite loops",
        "dangQBJD": "Currently edited",
        "xiaoXZY": "Message guidance",
        "qingSRXXZY": "Please enter message guidance",
        "xiaoXSZ": "Message prompt",
        "tuP": "Picture",
        "qingSZTP": "Please upload pictures",
        "ziJLX": "Event type",
        "qingXZZJLX": "Please select the event type",
        "kaiSZJ": "Start Event",
        "jinXZZJ": "Ongoing events",
        "jieSZJ": "end event",
        "caoZJL": "Operation results",
        "yiB": "fail",
        "qingXZCZJL": "Please select the operation result",
        "tanCXG": "Pop up related",
        "xinCCZZY": "New Operation Guidelines",
        "bianJCZZY": "Editing Operation Guidelines",
        "xuX": "Index",
        "gouX": "Check the box",
        "peiZXX": "Configuration information",
        "peiZXRFS": "Configure access method",
        "bianJPZXX": "Edit configuration information",
        "qiH": "switch",
        "xiuGCGSX": "Modified successfully and refreshed",
        "huoQCPJSGZ": "Obtain product analysis rules",
        "baoCCPJSGZ": "Save product parsing rules",
        "xieY": "protocol",
        "zhaXTJ": "query criteria",
        "shiJSBSJ": "Submit device data",
        "fanHWBZJRYDSJ": "Return the data required by external components",
        "qingXZXRFS": "Please select the access method",
        "daKBQXC": "Open tag to add",
        "yongSSGSBXRTL": "To provide device access capability",
        "qingLJGLYPZCPXRFS": "Please contact the administrator to configure the product access method",
        "gengH": "replace",
        "xianZ": "display",
        "wenJNY": "File content",
        "zanMLXXX": "There is currently no connection information available",
        "ciPZLZXCPXRFSSXZDXY": "This configuration comes from the protocol selected for the product's access method",
        "cunCCL": "Storage strategy",
        "ruoXGCCCL": "If the storage policy is modified",
        "ruYSDZSJQY": "Manual data migration is required",
        "pingSZTXSCXCCCLZDSJ": "The platform can only search for data in the latest storage strategy",
        "wuMXCLFS": "Model processing method",
        "cunCSJ": "Storing data",
        "daKSBTC": "Open device pop-up window",
        "baoCYDYSJ": "Save guide page data",
        "xianZYDY": "Display guide page",
        "woZDL": "I got it",
        "buZSZ": "Don't prompt again",
        "shangXX": "Up and Down",
        "zhiL": "Example",
        "huoQSXXSJ": "Obtain upstream and downstream data",
        "shangX": "upstream",
        "xiaX": "down",
        "zhaXXRFS": "Query access method",
        "fenZ": "grouping",
        "zhaXXYXX": "Query protocol information",
        "huoQXYLXMC": "Get Protocol Type Name",
        "zhaXBCSJXX": "Query and save data information",
        "liuZSMS": "Streaming mode",
        "chuSW": "Initially",
        "muS": "mode",
        "qiHXRFSZHHQSPXZYD": "Obtain whether to display guidance after switching access methods",
        "youSBXR": "There is device access",
        "chanPYWMX": "Product physical model",
        "sheBXRMY": "Device access not available",
        "quCPWMX": "Take product model",
        "sheBXRYWMX": "Device access with physical model",
        "chanPMY": "The product does not have any",
        "quSBXRDWMX": "Take the physical model of device access",
        "piZQKZFC": "Otherwise, take an empty string",
        "buTW": "Cannot be for",
        "gengXXZSB": "Update selected devices",
        "gengXCPPZXX": "Update product configuration information",
        "baoCSBXR": "Save device access",
        "gaiCPSPYWMX": "Does the product have a physical model",
        "youZTCJXCL": "If there is a pop-up window for processing",
        "erCQRSPFGWMX": "Secondary confirmation of whether to cover the object model",
        "tiaoZWMXBQ": "Jump object model label",
        "chanPXYSBSLSBTGHXRFS": "When there are device instances under the product, the access method cannot be changed",
        "tingYCPHCKGHXRFS": "The access method can only be changed after disabled the product",
        "xuanZCLFS": "Choose processing method",
        "pingS": "platform",
        "zhongSYSJ": "Existing data in",
        "qingXZCLFS": "Please choose the processing method",
        "moRCYFGDFSCLGT": "By default, the feature is processed using an overlay method",
        "biaoQXDSJ": "Data under tags",
        "chuLFS": "Processing method",
        "quJJ": "intersect",
        "fuBLBSYZDZX": "Only retain attributes with consistent identification",
        "quBJ": "Union set",
        "baoLPS": "Reserve Platform",
        "zhaJZDSYZX": "All attributes in the plugin",
        "fuBLPSZDZX": "Only retain attributes in the platform",
        "fuG": "cover",
        "fuBLZJZDZX": "Keep only the properties in the plugin",
        "chanPWMX": "Product model",
        "jiaoJ": "intersection",
        "bingJ": "also",
        "jiaoJCLHS": "Intersection processing function",
        "zhiBLLZZJZDZX": "Only retain properties from plugins",
        "zhaJWMX": "Plugin object model",
        "bingJHSCL": "Union function processing",
        "tongFS": "When repeating",
        "zhiBLLZZJZD": "Keep only those from the plugin",
        "tiaoZX": "Attribute",
        "yinDYSJ": "Guide page data",
        "peiZWMX": "Configuration object model",
        "peiZCPWMX": "Configure product model",
        "shiXSBZYDDGTMS": "Describe the functionality of the device in the cloud",
        "qiYCP": "Enable product",
        "qiYCPH": "After enabling the product",
        "keZCPXXCSB": "New devices can be added under the product",
        "tianJSB": "Add device",
        "bingLXDPS": "And connect to the platform",
        "chenXPZ": "Fill in configuration",
        "chenXSBXRSRDPZCS": "Fill in the configuration parameters required for device access",
        "queDYYPZ": "Determine application configuration",
        "qingXQYCP": "Please enable the product first",
        "jinYCP": "Prohibit products",
        "zhaXSBSL": "Query the number of devices",
        "shiPXZSJJSMK": "Is the data parsing module displayed",
        "xiangQYTZDSBY": "Details page redirects to device page",
        "chanPBCCGHDSZK": "Prompt box after successfully saving the product",
        "chanPCJCG": "Product creation successful",
        "zhaKXQ": "View details",
        "xieXLTJCZ": "Next, recommended actions",
        "peiZCPXRFS": "Configure product access methods",
        "dianJJBCPDZKAN": "Click on the specific product view button",
        "ye": "page",
        "bingCZSBMPSMXZPPDXRFS": "And refer to the instructions on the equipment nameplate to choose the matching access method",
        "tianJCSSB": "Add testing equipment",
        "jinRSBLB": "Enter device list",
        "tianJSGSB": "Add a single device",
        "yongXYZCPMXSPPZZQ": "Used to verify if the product model is configured correctly",
        "gongTDS": "Functional debugging",
        "dianJZKJBSB": "Click to view specific devices",
        "duiTJDCSSBJXGTDS": "Perform functional debugging on the added testing equipment",
        "yanZTPLXDPS": "Verify if it can connect to the platform",
        "sheBGTSPPZZQ": "Is the device function configured correctly",
        "piLTJSB": "Batch Add Devices",
        "jinRSBLBYM": "Enter the device list page",
        "dianJPLDRSB": "Click to batch import devices",
        "piLTJTYCPXDSB": "Batch adding devices under the same product",
        "tanCGB": "Pop up close",
        "weiXR": "Not connected",
        "biaoLSJ": "Table data",
        "siQYDCPBTSS": "Products that have been enabled cannot be deleted",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "sheZDRDCPZTWWFB": "Set the imported product status to Unpublished",
        "shuaXSJ": "refresh data",
        "jiangZZTYXNXZSYWB": "Force all text to be displayed within the same line",
        "zhiDWBJSYZZY": "Until the end of the text or encountering",
        "biaoQDXCHX": "Label objects only wrap",
        "chaoCBFYZ": "Exceeding parts hidden",
        "yinZBFSXLXDT": "Replace hidden parts with ellipses",
        "bianJCP": "Edit product",
        "qingXZCPFL": "Please select a product category",
        "sheBBDCZZY": "Safe Binding Operation Guidelines",
        "sheBBDZYTP": "Safe Open Image",
        "miMYSSL": "Number of password keys",
        "qingSRMMYSSL": "Please enter the number of password keys",
        "zhiWYSSL": "Number of fingerprint keys",
        "qingSRZWYSSLSL": "Please enter the number and quantity of fingerprint keys",
        "yueSSL": "Number of keys",
        "jianWSL": "Number of key positions",
        "qingSRJWSLSL": "Please enter the number and quantity of key positions",
        "zhiLWLWPSDSB": "Devices directly connected to IoT platforms",
        "taiGZZSBYPSJXTXDSB": "Devices that can mount sub devices for communication with the platform",
        "zuoWWGDZSB": "As a sub device of the gateway",
        "youWGDLLXDWLWPS": "Gateway proxy connection to IoT platform",
        "jiaoY": "check",
        "jiaoYSPXZSBLX": "Verify if device type is selected",
        "qingXZSBLX": "Please select the device type",
        "weiZF": "Bit character",
        "zhaXCPFL": "Query product classification",
        "chuLCPFL": "Process product classification",
        "shiJBSSJ": "Submit form data",
        "miMYSSLBTWK": "The number of password keys cannot be empty",
        "zhiWYSSLBTWK": "The number of fingerprint keys cannot be empty",
        "yueSSLBTWK": "The number of keys cannot be empty",
        "jianWSLBTWK": "The number of key positions cannot be empty",
        "zhiYXX": "Guidance information",
        "chanPFLBXZKZFC": "Do not select product classification and pass an empty string",
        "yunXZTCRYSJ": "Data is required at the operational status",
        "buM": "department",
        "sheBXQ": "device details",
        "ceS": "test",
        "dianL": "Electricity level",
        "buZX": "Not online",
        "menZT": "Door status",
        "kaiQ": "Open",
        "wenZ": "temperature",
        "shiZ": "humidity",
        "liangZ": "brightness",
        "yinL": "volume",
        "guan": "Close",
        "gao": "high",
        "kaiS": "Unlocking",
        "kai": "Open",
        "sheBGL": "device management",
        "yueSGL": "Key management",
        "fenXSB": "Sharing devices",
        "shengJ": "upgrade",
        "fenXGL": "Sharing Management",
        "sheBSZ": "devices setting up",
        "sheBSJ": "Equipment time",
        "jieSPZ": "Unlock configuration",
        "baoJSZ": "Alarm settings",
        "fangJCSZ": "Anti hijacking settings",
        "dingYCS": "Subscription timeout",
        "huoQYTLSJL": "Get a history record",
        "xieZXDSB": "Write attributes to device",
        "zhuXZ": "Attribute value",
        "juBCSCZWMX": "Specific parameter reference model",
        "xuanZFS": "Selection method",
        "qingXZFS": "Please choose a method",
        "guanJ": "relationship",
        "qingXZGJ": "Please select a relationship",
        "qingXZBQ": "Please select a label",
        "bianL": "variable",
        "shouCCZBQSJ": "Initial operation of label data",
        "huiXWT": "Echo problem",
        "ziDY": "custom",
        "ziDYXZDQCPXDLYSB": "Customize the selection of any device under the current product",
        "anGJ": "By relationship",
        "xuanZYCFSBJYXTGJDSB": "Select devices with the same relationship as the triggering device",
        "anBQ": "By label",
        "anBQXZCPXJYTDBQDSB": "Select devices with specific labels under the product by label",
        "anBL": "By variable",
        "weiSLBLZDSB": "Equipment for upstream variable values",
        "panDXTCPCYABL": "Only by variable can the same product be judged",
        "qiHSB": "Switching devices",
        "bianLXZ": "variable selection",
        "wei": "by",
        "biaoQXZ": "Label selection",
        "ziCFP": "Asset allocation",
        "piLCZ": "Batch operations",
        "shiPPLJSBD": "Whether to unbind in bulk",
        "piLJB": "Batch unbinding",
        "piLBJ": "Batch editing",
        "ziCQW": "Asset permissions",
        "shiPJSBD": "Unbind or not",
        "jieSBD": "Unbind",
        "huoQQWSJZD": "Obtain permission data dictionary",
        "huoQQWMC": "Obtain permission name",
        "quXQX": "Deselect All",
        "quNSJ": "Remove data",
        "huoQBZLSJ": "Obtain and organize data",
        "zhengLCSBHQSJ": "Organize parameters and obtain data",
        "sheBZCFPTCCZLX": "Equipment asset allocation pop-up operation type",
        "shouDDJZCFPAN": "Manually click on the asset allocation button",
        "chanPZCFPH": "After product asset allocation",
        "ziDTCSBZCFP": "Automatically pop up device asset allocation",
        "qingGXRYBJDSJ": "Please check the data that needs to be edited",
        "tag": "Tag",
        "batchEnable": "Batch Enable",
        "state": "State",
        "qingXZZT": "Please select state"
    },
    "Log": {
        "yhdl": "User login",
        "htdl": "Background login",
        "lianX": "connect",
        "quanWYZ": "Permission verification",
        "jieM": "decoding",
        "bianM": "coding",
        "qingQ": "request",
        "xiangY": "response",
        "xiaXXX": "Downward message",
        "shangXXX": "Uplink message",
        "leiX": "Type",
        "shiJ": "time",
        "neiY": "content",
        "caoZ": "Operation",
        "zhaK": "check",
        "xiangXXX": "detailed information",
        "xiangQ": "Details",
        "qingQFF": "Request Method",
        "dongZ": "action",
        "leiM": "Class name",
        "fangFM": "Method name",
        "qingQSJ": "Request time",
        "qingQHS": "Request time consumption",
        "qingQT": "Request header",
        "qingQCS": "Request parameters",
        "yiCXX": "Abnormal information",
        "zanMSJ": "There is currently no data available",
        "guanB": "close",
        "qingQLJ": "Request Path",
        "shuiM": "Explain",
        "qingQYH": "Requesting Users",
        "xiaoS": "search",
        "fangWRZ": "Access logs",
        "jiTRZ": "system log",
        "mingC": "Name",
        "riZJB": "log level",
        "riZNY": "Log content",
        "fuWM": "service name",
        "chuangJSJ": "Creation time",
        "sheBRZ": "Device logs",
        "weiSBRZ": "For device logs",
        "weiGJRZ": "For alarm logs",
        "zhaXCS": "Query parameters",
        "xuX": "Index",
        "sheBMC": "Device Name",
        "caoZLX": "Operation type",
        "caoZSJ": "Operation time",
        "caoZR": "Operator",
        "gaoJNY": "Alarm content",
        "gaoJSJ": "Alarm time",
        "tongZJL": "Notification Record",
        "faSSJ": "Sending time",
        "zhuangT": "State",
        "chengG": "success",
        "yiB": "fail",
        "zhaKCWXX": "View error messages",
        "cuoWXX": "error message",
        "zhaKXQ": "View details",
        "xiangQXX": "Details",
        "gaoJMC": "Alarm name",
        "dangQGJJLXX": "Current alarm record information",
        "gaoJJLDXQ": "Details of alarm records",
        "huoQXQLB": "Get a detailed list",
        "genJ": "according to",
        "chuSHSJ": "Initialize data",
        "gaoJSB": "Alarm equipment",
        "guanBMTTC": "Close modal pop ups",
        "queD": "Confirm",
        "quX": "Cancel",
        "sheB": "Equipment",
        "gaoJJB": "Alarm level",
        "gaoJSM": "Alarm Description",
        "gaoJLS": "Alarm flow",
        "quanB": "whole",
        "chanP": "Product",
        "zuZ": "organization",
        "qiT": "other",
        "jiLLB": "Record List",
        "chuLSJ": "processing time",
        "chuLLX": "Processing type",
        "jiT": "system",
        "renG": "artificial",
        "gaoJCL": "alarm handling",
        "guanBTC": "Close pop-up window",
        "chuLJL": "Processing Records",
        "qingSRCLJL": "Please enter the processing result",
        "caoZCG": "Operation successful",
        "caoZYB": "operation failed",
        "cuoJGJSJ": "Recent alarm time",
        "gaoJZ": "In alarm",
        "moGJ": "No alarm",
        "chanPMC": "Product Name",
        "zuZMC": "Organization Name",
        "chuLZHD": "After processing",
        "mo": "nothing",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "gaoJRZ": "Alarm Log",
        "guanBGJRZ": "Turn off alarm logs",
        "guanBCLJL": "Close processing records",
        "beiA": "Filing",
        "yu": "Chongqing",
        "bei": "prepare",
        "xiao": "Number",
        "yuY": "language",
        "zhongW": "Chinese",
        "yingW": "English",
        "zhangX": "Account",
        "qingSRZX": "Please enter your account number",
        "miM": "password",
        "qingSRMM": "Please enter password",
        "yanZM": "Verification code",
        "qingSRYZM": "Please enter the verification code",
        "jiZW": "Remember me",
        "deL": "Login",
        "meiYZX": "No account",
        "qingN": "Please",
        "xiaZ": "download",
        "zhuC": "register",
        "qiuDQXLZKSHTPJT": "The team's new masterpiece is a visual large screen system",
        "benY": "experience",
        "xianZ": "display",
        "diXZEWM": "Download QR code for",
        "huanYSY": "Welcome to use",
        "erWM": "QR code",
        "quDLLQD": "Get the browser's",
        "ziFC": "character string",
        "panDSP": "Determine if",
        "liuLQ": "browser",
        "chuangJYG": "Create a",
        "shiL": "example",
        "jiaMFF": "Encryption method",
        "sheZGY": "Set public key",
        "jiaM": "encryption",
        "jiaMHDSJ": "Encrypted data"
    },
    "edge": {
        "xinC": "Create",
        "daoR": "Import",
        "sheBLX": "Equipment type",
        "chanPMC": "Product Name",
        "sheBMC": "Device Name",
        "zhuCSJ": "Registration time",
        "zhuangT": "State",
        "jinY": "Disabled",
        "liX": "Offline",
        "zaiX": "Online",
        "chanPFL": "Product classification",
        "xieRFS": "Access method",
        "zhiLSB": "Direct connected devices",
        "wangGZSB": "Gateway sub equipment",
        "wangGSB": "Gateway device",
        "shuiM": "Explain",
        "caoZ": "Operation",
        "zhaK": "check",
        "bianJ": "Edit",
        "qiY": "Enable",
        "queR": "Confirm",
        "caoZCG": "Operation successful",
        "caoZYB": "operation failed",
        "yuanCQZ": "Remote control",
        "tongZMM": "reset password",
        "queRTZMMW": "Confirm resetting password to",
        "shanS": "Delete",
        "siQYDSBBTSS": "Devices that have been enabled cannot be deleted",
        "queRSS": "confirm deletion",
        "qiT": "other",
        "qingSRYWYZSZYZ": "Please enter English or numbers or",
        "yuZ": "perhaps",
        "cuoDSR": "Maximum Input",
        "geZF": "Characters",
        "ruoBCX": "If not filled in",
        "jiTQZDSCWY": "The system will automatically generate a unique",
        "qingSR": "Please enter",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "qingXZSZCP": "Please select the product you belong to",
        "suoZCP": "Belonging products",
        "zhiTXZ": "Can only choose",
        "zhengC": "Normal",
        "zhuangTDCP": "Products with status",
        "qingSRSM": "Please enter a description",
        "tongF": "repeat",
        "tongXXY": "communication protocol",
        "suoZBYWG": "Belonging to Edge Gateway",
        "xieR": "Access",
        "chuangJSJ": "Creation time",
        "xiaF": "Distribute",
        "qingXQY": "Please enable it first",
        "zaiXF": "Further distribution",
        "qingXJY": "Please disable first",
        "zaiSS": "Delete again",
        "xiaFSB": "Distribute equipment",
        "liXSBMFJXSBMBXF": "Offline devices cannot issue device templates",
        "qingXZSB": "Please select a device",
        "xiaFJL": "Issuing results",
        "chengG": "success",
        "yiB": "fail",
        "xiaFYBYY": "Reason for failed distribution",
        "xiaZ": "download",
        "xiaFSBSL": "Number of issued devices",
        "siXFSL": "Issued quantity",
        "chuangJYZDKXZLX": "Create hidden downloadable links",
        "ziFCNYZC": "Convert string content to",
        "deZ": "address",
        "chuFDJ": "Trigger Click",
        "yiS": "remove"
    },
    "home": {
        "jiCTJ": "Basic statistics",
        "xiangQ": "Details",
        "shiYL": "Usage rate",
        "neiC": "Memory",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "wuLWYD": "IoT guidance",
        "yunWYD": "Operations guidance",
        "sheBXRTJBZ": "Recommended steps for device access",
        "buTDSBYWTXXYDBT": "Different devices due to differences in communication protocols",
        "cunZXRBZDCY": "There are differences in the access steps",
        "yunWGLTJBZ": "Recommended steps for operation and maintenance management",
        "qingGJYWRYDXSBZJXXZXCZ": "Please perform selective operations on the following steps according to business needs",
        "anNQWQZ": "Button permission control",
        "shuJ": "data",
        "chuangJCP": "Create product",
        "chuangJSB": "Create device",
        "guiZYQ": "Rule engine",
        "chanPSSBDJH": "A product is a collection of devices",
        "tongCZYZJYXTGTDSB": "Usually refers to a group of devices with the same functionality",
        "wuLSBBXTGCPJXXRFSPZ": "IoT devices must be configured through product access methods",
        "peiZCPXRFS": "Configure product access methods",
        "tongGCPDTYLXDSBJXTYDXRFSPZ": "Configure unified access methods for devices of the same type through products",
        "qingCZSBMPSMXZPPDXRFS": "Please refer to the instructions on the device nameplate to select the matching access method",
        "tianJCSSB": "Add testing equipment",
        "tianJSGSB": "Add a single device",
        "yongXYZCPMXSPPZZQ": "Used to verify if the product model is configured correctly",
        "gongTDS": "Functional debugging",
        "duiTJDCSSBJXGTDS": "Perform functional debugging on the added testing equipment",
        "yanZTPLXDPS": "Verify if it can connect to the platform",
        "sheBGTSPPZZQ": "Is the device function configured correctly",
        "piLTJSB": "Batch Add Devices",
        "piLTJTYCPXDSB": "Batch adding devices under the same product",
        "yunWGLYD": "Operation and maintenance management guidance",
        "sheBXRPZ": "Device access configuration",
        "riZBZ": "Log troubleshooting",
        "shiSJQ": "Real time monitoring",
        "xieYGL": "Protocol management",
        "genJYWRQZDYKFDYDCP": "Customize and develop corresponding products according to business needs",
        "sheBMX": "Equipment model",
        "xieRXY": "Access Protocol",
        "bingSZDPS": "And upload it to the platform",
        "zhengSGL": "Certificate Management",
        "tongYWHPSNDZS": "Unified maintenance of certificates within the platform",
        "yongXSJTXJM": "Used for data communication encryption",
        "wangLZJ": "Network components",
        "genJBTDZSLXPZPSDCWLZJXGCS": "Configure platform underlying network component related parameters based on different transmission types",
        "sheBXRWG": "Device access gateway",
        "genJBTDZSLX": "According to different transmission types",
        "guanLXXXY": "Association Message Protocol",
        "peiZSBXRWGXGCS": "Configure device access gateway related parameters",
        "riZGL": "Log management",
        "jianQJTRZ": "Monitoring system logs",
        "jiSCLJTYC": "Timely handling of system exceptions",
        "sheBTJ": "Equipment statistics",
        "chanPSL": "Product quantity",
        "sheBSL": "Number of devices",
        "youCPCSQWZHQSJ": "Obtain data with product menu permission",
        "youSBCSQWZHQSJ": "Obtain data with device menu permission",
        "xuanZSB": "Select device",
        "tanCQZ": "Pop up control",
        "qingXZSB": "Please select a device",
        "sheB": "Equipment",
        "sheBMC": "Device Name",
        "chanPMC": "Product Name",
        "zhuCSJ": "Registration time",
        "zhuangT": "State",
        "zaiX": "Online",
        "liX": "Offline",
        "xuanZCP": "Select product",
        "chanP": "Product",
        "gaiZDSBCZD": "This field is mandatory",
        "qingXZSYST": "Please select the homepage view",
        "queD": "Confirm",
        "chaoGMRZHGLST": "Super tube default comprehensive management view",
        "pingSJGT": "Platform architecture diagram",
        "tiaoZYM": "Jump to page",
        "jiBXX": "Basic information",
        "huoQXZDST": "Get selected views",
        "panDSPS": "Determine if it is",
        "yongH": "User",
        "buSZHQXZDST": "If not, obtain the selected view",
        "tuiJBZMX": "Recommended steps for each item",
        "chanPLBLDMX": "Each item in the product list",
        "jiCNY": "Basic content",
        "jiTMC": "System Name",
        "qingSRJTMC": "Please enter the system name",
        "zhuTS": "Theme color",
        "boS": "white",
        "heiS": "black",
        "gaoD": "Gaode",
        "peiZHPSKDYGDDT": "After configuration, the platform can call Gaode Map",
        "fuW": "service",
        "qingSRGD": "Please enter Gaode",
        "jiTHSFWD": "System backend access",
        "qingSR": "Please enter",
        "jiT": "system",
        "dianJXG": "Click to modify",
        "tuiJCC": "Recommended size",
        "zhiC": "support",
        "liuLQYQ": "Browser tab",
        "liuLQ": "browser",
        "yeZXZDTPYS": "Image elements displayed on the page",
        "luoS": "format",
        "deLBJT": "Login background image",
        "siNDTP": "Images within",
        "jianYCC": "Suggested size",
        "biaoSSJ": "Form Data",
        "cuoDKSR": "Up to input",
        "wei": "position",
        "qingXZZTS": "Please select a theme color",
        "shiJSJ": "Submit data",
        "luoSJY": "format checks",
        "qingSZ": "Please upload",
        "luoSDTP": "Format image",
        "tuPTXBXXX": "The image size must be smaller than",
        "shangZGBZJ": "Upload change event",
        "liuLQYQSZZQ": "Before uploading browser tabs",
        "liuLQYQFSGB": "Browser tab has changed",
        "beiJTPSZZQ": "Before uploading the background image",
        "beiJTPFSGB": "The background image has changed",
        "gunDZ": "Rolling groove",
        "gunDTHK": "Scroll bar slider",
        "buZC": "Not supported",
        "geRZX": "Personal Center",
        "xiuGMM": "Change password",
        "tongZDY": "Notification Subscription",
        "zhaK": "check",
        "wuLW": "Internet of Things",
        "shouY": "home page",
        "tongZGL": "Notification Management",
        "tongZPZ": "Notification Configuration",
        "tongBYH": "Synchronize Users",
        "tongZJL": "Notification Record",
        "diaoS": "debugging",
        "daoC": "Export",
        "daoR": "Import",
        "shanS": "Delete",
        "bianJ": "Edit",
        "xinC": "Create",
        "tongZMB": "Notification template",
        "sheBGL": "device management",
        "yiBP": "Instrument panel",
        "qi": "Start",
        "jinY": "Disabled",
        "chanPFL": "Product classification",
        "yunWGL": "Mocha ITOM",
        "liuMBFW": "Streaming Services",
        "yuanCSJ": "Remote upgrade",
        "jianXZC": "Indirect support",
        "shuJCJ": "data acquisition",
        "tongDGL": "Channel management",
        "qiY": "Enable",
        "caiJQ": "collector",
        "gaoJZX": "Alarm Center",
        "jiCPZ": "Basic configuration",
        "baoC": "Save",
        "gaoJPZ": "Alarm configuration",
        "shouDCF": "Manual triggering",
        "gaoJJL": "Alarm Log",
        "gaoJCL": "alarm handling",
        "beiXSC": "Northbound output",
        "aLY": "Alibaba Cloud",
        "guiZBB": "Rule arrangement",
        "changJLD": "Scene linkage",
        "bianYWG": "Edge gateway",
        "wangGSB": "Gateway device",
        "yuanCQZ": "Remote control",
        "tongZMM": "reset password",
        "ziYK": "Resource Library",
        "xiaF": "Distribute",
        "shiPZX": "Video Center",
        "shiPSB": "Video equipment",
        "fenPZZ": "Split screen display",
        "guoBJL": "National standard cascading",
        "tuiS": "Push",
        "xuanZTD": "Select channel",
        "jiTGL": "system management",
        "yongHGL": "User management",
        "zuZGL": "Organizational management",
        "ziCJB": "Asset unbinding",
        "bangDYH": "Bind Users",
        "ziCFP": "Asset allocation",
        "jiaoSGL": "Role management",
        "caiSGL": "Menu management",
        "quanWGL": "Permission management",
        "guanJPZ": "Relationship Configuration",
        "shuJYGL": "Data source management",
        "guanL": "Administration",
        "peiZ": "allocation",
        "yingYGL": "Application Management",
        "wuLQ": "IoT card",
        "wuLQGL": "IoT card management",
        "tongB": "synchronization",
        "jiH": "activation",
        "bangD": "binding",
        "chongZGL": "Recharge management",
        "chongZ": "Recharge",
        "pingSXR": "Platform access",
        "caoZJL": "Operation records",
        "chuSHSJSJBS": "Initialize data submission form",
        "benDDZ": "Local address",
        "benDDK": "Local Port",
        "gongWDZ": "Public network address",
        "gongWDK": "Public network port",
        "jiBXXBS": "Basic Information Form",
        "deLBJ": "Login background",
        "tuPSZBS": "Image upload form",
        "neiZJSSJ": "Built in character data",
        "sheBXRG": "Equipment access post",
        "gaiJSFZSBXRMKDWHGL": "This role is responsible for the maintenance and management of the device access module",
        "zhengC": "Normal",
        "yunWGLG": "Operation and Maintenance Management Post",
        "gaiJSFZJTYWMKDWHGL": "This role is responsible for the maintenance and management of the system operation and maintenance module",
        "zongHGLG": "Comprehensive Management Post",
        "gaiJSFZJTYWHSBXRMKDWHGL": "This role is responsible for system operation and maintenance management of device access modules",
        "quanBSJ": "All data",
        "ziQCJD": "Created by oneself",
        "suoZZZ": "Organization",
        "suoZZZJXJZZ": "Organization and subordinate organizations",
        "ciCSZCSJQWQZ": "This menu supports data permission control",
        "ciCSBZCSJQWQZ": "This menu does not support data permission control",
        "jiTCSH": "System initialization",
        "peiZPSMC": "Configure platform name",
        "zhuTSDJBXX": "Basic information such as theme colors",
        "caiSCSH": "Menu initialization",
        "chuSHCSSJ": "Initialize menu data",
        "jiaoSCSH": "Character initialization",
        "chuSHNZJSYQWSJ": "Initialize built-in role and permission data",
        "chuSHSJ": "Initialize data",
        "chuSHSBXRZLSJ": "Sample data for initializing device access",
        "moRDKDYGCSCS": "Open the first initial menu by default",
        "shiJJCBS": "Submit Basic Form",
        "tiaoZSY": "Jump to homepage",
        "shiJSYSJ": "Submit all data",
        "dangQSJSPCGSJ": "Is the current data successfully submitted",
        "baoCCG": "Save successful",
        "jiLCSHSJ": "Record initialization data",
        "panDSPSYPZ": "Determine if there is already a configuration",
        "chuSSJSJBS": "Initial Data Submission Form",
        "chuSSJ": "Initial data",
        "quX": "Cancel",
        "chuSHSJPK": "The initialization data includes",
        "leiXSBXRWG": "Type of device access gateway",
        "guanFXY": "Official agreement",
        "bangDDFWQSDWQDZ": "Bind the network card address to the server",
        "bangDDSYWQ": "Bind to all network cards",
        "duiWSGFWDDZNWHJSCXFWQDNW": "Fill in the server's intranet when providing access to external addresses and intranet environments",
        "deZ": "address",
        "qingSRGWDZ": "Please enter the public network address",
        "jianYZDDKDQQ": "Listen for requests on specified ports",
        "qingXZBDDK": "Please select a local port",
        "duiWSGFWDDK": "Ports provided for external access",
        "qingSRGWDK": "Please enter the public network port",
        "chuSHSJZT": "Initialize data status",
        "chuSHTCBSSJ": "Initialize pop-up form data",
        "jiaoYGWDZ": "Verify official website address",
        "qingSRZQDGWDZ": "Please enter the correct public network address",
        "jiaoYSZ": "Check digit",
        "diZZS": "A positive integer for",
        "qingXZBDDZ": "Please select a local address",
        "chuSSJTCDJZJ": "Initial data pop-up click event",
        "biaoSQXZJ": "Form cancellation event",
        "shiJCSHSJ": "Submit initialization data",
        "zhaXDKSJ": "Query port data",
        "shiJCSSJBS": "Submit initial data form",
        "xinCWLZJ": "Add network components",
        "baoCXY": "Save Protocol",
        "xinCSBXRWG": "Add device access gateway",
        "xinCCP": "New products added",
        "xinCSB": "New equipment added",
        "chuSH": "initialization",
        "geCS": "Menus",
        "chuSHHDCSKZ": "The initialized menu can be found in the",
        "yeMJXWHGL": "Maintaining and managing pages",
        "huoQCSSJ": "Get menu data",
        "huoQDQJTQWXX": "Obtain current system permission information",
        "guoLCS": "Filter menu",
        "jiSCSSL": "Calculate the number of menus",
        "chuSHCS": "Initialize menu",
        "yongHZX": "User Center",
        "jiaoSGXSJ": "Role selection data",
        "huoQJSXZSJ": "Get character selection data",
        "genJCSZJS": "Find a role based on the menu",
        "tianJGJS": "Add this role",
        "gengXQW": "Update permissions",
        "baoCJS": "Save Role"
    },
    "iframe": {},
    "init-home": {
        "jiCNY": "Basic content",
        "jiTMC": "System Name",
        "qingSRJTMC": "Please enter the system name",
        "zhuTS": "Theme color",
        "boS": "white",
        "heiS": "black",
        "gaoD": "Gaode",
        "peiZHPSKDYGDDT": "After configuration, the platform can call Gaode Map",
        "fuW": "service",
        "qingSRGD": "Please enter Gaode",
        "jiTHSFWD": "System backend access",
        "qingSR": "Please enter",
        "jiT": "system",
        "dianJXG": "Click to modify",
        "tuiJCC": "Recommended size",
        "zhiC": "support",
        "liuLQYQ": "Browser tab",
        "liuLQ": "browser",
        "yeZXZDTPYS": "Image elements displayed on the page",
        "luoS": "format",
        "deLBJT": "Login background image",
        "siNDTP": "Images within",
        "jianYCC": "Suggested size",
        "biaoSSJ": "Form Data",
        "cuoDKSR": "Up to input",
        "wei": "position",
        "qingXZZTS": "Please select a theme color",
        "shiJSJ": "Submit data",
        "luoSJY": "format checks",
        "qingSZ": "Please upload",
        "luoSDTP": "Format image",
        "tuPTXBXXX": "The image size must be smaller than",
        "shangZGBZJ": "Upload change event",
        "liuLQYQSZZQ": "Before uploading browser tabs",
        "liuLQYQFSGB": "Browser tab has changed",
        "beiJTPSZZQ": "Before uploading the background image",
        "beiJTPFSGB": "The background image has changed",
        "gunDZ": "Rolling groove",
        "gunDTHK": "Scroll bar slider",
        "buZC": "Not supported",
        "geRZX": "Personal Center",
        "xiuGMM": "Change password",
        "tongZDY": "Notification Subscription",
        "zhaK": "check",
        "wuLW": "Internet of Things",
        "shouY": "home page",
        "tongZGL": "Notification Management",
        "tongZPZ": "Notification Configuration",
        "tongBYH": "Synchronize Users",
        "tongZJL": "Notification Record",
        "diaoS": "debugging",
        "daoC": "Export",
        "daoR": "Import",
        "shanS": "Delete",
        "bianJ": "Edit",
        "xinC": "Create",
        "tongZMB": "Notification template",
        "sheBGL": "device management",
        "yiBP": "Instrument panel",
        "chanP": "Product",
        "qi": "Start",
        "jinY": "Disabled",
        "sheB": "Equipment",
        "chanPFL": "Product classification",
        "yunWGL": "Mocha ITOM",
        "sheBXRWG": "Device access gateway",
        "xieYGL": "Protocol management",
        "riZGL": "Log management",
        "wangLZJ": "Network components",
        "zhengSGL": "Certificate Management",
        "liuMBFW": "Streaming Services",
        "yuanCSJ": "Remote upgrade",
        "jianXZC": "Indirect support",
        "shuJCJ": "data acquisition",
        "tongDGL": "Channel management",
        "qiY": "Enable",
        "caiJQ": "collector",
        "gaoJZX": "Alarm Center",
        "jiCPZ": "Basic configuration",
        "baoC": "Save",
        "gaoJPZ": "Alarm configuration",
        "shouDCF": "Manual triggering",
        "gaoJJL": "Alarm Log",
        "gaoJCL": "alarm handling",
        "beiXSC": "Northbound output",
        "aLY": "Alibaba Cloud",
        "guiZYQ": "Rule engine",
        "guiZBB": "Rule arrangement",
        "changJLD": "Scene linkage",
        "bianYWG": "Edge gateway",
        "wangGSB": "Gateway device",
        "yuanCQZ": "Remote control",
        "tongZMM": "reset password",
        "ziYK": "Resource Library",
        "xiaF": "Distribute",
        "shiPZX": "Video Center",
        "shiPSB": "Video equipment",
        "fenPZZ": "Split screen display",
        "guoBJL": "National standard cascading",
        "tuiS": "Push",
        "xuanZTD": "Select channel",
        "jiTGL": "system management",
        "yongHGL": "User management",
        "zuZGL": "Organizational management",
        "ziCJB": "Asset unbinding",
        "bangDYH": "Bind Users",
        "ziCFP": "Asset allocation",
        "jiaoSGL": "Role management",
        "caiSGL": "Menu management",
        "quanWGL": "Permission management",
        "guanJPZ": "Relationship Configuration",
        "shuJYGL": "Data source management",
        "guanL": "Administration",
        "peiZ": "allocation",
        "yingYGL": "Application Management",
        "wuLQ": "IoT card",
        "wuLQGL": "IoT card management",
        "tongB": "synchronization",
        "jiH": "activation",
        "bangD": "binding",
        "chongZGL": "Recharge management",
        "chongZ": "Recharge",
        "pingSXR": "Platform access",
        "caoZJL": "Operation records",
        "chuSHSJSJBS": "Initialize data submission form",
        "benDDZ": "Local address",
        "benDDK": "Local Port",
        "gongWDZ": "Public network address",
        "gongWDK": "Public network port",
        "jiBXXBS": "Basic Information Form",
        "deLBJ": "Login background",
        "tuPSZBS": "Image upload form",
        "neiZJSSJ": "Built in character data",
        "sheBXRG": "Equipment access post",
        "gaiJSFZSBXRMKDWHGL": "This role is responsible for the maintenance and management of the device access module",
        "zhengC": "Normal",
        "yunWGLG": "Operation and Maintenance Management Post",
        "gaiJSFZJTYWMKDWHGL": "This role is responsible for the maintenance and management of the system operation and maintenance module",
        "zongHGLG": "Comprehensive Management Post",
        "gaiJSFZJTYWHSBXRMKDWHGL": "This role is responsible for system operation and maintenance management of device access modules",
        "quanBSJ": "All data",
        "ziQCJD": "Created by oneself",
        "suoZZZ": "Organization",
        "suoZZZJXJZZ": "Organization and subordinate organizations",
        "ciCSZCSJQWQZ": "This menu supports data permission control",
        "ciCSBZCSJQWQZ": "This menu does not support data permission control",
        "jiTCSH": "System initialization",
        "jiBXX": "Basic information",
        "peiZPSMC": "Configure platform name",
        "zhuTSDJBXX": "Basic information such as theme colors",
        "caiSCSH": "Menu initialization",
        "chuSHCSSJ": "Initialize menu data",
        "jiaoSCSH": "Character initialization",
        "chuSHNZJSYQWSJ": "Initialize built-in role and permission data",
        "chuSHSJ": "Initialize data",
        "chuSHSBXRZLSJ": "Sample data for initializing device access",
        "queD": "Confirm",
        "moRDKDYGCSCS": "Open the first initial menu by default",
        "shiJJCBS": "Submit Basic Form",
        "tiaoZSY": "Jump to homepage",
        "shiJSYSJ": "Submit all data",
        "dangQSJSPCGSJ": "Is the current data successfully submitted",
        "baoCCG": "Save successful",
        "jiLCSHSJ": "Record initialization data",
        "panDSPSYPZ": "Determine if there is already a configuration",
        "chuSSJSJBS": "Initial Data Submission Form",
        "chuSSJ": "Initial data",
        "quX": "Cancel",
        "chuSHSJPK": "The initialization data includes",
        "leiXSBXRWG": "Type of device access gateway",
        "guanFXY": "Official agreement",
        "bangDDFWQSDWQDZ": "Bind the network card address to the server",
        "bangDDSYWQ": "Bind to all network cards",
        "duiWSGFWDDZNWHJSCXFWQDNW": "Fill in the server's intranet when providing access to external addresses and intranet environments",
        "deZ": "address",
        "qingSRGWDZ": "Please enter the public network address",
        "jianYZDDKDQQ": "Listen for requests on specified ports",
        "qingXZBDDK": "Please select a local port",
        "duiWSGFWDDK": "Ports provided for external access",
        "qingSRGWDK": "Please enter the public network port",
        "chuSHSJZT": "Initialize data status",
        "chuSHTCBSSJ": "Initialize pop-up form data",
        "jiaoYGWDZ": "Verify official website address",
        "qingSRZQDGWDZ": "Please enter the correct public network address",
        "jiaoYSZ": "Check digit",
        "diZZS": "A positive integer for",
        "qingXZBDDZ": "Please select a local address",
        "chuSSJTCDJZJ": "Initial data pop-up click event",
        "biaoSQXZJ": "Form cancellation event",
        "shiJCSHSJ": "Submit initialization data",
        "zhaXDKSJ": "Query port data",
        "shiJCSSJBS": "Submit initial data form",
        "xinCWLZJ": "Add network components",
        "baoCXY": "Save Protocol",
        "xinCSBXRWG": "Add device access gateway",
        "xinCCP": "New products added",
        "xinCSB": "New equipment added",
        "chuSH": "initialization",
        "geCS": "Menus",
        "chuSHHDCSKZ": "The initialized menu can be found in the",
        "yeMJXWHGL": "Maintaining and managing pages",
        "huoQCSSJ": "Get menu data",
        "huoQDQJTQWXX": "Obtain current system permission information",
        "guoLCS": "Filter menu",
        "jiSCSSL": "Calculate the number of menus",
        "chuSHCS": "Initialize menu",
        "yongHZX": "User Center",
        "jiaoSGXSJ": "Role selection data",
        "huoQJSXZSJ": "Get character selection data",
        "genJCSZJS": "Find a role based on the menu",
        "tianJGJS": "Add this role",
        "gengXQW": "Update permissions",
        "baoCJS": "Save Role"
    },
    "iot-card": {
        "bangDSB": "Binding devices",
        "xuanZSB": "Select device",
        "queD": "Confirm",
        "quX": "Cancel",
        "sheBMC": "Device Name",
        "zhuCSJ": "Registration time",
        "zhuangT": "State",
        "jinY": "Disabled",
        "liX": "Offline",
        "zaiX": "Online",
        "caoZCG": "Operation successful",
        "wuLQZK": "IoT card viewing",
        "xinC": "Create",
        "bianJ": "Edit",
        "jiBXX": "Basic information",
        "qiaX": "Card number",
        "pingSLX": "Platform type",
        "pingSMC": "Platform Name",
        "yunYS": "Operators",
        "leiX": "Type",
        "jiHRJ": "Activation date",
        "gengXSJ": "Update time",
        "zongLL": "Total flow",
        "shiYLL": "Using traffic",
        "shengTLL": "Remaining traffic",
        "shuiM": "Explain",
        "liuLTJ": "Traffic statistics",
        "shuJTJ": "data statistics",
        "zuoRLLXH": "Yesterday's traffic consumption",
        "dangRLLXH": "Monthly traffic consumption",
        "benNLLXH": "Current year's traffic consumption",
        "zuoR": "yesterday",
        "jinYZ": "Last week",
        "jinYR": "In the past month",
        "jinYN": "In the past year",
        "bianJGBTC": "Edit Close Pop Up",
        "jiaZBL": "Load Table",
        "zhaXJR": "Query Today",
        "dangR": "Current month",
        "benNSJ": "Data for this year",
        "daoR": "Import",
        "daoC": "Export",
        "wenJLS": "file format",
        "qingXZWJLS": "Please select a file format",
        "wuLQGL": "IoT card management",
        "pingSDX": "Platform docking",
        "qingXZPSDX": "Please select platform docking",
        "wenJSZ": "File upload",
        "xiaZMB": "Download template",
        "siWC": "Completed",
        "zongSL": "Total quantity",
        "yiB": "fail",
        "guanB": "close",
        "daoRCG": "Import successful",
        "daoRYB": "Import failed",
        "wuLQDRMB": "IoT Card Import Template",
        "piLCZ": "Batch operations",
        "piLDC": "Batch export",
        "piLDR": "Batch import",
        "queRJHM": "Are you sure to activate it",
        "piLJH": "Batch activation",
        "queRTYM": "Are you sure to deactivate it",
        "piLTY": "Batch deactivation",
        "queRFJM": "Are you sure to resume the machine",
        "piLFJ": "Batch replication",
        "queRTBZTM": "Are you sure about the synchronization status",
        "tongBZT": "Synchronization status",
        "queRSSM": "Are you sure to delete it",
        "piLSS": "Batch deletion",
        "zongH": "altogether",
        "ningQ": "Annual Card",
        "jiQ": "Seasonal card",
        "ruQ": "Monthly Card",
        "qiT": "other",
        "jiH": "activation",
        "weiJH": "not active",
        "tingJ": "Shutdown",
        "qiY": "Enable",
        "caoZ": "Operation",
        "jieBSB": "Unbind device",
        "queRJBSB": "Confirm unbound device",
        "fuJ": "Repeated machine",
        "tingY": "Deactivate",
        "queRJH": "Confirm activation",
        "queRFJ": "Confirm resumption of operation",
        "queRTY": "Confirm deactivation",
        "shanS": "Delete",
        "queRSS": "confirm deletion",
        "caoZYB": "operation failed",
        "zhaK": "check",
        "chuLQYZT": "Handling other states",
        "bangDSBGBCK": "Bind device to close window",
        "qingXZSJ": "Please select data",
        "fuZCTYGYYSXJCS": "Only supports the same operator and at a minimum",
        "tiaoSJ": "Piece of data",
        "cuoD": "most",
        "tongBZTCG": "Sync status successful",
        "qingSRQX": "Please enter the card number",
        "qiaDWYSBXM": "The unique identification number of the card",
        "qingSR": "Please enter",
        "qingXZYYS": "Please select an operator",
        "qingXZLX": "Please select the type",
        "qingSRSM": "Please enter a description",
        "qingSRSRZQD": "Please enter the correct input",
        "cuoDSR": "Maximum Input",
        "geZF": "Characters",
        "jinXZ": "In progress",
        "zuJSXBDDZ": "The value of bidirectional binding of components",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "tuBYS": "Chart Colors",
        "shiPZZ": "Whether to display",
        "zhou": "axis",
        "tuBSJ": "Chart data",
        "huiZTB": "Draw a chart",
        "liuLXH": "Traffic consumption",
        "xianZKQAN": "Show shortcut buttons",
        "kuaiQANLB": "List of shortcut buttons",
        "jinR": "today",
        "wuLQ": "IoT card",
        "yiBP": "Instrument panel",
        "liuLSY": "Traffic usage",
        "kaiSSJ": "start time",
        "jieSSJ": "End time",
        "yiD": "move",
        "dianX": "telecom",
        "lianT": "Unicom",
        "zhiFFS": "Payment method",
        "zhiFBSJWZZF": "Alipay mobile website payment",
        "zhiFBWYJSDZZF": "Alipay web page paid in time",
        "weiXGZXZF": "WeChat official account payment",
        "weiXSMZF": "WeChat QR code payment",
        "shouY": "home page",
        "wuLQYD": "IoT card guidance",
        "jiCTJ": "Basic statistics",
        "xiangQ": "Details",
        "zuoRLLTJ": "Yesterday's traffic statistics",
        "pingSJGT": "Platform architecture diagram",
        "caiSQW": "Menu permissions",
        "anNQW": "Button permissions",
        "caoZJL": "Operation records",
        "zhengC": "Normal",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "huoQZRLLXH": "Obtain yesterday's traffic consumption",
        "huoQCJ": "Get Recent",
        "tianLLXHTJTSJ": "Daily traffic consumption statistics chart data",
        "huoQWLQZTSJ": "Obtaining IoT card status data",
        "cuoJ": "recently",
        "tianLLXHTJT": "Daily traffic consumption statistics chart",
        "sheSZZZKZ": "Set the width of a single column",
        "sheZZZDBJXN": "Set a columnar background virtual",
        "wuLQBT": "IoT Card Pie Chart",
        "shiPQYFZBQTDCL": "Is the anti label overlap policy enabled",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "qingSRMM": "Please enter password",
        "xieKDZ": "Interface address",
        "qingSRXKDZ": "Please enter the interface address",
        "yongH": "User",
        "qingSRYH": "Please enter the User",
        "miM": "password",
        "chuangJZ": "creator",
        "qingSRCJZ": "Please enter the creator",
        "baoC": "Save",
        "cuoDKSR": "Up to input",
        "baoCCG": "Save successful",
        "zhongGYDWLQTLKFPS": "China Mobile IoT Card Capability Open Platform",
        "gaiS": "summary",
        "pingSDXTG": "Platform docking passed",
        "diFSYSFJTJXSJDX": "The method of data integration with third-party systems",
        "weiWLQDGLSGSJJHZC": "Provide data exchange support for the management of IoT cards",
        "peiZSM": "Configuration Description",
        "diSFYYWYBS": "Unique identifier for third-party applications",
        "zhongGYDWLWQWGLYZ": "China Mobile IoT Network Administrators",
        "taiLKFPSSFPBZZGJQQH": "Allocate and display to group customers on the capability open platform",
        "huoQLJ": "Get Path",
        "zhongYWLQTLKFPS": "China Mobile IoT Card Capability Open Platform",
        "geRZX": "Personal Center",
        "qiaHXX": "Customer Information",
        "xieRXX": "Access information",
        "xieRBY": "Access key",
        "yaoZGYDWLWSG": "Provided by China Mobile's Internet of Things",
        "jiQQHC": "Group clients from",
        "taiLKFPS": "Capability Open Platform",
        "huoQ": "obtain",
        "houZQGJSJQKCX": "Please fill in the suffix according to the actual situation",
        "zhiL": "Example",
        "lianXGLPS": "Connection management platform",
        "lianXGLPSYHDWYBS": "Unique identifier for connecting to the management platform User",
        "yongXSFSB": "Used for identity recognition",
        "taiLKF": "Ability openness",
        "wangGZXGL": "Gateway account management",
        "jingJMZHDMM": "Encrypted password",
        "weiYBY": "Unique key",
        "yanFZL": "Yanfei Zhilian",
        "pingS": "platform",
        "woDYY": "My app",
        "yingYLB": "Application List",
        "xieKCSZD": "In the interface parameters",
        "queR": "Confirm",
        "qingXJYZSS": "Please disable before deleting",
        "chongZJE": "Recharge amount",
        "zhangH": "account",
        "dingSX": "Order number",
        "zhiF": "payment",
        "dingSSJ": "Order time",
        "chongZGL": "Recharge management",
        "chongZ": "Recharge",
        "benPSFSGCZRK": "This platform only provides a recharge entrance",
        "juBCZJLRSYYSDCZJLWZ": "The specific recharge results shall be subject to the operator's recharge results",
        "chongZGBTC": "Recharge Close Pop Up",
        "zhaKXQTCGB": "View details pop-up closes",
        "zanZZCYD": "Only mobile is currently supported",
        "qingSRZH": "Please enter your account",
        "zhuJDJE": "Amount between",
        "qingXZZFFS": "Please select payment method",
        "qingSRCZJE": "Please enter the recharge amount",
        "zhuJDSZ": "Numbers between",
        "jiaoFYB": "Payment failed",
        "caoZGXPP": "Frequent operation",
        "qingSHZS": "Please try again later",
        "caoZLX": "Operation type",
        "caoZSJ": "Operation time",
        "caoZR": "Operator"
    },
    "link": {
        "jiBXX": "Basic information",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "shuiM": "Explain",
        "qingSRSM": "Please enter a description",
        "baoC": "Save",
        "peiZGL": "Configuration Overview",
        "xieRFS": "Access method",
        "xiaoXXY": "Message Protocol",
        "sheBXRZY": "Device Access Guidelines",
        "peiZ": "allocation",
        "tongD": "passageway",
        "chuangJ": "establish",
        "sheBXRWG": "Device access gateway",
        "chuangJCP": "Create product",
        "bingXZXRFSW": "And select the access method as",
        "tianJSB": "Add device",
        "shanDWMYGSBJXSJDBD": "Bind data points separately for each device",
        "caoZCG": "Operation successful",
        "tongG": "adopt",
        "pingSD": "Platform's",
        "tuiSFWJXSJXR": "Push service for data access",
        "xieKDZ": "Interface address",
        "qingSR": "Please enter",
        "caoZZY": "Operating instructions",
        "duanCJCP": "Creating products on the end",
        "sheB": "Equipment",
        "siJYGDSFYY": "And a third-party application",
        "duanPZCP": "End configuration products",
        "fenZJDY": "Group level subscription",
        "dingYF": "Subscribers",
        "deZQCX": "Please fill in the address",
        "duanCJLXW": "The end creation type is",
        "diSBXRWG": "Device access gateway",
        "xuanZXRFSW": "Select access method as",
        "chenX": "fill in",
        "pingSZDCP": "Products in the platform",
        "duanTJSB": "Add devices on the end",
        "weiMYSSBSZWYD": "Set unique settings for each device",
        "ruY": "Need to communicate with",
        "pingSZCXDZYZ": "The values filled in the platform are consistent",
        "sheBXRWGPZSM": "Device access gateway configuration instructions",
        "qingQ": "Please add",
        "di": "of",
        "pingS": "platform",
        "yingYGLZD": "In application management",
        "huo": "and",
        "fuZDDQYM": "Copy to current page",
        "qiTSM": "Other instructions",
        "zai": "stay",
        "duanQYSBS": "When the device is enabled on the end",
        "ruo": "if",
        "pingSMYYZDYDSB": "The platform does not have a corresponding device",
        "zeQZ": "Then it will be",
        "duanZDCJXSB": "Automatically create new devices on the end",
        "zhiTXZ": "Can only choose",
        "tongXFSDXY": "Protocol for communication methods",
        "xinC": "Create",
        "zanMSJ": "There is currently no data available",
        "chuangJLXW": "Create type as",
        "xuanZHRCX": "After selection, it needs to be filled in",
        "ma": "code",
        "pingSMYDYDSB": "The platform does not have a corresponding device",
        "qiangHTG": "Will be passed through",
        "pingSSGD": "Platform provided",
        "xieYZDCJ": "Protocol automatic creation",
        "shangYB": "Previous step",
        "xiaYB": "Next step",
        "xieRPZ": "Access configuration",
        "wanC": "complete",
        "qingXZXXXY": "Please select message protocol",
        "tongBWLWPSSBSJD": "Synchronize IoT platform device data to",
        "qingSRTZ": "Please enter notification",
        "tongZ": "notice",
        "ziDY": "custom",
        "keYXYZQQSPLZ": "Can be used to verify if the request is from",
        "duanSCDXXJM": "End generated message encryption",
        "bingPZ": "And configure it",
        "tuiS": "Push",
        "leiXDSBXRWG": "Type of device access gateway",
        "duanDCP": "End products",
        "zaiSBSLYMWMYSSBSZWYD": "Set unique settings for each device on the device instance page",
        "pingSZDZYZ": "The values in the platform are consistent",
        "tuiSPZSM": "Push configuration instructions",
        "tuiSPZLJ": "Push configuration path",
        "yingYKF": "application development",
        "shuJTS": "Data push",
        "cenS": "parameter",
        "shiLMC": "Instance Name",
        "tuiSSLDMC": "The name of the push instance",
        "tuiSDZ": "Push address",
        "yongXXS": "Used for receiving",
        "tuiSSBSJDDZWLWPSDZ": "The address of the IoT platform for pushing device data",
        "xiaoXJM": "Message encryption",
        "caiY": "adopt",
        "jiaMSFDTSDSJJXSJJM": "Encryption algorithm encrypts the pushed data",
        "weiJMBY": "For encryption keys",
        "pingSZJBCPD": "Specific products on the platform",
        "shuJTSPZZSZD": "Set in data push configuration",
        "shuJTSPZLXXJM": "The data push is configured with message encryption",
        "ciCCX": "Fill in here",
        "duanSJTSPZZSZD": "Set in the configuration of end data push",
        "xuanZYSBTXDWLZJ": "Select network components to communicate with devices",
        "wangLZJ": "Network components",
        "qingXZWLZJ": "Please select network components",
        "peiZSBXLCS": "Configure device signaling parameters",
        "yu": "or",
        "qingXZJQ": "Please select a cluster",
        "jiQ": "colony",
        "hongXPZ": "Shared Configuration",
        "jiQXSYJDHYTYPZ": "All nodes in the cluster share the same configuration",
        "duWPZ": "Independent configuration",
        "jiQXBTJDSYBTPZ": "Different nodes in the cluster use different configurations",
        "deZ": "address",
        "qingXZ": "Please select",
        "qingXZDK": "Please select a port",
        "gongW": "Public network",
        "qingSRZQD": "Please enter the correct",
        "shuRDK": "Input Port",
        "qingSRDK": "Please enter the port",
        "peiZXX": "Configuration information",
        "shanS": "Delete",
        "jieDMC": "Node Name",
        "qingXZJDMC": "Please select a node name",
        "daoFWQSDWQDZ": "To the network card address on the server",
        "bangDDSYWQ": "Bind to all network cards",
        "qingSRGW": "Please enter the public network",
        "jianYZDDKDQQ": "Listen for requests on specified ports",
        "xinLPZ": "Signal configuration",
        "qingXCYWSPZ": "Please add or improve configurations",
        "jiaoYWTGDZK": "Unfolded verification failed",
        "shiYXZDXXXY": "Use the selected message protocol",
        "duiWLZJTXSJJXBJM": "Encode and decode communication data of network components",
        "renZDCZ": "Authentication and other operations",
        "xinX": "information",
        "shangX": "upstream",
        "xiaX": "down",
        "fenZ": "grouping",
        "xuanZZJ": "Select plugins",
        "zhaJ": "Plugins",
        "banBX": "Version number",
        "tongYPZ": "Universal configuration",
        "shuR": "input",
        "xuanZ": "choice",
        "qing": "please",
        "qingXZZJ": "Please select a plugin",
        "bianJ": "Edit",
        "huiXBSZ": "Echo form values",
        "xieR": "Access",
        "keSRSBMRJWLXJKSYPSZSSJ": "Can allow devices to transfer data to the platform without establishing a connection",
        "zaiYHYDCZDPDQKX": "When allowing for a certain degree of packet loss",
        "shiGQLHJJSDLX": "Provide lightweight and simple connectivity",
        "fuWSYCMXLXD": "Service is a connection oriented approach",
        "keKD": "Reliable",
        "jiXZJLDZSCTXXY": "Transport layer communication protocol based on byte stream",
        "sheBKTG": "The device can be accessed through",
        "fuWYPSJXZLX": "Long link between service and platform",
        "shiSGXZTBFSXX": "Real time update status and send messages",
        "keZDYDCNCPGZ": "Customizable multiple rules for sticking and unpacking packages",
        "chuLZSGCZKTFSDNCPWT": "Handling possible sticking and unpacking issues during transmission",
        "shiYCZSG": "It is a type of",
        "lianXSJXQSGTXDXY": "Protocol for Full Duplex Communication on Connection",
        "yuanHFWDZDXQHDTSSJ": "Allow the server to actively push data to the client",
        "sheBTG": "The device passes through",
        "juKSFBDYXX": "And can publish subscription messages",
        "shi": "Time",
        "biaoZXJXFB": "Based on publication under standard",
        "dingYFSDXXXY": "Message Protocol for Subscription Paradigm",
        "juYQL": "Lightweight",
        "jianS": "simple",
        "kaiFHYXSXDTD": "The characteristics of openness and ease of implementation",
        "pingSSYZDD": "The platform uses the specified",
        "xieRQTYCPS": "Access to other remote platforms",
        "dingYXX": "Subscription message",
        "yiKTJYHMHMMJY": "User name and password verification can also be added",
        "keSZCTXXZZ": "Maximum message length can be set",
        "keTYSZHXDDYQZ": "Unified setting of shared subscription prefixes",
        "fuWSYGJSDQQ": "Service is a simple request",
        "xiangYDJX": "Response based on",
        "diMZTXY": "Stateless protocol",
        "fuWYPSJXLHDDLXTX": "Flexible short link communication between services and platforms",
        "fuZCSBHPSZJSDSDQQ": "Only supports single to single requests between devices and platforms",
        "xiangYMS": "Response mode",
        "shiG": "provide",
        "diFWD": "The server of the",
        "siGSBSZLXDFSXRPS": "For devices to access the platform through long links",
        "sheBSYWYD": "The device uses unique",
        "shiZDZYSLDNCKJHYWDJSTLSGDYCJX": "It is based on a limited amount of memory space and limited computing power",
        "diXY": "Protocol",
        "bianXDGHYWLSWDSBYPSTX": "Facilitate communication between low-power or network limited devices and platforms",
        "shangXX": "Up and Down",
        "zhiL": "Example",
        "fanH": "return",
        "houJYH": "Post optimization",
        "shiPLSBXR": "Video device access",
        "yunPSXR": "Cloud platform access",
        "tongDLSBXR": "Channel type device access",
        "guanFXR": "Official access",
        "ziDYSBXR": "Custom device access",
        "zhaJSBXR": "Plug in device access",
        "kuaiSTJXRWG": "Quickly add access gateway",
        "jianZSPBCPSY": "Check if it is being used by the product",
        "xieY": "protocol",
        "wangGLX": "Gateway Type",
        "zhuangT": "State",
        "jinY": "Disabled",
        "zhengC": "Normal",
        "caoZ": "Operation",
        "qiY": "Enable",
        "queR": "Confirm",
        "qingXJY": "Please disable first",
        "zaiSS": "Delete again",
        "queRSS": "confirm deletion",
        "xiaoS": "hour",
        "chaoCDWBYZ": "Exceeding text hiding",
        "yiCYXLXXZ": "Overflow displayed with ellipses",
        "yiCBHX": "Overflow without line breaks",
        "shangZWJ": "Upload",
        "shangZCG": "Upload successful",
        "qingSZ": "Please upload",
        "luoSDWJ": "Format files",
        "zhengSBZ": "Certificate standards",
        "zhengSMC": "Certificate Name",
        "qingSRZSMC": "Please enter the certificate name",
        "zhengSWJ": "Certificate file",
        "qingSRZSWJ": "Please enter the certificate file",
        "zhengSSY": "Certificate private key",
        "qingSRZSSY": "Please enter the certificate private key",
        "gaiS": "summary",
        "zhengSYSXLDSZZSBFJG": "The certificate is issued by a trusted digital certificate authority",
        "zaiYZFWQSFHBF": "Issued after verifying server identity",
        "juYFWQSFYZHSJZSJMGT": "Capable of server authentication and data transmission encryption",
        "baoZSBYPSJDSJZSAQ": "Ensure data transmission security between devices and platforms",
        "peiZHKBWLZJYY": "Can be referenced by network components after configuration",
        "peiZSM": "Configuration Description",
        "ninKSSYWBBJGJDK": "You can use text editing tools to open",
        "luoSDZSWJ": "Certificate file in format",
        "fuZQZDNYBNTDGWBK": "Copy the content and paste it into the text box",
        "yuZSJGWBKXDSZ": "Alternatively, click on the upload button below the text box",
        "bingXZCCZBDJSJDZSWJ": "And select the certificate file stored on the local computer",
        "qiangWJNYSZDWBK": "Upload file content to a text box",
        "chenXZSSYNYD": "Fill in the private key content of the certificate",
        "bianM": "coding",
        "luoSDZSSYWJ": "Format certificate private key file",
        "yuZSJGWBKXDSZBXZCCZBDJSJDZSSYWJ": "Alternatively, click on Upload under the text box and select the certificate private key file stored on the local computer",
        "qingXZZSBZ": "Please select the certificate standard",
        "qingSRYSZWJ": "Please input or upload the file",
        "guoJBZ": "international standard",
        "zhaK": "check",
        "queD": "Confirm",
        "quX": "Cancel",
        "shiYLQS": "Usage trend",
        "cuoJ": "recently",
        "jinR": "today",
        "jinYZ": "Last week",
        "neiCSYLQS": "Memory usage trend",
        "wangLLL": "network flow",
        "ru": "as",
        "ri": "day",
        "ning": "year",
        "shiYL": "Usage rate",
        "neiC": "Memory",
        "zong": "total",
        "ciPTY": "Disk occupancy",
        "zongCPTX": "Total disk size",
        "jiTNC": "system memory",
        "zhaJLX": "Plugin type",
        "zhaJMC": "Plugin Name",
        "caoZYB": "operation failed",
        "ruoBCX": "If not filled in",
        "jiTQZDSCWY": "The system will automatically generate a unique",
        "wenJ": "file",
        "qingSZWJ": "Please upload the file",
        "banB": "Version",
        "xinCJY": "Add verification",
        "tongF": "repeat",
        "luoSYQ": "Format requirements",
        "wenJM": "file name",
        "jiTSZ": "System prompt",
        "jiTWZCW": "Unknown system error",
        "qingFKGGLY": "Please provide feedback to the administrator",
        "wuGL": "Material management",
        "leiX": "Type",
        "shangZ": "upload",
        "pao": "package",
        "qingXZLX": "Please select the type",
        "wenJDZ": "File address",
        "qingSRWJDZ": "Please enter the file address",
        "buCL": "Not processed",
        "fenJF": "Delimiter",
        "ziDYJB": "Custom Script",
        "guDZZ": "Fixed length",
        "zhangZZD": "Length field",
        "taiD": "Big end",
        "xiaoD": "Small end",
        "deZYZYM": "Address or domain name",
        "cuoTKSR": "Maximum Input",
        "qingXZBDDZ": "Please select a local address",
        "qingXZBDDK": "Please select a local port",
        "qingSRGWDZ": "Please enter the public network address",
        "qingSRGWDK": "Please enter the public network port",
        "zhuJDZZS": "Positive integers between",
        "qingSRYCDZ": "Please enter remote address",
        "qingSRZQLSDYMY": "Please enter the correct format of the domain name or",
        "shuRYCDK": "Enter remote port",
        "qingSRYHM": "enter one User name",
        "qingSRMM": "Please enter password",
        "qingSRCTXXZZ": "Please enter the maximum message length",
        "qingXZZS": "Please select a certificate",
        "qingSRSYBM": "Please enter the private key alias",
        "qingXZNCPGZ": "Please select the rules for sticking and unpacking packages",
        "qingSRFJF": "Please enter a separator character",
        "qingXZJBYY": "Please select a script language",
        "qingSRJB": "Please enter the script",
        "qingSRZZZ": "Please enter a length value",
        "qingXZZZ": "Please select the length",
        "benDDZ": "Local address",
        "bangDDFWQSDWQDZ": "Bind the network card address to the server",
        "benDDK": "Local Port",
        "gongWDZ": "Public network address",
        "duiWSGFWDDZ": "Provide external access addresses",
        "neiWHJSCXFWQDNW": "Fill in the server's intranet in the intranet environment",
        "gongWDK": "Public network port",
        "duiWSGFWDDK": "Ports provided for external access",
        "yuanCDZ": "Remote address",
        "yuanCDK": "teleport",
        "qingSRYCDK": "Please enter the remote port",
        "yongHM": "User name",
        "miM": "password",
        "dingYQZ": "Subscription prefix",
        "dangLXDFWW": "When the connected service is",
        "keTRYSYHXDDYQZ": "It may be necessary to use a shared subscription prefix",
        "qingSRDYQZ": "Please enter the subscription prefix",
        "cuoTXXZZ": "Maximum message length",
        "shanCSFXXDCTZZ": "The maximum length of a single sending and receiving message",
        "shanW": "unit",
        "ziJ": "byte",
        "sheZGTKTHYXXT": "Setting it too large may affect performance",
        "kaiQ": "Open",
        "pi": "no",
        "zhengS": "certificate",
        "siYBM": "Private key alias",
        "nianCPGZ": "Sticking and unpacking rules",
        "chuL": "handle",
        "nianCPDFS": "The method of sticking and unpacking packages",
        "jueBYY": "scripting language",
        "jieSJB": "Parsing scripts",
        "zhangZZ": "Length value",
        "zhangZ": "length",
        "pianYL": "Offset",
        "qingSRPYL": "Please enter the offset amount",
        "taiXD": "Large and small ends",
        "qingXZTXD": "Please select the size range",
        "zhiBL": "Only keep",
        "paoH": "contain",
        "diSJ": "Data for",
        "shuJ": "data",
        "fangZBJS": "Prevent editing",
        "biaoSZDBWS": "Incomplete form fields",
        "daoZSR": "Causing input",
        "xuanZKXCXSZBD": "Unable to find the new selection box when it appears",
        "gengXDK": "Update Port",
        "bianLSJGXDYDBDDK": "Traverse data to update the corresponding local port",
        "xiangQ": "Details",
        "qingXJYGZJ": "Please disable this component first",
        "yuanC": "remote",
        "queDYSSM": "Are you sure you want to delete it",
        "qingXZSB": "Please select a device",
        "piLDRSB": "Batch import of devices",
        "piLSSSB": "Batch deletion of devices",
        "siQYDSBMFSS": "Devices that have been enabled cannot be deleted",
        "queRSSXZDJYZTSB": "Confirm deletion of selected disabled devices",
        "xuX": "Index",
        "sheP": "Equipment configuration",
        "shePMC": "Configuration Name",
        "chanPMC": "Product Name",
        "bangDYH": "Bind Users",
        "siJH": "Activated",
        "weiJH": "not active",
        "chuangJSJ": "Creation time",
        "daoR": "Import",
        "luoSWJ": "Format file",
        "daoRSJ": "Import data",
        "daoRCG": "Import successful",
        "daoRYB": "Import failed",
        "qingTS": "Please try again",
        "luoS": "format"
    },
    "linkDeviceList": {
        "xinC": "Create",
        "shanS": "Delete",
        "queDYSSM": "Are you sure you want to delete it",
        "qingXZSB": "Please select a device",
        "caoZCG": "Operation successful",
        "piLDRSB": "Batch import of devices",
        "piLSSSB": "Batch deletion of devices",
        "siQYDSBMFSS": "Devices that have been enabled cannot be deleted",
        "queRSSXZDJYZTSB": "Confirm deletion of selected disabled devices",
        "xuX": "Index",
        "sheP": "Equipment configuration",
        "shePMC": "Configuration Name",
        "chanPMC": "Product Name",
        "bangDYH": "Bind Users",
        "zhuangT": "State",
        "siJH": "Activated",
        "weiJH": "not active",
        "deZ": "address",
        "chuangJSJ": "Creation time",
        "caoZ": "Operation",
        "caoZYB": "operation failed",
        "xiaoS": "search",
        "daoR": "Import",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "daoRSJ": "Import data",
        "daoRCG": "Import successful",
        "daoRYB": "Import failed",
        "qingTS": "Please try again",
        "luoS": "format"
    },
    "media": {
        "guoBJL": "National standard cascading",
        "bangDTD": "Binding channels",
        "quX": "Cancel",
        "queD": "Confirm",
        "tongDLB": "Channel List",
        "sheBMC": "Device Name",
        "tongDMC": "Channel name",
        "anZDZ": "Installation address",
        "changS": "manufacturer",
        "zhuangT": "State",
        "siLX": "Connected",
        "weiLX": "Not connected",
        "xiaoS": "search",
        "qingGXSJ": "Please check the data box",
        "caoZCG": "Operation successful",
        "caoZYB": "operation failed",
        "queRJB": "Confirm unbinding",
        "piLJB": "Batch unbinding",
        "guoBJLY": "National standard cascading includes",
        "wei": "by",
        "weiLCLS": "Two formats",
        "zaiDQYMXGBHXGSPSB": "Modifying on the current page will not modify the video device",
        "tongDYMZDGB": "The national standard in the channel page",
        "bianJGB": "Edit national standard",
        "qingSRGB": "Please enter the national standard",
        "gaiGB": "This national standard",
        "zaiTYSBXSCZ": "Already exists on the same device",
        "baoC": "Save",
        "guoB": "National standard",
        "biaoTSYLZZ": "Table header cell slot",
        "zaiXZT": "Online status",
        "caoZ": "Operation",
        "biaoLCZAN": "Table operation button",
        "biaoLSJX": "Table data items",
        "biaoLZZLX": "Table display type",
        "jieB": "Unbind",
        "qingXXZRYJBDTDLB": "Please first select the list of channels that need to be unbound",
        "yanZ": "validate",
        "shiPCZ": "Does it exist",
        "xinC": "Create",
        "tongDSL": "Number of channels",
        "mingC": "Name",
        "shangJ": "Superior",
        "deZ": "address",
        "zhengC": "Normal",
        "jinY": "Disabled",
        "jiLZT": "Cascade state",
        "chuLBLSJ": "Processing Table Data",
        "anN": "button",
        "bianJ": "Edit",
        "xuanZTD": "Select channel",
        "tuiS": "Push",
        "jinYZTXBKTS": "Cannot push in disabled state",
        "qiY": "Enable",
        "queR": "Confirm",
        "shanS": "Delete",
        "qingXJY": "Please disable first",
        "zaiSS": "Delete again",
        "queRSS": "confirm deletion",
        "chengG": "success",
        "yiB": "fail",
        "tuiSYB": "Push failed",
        "xiaZ": "download",
        "tuiSTDSL": "Number of push channels",
        "siTSTDSL": "Number of pushed channels",
        "guoBJLXC": "National standard cascade addition",
        "jiBXX": "Basic information",
        "qingSRMC": "Please enter a name",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "daiLSPL": "Proxy Video Streaming",
        "qingXZDLSPL": "Please select a proxy video stream",
        "xinLFWPZ": "Signaling service configuration",
        "qingXZJQJD": "Please select cluster nodes",
        "jiQJD": "Cluster nodes",
        "shiYCJQJDJLDSJPS": "Cascade this cluster node to the higher-level platform",
        "xinLMC": "Signal name",
        "qingSRXLMC": "Please enter the signaling name",
        "qingSRSJ": "Please enter your superior",
        "yu": "field",
        "qingSRSJPS": "Please enter the superior platform",
        "qingSR": "Please enter",
        "qingSRDK": "Please enter the port",
        "benD": "local",
        "qingSRWGZD": "Please enter the gateway side",
        "wangGZD": "Gateway side",
        "qingXZ": "Please select",
        "benDDZ": "Local address",
        "shiYZDDWQHDKJXQQ": "Request using specified network card and port",
        "qingXZDK": "Please select a port",
        "yuanCDZ": "Remote address",
        "zhuanSXY": "transport protocol",
        "qingXZZSXY": "Please select a transmission protocol",
        "yongH": "User",
        "qingSRYH": "Please enter the User",
        "xieRMM": "Access password",
        "qingSRXRMM": "Please enter the access password",
        "qingSRCS": "Please enter the manufacturer",
        "xingX": "model",
        "qingSRXX": "Please enter the model number",
        "banBX": "Version number",
        "qingSRBBX": "Please enter the version number",
        "xinTZJ": "Heartbeat cycle",
        "miao": "second",
        "qingSRXTZJ": "Please enter the heartbeat cycle",
        "zhuCJJ": "Registration interval",
        "qingSRZCJJ": "Please enter the registration interval",
        "gaiS": "summary",
        "peiZGBJL": "Configure national standard cascading",
        "pingSKSQSJXRDZSDSXTHXGDSFDYBF": "The platform can share cameras that have already been connected to it with third parties for playback",
        "zhu": "notes",
        "gaiPZZYXQBPSXSJLZDSFPS": "This configuration is only used to connect this platform to third-party platforms from higher levels",
        "ruRDSFPSXSJLZBPS": "If a third-party platform is needed to connect to the higher authorities on this platform",
        "qingZ": "Please check in the",
        "shiPSB": "Video equipment",
        "yeMXCSBSXZ": "Select when adding a new device on the page",
        "xieRFS": "Access method",
        "peiZSM": "Configuration Description",
        "siXPZSMSQBPSSJJLD": "The following configuration instructions are used to cascade data from this platform to",
        "pingSWL": "Taking the platform as an example",
        "qingCXDSFPSZPZD": "Please fill in the configuration of the third-party platform",
        "qingCXBDD": "Please fill in the local",
        "deZYZXBM": "Address encoded by center",
        "xingYBM": "Industry code",
        "leiXBM": "Type code",
        "huoXX": "And serial number",
        "siGMDH": "Four code segments in total",
        "weiS": "Bit Ten",
        "jinZSZZFGC": "Composition of base digit characters",
        "xiangXGZQCX": "For detailed rules, please refer to",
        "zhongBL": "Appendix",
        "buF": "part",
        "zhiDDWQHDK": "Designated network card and port",
        "ruYYWQLJJTYWRY": "If you have any questions, please contact the system operation and maintenance personnel",
        "buFPSYJXYHHXRMMDTSRZ": "Some platforms have special authentication based on Users and access passwords",
        "tongCQKX": "under normal conditions",
        "qingCX": "Please fill in",
        "zhi": "to",
        "ruYSJPSSZDXRMMYZ": "Must match the access password set by the superior platform",
        "yongXSFRZ": "Used for identity authentication",
        "benPSQS": "This platform will use",
        "sheB": "Equipment",
        "diSFJLDSJPS": "Cascade the identity of to the higher-level platform",
        "qingSZBPSZSJPSZXZDCS": "Please set the vendors displayed on this platform in the higher-level platform",
        "ruYSJPSSZDXTZJBCYZ": "Need to maintain consistency with the heartbeat cycle set by the superior platform",
        "tongCMR": "Usually default",
        "ruo": "if",
        "daiLTGZCFSJS": "Proxy calibration through registration method",
        "qiZCJJSJYSZWXX": "The registration interval should be set to be less than",
        "daiLY": "Agency and",
        "fuWQCX": "Server error",
        "wu": "error",
        "chaiSJGDYXSJ": "The running time passed by the difference",
        "biaoSSJ": "Form submission",
        "siXZD": "The following fields",
        "shiJSRSQD": "Extract when submitting",
        "ziDDZ": "In the field",
        "huoQJQJD": "Get cluster nodes",
        "huoQDK": "Get Port",
        "huoQXQ": "Get details",
        "ziDYZ": "Field validation",
        "ciCBDDS": "What is bound here is",
        "duanK": "port",
        "qingSRZQD": "Please enter the correct",
        "duanKQSR": "Port, please enter",
        "zhuJDZZS": "Positive integers between",
        "jinR": "today",
        "jinYZ": "Last week",
        "jinYR": "In the past month",
        "jinYN": "In the past year",
        "zanMSJ": "There is currently no data available",
        "tuBSJ": "Chart data",
        "tongJSJWZ": "Statistical time dimension",
        "huiZTB": "Draw a chart",
        "boFSL": "Playback quantity",
        "renC": "Number of people",
        "guaiDTX": "Inflection point size",
        "chuDYS": "Color at",
        "queXW": "Default to",
        "sheBSL": "Number of devices",
        "luXSL": "Number of recordings",
        "boFZSL": "Playing quantity",
        "dangQZZBFDTDSLZH": "The sum of the number of channels currently playing",
        "zaiX": "Online",
        "liX": "Offline",
        "tongD": "passageway",
        "luX": "videotape",
        "zongSZ": "Total duration",
        "boFZ": "Playing",
        "boFRS": "Player count",
        "huoQBFSL": "Get playback count",
        "xieRFSW": "The access method is",
        "shi": "Time",
        "buZCXC": "Create not supported",
        "huiF": "playback",
        "boFBYQWQZ": "Play without permission control",
        "boF": "play",
        "zuoZS": "Left side tree",
        "kaiSLX": "Start recording",
        "benDCC": "Local storage",
        "cunCZSBBD": "Store locally on the device",
        "yunDCC": "cloud storage",
        "cunCZFWQZ": "Store on server",
        "qingQLXZ": "Request recording in progress",
        "tingZLX": "Stop recording",
        "shuaX": "Refresh",
        "tongZQDKZB": "Resetting will disconnect the live broadcast",
        "keTHYXQTBFZ": "May affect other players",
        "tongZ": "Reset",
        "boFQ": "player",
        "shiPDZ": "Video address",
        "shiPLX": "Video type",
        "meiBKSBF": "Media starts playing",
        "luXZT": "Recording status",
        "zhaXLXZT": "Query recording status",
        "dianJQZAN": "Click on the control button",
        "qiangZLX": "control type",
        "zhiK": "Empty",
        "jiXHBFQ": "Destroy the player immediately",
        "tanC": "pop-up notification",
        "yongXXC": "Used for adding new",
        "xiuGSJ": "Modify data",
        "ruoBCX": "If not filled in",
        "jiTQZDSCWY": "The system will automatically generate a unique",
        "qingSRTD": "Please enter the channel",
        "qingSRTDMC": "Please enter the channel name",
        "qingSRCSMC": "Please enter the manufacturer's name",
        "qingSRSPDZ": "Please enter the video address",
        "buTCJD": "Different manufacturers",
        "guDDZGZBT": "Different fixed address rules",
        "qingADYCJDGZCX": "Please fill in according to the rules of the corresponding manufacturer",
        "yongHM": "User name",
        "qingSRYHM": "enter one User name",
        "miM": "password",
        "qingSRMM": "Please enter password",
        "qingSRAZDZ": "Please enter the installation address",
        "yunSLX": "Pan tilt type",
        "weiZ": "unknown",
        "qiuB": "sphere",
        "panQB": "Hemisphere",
        "guDQJ": "Fixed gun mechanism",
        "yaoQQJ": "Remote-controlled firearm",
        "qingXZYSLX": "Please select the pan tilt type",
        "shuiM": "Explain",
        "ziDYZSPCZ": "Verify if the field exists",
        "feiBC": "Not mandatory",
        "meiYSR": "No input",
        "buJY": "Do not verify",
        "bianJSBJYWYX": "Do not verify uniqueness during editing",
        "gai": "This",
        "siCZ": "Existing",
        "jiaoYSPDZ": "Verify video address",
        "qingSRZQDSPDZ": "Please enter the correct video address",
        "shiJ": "Submit",
        "meiYSX": "Not effective",
        "siXZDFBSSC": "The following fields are not filled in by the form",
        "tongZZDRSDZK": "Resetting fields requires manual emptying",
        "qingSRMLMC": "Please enter the directory name",
        "huoQSBXQ": "Get device details",
        "guDDZ": "Fixed address",
        "beiD": "passive",
        "chanPMC": "Product Name",
        "zhaKTD": "View channels",
        "zhaK": "check",
        "gengXTD": "Update channels",
        "guDDZMFGXTD": "Fixed address cannot update channel",
        "sheBSLX": "The device is offline",
        "sheBSJY": "Device disabled",
        "tongDGXCG": "Channel update successful",
        "zaiXSBMFSS": "Online devices cannot be deleted",
        "qiaPBZZZKAN": "Card not displaying view button",
        "shiPTBZJ": "Video icon component",
        "shiYX": "Time effective",
        "weiXZ": "Not downloaded",
        "xiaZZ": "Downloading",
        "siXZ": "Downloaded",
        "shangZYDRYYDSJ": "Uploading to the cloud takes some time",
        "qingSHZKYDSJ": "Please check the cloud data later",
        "jinXTZ": "Perform jump",
        "jinXXZ": "Download",
        "jinZT": "Progress bar",
        "yunD": "Cloud based",
        "leiX": "Type",
        "zanT": "suspend",
        "xiaZLXWJ": "Download video files",
        "xiaZDYD": "Download to the cloud",
        "boFZT": "Playback status",
        "tingZ": "cease",
        "boFWC": "Playback completed",
        "dangQBFSPBS": "Current video playback identifier",
        "shiPJSBF": "Whether to end playback",
        "zhaXBDSP": "Query local videos",
        "panDSPSXZYDSP": "Determine if cloud video has been downloaded",
        "zhaXYDSP": "Query cloud videos",
        "boFJZTDJ": "Play progress bar click",
        "riLCZ": "calendaring operation",
        "boFQSJKZ": "Player Time Scale",
        "huoQXZDTKSSJC": "Obtain the start timestamp of the selected day",
        "huoQXZDTJSSJC": "Obtain the end timestamp of the selected day",
        "zhaZXYGSP": "Find the next video",
        "shiPWCHYG": "Is it the last one",
        "boFDYG": "Play the first one",
        "shiPCBDTZDYDBF": "Whether to jump from local to cloud playback",
        "huoQTZBFD": "Get jump playback segments",
        "boFPD": "Play clips",
        "meiYKBFDSPZY": "No playable video resources available",
        "benDTZYDYSMZY": "Local jump to cloud but no resources available",
        "shaDMY": "Nothing",
        "shiPSBXC": "New video devices added",
        "qingXZXRFS": "Please select the access method",
        "cuoDSR": "Maximum Input",
        "qingSRYWYZSZYZ": "Please enter English or numbers or",
        "yuZ": "perhaps",
        "qingSRSBMC": "Please enter the device name",
        "suoZCP": "Belonging products",
        "qingXZSZCP": "Please select the product you belong to",
        "liuZSMS": "Streaming mode",
        "qingXZLZSMS": "Please select streaming mode",
        "sheBCS": "Equipment manufacturer",
        "qingSRSBCS": "Please enter the device manufacturer",
        "sheBXX": "Equipment model",
        "qingSRSBXX": "Please enter the device model",
        "guJBB": "Firmware version",
        "qingSRGJBB": "Please enter the firmware version",
        "qingSRSM": "Please enter a description",
        "shiPSBTG": "Video devices pass through",
        "xieRPSZBFW": "The overall access platform is divided into",
        "paoKPSDPZHSBDPZ": "Including platform side configuration and device side configuration",
        "buTDSBDPZDLJYYMCZCY": "There are differences in the paths or pages configured on different devices",
        "yanPZXJBTTXY": "But the configuration items are basically the same",
        "pingSDPZ": "Platform side configuration",
        "sheBWYBS": "Device Unique Identification",
        "qingCXSBDPZDSBBX": "Please fill in the device number configured on the device side",
        "zhiTXZXRFSW": "Only the access method can be selected as",
        "diCP": "Product",
        "ruoDQMDYCP": "If there is currently no corresponding product",
        "keDJYZKSTJAN": "You can click the quick add button on the right side",
        "chenXCPMCHXZ": "Fill in the product name and selection",
        "leiXDWGWCCPCJ": "Complete product creation for gateway types",
        "peiZXRMM": "Configure access password",
        "sheBDPZDMMRYGMMYZ": "The password configured on the device side must match this password",
        "gaiZDKZCP": "This field can be used in the product",
        "sheBXRYMJXTYPZ": "Unified configuration of device access pages",
        "peiZHSYSBQJCCPPZ": "After configuration, all devices will inherit the product configuration",
        "sheBSDXGHQTLJCGJ": "After individual modification of the device, it will detach from the inheritance relationship",
        "sheBDPZ": "Device side configuration",
        "geGCJ": "Various manufacturers",
        "buTSBXXDSBDPZYMBJCZCY": "There are differences in the layout of device side configuration pages for different device models",
        "ciCSTHSXTWLZWXRPZZL": "Taking Dahua Camera as an example of access configuration here",
        "fuWQBX": "Server number",
        "fuWQBXCRGSBSZCP": "Fill in the server number with the product to which the device belongs",
        "xieRFSYM": "Access Method Page",
        "lianXXX": "Connection information",
        "di": "of",
        "yuTCW": "The domain is usually",
        "fuWQBXDQ": "Before server number",
        "fuWQ": "server",
        "duanKCRGSBSZCP": "Fill in the port with the product to which the device belongs",
        "xieRFSYMZ": "In the access method page",
        "sheBBX": "Equipment number",
        "sheBBXWSBWYXBS": "The device number is a unique identifier for the device",
        "wuLWPSDSBXRMYJYGZD": "The device access to the Internet of Things platform did not verify this field",
        "shuRLYSZYBYXSBXRPS": "Entering any number does not affect the device's access to the platform",
        "zhuCMM": "Registration password",
        "chenRGSBSZCP": "Fill in the product to which the device belongs",
        "peiZ": "allocation",
        "chuDXRMM": "Access password at",
        "qiTZD": "Other fields",
        "buYXSBXRPS": "Does not affect device access to the platform",
        "keBCSBCSHZ": "Can maintain device initialization values",
        "guDDZXRPSFW": "Fixed address access platforms are divided into",
        "bu": "step",
        "tianJSPSB": "Add video device",
        "tianJSPXDTDDZ": "Add channel address under video",
        "dangQYMWXCSPSB": "The current page is for adding new video devices",
        "xinCWCHDJSBD": "After adding, click on the device's",
        "tianJTD": "Add channel",
        "jiTQZDSCWYBS": "The system will automatically generate a unique identifier",
        "zhiTXZXRFSWGDDZDCP": "Only products with fixed address access method can be selected",
        "chenXCPMCHXZGDDZLXDWGWCCPCJ": "Fill in the product name and select a gateway with a fixed address type to complete product creation",
        "bianJZD": "Edit Fields",
        "huoQSZCP": "Obtain the product to which it belongs",
        "xinCCP": "New products added",
        "baoCCG": "Save successful",
        "kuaiSTJ": "Quick Add",
        "qingSRCPMC": "Please enter the product name",
        "xieRWG": "Access gateway",
        "qingXZXRWG": "Please select the access gateway",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "qingX": "Please proceed first",
        "tianJ": "Add",
        "huoQXRWG": "Obtain access gateway",
        "dianJXRWG": "Click to access the gateway",
        "huoQDYPZ": "Obtain corresponding configuration",
        "tianJXRWG": "Add access gateway",
        "jiCTJ": "Basic statistics",
        "xiangQ": "Details",
        "shiPZXYD": "Video center guidance",
        "sheBXRTJBZ": "Recommended steps for device access",
        "buTDSBYWTXXYDBT": "Different devices due to differences in communication protocols",
        "cunZXRBZDCY": "There are differences in the access steps",
        "xuanZSB": "Select device",
        "quanWQZ": "Permission control",
        "fenPZZ": "Split screen display",
        "genJSPSBDZSXY": "According to the transmission protocol of video devices",
        "zaiSCJDCPXTJDYDSB": "Add corresponding devices under the created products",
        "zhaKSBXDTDSJ": "View channel data under the device",
        "keSJXZB": "Live streaming is possible",
        "luZDCZ": "Recording and other operations",
        "duiDGTDDSPLSJJXFPZZ": "Split screen display of video stream data from multiple channels",
        "qingXZSB": "Please select a device",
        "huoQSPLX": "Get video link",
        "dianJZZSXT": "Click on the left camera",
        "boFDYSP": "Play the corresponding video",
        "dianJJD": "Click on node",
        "shiPWZJD": "Is it a child node",
        "huoQSBLB": "Get device list",
        "gengXSJ": "Update data",
        "huoQZJD": "Get Child Nodes",
        "yiBJZZJDSJ": "Asynchronous loading of child node data",
        "liuMBMC": "Streaming Name",
        "qingSRLMBMC": "Please enter the streaming media name",
        "cuoTKSR": "Maximum Input",
        "fuWS": "Service providers",
        "qingXZFWS": "Please select a service provider",
        "miY": "secret key",
        "qingSRMY": "Please enter the key",
        "deZYZYM": "Address or domain name",
        "diaoYLMBXKSQQDFWDZ": "The service address requested when calling the streaming interface",
        "qingSRSRDK": "Please enter the input port",
        "shiPSBQLTSDG": "Video devices push streams to this location",
        "deZX": "Under the address",
        "buFSBFZC": "Some devices only support",
        "jianYQSY": "Suggest using all of them",
        "qingSRQSDK": "Please enter the starting port",
        "qiSDK": "Starting port",
        "qingSRZZDK": "Please enter the termination port",
        "zhongZDK": "Terminate Port",
        "dongTDK": "Dynamic port",
        "zhengCDLMBBTSS": "Normal streaming media cannot be deleted",
        "chaoCDWBYZ": "Exceeding text hiding",
        "yiCYXLXXZ": "Overflow displayed with ellipses",
        "yiCBHX": "Overflow without line breaks"
    },
    "my": {
        "wangZ": "website",
        "dianH": "telephone",
        "youX": "Mailbox",
        "deZ": "address",
        "zhou": "State",
        "shi": "yes",
        "jieD": "street",
        "luo": "road",
        "xiao": "Number",
        "benGCZYDYSWBGS": "Some professional text introductions of our company",
        "fangJCGJTZPZ": "Anti hijacking alarm notification configuration",
        "ziDSX": "Automatic reminder",
        "kai": "Open",
        "guan": "Close",
        "shiXFS": "Reminder method",
        "shouJ": "Mobile phone",
        "sheBGJTZPZ": "Device alarm notification configuration",
        "xinC": "Create",
        "xiuG": "modify",
        "zanM": "Currently unavailable",
        "gaoJTZPZ": "Alarm notification configuration",
        "qingXZGJFS": "Please select the alarm method",
        "qingSRDHXM": "Please enter a phone number",
        "qingSRYX": "Please enter your email address",
        "sheZCG": "Set successfully",
        "guanLSB": "Manage devices",
        "xinCSB": "New equipment added",
        "yiSSB": "Remove device",
        "xuanZD": "Selected",
        "qingCSXZYSSB": "Please select at least one device",
        "shiPSTJJFZ": "Is it added to the group",
        "fenZ": "minute",
        "liB": "list",
        "caoZCG": "Operation successful",
        "zuoS": "left hand",
        "youS": "right hand",
        "taiMZ": "thumb",
        "shiZ": "humidity",
        "zhongZ": "middle finger",
        "moMZ": "Ring finger",
        "xiaoMZ": "Thumb",
        "jinY": "Disabled",
        "qiY": "Enable",
        "zhiW": "fingerprint",
        "luRZW": "Enter fingerprint",
        "xuanZZW": "Select fingerprint",
        "baoC": "Save",
        "shiPXC": "Is it necessary to add a new one",
        "dingYH": "After subscription",
        "fanHDXX": "Information returned",
        "zhiWSZ": "Fingerprint upload",
        "zhiWLX": "Fingerprint type",
        "luR": "Input",
        "xuanZ": "choice",
        "zhiWXB": "Fingerprint index",
        "muZ": "thumb",
        "zhiWMB": "Fingerprint template",
        "gengX": "update",
        "shanS": "Delete",
        "tongS": "rebuild",
        "shanSSY": "Delete all",
        "sheB": "Equipment",
        "chanP": "Product",
        "zhiWSZMB": "Fingerprint upload template",
        "diaoYSZZWMBXKFHD": "The call to upload fingerprint template interface returns",
        "zhiWSZYB": "Fingerprint upload failed",
        "zhiWSZCG": "Fingerprint upload successful",
        "tongZ": "Reset",
        "kaiQDY": "Enable subscription",
        "shangZZWMB": "Upload fingerprint template",
        "huoQZWWJLB": "Get a list of fingerprint files",
        "qingXXZZW": "Please select your fingerprint first",
        "miaoN": "Within seconds",
        "meiYFHSJCGB": "No data returned, test closed",
        "meiSJFH": "No data returned",
        "tianJYB": "Add failed",
        "qingJZSB": "Please check the equipment",
        "yongHM": "User name",
        "miM": "password",
        "weiSZMM": "Digit password",
        "shiSZ": "It's a number",
        "buSSZ": "Not a number",
        "gengXZWLB": "Update fingerprint list",
        "xianPDYMYSRYHMGMM": "First, check if you have entered a Username and password",
        "qingSRYHMHMM": "Please enter your Username and password",
        "miMZZRYW": "The password length needs to be",
        "weiSZ": "Digit number",
        "yiBL": "failed",
        "fenXSB": "Sharing devices",
        "sheBXX": "Device information",
        "sheBMC": "Device Name",
        "xingX": "model",
        "xuanZYH": "Select Users",
        "qingSRDLYH": "Please enter the login User",
        "sheBGL": "device management",
        "huoQYHFZ": "Get User groups",
        "qingXXZYH": "Please select a User first",
        "qingXZYFXDQW": "Please select the permissions to share",
        "sheBJL": "Equipment records",
        "beiFXZ": "Shared by",
        "fenXDQW": "Shared permissions",
        "fenXCG": "Sharing successful",
        "shengJ": "upgrade",
        "shiQ": "time zone",
        "shiJ": "time",
        "chanPX": "product ID",
        "jiTBB": "System version",
        "panDGJSPRYSJ": "Determine if firmware upgrade is required",
        "jiTBBXXZML": "How to obtain system version information",
        "xieSD": "Written to death",
        "haiSZGXKRLRYSJDSHFHDM": "Is this interface still returned when an upgrade is needed",
        "gengXCG": "Update successful",
        "guJSJSPRY": "Is firmware upgrade required",
        "jianYJZD": "Monitoring progress",
        "guJSJCG": "Firmware upgrade successful",
        "sheBSZ": "devices setting up",
        "jieSPZ": "Unlock configuration",
        "baoJSZ": "Alarm settings",
        "fangJCSZ": "Anti hijacking settings",
        "muS": "mode",
        "jieSKG": "Unlock switch",
        "linSMM": "Temporary password",
        "duoTJSPZ": "Multiple unlocking configurations",
        "duoTJSKG": "Multiple unlock switch",
        "duoTJSMM": "Multiple unlock passwords",
        "duoTZW": "Multiple fingerprints",
        "diDLGJ": "Low battery alarm",
        "fangQGJ": "Anti prying alarm",
        "menWGGJ": "Door not closed alarm",
        "quLGJ": "Drive Away Alarm",
        "jieCGJ": "Hijacking alarm",
        "zhaZCSB": "Find this device",
        "daKHQFCSY": "After opening, a sound will be emitted",
        "chiX": "sustained",
        "miao": "second",
        "wenZXWGJSZ": "Temperature lower limit alarm setting",
        "wenZSWGJSZ": "Temperature upper limit alarm setting",
        "shiZXWGJSZ": "Humidity lower limit alarm setting",
        "shiZSWGJSZ": "Humidity upper limit alarm setting",
        "miMYS": "Password key",
        "zhiWYS": "Fingerprint key",
        "di": "low",
        "zhong": "in",
        "gao": "high",
        "huoQYHLB": "Get User list",
        "xieZXDSB": "Write attributes to device",
        "zhuXZ": "Attribute value",
        "juBCSCZWMX": "Specific parameter reference model",
        "sheBYHJL": "Device User records",
        "shiPSFJCYH": "Is it an anti hijacking User",
        "yueSFL": "Key classification",
        "chuangJLSMM": "Create temporary password",
        "huoQ": "obtain",
        "youXJ": "Validity period",
        "shiYCS": "Usage frequency",
        "ci": "second",
        "queDSSLSMM": "Confirm deleting temporary password",
        "queD": "Confirm",
        "quX": "Cancel",
        "shanSLSMM": "Delete temporary password",
        "miMGL": "Password management",
        "guanLY": "administrators",
        "puT": "ordinary",
        "pi": "no",
        "buRY": "No need",
        "ruY": "need",
        "queDSSM": "Are you sure to delete it",
        "queDSZZWMBM": "Are you sure to upload the fingerprint template",
        "shangZ": "upload",
        "shangZZWMBM": "Upload fingerprint template",
        "siSZZW": "Fingerprint uploaded",
        "xinCMM": "Add password",
        "xinCZWYS": "Add fingerprint key",
        "chuangJXYH": "Create a new User",
        "qingSRYHM": "enter one User name",
        "queR": "Confirm",
        "zhiWWJ": "Fingerprint file",
        "tanC": "pop-up notification",
        "miMTC": "Password pop-up",
        "zhiWTC": "Fingerprint pop-up window",
        "lianSMM": "Zero time password",
        "xinCZW": "Add fingerprint",
        "zhiWMBTC": "Fingerprint template pop-up",
        "siJYLSMM": "There is already a zero hour password",
        "zhaKLSMM": "View Zero Time Password",
        "yaoGJ": "To be more precise",
        "xuanZDYHQK": "Selected User information",
        "tanCXCZW": "Pop up window adding fingerprint",
        "chuangJYH": "Creating Users",
        "daKTC": "Open pop-up window",
        "miMGLXZR": "Password management selector",
        "zhiWBYDZSXMZ": "Left Thumb of Fingerprint Key",
        "queDSZZWMB": "Confirm uploading fingerprint template",
        "zheLRYKQ": "This needs to be opened",
        "huoQMM": "Get password",
        "baoCLSMM": "Save Zero Time Password",
        "queSLSMM": "Missing zero time password",
        "queSYXSJ": "Lack of effective time",
        "queSYXCS": "Missing valid times",
        "linSMMCJCG": "Temporary password created successfully",
        "linSMMCJYB": "Temporary password creation failed",
        "linSMMSSCG": "Temporary password deleted successfully",
        "qingKDSQ": "Clear Timer",
        "sheBXQ": "device details",
        "ceS": "test",
        "dianL": "Electricity level",
        "buZX": "Not online",
        "zaiX": "Online",
        "menZT": "Door status",
        "guanB": "close",
        "kaiQ": "Open",
        "wenZ": "temperature",
        "liangZ": "brightness",
        "yinL": "volume",
        "kaiS": "Unlocking",
        "yueSGL": "Key management",
        "fenXGL": "Sharing Management",
        "sheBSJ": "Equipment time",
        "dingYCS": "Subscription timeout",
        "huoQYTLSJL": "Get a history record",
        "sheBRZ": "Device logs",
        "weiSBRZ": "For device logs",
        "weiGJRZ": "For alarm logs",
        "zhaXCS": "Query parameters",
        "xuX": "Index",
        "caoZLX": "Operation type",
        "neiY": "content",
        "caoZSJ": "Operation time",
        "caoZR": "Operator",
        "gaoJNY": "Alarm content",
        "gaoJSJ": "Alarm time",
        "qingSRMC": "Please enter a name",
        "xinCFZ": "Add grouping",
        "xiuGMC": "Change Name",
        "xiuGFZMC": "Modify group name",
        "chengG": "success",
        "fenZMC": "Group Name",
        "xuanZCPXX": "Select product model",
        "qingDKSB": "Please open the device",
        "qiangSBZXHXTJMS": "Place the device in shared add mode",
        "xiaYB": "Next step",
        "qingZSBSLRXFDHXPDM": "Please enter the shared pairing code below on the device",
        "youXSJ": "Effective time",
        "siYX": "Invalid",
        "fanH": "return",
        "peiDCG": "Paired successfully",
        "qingSRSBMC": "Please enter the device name",
        "chanPJT": "Product system",
        "hongXTJ": "Share Add",
        "huoQSYCPFL": "Get all product categories",
        "genJCPFLHQCP": "Obtain products based on product classification",
        "dangQCPFLXMCP": "There are no products under the current product category",
        "qingHYGCPFL": "Please change to a different product category",
        "huoQHXM": "Get shared code",
        "dangQDLYHM": "Current login Username",
        "fenXM": "Sharing code",
        "sheBFZ": "Equipment grouping",
        "fanHSBLB": "Return to device list",
        "chanPJL": "Product series",
        "gaoJRZ": "Alarm Log",
        "tongMM": "rename",
        "queDSSSBM": "Are you sure you want to delete the device",
        "shanSSB": "Delete device",
        "queDSSFZM": "Are you sure to delete the group",
        "shanSFZ": "Delete Group",
        "hong": "Total",
        "siSB": "Device",
        "riZ": "journal",
        "zhiFFS": "Payment method",
        "zhengC": "Normal",
        "huoQLB": "Get List",
        "sheBBZX": "Device not online",
        "dianJFZ": "Click to group",
        "shanSCG": "Delete successful",
        "dingSX": "Order number",
        "qiaHZH": "Account",
        "shiPKP": "Invoiced",
        "dingSJE": "Order amount",
        "dingSZT": "Order status",
        "huiYJB": "Membership level",
        "jiaoYFS": "Transaction method",
        "chuangJSJ": "Creation time",
        "shengTYXJ": "Remaining validity period",
        "xiaoS": "search",
        "qiaHLB": "Customer List",
        "shiYXY": "Use Protocol",
        "benBFBRJ": "Release date of this version",
        "ning": "year",
        "ru": "month",
        "ri": "day",
        "shengXRJ": "effective date",
        "yinSBHXYDXYNYYJFSG": "The content of the privacy protection agreement shall be provided by Party A",
        "queRDC": "Confirm export",
        "daoC": "Export",
        "queRSS": "confirm deletion",
        "yongH": "User",
        "mingC": "Name",
        "shangZSJ": "Upload time",
        "caoZ": "Operation",
        "caoZYB": "operation failed",
        "woFXD": "What I shared",
        "liX": "Offline",
        "fenXYHLB": "Share User list",
        "queDSSFXM": "Are you sure to delete the share",
        "shanSFX": "Delete sharing",
        "fenXGWD": "Share with me",
        "sheBLB": "Device List",
        "sheBTP": "Device images",
        "zhuangT": "State",
        "yongHMC": "User Name",
        "quXFX": "Cancel sharing",
        "fenXQXCG": "Sharing canceled successfully",
        "yongHZCDXYNYYJFSG": "The content of the User registration agreement shall be provided by Party A"
    },
    "User": {
        "shiYXY": "Use Protocol",
        "benBFBRJ": "Release date of this version",
        "ning": "year",
        "ru": "month",
        "ri": "day",
        "shengXRJ": "effective date",
        "yongHZCDXYNYYJFSG": "The content of the User registration agreement shall be provided by Party A",
        "bangD": "binding",
        "tanCXG": "Pop up related",
        "caoZCG": "Operation successful",
        "qingXZYBDDYH": "Please select the User to bind",
        "xingM": "Name",
        "yongHM": "User name",
        "shouDXZGB": "Manually selecting changes",
        "fenYH": "Pagination meeting",
        "quXXZ": "Deselect",
        "bangDYH": "Bind Users",
        "shiPJSBD": "Unbind or not",
        "piLJB": "Batch unbinding",
        "zhengC": "Normal",
        "jinY": "Disabled",
        "zhuangT": "State",
        "caoZ": "Operation",
        "xiaoSCS": "Search parameters",
        "biaoL": "Form",
        "biaoLSL": "Table instance",
        "qingGXRYJBDSJ": "Please check the data that needs to be unbound",
        "shuaXLB": "Refresh List",
        "beiA": "Filing",
        "yu": "Chongqing",
        "bei": "prepare",
        "xiao": "Number",
        "yuY": "language",
        "zhongW": "Chinese",
        "yingW": "English",
        "zhangX": "Account",
        "qingSRZX": "Please enter your account number",
        "miM": "password",
        "qingSRMM": "Please enter password",
        "yanZM": "Verification code",
        "qingSRYZM": "Please enter the verification code",
        "jiZW": "Remember me",
        "deL": "Login",
        "meiYZX": "No account",
        "qingN": "Please",
        "xiaZ": "download",
        "zhuC": "register",
        "qiuDQXLZKSHTPJT": "The team's new masterpiece is a visual large screen system",
        "benY": "experience",
        "xianZ": "display",
        "diXZEWM": "Download QR code for",
        "huanYSY": "Welcome to use",
        "erWM": "QR code",
        "quDLLQD": "Get the browser's",
        "ziFC": "character string",
        "panDSP": "Determine if",
        "liuLQ": "browser",
        "chuangJYG": "Create a",
        "shiL": "example",
        "jiaMFF": "Encryption method",
        "sheZGY": "Set public key",
        "jiaM": "encryption",
        "jiaMHDSJ": "Encrypted data"
    },
    "Northbound": {
        "aLYWLWPS": "Alibaba Cloud IoT Platform",
        "gaiS": "summary",
        "zaiTDCJX": "In specific scenarios",
        "sheBMFZXXRALYWLWPSS": "When the device cannot directly connect to the Alibaba Cloud IoT platform",
        "ninKXQSBXRWLWPS": "You can first connect your device to the IoT platform",
        "zaiSYALY": "Reusing Alibaba Cloud",
        "yunYDX": "Cloud to cloud docking",
        "kuaiSGJQXFW": "Quickly build bridging services",
        "taJWLWPSYALYWLWPSDSXSJTD": "Building a bidirectional data channel between the IoT platform and Alibaba Cloud IoT platform",
        "peiZSM": "Configuration Description",
        "fuWDZ": "Service address",
        "aLYNBGMSJQSZDWYBX": "A unique number set internally by Alibaba Cloud for each machine",
        "qingGJGMDALYFWQDZJXXZ": "Please select based on the purchased Alibaba Cloud server address",
        "huoQLJ": "Get Path",
        "yongXCXTZFSDYYFWF": "Cloud service fee for calling program notification methods",
        "diYHBSHBYHQLJ": "User ID and key acquisition path for",
        "aLYGLQZS": "Alibaba Cloud Management Console",
        "yongHTX": "User profile picture",
        "guanL": "Administration",
        "zhaK": "check",
        "wangQCP": "Bridge products",
        "wuLWPSDXALYWLWPS": "The IoT platform is crucial for Alibaba Cloud's IoT platform",
        "shiYGWGSB": "It is a gateway device",
        "ruYYSDALYWLWPSDJBCP": "Specific products that need to be mapped to the Alibaba Cloud IoT platform",
        "chanPYS": "Product mapping",
        "qiangALYWLWPSZDCPSLYWLWPSDCPSLJXGL": "Associate product instances in Alibaba Cloud IoT platform with product instances in IoT platform",
        "guanLHRYJRGCPXDMYGSBDSLXXY": "After association, it is necessary to enter the instance information page of each device under the product",
        "chenRDYDALYWLWPSSBD": "Fill in the corresponding Alibaba Cloud IoT platform device",
        "jinXYDYBD": "Perform one-to-one binding",
        "jiBXX": "Basic information",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "cuoDSR": "Maximum Input",
        "geZF": "Characters",
        "qingXZFWDZ": "Please select a service address",
        "shiL": "example",
        "aLYWLWPSZDSL": "Examples in Alibaba Cloud IoT Platform",
        "meiYZBC": "If not, do not fill in",
        "qingSRSL": "Please enter an instance",
        "qingSR": "Please enter",
        "yongXCXTZFSDYYFW": "Used for calling cloud services through program notification methods",
        "diYHBS": "User ID for",
        "diBYBS": "Key identification for",
        "qingXZWQCP": "Please select a bridge product",
        "wuLWPSDYDALYCP": "Alibaba Cloud products corresponding to IoT platforms",
        "aLYCP": "Alibaba Cloud Products",
        "qingXZALYCP": "Please select Alibaba Cloud products",
        "pingSCP": "Platform products",
        "qingXZPSCP": "Please select platform products",
        "tianJ": "Add",
        "shuiM": "Explain",
        "qingSRSM": "Please enter a description",
        "baoC": "Save",
        "caoZCG": "Operation successful",
        "xinC": "Create",
        "zhuangT": "State",
        "zhengC": "Normal",
        "jinY": "Disabled",
        "caoZ": "Operation",
        "bianJ": "Edit",
        "qiY": "Enable",
        "queR": "Confirm",
        "caoZYB": "operation failed",
        "shanS": "Delete",
        "qingXJYGSJ": "Please disable this data first",
        "zaiSS": "Delete again",
        "queRSS": "confirm deletion",
        "qingXZ": "Please select",
        "shi": "yes",
        "pi": "no",
        "cenSMC": "Parameter Name",
        "leiX": "Type",
        "zhi": "value",
        "zhiLLX": "Instruction type",
        "qingXZZLLX": "Please select the instruction type",
        "duQZX": "read attribute",
        "xiuGZX": "modify attribute",
        "diaoYGT": "Call function",
        "zhuX": "attribute",
        "qingXZZX": "Please select attributes",
        "qingSRZ": "Please enter a value",
        "gongT": "function",
        "qingXZGT": "Please select the function",
        "cenSLB": "parameter list",
        "qingSRCSLB": "Please enter a parameter list",
        "xiaoZZTJJKFPS": "Xiaodu Smart Home Open Platform",
        "zhiCJJCJXDYDQZ": "Support cloud control in home scenarios",
        "gaiYMZYQPSDCPY": "This page mainly links the platform's products with",
        "zhiCYYQZDCPJXYS": "Mapping products that support voice control",
        "siDTXZPSQZBPSSBDMD": "To achieve the purpose of controlling the equipment on this platform on the Xiaodu platform",
        "caoZBZ": "Operation steps",
        "zaiMZXZJTPSCJJT": "Create skills on Baidu Xiaodu Skills Platform",
        "bingSQ": "And authorize",
        "wanCWLWPSY": "Complete the integration of IoT platform and",
        "diGL": "Association of",
        "shouQDZ": "Authorized address",
        "wuLWPSDDLDZ": "Login address for IoT platforms",
        "zhuYRYW": "Attention needs to be paid to",
        "qingFZBCX": "Please copy and fill in",
        "qingCXJTGL": "Please fill in the system management form",
        "yingYGLZD": "In application management",
        "huiDDZ": "token url",
        "qingFZ": "Please copy",
        "pingSZDZ": "Values in the platform",
        "chenXDJTGL": "Fill in to system management",
        "yingYGLZ": "In application management",
        "zhong": "in",
        "deZ": "address",
        "qingFZJTGL": "Please copy the system management",
        "chenXD": "Fill in to",
        "pingS": "platform",
        "deLWLWPS": "Login to IoT platform",
        "jinXPSNCPY": "Conduct platform based product collaboration",
        "chanPDSJYS": "Product data mapping",
        "zhiTJJYHTGWLWPSZDYH": "Smart home Users through Users in IoT platforms",
        "deLXZ": "Login to Xiaodu",
        "huoQPSNDQYHDSZSB": "Obtain the device belonging to the current User on the platform",
        "huoQHJKJXYYQZ": "Once obtained, voice control can be performed",
        "sheBLX": "Equipment type",
        "wei": "by",
        "pingSNDDBZGF": "Standard specifications proposed by the platform",
        "sheBLXQJD": "The device type will determine",
        "dongZYS": "Action mapping",
        "dongZ": "action",
        "diXLXX": "Dropdown options for",
        "siJ": "as well as",
        "zhuXYS": "Attribute mapping",
        "chanP": "Product",
        "qingXZCP": "Please select a product",
        "qingXZSBLX": "Please select the device type",
        "pingSNDDGF": "Specification proposed by the platform",
        "chanPMC": "Product Name",
        "qingXZDZ": "Please select an action",
        "pingSNDDSBLXJYDXGDZ": "The relevant actions of the device type proposed by the platform",
        "qingXZCZ": "Please select an action",
        "yangSWLWPSZSXCPJBDDZ": "Map the actions that the selected product in the IoT platform has",
        "xiaFZL": "Issue instructions",
        "huoQLSSJ": "Obtain historical data",
        "xinCDZ": "Add Action",
        "pingSZX": "Platform attributes",
        "qingXZPSZX": "Please select platform properties",
        "xinCZX": "New attributes added",
        "zhaXCPLB": "Query product list",
        "quNTFD": "Remove duplicate"
    },
    "notice": {
        "diaoS": "debugging",
        "quX": "Cancel",
        "queD": "Confirm",
        "tongZMB": "Notification template",
        "gaiZDWBCZD": "This field is mandatory",
        "qingXZTZMB": "Please select a notification template",
        "bianL": "variable",
        "huoQTZMB": "Get notification template",
        "huoQMBXQ": "Get template details",
        "dianHZDJY": "Telephone field verification",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "qingSRYXXM": "Please enter a valid number",
        "mingC": "Name",
        "zhi": "value",
        "biaoSSJ": "Form submission",
        "shiJ": "time",
        "caoZCG": "Operation successful",
        "qingQTKBJBL": "Request header editable table",
        "tianJ": "Add",
        "caoZ": "Operation",
        "aLYGLQZS": "Alibaba Cloud Management Console",
        "gaiS": "summary",
        "tongZPZKSJHTZPZWGJXXTZSGZC": "Notification configuration can be combined with notification configuration to provide support for alarm message notification",
        "yiKSYXJTZQTZDYMKDDY": "It can also be used for calling other custom modules in the system",
        "tongZPZSM": "Notification Configuration Description",
        "aLYNBGMSJQSZDWYBX": "A unique number set internally by Alibaba Cloud for each machine",
        "qingGJGMDALYFWQDZJXCX": "Please fill in according to the address of the purchased Alibaba Cloud server",
        "aLYDYHKYQDZBDZ": "Alibaba Cloud Region and Availability Zone Comparison Table Address",
        "yongXCXTZFSDYYFWF": "Cloud service fee for calling program notification methods",
        "diYHBSHBY": "User ID and key for",
        "huoQLJ": "Get Path",
        "yongHTX": "User profile picture",
        "guanL": "Administration",
        "zhaK": "check",
        "aLYFWDYYDYD": "Alibaba Cloud service regions and corresponding",
        "qingGJGMDALYFWQDYJXXZ": "Please choose based on the region of the purchased Alibaba Cloud server",
        "diYHBSHBYHQLJ": "User ID and key acquisition path for",
        "yaMSGLQZS": "Amazon Management Console",
        "yongHDPZ": "User's credentials",
        "sheZDZ": "Set Address",
        "yongHDBY": "User's Secret Key",
        "dingDKFPS": "DingTalk Open Platform",
        "qiYNBYYDWYSFBS": "Unique identity identification for internal enterprise applications",
        "zaiDDKFZHSCJQYNBYYH": "After creating internal enterprise applications in the DingTalk developer backend",
        "jiTHZDSCYD": "The system will automatically generate a pair",
        "huo": "and",
        "yingYKF": "application development",
        "yingYXX": "Application Information",
        "dingDYYDYDDYMY": "The calling key corresponding to the DingTalk application",
        "zaiDDQNMCJYGDDQZDYJQRDHCSWYD": "Each custom robot created within a DingTalk group will generate a unique",
        "deZ": "address",
        "dingDZMQHD": "DingTalk Desktop Client",
        "qunSZ": "Group settings",
        "zhiTQCS": "Intelligent group assistant",
        "jiQRXX": "Robot information",
        "deLDDZMQHD": "Login to DingTalk Desktop Client",
        "jinRQSZ": "Enter group settings",
        "dianJZTQCS": "Click on the intelligent group assistant",
        "zhaKJQRXX": "View robot information",
        "fuWQDZ": "server address",
        "xiaLKXZGNCYDYXFWPZ": "Dropdown to select commonly used email service configurations in China",
        "yiZCSDSRQTDZ": "It also supports manual input of other addresses",
        "jiTSY": "System usage",
        "xieY": "protocol",
        "yuanHDZYJQHDXZFWQSDYJ": "Allow email clients to download emails from the server",
        "yanSNZDZYJQHDDCZ": "But your actions in the email client",
        "ru": "as",
        "yiDYJ": "Mobile Mail",
        "biaoJSDD": "Mark as read, etc",
        "zheSBHFKDFWQS": "At this point, it will not be fed back to the server",
        "faJR": "From",
        "yongXFSYJS": "Used for sending emails",
        "xinXDXZ": "Display of information",
        "yongHM": "User name",
        "yongGZXJXFSYJ": "Use this account to send emails",
        "miM": "password",
        "yongXZXSFRZ": "Used for account identity authentication",
        "renZTGHKTGGZXJXFSYJ": "After authentication, you can send emails through this account",
        "shiYGXS": "It's a receiver",
        "qingQD": "Requested",
        "benPSMRZZC": "This platform only supports by default",
        "qingQ": "request",
        "shiXL": "Implemented",
        "diDSFJTKSJXG": "The third-party system can be based on this",
        "dingYBPSJTXX": "Subscribe to system information on this platform",
        "benPSAPZBTDDZJJLTSDZDDDZ": "This platform pushes specific event results to the specified address according to configuration",
        "bianXJTZHXCL": "Facilitate subsequent processing by the system",
        "qingQT": "Request header",
        "zhiCGJJTSGDXKSZBTDQQT": "Support setting different request headers based on the interface provided by the system",
        "weiXGZPS": "WeChat public platform",
        "weiXFWXDWYZZBX": "Unique and exclusive WeChat service number",
        "guanLHS": "Management backend",
        "sheZYKF": "Settings and Development",
        "jiBPZ": "Basic configuration",
        "gongZXKFZSFDMM": "Password of official account developer",
        "qiYWXGLHS": "Enterprise WeChat Management Backend",
        "qiYXDWYZZBX": "The unique and exclusive number of the enterprise account",
        "qiYWX": "WeCom",
        "woDQY": "My Business",
        "qiY": "enterprise",
        "yingYDWY": "Unique application",
        "yiGQYWXZKSYDG": "There can be multiple accounts in a single enterprise WeChat account",
        "yingYYXCX": "Applications and Mini Programs",
        "ziJYY": "Self built applications",
        "zhongHQ": "Obtain from",
        "tongZPZXQ": "Notification Configuration Details",
        "tongZFS": "Notification method",
        "qingXZTZFS": "Please choose the notification method",
        "qingSRMC": "Please enter a name",
        "leiX": "Type",
        "dingD": "DingTalk",
        "qingSR": "Please enter",
        "weiX": "WeChat",
        "youJ": "mail",
        "qingSRFWQDZ": "Please enter the server address",
        "kaiQ": "Open",
        "qingSRFJR": "Please enter the sender",
        "qingSRYHM": "enter one User name",
        "qingSRMM": "Please enter password",
        "yuY": "speech",
        "duanX": "message",
        "qingXZ": "Please select",
        "shuiM": "Explain",
        "qingSRSM": "Please enter a description",
        "baoC": "Save",
        "xiaoXLX": "Message type",
        "dingDXX": "DingTalk Message",
        "qunJQRXX": "Group robot messages",
        "genJTZFSZZDYDZD": "Display the corresponding fields according to the notification method",
        "yanZGZ": "Validation rules",
        "qingXZLX": "Please select the type",
        "aLYYY": "Alibaba Cloud Voice",
        "ruYSYGHFD": "It needs to be a legal one",
        "qingSRBY": "Please enter the key",
        "tongZFSGB": "Notification method change",
        "tongZLXGB": "Notification type change",
        "tongZZDZ": "Reset Field Values",
        "baoCCG": "Save successful",
        "shuJLY": "data sources",
        "gongHY": "Public Cloud",
        "zhongGDQ": "China region",
        "paoHZGXH": "Including Hong Kong, China",
        "zhongGAM": "Macau, China",
        "buPHZGSW": "Excluding Taiwan, China",
        "huaB": "North China",
        "jingD": "Qingdao",
        "beiJ": "Beijing",
        "zhangJK": "Zhangjiakou",
        "xuHGT": "Hohhot",
        "wuLCB": "Ulanqab",
        "huaD": "East China",
        "kangZ": "Hangzhou",
        "shangH": "Shanghai",
        "nanJ": "Nanjing",
        "benDDY": "Local region",
        "fuZ": "Fuzhou",
        "huaN": "south China",
        "shenH": "Shenzhen",
        "heY": "silicon valley",
        "yanZ": "Guangzhou",
        "xiN": "Sydney",
        "chengD": "Chengdu",
        "zhongGXH": "Hong Kong, China",
        "qiTGJHDQ": "Other countries and regions",
        "xinJP": "Singapore",
        "aoTLY": "Australia",
        "maLXY": "Malaysia",
        "jiLP": "Kuala Lumpur",
        "yinZNXY": "Indonesia",
        "yaJT": "Jakarta",
        "feiLB": "the Philippines",
        "maNL": "Manila",
        "taiG": "Thailand",
        "manY": "Bangkok",
        "yinZ": "India",
        "mengM": "bombay",
        "riB": "Japan",
        "dongJ": "Tokyo",
        "hanG": "Korea",
        "shouE": "Seoul",
        "meiG": "United States",
        "fuJNY": "Virginia",
        "deG": "Germany",
        "faLKF": "Frankfurt",
        "yingG": "Britain",
        "lunZ": "London",
        "aLQ": "UAE",
        "diB": "Dubai",
        "tongZPZ": "Notification Configuration",
        "xinC": "Create",
        "daoR": "Import",
        "queRDC": "Confirm export",
        "daoC": "Export",
        "peiZMC": "Configuration Name",
        "xiaoS": "search",
        "genJTZFSZZDY": "Display corresponding information according to the notification method",
        "tongZFSZDZZDYWZ": "Display corresponding text in the notification method field",
        "genJLXZZDYWA": "Display corresponding copy according to type",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "tongZPZSJ": "Notification Configuration Data",
        "bianJ": "Edit",
        "shanS": "Delete",
        "queRSS": "confirm deletion",
        "caoZYB": "operation failed",
        "qiT": "other",
        "tongBYH": "Synchronize Users",
        "tongZJL": "Notification Record",
        "faSSJ": "Sending time",
        "zhuangT": "State",
        "chengG": "success",
        "yiB": "fail",
        "zhaKCWXX": "View error messages",
        "cuoWXX": "error message",
        "zhaKXQ": "View details",
        "xiangQXX": "Details",
        "qingSRBMMC": "Please enter the department name",
        "ziDBD": "Automatic binding",
        "di": "Section",
        "tiao": "strip",
        "zongH": "altogether",
        "bangDYH": "Bind Users",
        "yongH": "User",
        "qingXZYH": "Please select a User",
        "zuoZBM": "Left department",
        "huoQBM": "Obtain department",
        "buMDJ": "Department click",
        "youZBL": "Right Table",
        "dingDYHM": "DingTalk Username",
        "bangDZT": "Binding status",
        "bangD": "binding",
        "jieB": "Unbind",
        "queRJB": "Confirm unbinding",
        "huoQDD": "Get DingTalk",
        "weiXBMYH": "WeChat department Users",
        "huoQSJBDDYH": "Get bound Users",
        "huoQSYYHWBDDYH": "Get all unbound Users for all Users",
        "chuLLBSJ": "Processing List Data",
        "weiBDDYH": "Unbound Users",
        "bangDDYH": "Bound Users",
        "siBD": "Bound",
        "weiBD": "Unbound",
        "qianDFY": "Front end pagination",
        "yongHXLSX": "User dropdown filtering",
        "bangDSJ": "Binding submission",
        "qiYXX": "Enterprise News",
        "fuWXXX": "Service Number Message",
        "aLYDX": "Alibaba Cloud SMS",
        "ziDGJYS": "Field Relationship Mapping",
        "peiZ": "allocation",
        "muB": "Template",
        "dingDJQR": "DingTalk robot",
        "yuYTZLX": "Voice notification type",
        "yuYTZ": "Voice notification",
        "yuYYZM": "Voice verification code",
        "qingXZTZPZ": "Please select notification configuration",
        "sheZMRPZ": "Set default configuration",
        "buJXX": "Attachment information",
        "shanSBJ": "Delete attachments",
        "tianJBJ": "Add attachments",
        "buJBS": "Attachment identification",
        "qingXZSXBM": "Please select the receiving department",
        "qingXZBQTS": "Please select tag push",
        "qingXZSXR": "Please select the recipient",
        "muBNY": "Template content",
        "bianLLB": "Variable List",
        "mo": "nothing",
        "biaoSJY": "Form verification",
        "shouDTJJY": "Manually adding verification",
        "gaiZDSBCZD": "This field is mandatory",
        "ziFC": "character string",
        "shuZ": "number",
        "luoSW": "Format is",
        "daiBSZBLDXSWS": "Representing the number of decimal places to be retained",
        "dang": "When",
        "shi": "Time",
        "daiBLSWZS": "Represent format as integer",
        "luoS": "format",
        "aLYDXFWPS": "Alibaba Cloud SMS Service Platform",
        "tongZMBJHTZPZWGJXXTZSGZC": "Notification templates combined with notification configurations provide support for alarm message notifications",
        "tongZMBZTDYTYLXDTZPZFW": "Notification templates can only call notification configuration services of the same type",
        "shiYALYDXSRXZALYDXFWPSCJDXMB": "When using Alibaba Cloud SMS, you need to first create a SMS template on the Alibaba Cloud SMS service platform",
        "muBPZSM": "Template configuration instructions",
        "bangDPZ": "Binding configuration",
        "shiYGDDTZPZFSCTZMB": "Send this notification template using a fixed notification configuration",
        "aLYDXPSZDYDMBMC": "The template name customized by Alibaba Cloud SMS platform",
        "shouXR": "Recipient",
        "dangQFZCGNSJX": "Currently, only domestic phone numbers are supported",
        "ciCRBC": "If not filled in here",
        "zeZMBDSHPZGJTZSSDCX": "Manually fill in alarm notifications during template debugging and configuration",
        "qianM": "autograph",
        "yongXDXNYQMXXXZ": "Used for displaying SMS content signature information",
        "ruZALYDXJXPZ": "Configuration needs to be done through Alibaba Cloud SMS",
        "bianLZX": "Variable Properties",
        "ruYZDQYMSDSZYALYDXMBZYYDBL": "You need to manually set the same variables as in the Alibaba Cloud SMS template on the current page",
        "piZHDZFSYC": "Otherwise, it will cause abnormal sending",
        "aLYYYFWPS": "Alibaba Cloud Voice Service Platform",
        "shiYALYYYSRXZALYYYFWPSCJYYMB": "When using Alibaba Cloud voice, you need to first create a voice template on the Alibaba Cloud voice service platform",
        "aLYYYTZLX": "Alibaba Cloud voice notification types",
        "dangLXWYZMLXSKPZBL": "Can configure variables when the type is a verification code type",
        "aLYYYDMYTYYTZFPDWY": "The unique allocation of each voice notification by Alibaba Cloud Voice",
        "biaoS": "identification",
        "beiJXM": "Callee number",
        "ruoNSYDYYTZWJWGHMSWX": "If the voice notification file you are using is a public mode outbound call",
        "zeGCSZBC": "Then the parameter value is not filled in",
        "ruoNSYDYYTZWJWZZMSWX": "If the voice notification file you are using is in exclusive mode for outbound calls",
        "zeBXZRSGMDXM": "Then the purchased number must be passed in",
        "fuZCYGXM": "Only supports one number",
        "beiJXX": "Called display number",
        "yongHXJXMXZ": "User call number display",
        "biXSZALYGMDXM": "Must be a number purchased from Alibaba Cloud",
        "boFCS": "Playback count",
        "cuoDKBF": "Up to playable",
        "ci": "second",
        "fuDTZLXWYZMLXSKJXPZ": "Can only be configured when the notification type is a verification code type",
        "bianLBSRYALYMBZDBSYZ": "Variable identification needs to be consistent with the identification in the Alibaba Cloud template",
        "zhiCCXDBLDDTMB": "Support filling in dynamic templates with variables",
        "bianLCXGFZL": "Example of variable filling specification",
        "chenXDTCSH": "After filling in dynamic parameters",
        "keDBLDMC": "Name of variable that can be paired",
        "luoSJXPZ": "Format configuration",
        "siBGJTZSCX": "To be filled in during alarm notification",
        "dingDGLHS": "DingTalk Management Backend",
        "shiYDDXXTZSRZDDKFPSZCJHDYDYY": "When using DingTalk message notifications, it is necessary to create corresponding applications in the DingTalk open platform",
        "yingYWYBS": "Application unique identification",
        "zhaKYY": "View applications",
        "shouXBM": "Receiving department",
        "ruoBCXSXR": "If the recipient is not filled in",
        "tongXL": "Address book",
        "zhaKYH": "View Users",
        "bianJBM": "Editing Department",
        "shiYDDQJQRXXTZSRZDDKFPSZCJHDYDJQR": "When using DingTalk group robot message notifications, corresponding robots need to be created in the DingTalk open platform",
        "zaiDDDQHDZDYDQZBDZTJQR": "Then to the DingTalk client, bind the intelligent robot in the corresponding group",
        "muQZC": "Currently supported",
        "chong": "species",
        "biaoT": "title",
        "fuWQDZZCZDYSR": "Server address supports custom input",
        "zhiCSRBL": "Support input variables",
        "bianLLS": "Variable Format",
        "shouJR": "recipients",
        "zhiCLRDGYXDZ": "Support entering multiple email addresses",
        "keCXBLCS": "Variable parameters can be filled in",
        "qingQB": "Request Body",
        "qingQBZDSJLZXFSTZSZDDSYBL": "The data in the request body comes from all variables specified when sending notifications",
        "yiKTGZDYDFSJXBLPZ": "Variable configuration can also be done through customization",
        "shiY": "apply",
        "tongZS": "When notified",
        "jiTHQGZJTGNZDD": "The system will pass the event through the one you specified",
        "si": "with",
        "fangSFS": "Method of sending",
        "yongHBQ": "User tags",
        "siBQDWZTZGBQXSYYH": "Notify all Users under the label in the dimension of the label",
        "xiaoXMB": "Message template",
        "weiXGZXZPZDXXMB": "Message template configured in WeChat official account",
        "muBTZLX": "Template jump link",
        "dianJXXZHJXYMTZ": "After clicking on the message, perform a page jump",
        "tiaoZXCX": "Jump to Mini Program",
        "dianJXXZHDKDYDXCX": "After clicking on the message, open the corresponding mini program",
        "tiaoZXCXJBLJ": "Jump to the specific path of the mini program",
        "dianJXXZHTZDXCXDJBYM": "After clicking on the message, jump to the specific page of the mini program",
        "yingYGL": "Application Management",
        "yingY": "application",
        "biaoQTS": "Tag push",
        "xieSTZD": "Receiving notifications",
        "chongFS": "Various ways",
        "geZDRZCYMDMYCX": "If none of the fields are filled in on this page",
        "zeZMBDSHPZGJTZSRYSDCX": "Manual filling is required during template debugging and configuring alarm notifications",
        "chengYXX": "Member Information",
        "zhaKCYZX": "View member accounts",
        "shouXZZ": "Receiving organization",
        "buMXX": "Department Information",
        "zhaKBM": "View Department",
        "tongZMBXQ": "Notification template details",
        "shiYGDDTZPZLFSCTZMB": "Use a fixed notification configuration to send this notification template",
        "qingXZBDPZ": "Please select binding configuration",
        "ruLBCXGZD": "If this field is not filled in",
        "qiangZSYCMBFSTZSJXZD": "Will be specified when sending notifications using this template",
        "qingXZXXLX": "Please select message type",
        "qingSRBT": "Please enter a title",
        "tuPLX": "pictures linking",
        "qingSRTPLX": "Please enter the image link",
        "neiYLX": "Content Link",
        "qingSRNYLX": "Please enter the content link",
        "benQYWXDBQ": "Our company's WeChat tags",
        "liB": "list",
        "cuoDZC": "Maximum support",
        "ge": "individual",
        "youJBT": "Email Title",
        "duoGSJRYHXFJ": "Multiple recipients separated by line breaks",
        "cuoTZC": "Maximum support",
        "geXM": "Number",
        "qingSRSJRYX": "Please enter the recipient's email address",
        "buJZSRWJMCQZFSYJSJXWJSZ": "The attachment only includes the file name, which will be uploaded when sending emails",
        "yuYYZMLXKPZBL": "Voice verification code type configurable variable",
        "bingJZZCSZHYWZW": "And it only supports numbers and English letters",
        "aLYNBFPDWY": "Unique internal allocation within Alibaba Cloud",
        "qingSRMB": "Please enter a template",
        "fuZCZGTLXM": "Only Chinese Mainland numbers are supported",
        "qingSRBJXM": "Please enter the called number",
        "biXSSGMDXM": "Must be a purchased number",
        "yongXXJXMXZ": "Used for displaying call numbers",
        "qingSRBJXX": "Please enter the called display number",
        "yuYWJDBFCS": "The number of times voice files are played",
        "qingSRBFCS": "Please enter the number of plays",
        "yuYYZMNYSRK": "Voice verification code content input box",
        "yongXXRYYYZMBL": "Used for rendering voice verification code variables",
        "neiYZDBLQYXALYYYYZM": "The variables in the content will be used for Alibaba Cloud voice verification codes",
        "qingXZMB": "Please select a template",
        "qingSRSXR": "Please enter the recipient",
        "qingXZQM": "Please select a signature",
        "dianHXM": "Telephone number",
        "moR": "default",
        "ziDY": "custom",
        "faSDNY": "Content sent",
        "zhiCLRBL": "Support variable input",
        "zhiL": "Example",
        "zunJD": "Dear",
        "youSBCFGJ": "Device triggered alarm",
        "qingZYCL": "Please pay attention to handling",
        "youJLSCW": "Email format error",
        "fuZC": "Only supported",
        "qingSRQM": "Please enter your signature",
        "gaiZDBSYXDSJX": "This field is not a valid phone number",
        "qingSRMBNY": "Please enter the template content",
        "ziDSQBL": "Field extraction variables",
        "qiangRYSQBLDZDZPXWYGZFC": "Concatenate the field values that need to be extracted as variables into a string",
        "yongXTYSQBL": "Used for uniformly extracting variables",
        "genJZDSRNY": "Enter content based on fields",
        "shiQBL": "Extract variables",
        "siJCZDBL": "Existing variables",
        "zhengZSQ": "Regular extraction",
        "liMDZ": "The values inside",
        "xiaoXLXXZGB": "Change in message type selection",
        "huoQXQ": "Get details",
        "aLYYYMBNYZDCYBM": "Aliyun voice template content fields use aliases",
        "huoQBDPZ": "Get binding configuration",
        "peiZXZGB": "Configuration selection change",
        "huoQALMB": "Obtain Alibaba template",
        "duanXMBGB": "SMS template change",
        "huoQQM": "Obtain signature",
        "bianLLBCZ": "Variable list exists",
        "juCZKZ": "And there are empty values present",
        "youJMYPZZD": "Email does not have configured fields",
        "ziDYQTLXD": "Fields and other types of",
        "ziDTM": "Field duplication",
        "yanYYBRYBCYZ": "But voice does not require mandatory verification",
        "quBM": "Taking an alias",
        "yanZTGZH": "After verification is passed",
        "fuZH": "Assignment back",
        "ziD": "field",
        "bingSSBMZD": "And delete the alias field",
        "muBMC": "Template Name",
        "tongZMBSJ": "Notification template data",
        "bangDPZLX": "Binding configuration type"
    },
    "oauth": {
        "deL": "Login",
        "shouQDL": "Authorized login",
        "ninZZSQDL": "You are authorizing login",
        "qiangHDSXQW": "Will obtain the following permissions",
        "guanL": "correlation",
        "zhangX": "Account",
        "huoQNDGRXX": "Get your personal information",
        "tongYSQ": "Agree to authorize",
        "qiHZX": "Switch accounts",
        "yongHM": "User name",
        "qingSRYHM": "enter one User name",
        "miM": "password",
        "qingSRMM": "Please enter password",
        "qingSRYZM": "Please enter the verification code",
        "shouQ": "authorization",
        "huoQYZMPZ": "Obtain verification code configuration",
        "weiDL": "Not logged in",
        "shiPZ": "Whether to go or not",
        "huoQ": "obtain",
        "zhongDPZXX": "Configuration information in",
        "huoQYHXX": "Obtain User information"
    },
    "rule-engine": {
        "gaoJJBPZ": "Alarm level configuration",
        "jiB": "level",
        "baoC": "Save",
        "gongTSM": "Function Description",
        "gaoJJBYXMSGJDYTCZ": "The alarm level is used to describe the severity of the alarm",
        "qingGJYWGLFSJXZDY": "Please customize according to the business management method",
        "gaoJJBQHZGJPZZBYY": "The alarm level will be referenced in the alarm configuration",
        "cuoDKPZ": "Up to configurable",
        "geJB": "Level",
        "gaoJJB": "Alarm level",
        "shuJLZ": "Data flow",
        "caoZCG": "Operation successful",
        "gaoJSJSC": "Alarm data output",
        "qiangGJSJSCDQTDSFJT": "Output alarm data to other third-party systems",
        "deZ": "address",
        "zhuangT": "State",
        "gaoJCLJLSR": "Alarm processing result input",
        "xieSDSFJTCLDGJJL": "Receive alarm results processed by third-party systems",
        "gongTTZ": "Functional diagram",
        "pingSZCQGJSJSCD": "The platform supports outputting alarm data to",
        "diSFJTKDY": "Third party systems can be subscribed to",
        "zhongDGJSJ": "Alarm data in",
        "jinXYWCL": "Perform business processing",
        "shuCCS": "Output parameters",
        "zhiL": "Example",
        "pingSZCDY": "Platform supports subscription",
        "zhongGJCLSJ": "Medium alarm processing data",
        "bingGXGJJLZT": "And update the alarm record status",
        "dingYCS": "Subscription parameters",
        "gaoJPZMC": "Alarm configuration name",
        "tuiSDGJPZMC": "Push alarm configuration name",
        "yinGGJ": "Smoke detection alarm",
        "gaoJPZ": "Alarm configuration",
        "tuiSDGJPZ": "Push alarm configuration",
        "gaoJSJ": "Alarm time",
        "gaoJWYXBS": "Alarm uniqueness identification",
        "gaoJJL": "Alarm Log",
        "gaoJJLDWYBS": "Unique identification of alarm records",
        "keGJC": "Based on this",
        "chuLGJ": "Handling alarms",
        "gaoJMBLX": "Alarm target type",
        "gaoJSZDYWLX": "The business type to which the alarm belongs",
        "juBYCP": "Specific products available",
        "sheB": "Equipment",
        "buM": "department",
        "qiT": "other",
        "chanP": "Product",
        "gaoJMB": "Alarm target",
        "gaoJMBWYXBS": "Unique identification of alarm targets",
        "gaoJMBMC": "Alarm target name",
        "gaoJMBSLMC": "Alarm target instance name",
        "haiKYG": "Haikang Smoke Sense",
        "gaoJCFSJ": "Alarm triggering time",
        "gaoJYLX": "Alarm source type",
        "chuFGJDYLX": "The source type that triggers the alarm",
        "dangQZY": "Currently, only",
        "gaoJY": "Alarm source",
        "chuFGJDY": "The source that triggers the alarm",
        "ruSB": "Such as equipment",
        "gaoJYMC": "Alarm source name",
        "chuFGJDYMC": "The source name that triggers the alarm",
        "ruSBMC": "Such as device name",
        "louYG": "Lou Yan Gan",
        "gaoJYTCZZB": "Alarm severity index",
        "gaoJSM": "Alarm Description",
        "gaoJGZSM": "Explanation of alarm rules",
        "louYGTYGJGZSZ": "Setting of unified alarm rules for building smoke detectors",
        "shi": "Time",
        "chuLSJ": "processing time",
        "gaoJCLSJ": "Alarm processing time",
        "buCSMRWXXCLSJ": "If left blank, it defaults to message processing time",
        "chuLSM": "Processing instructions",
        "gaoJCLNYXXMSSM": "Detailed description and explanation of alarm processing content",
        "siLJDSFRYJXGJCL": "Contacted third-party personnel for alarm handling",
        "xianGJSHF": "The current alarm has been restored",
        "chuLLX": "Processing type",
        "zhiC": "support",
        "chuLHDZT": "Processed state",
        "mingC": "Name",
        "biaoS": "identification",
        "leiX": "Type",
        "shuiM": "Explain",
        "zhiLZ": "Example value",
        "biC": "Required",
        "sheBWZGG": "Equipment temperature too high",
        "wenZXCSB": "Temperature detection equipment",
        "bianJ": "Edit",
        "quX": "Cancel",
        "queD": "Confirm",
        "cuoDSR": "Maximum Input",
        "geZF": "Characters",
        "qingSR": "Please enter",
        "qiY": "Enable",
        "dingY": "subscription",
        "tuiS": "Push",
        "jinY": "Disabled",
        "zhengC": "Normal",
        "xinC": "Create",
        "guanLCJLD": "Associated scene linkage",
        "zuZ": "organization",
        "caoZ": "Operation",
        "shouDCF": "Manual triggering",
        "weiQY": "not enabled",
        "buTSDCF": "Cannot be manually triggered",
        "queDSDCF": "Confirm manual triggering",
        "caoZYB": "operation failed",
        "jinYGJBHYXGLDCJZT": "Disabled alarms will not affect the associated scene state",
        "queDYJYM": "Are you sure you want to disable it",
        "queRQY": "Confirm activation",
        "shanS": "Delete",
        "qingXJYGGJ": "Please disable this alarm first",
        "zaiSS": "Delete again",
        "queRSS": "Confirm deletion",
        "qingSRMC": "Please enter a name",
        "qingXZLX": "Please select the type",
        "qingXZJB": "Please select a level",
        "cuoDKSR": "Up to input",
        "qingPZGLDCJLD": "Please configure the associated scene linkage",
        "jiCPZ": "Basic configuration",
        "qingXBCJCPZ": "Please save the basic configuration first",
        "shiYXDSFPSXWLWPSXFZLQZSB": "Applicable to third-party platforms issuing command control devices to IoT platforms",
        "dingSCF": "Timed trigger",
        "shiYXDJZXGDLW": "Suitable for regularly executing fixed tasks",
        "sheBCF": "Device triggered",
        "shiYXSBSJYXWMJCFTJS": "Applicable when device data or behavior meets triggering conditions",
        "zhiXZDDDZ": "Execute the specified action",
        "jieB": "Unbind",
        "queDJB": "Confirm unbinding",
        "chuFFS": "Trigger method",
        "quXXZZJ": "Deselect Event",
        "baoCXZGLCJ": "Save the selected associated scene",
        "qingXZZSYTSJ": "Please select at least one piece of data",
        "gaoJMC": "Alarm name",
        "zhaK": "check",
        "dangQGJJLXX": "Current alarm record information",
        "gaoJJLDXQ": "Details of alarm records",
        "huoQXQLB": "Get a detailed list",
        "genJ": "according to",
        "chuSHSJ": "Initialize data",
        "gaoJSB": "Alarm equipment",
        "guanBMTTC": "Close modal pop ups",
        "xiangQ": "Details",
        "gaoJLS": "Alarm flow",
        "quanB": "whole",
        "jiLLB": "Record List",
        "jiT": "system",
        "renG": "artificial",
        "gaoJCL": "alarm handling",
        "guanBTC": "Close pop-up window",
        "chuLJL": "Processing Records",
        "qingSRCLJL": "Please enter the processing result",
        "cuoJGJSJ": "Recent alarm time",
        "gaoJZ": "In alarm",
        "moGJ": "No alarm",
        "chanPMC": "Product Name",
        "sheBMC": "Device Name",
        "zuZMC": "Organization Name",
        "chuLZHD": "After processing",
        "mo": "nothing",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "gaoJRZ": "Alarm Log",
        "guanBGJRZ": "Turn off alarm logs",
        "guanBCLJL": "Close processing records",
        "tuBSJ": "Chart data",
        "huiZTB": "Draw a chart",
        "cuoXGJ": "Latest alarm",
        "xianZKQAN": "Show shortcut buttons",
        "kuaiQANLB": "List of shortcut buttons",
        "jinR": "today",
        "jinYZ": "Last week",
        "jinYR": "In the past month",
        "jinYN": "In the past year",
        "jinRGJ": "Today's alarm",
        "gaoJTJ": "Alarm Statistics",
        "gaoJBM": "Alarm ranking",
        "dangRGJ": "Monthly alarm",
        "gaoJS": "Number of alarms",
        "chuDYS": "Color at",
        "queXW": "Default to",
        "chuSHZXTJ": "Initialize query conditions",
        "ru": "month",
        "ri": "day",
        "ning": "year",
        "gaoJQS": "Alarm trend",
        "wangLQQ": "Network request",
        "queR": "Confirm",
        "qingXJYZSS": "Please disable before deleting",
        "shuaXSJ": "refresh data",
        "qingSRSM": "Please enter a description",
        "weiPZGZDBTQY": "Unconfigured rules cannot be enabled",
        "qingXJYGCJ": "Please disable this scene first",
        "queRSDCF": "Confirm manual triggering",
        "chuFLX": "Trigger type",
        "yanCZX": "Delayed execution",
        "qingSRSJ": "Please enter the time",
        "miao": "second",
        "fen": "branch",
        "xiaoS": "hour",
        "hou": "after",
        "zhiXHXDZ": "Execute subsequent actions",
        "cenSMC": "Parameter Name",
        "zhi": "value",
        "qingXZ": "Please select",
        "shouDSR": "Manual input",
        "neiZCS": "Built in parameters",
        "dongZLX": "Action type",
        "qingXZDZLX": "Please select the action type",
        "gongTDY": "Function call",
        "qingXZGT": "Please select the function",
        "duQZX": "read attribute",
        "qingXZDQZX": "Please choose to read properties",
        "qingXZZX": "Please select attributes",
        "sheZZX": "set a property",
        "qingSRGTZ": "Please enter the function value",
        "qingXZSZZX": "Please choose to set properties",
        "zhuXZ": "Attribute value",
        "sheBLX": "Device offline",
        "chuangJSJ": "Creation time",
        "liX": "Offline",
        "zaiX": "Online",
        "xuanZFS": "Selection method",
        "qingXZFS": "Please choose a method",
        "guanJ": "relationship",
        "qingXZGJ": "Please select a relationship",
        "qingXZBQ": "Please select a label",
        "bianL": "variable",
        "qingXZCS": "Please select parameters",
        "shouCCZBQSJ": "Initial operation of label data",
        "huiXWT": "Echo problem",
        "ziDY": "custom",
        "ziDYXZDQCPXDLYSB": "Customize the selection of any device under the current product",
        "anGJ": "By relationship",
        "xuanZYCFSBJYXTGJDSB": "Select devices with the same relationship as the triggering device",
        "anBQ": "By label",
        "anBQXZCPXJYTDBQDSB": "Select devices with specific labels under the product by label",
        "anBL": "By variable",
        "xuanZSB": "Select device",
        "weiSLBLZDSB": "Equipment for upstream variable values",
        "panDXTCPCYABL": "Only by variable can the same product be judged",
        "biaoQ": "label",
        "qiHSB": "Switching devices",
        "bianLXZ": "variable selection",
        "bingJ": "also",
        "yuZ": "perhaps",
        "wei": "by",
        "qingXZSB": "Please select a device",
        "biaoQXZ": "Label selection",
        "pi": "no",
        "zhiXDZ": "Execute action",
        "xuanZCP": "Select product",
        "shangYB": "Previous step",
        "xiaYB": "Next step",
        "zhuXGT": "Attribute function",
        "sheZGT": "Setting functions",
        "xuanZQBS": "Selector identification",
        "chuFSB": "Trigger device",
        "zhiX": "implement",
        "duQ": "read",
        "sheZ": "set up",
        "qingXZCP": "Please select a product",
        "xieRFS": "Access method",
        "weiXR": "Not connected",
        "panDSPWDYC": "Determine if it is the first time",
        "wangGLX": "Gateway Type",
        "zhiLSB": "Direct connected devices",
        "wangGZSB": "Gateway sub equipment",
        "wangGSB": "Gateway device",
        "fenL": "classification",
        "suoZZZ": "Affiliated organization",
        "chuanX": "serial",
        "anSXYCZXDZ": "Execute actions in sequence",
        "shiYXJXDZSCCS": "Applicable to action based output parameters",
        "panDSPZXHXDZDCJ": "Scenario for determining whether to execute subsequent actions",
        "bingX": "parallel",
        "tongSZXSYDZ": "Execute all actions simultaneously",
        "shiYXBRYGZZXDZXHSXHJLDCJ": "Suitable for scenarios that do not require attention to the sequence and results of executing actions",
        "jiaoYDQZXDZDSBYZCPSPSS": "Verify whether the device or product currently executing the action has been deleted",
        "chanPSSS": "Product has been deleted",
        "zhiXZYGSB": "Select only one device",
        "siSBWMXWZ": "Based on the equipment model",
        "meiYGSBBSS": "A certain device has been deleted",
        "ruLSABL": "If it is by variable",
        "jiaoYSYGSBSCDCP": "Verify the product output from the previous device",
        "panDZ": "Judgment value",
        "jiaoYDQZXDZDTZPZ": "Verify the notification configuration for the current execution action",
        "xiaoXMBSPSS": "Delete message template",
        "tongZPZ": "Notification Configuration",
        "tongZMB": "Notification template",
        "panDCSSPFSBH": "Determine whether the parameters have changed",
        "weiXYH": "WeChat Users",
        "dingDYH": "DingTalk Users",
        "huoQPSYH": "Obtain platform Users",
        "pingSYH": "Platform Users",
        "guanJYH": "Related Users",
        "sheBSC": "Device output",
        "bingJYZ": "And it has value",
        "dingYSYG": "Subscribe to the previous one",
        "caoZF": "Operator",
        "cenSZ": "Parameter values",
        "tiaoJZ": "Conditional value",
        "moRSDSRXL": "Default manual input dropdown",
        "chuL": "handle",
        "ruLCSLXWFSBH": "If the parameter type has not changed",
        "zeBXGCZFSJZ": "Do not modify operators and values",
        "xiuGCZF": "Modifying Operators",
        "ruLSCDZ": "If the last value",
        "zai": "stay",
        "zhong": "in",
        "zeBB": "Then remain unchanged",
        "fenZ": "grouping",
        "gaiSJSFSBG": "The data has changed",
        "qingTXPZ": "Please reconfigure",
        "qingXZCZF": "Please select an operator",
        "qingXZYSRCSZ": "Please select or enter parameter values",
        "shuJFSBH": "Data changes",
        "manJTJHQCF": "Once the conditions are met, it will trigger",
        "guanLCCJDGJ": "Associate alarms for this scenario",
        "manJTJHQJS": "Once the conditions are met, it will be lifted",
        "tongG": "adopt",
        "qunJQRXX": "Group robot messages",
        "faS": "send",
        "dingD": "DingTalk",
        "xiang": "towards",
        "weiX": "WeChat",
        "youJ": "mail",
        "yuY": "speech",
        "duanX": "message",
        "di": "of",
        "yu": "Related to",
        "juYXT": "Having the same",
        "sheBD": "Equipment",
        "dianJPZZXDZ": "Click to configure and execute actions",
        "manJCTJHZXHXDZ": "Execute subsequent actions after meeting this condition",
        "tianJGLTJ": "Add filtering conditions",
        "faBXX": "Release messages",
        "qingKSYGCXZXDZZD": "Clear the previous serial execution action",
        "huo": "and",
        "tianJZXDZ": "Add execution action",
        "zhiXBJZXDSJ": "Directly edit and execute building data",
        "peiZSBDYGT": "Configure device call function",
        "sheZZXGZ": "Set attribute rules",
        "xiaoXTZ": "Message notification",
        "peiZXZDYHFYJ": "Configure sending emails to specified Users",
        "duanXDTZ": "SMS and other notifications",
        "dengDYDSJH": "After waiting for a period of time",
        "zaiZXHXDZ": "Execute subsequent actions again",
        "chuFGJ": "Trigger alarm",
        "peiZCFGJGZ": "Configure triggering alarm rules",
        "ruPH": "Cooperation required",
        "shiY": "apply",
        "jieSGJ": "Release alarm",
        "peiZJSGJGZ": "Configure alarm release rules",
        "tongZFS": "Notification method",
        "muBBL": "Template variables",
        "yingY": "application",
        "qingXZTZFS": "Please choose the notification method",
        "qingXZTZPZ": "Please select notification configuration",
        "qingXZTZMB": "Please select a notification template",
        "zanMMBBL": "There are currently no template variables available",
        "cuoD": "most",
        "qingSRSJR": "Please enter the recipient",
        "qingSRZQDYXDZ": "Please enter the correct email address",
        "qingSRZQDSJXM": "Please enter the correct phone number",
        "qingSZWJ": "Please upload the file",
        "shangZBJ": "Upload attachments",
        "qingSZZQLSTP": "Please upload the correct format image",
        "tuPTXBXXX": "The image size must be smaller than",
        "qingXZZZ": "Please select an organization",
        "guDYX": "Fixed email",
        "guDXM": "Fixed number",
        "qingXZSXR": "Please select the recipient",
        "qingSRSJRYX": "Please enter the recipient's email address",
        "duoGSJRYHXFJ": "Multiple recipients separated by line breaks",
        "qingSRGDXM": "Please enter a fixed number",
        "guanLGJSL": "Number of associated alarms",
        "shuJHX": "Data Echo",
        "qingAZ": "Please follow",
        "luoSSR": "Format input",
        "shiPWZBZ": "Is it an indicator value",
        "huiX": "Echo",
        "chuLZBZHX": "Processing indicator value echo",
        "zhiBZ": "Indicator value",
        "kaiQFD": "Enable anti shake",
        "guanBFD": "Turn off anti shake",
        "miaoNFS": "Send within seconds",
        "ciJSSS": "At or above times",
        "diYC": "First time",
        "cuoHYC": "Last time",
        "dang": "When",
        "piZ": "otherwise",
        "gaiCZQQKQYSYPZTJ": "This operation will clear all other conditions",
        "zhiSPZYGZXDZ": "Configure at least one execution action",
        "huoQDYZBZ": "Obtain corresponding indicator values",
        "huanCZBZ": "Cache metric value",
        "leiXFSBH": "Type changes",
        "chuFTJ": "Trigger conditions",
        "kai": "Open",
        "guan": "Close",
        "anZ": "Weekly",
        "anR": "monthly",
        "biaoTS": "expression",
        "zhouJZX": "Periodic execution",
        "zhiXYC": "Execute once",
        "mei": "each",
        "qingSRZQD": "Please enter the correct",
        "pinLSWQH": "Frequency unit switching",
        "xingJY": "Monday",
        "xingJE": "Tuesday",
        "xingJS": "Thursday",
        "xingJW": "Friday",
        "xingJL": "Saturday",
        "xingJR": "Sunday",
        "biaoZQ": "Before",
        "lianX": "continuity",
        "buLX": "Discontinuity",
        "zhiSSWLX": "At least three consecutive digits",
        "xiao": "Number",
        "meiT": "Every day",
        "meiZ": "weekly",
        "meiR": "monthly",
        "deng": "etc.",
        "tian": "day",
        "ci": "second",
        "quanX": "Select All",
        "dianJPZSBCF": "Click to configure device triggering",
        "chuFGZ": "Trigger Rule",
        "tuoZCS": "Expand parameters",
        "siSB": "Device",
        "suoYD": "All",
        "shangX": "Go live",
        "zhuXSB": "Attribute reporting",
        "shiPXZFSWSB": "Do you want to choose device as the method",
        "qingXZBM": "Please select department",
        "xuanZFSWSBJFXZYGSBS": "When the selection method is device and only one device is selected",
        "wuMXQGSB": "Take the device from the physical model",
        "panDCPSPSS": "Determine whether the product has been deleted",
        "huoQDQCPWMX": "Obtain the current product model",
        "panDSBSPSS": "Determine whether the device has been deleted",
        "panDWMX": "Judgment object model",
        "chanPXDSYSB": "All devices under the product",
        "anZZ": "By organization",
        "xuanZCPXGZXJBZZDSB": "Select equipment under the product that belongs to a specific organization",
        "qingPZSBCFGZ": "Please configure device triggering rules",
        "panDCP": "Judging products",
        "panDSBYZZZ": "Determine equipment or organization",
        "panDZX": "Determine attributes",
        "ziJ": "event",
        "gongT": "function",
        "dingSDYSXGT": "Regularly call the selected function",
        "huoQDQXZGTZX": "Get the current selection feature properties",
        "baiX": "Sort",
        "dingSDQSXZXZ": "Timed reading of selected attribute values",
        "geZX": "Attributes",
        "qingXZZJ": "Please select an event",
        "sheBSX": "Device online",
        "shangB": "Reporting",
        "ziJSB": "Event reporting",
        "xiuGZX": "modify attribute",
        "qingSRXGZ": "Please enter the modification value",
        "dingSDYSXZX": "Timed invocation of selected attributes",
        "xiuG": "modify",
        "qingXZZXZ": "Please select attribute values",
        "qingSRZXZ": "Please enter attribute values",
        "qingXZCFFS": "Please select the triggering method",
        "qingPZDSCFGZ": "Please configure timed trigger rules",
        "dianJPZDSCF": "Click to configure timed triggering",
        "faB": "release",
        "chuFLXW": "Trigger type is",
        "shiBTWK": "Time cannot be empty",
        "sheBCFPZ": "Device trigger configuration",
        "zhiXDZMYGCS": "The execution action does not have this parameter",
        "fangDPZ": "Anti shake configuration",
        "tuoZXX": "Expand information",
        "dingSCFPZ": "Timed trigger configuration",
        "shouDSRZ": "Manually entering values",
        "gongTDYSSY": "Use when calling functions",
        "duQZXSSY": "Use when reading properties",
        "duQZXSW": "When reading properties:",
        "shuZ": "array",
        "sheZZXSW": "Set properties to",
        "laiYW": "Source is",
        "zhiXQLXW": "The actuator type is",
        "zhongD": "Middle",
        "yongXSS": "Used for deletion",
        "jieGYTYZXTJXT": "The structure is the same as the general query conditions",
        "tiaoJSJLZXK": "Conditional data comes from the interface",
        "genJCFQJSCZCDTJL": "Resolve supported condition columns based on triggers",
        "dongZFZ": "Action branch",
        "yongXQDCCYSXRSJ": "Used for front-end storage of some rendering data"
    },
    "system": {
        "jiCCS": "Integrated menu",
        "qingXZJCJT": "Please select an integrated system",
        "dangQJCCS": "Current integrated menu",
        "tanCXG": "Pop up related",
        "caoZCG": "Operation successful",
        "qingGXPZCS": "Please check the configuration menu",
        "qingXZSZJT": "Please select the system you belong to",
        "yuJCJTGLDCS": "Menu associated with integrated system",
        "huoQDQYHKFWCS": "Get the menu that the current User can access",
        "huoQJCJTXX": "Get integrated system options",
        "shuXZZJ": "Tree selection event",
        "guoLJD": "Filter nodes",
        "moRDSFJD": "Default with parent node",
        "buFHTJDZXKN": "Cut off those that do not meet the conditions directly",
        "fuHTJDBL": "Eligible reservations",
        "bingJRYDGCLQZJD": "And it needs to recursively process its child nodes",
        "xinC": "Create",
        "yingYGLQDGYYJTDDLJHWYCDL": "Application management simplifies the login of multiple application systems into a single login",
        "shiXDCFW": "Implement multiple access points",
        "jiZGQDYWCJ": "Centralized control of business scenarios",
        "leiX": "Type",
        "shuiM": "Explain",
        "neiBDWYY": "Internal independent application",
        "weiXWZYY": "WeChat website application",
        "neiBJCYY": "Internal integrated applications",
        "dingDQYNBYY": "Internal Application of DingTalk Enterprises",
        "diSFYY": "Third party applications",
        "mingC": "Name",
        "zhuangT": "State",
        "zhengC": "Normal",
        "jinY": "Disabled",
        "caoZ": "Operation",
        "bianJ": "Edit",
        "qiY": "Enable",
        "queR": "Confirm",
        "shanS": "Delete",
        "qingXJYZSS": "Please disable before deleting",
        "queRSS": "confirm deletion",
        "qiT": "other",
        "youJCCSQW": "Has integrated menu permission",
        "you": "have",
        "caoZQW": "Operation permissions",
        "fuQ": "Empowerment",
        "zhaK": "check",
        "qiTBWK": "Other not empty",
        "gaiS": "summary",
        "neiBDWYYSYXQ": "Internal independent application is suitable for integrating",
        "guanFKF": "Official development",
        "diQTYYY": "Other applications and",
        "wuLWPSXHJC": "Integration of IoT platforms with each other",
        "liRQKSHPSJCZWLWPS": "For example, integrating visualization platforms into IoT platforms",
        "yuZQWLWPSJCZKSHPS": "Or integrate IoT platforms into visualization platforms",
        "siSXDCFW": "To achieve multiple access points",
        "neiBDWYYD": "Internally independent application",
        "houDFW": "Backend services",
        "xiangH": "mutual",
        "duWYX": "Independent operation",
        "huBYX": "Not affecting each other",
        "xieRFSSM": "Access method description",
        "yeMJC": "Page Integration",
        "jiCQTYYD": "Integrate with other applications",
        "qianDYM": "Front end page",
        "zhiWLWPSZ": "To the IoT platform",
        "weiSXYYYWLWPSSJHLHT": "To achieve data interconnection and interoperability between applications and IoT platforms",
        "tongCHRYPZ": "Usually, configuration is also required",
        "fuW": "service",
        "qiaHD": "client",
        "wuLWPS": "IoT platform",
        "qingQ": "request",
        "qiTYY": "Other applications",
        "diXK": "Interface for",
        "siSXQWLWPSJCZQTYYJT": "To achieve the integration of IoT platforms into other application systems",
        "ruRSX": "To achieve",
        "deLHKSFW": "After logging in, you can access",
        "yeM": "page",
        "haiRYPZSDDL": "We also need to configure single sign on",
        "waiBYY": "External applications",
        "shiXWLWPSDFWDYTL": "Implement service invocation capabilities for IoT platforms",
        "tongCHRYPZYMJC": "Usually, page integration also needs to be configured",
        "peiZ": "allocation",
        "fuWH": "After service",
        "jiTQ": "The system will",
        "ziDCJ": "Automatic creation",
        "duiYD": "Corresponding",
        "yongH": "User",
        "yongHD": "User's",
        "zhangX": "Account",
        "miM": "password",
        "fenBDY": "Corresponding to each other",
        "diSFYH": "Third party Users",
        "keDYD": "Callable",
        "zaiQYYGLQPD": "In its application management card",
        "jinX": "carry out",
        "ziDYPZ": "Custom Configuration",
        "shanDDL": "Single sign on",
        "tongG": "adopt",
        "diSFPSZX": "Third party platform account",
        "deLDWLWPS": "Log in to the IoT platform",
        "neiBJCYYSYXQ": "Internal integrated applications are suitable for integrating",
        "houDFWZTYGHJYX": "Backend services running in the same environment",
        "jiCHJTDBQXCDYDYYGLCS": "After integration, a corresponding application management menu will be added at the top of the system",
        "quQQQTYYDXK": "Request interfaces from other applications",
        "siSXQWLWPSJCZQTYY": "To achieve the integration of IoT platforms into other applications",
        "dingDKFPS": "DingTalk Open Platform",
        "dingDQYNBYYSYXTGDDDL": "The internal application of DingTalk Enterprise is suitable for logging in through DingTalk",
        "tongGDDZXDLDWLWPS": "Log in to the Internet of Things platform through a DingTalk account",
        "weiXKFPS": "WeChat Open Platform",
        "weiXWZYYSYXTGWXSQDL": "WeChat website application is suitable for logging in through WeChat authorization",
        "tongGWXZXDLDWLWPS": "Log in to the Internet of Things platform through WeChat account",
        "diSFYYSYX": "Third party applications are suitable for",
        "yu": "Related to",
        "liRQGCYWGLJTJCZWLWPS": "For example, integrating the company's business management system into the Internet of Things platform",
        "yuZQWLWPSJCZYWGLJT": "Or integrate the IoT platform into the business management system",
        "haiRYPZ": "Further configuration is required",
        "fuWPZ": "Service configuration",
        "qingQWLWPSXK": "Request IoT platform interface",
        "shiX": "achieve",
        "diFWDYTL": "Service invocation capability of",
        "qingSRMC": "Please enter a name",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "yingY": "application",
        "qingXZYY": "Please select an application",
        "xieRFS": "Access method",
        "qingXZXRFS": "Please select the access method",
        "qingSRXRDZ": "Please enter the access address",
        "xieRDZ": "Access address",
        "chenXFWQYPSDDZ": "Fill in the address to access other platforms",
        "luoYFS": "Routing method",
        "qingXZLYFS": "Please choose a routing method",
        "cenS": "parameter",
        "ziDYCS": "Custom parameters",
        "luoS": "format",
        "yongHM": "User name",
        "qingSRXKDZ": "Please enter the interface address",
        "xieKDZ": "Interface address",
        "fangW": "access",
        "fuWDDZ": "Service address",
        "qingSRSQDZ": "Please enter the authorized address",
        "shouQDZ": "Authorized address",
        "renZSQDZ": "Authentication authorization address",
        "qingSR": "Please enter",
        "deZ": "address",
        "sheZ": "set up",
        "lianPDDZ": "Token address",
        "huiDDZ": "token url",
        "shouQWCHTZDJBYMDHDDZ": "After authorization is completed, redirect to the callback address of the specific page",
        "qingSRHDDZ": "Please enter the callback address",
        "diSFYYWYBS": "Unique identifier for third-party applications",
        "diSFYYWYBSDMY": "Key uniquely identified by third-party applications",
        "renZFS": "Authentication method",
        "jiBRZ": "Basic certification",
        "renZ": "authentication",
        "qingQFS": "Request method",
        "qingXZQQFS": "Please select the request method",
        "qingQB": "Request Body",
        "qingQT": "Request header",
        "yingYWYBS": "Application unique identification",
        "yingYWYBSDBY": "Apply a unique identification key",
        "qingSRYHM": "enter one User name",
        "qingSRMM": "Please enter password",
        "qingSRQQT": "Please enter the request header",
        "genJBTYYDDYGF": "According to the calling specifications of different applications",
        "ziDYQQTNY": "Custom request header content",
        "qingSRCS": "Please enter parameters",
        "diSFYYWYBSPPDBY": "Third party application unique identification matching key",
        "qingXZJS": "Please select a role",
        "jiaoS": "role",
        "weiYYYHFPJS": "Assign roles to application Users",
        "genJBDDJS": "Based on the bound role",
        "jinXJTCSFQ": "Empowering system menus",
        "zuZ": "organization",
        "weiYYYHFPSZZZ": "Assign organization to application Users",
        "genJBDDZZ": "According to the bound organization",
        "jinXSJJL": "Perform data isolation",
        "qingXZZZ": "Please select an organization",
        "shouQHZDTZDYMDZ": "Page address automatically redirected after authorization",
        "boMS": "Whitelist",
        "duoGDZHCFJ": "Multiple addresses separated by carriage returns",
        "buCMRYKFW": "Accessible without filling in default",
        "qingXZRZFS": "Please choose the authentication method",
        "wenZYHFWYYCXDQW": "Restrict User access to applications",
        "dianJSZTP": "Click to upload image",
        "yongHXXDZ": "User Information Address",
        "qingSRYHXXDZ": "Please enter the User information address",
        "qingSRYH": "Please enter the User",
        "biaoTSCSQJLZHQDSFPSYHDWYBS": "Expression retrieves the unique identifier of a third-party platform User from the authorization result",
        "shuRCYHXXXKFHSJZDYH": "Input the User returned from the User information interface",
        "ziD": "field",
        "zhiL": "Example",
        "shuRCYHXXXKFHSJZDYHMZD": "Enter the Username field returned from the User information interface",
        "touX": "Avatar",
        "shuRCYHXXXKFHSJZDYHTXZD": "Input the User avatar field returned from the User information interface",
        "feiDSFYY": "Non third-party applications",
        "feiDD": "Non DingTalk",
        "feiWX": "Non WeChat",
        "dingD": "DingTalk",
        "weiX": "WeChat",
        "yingYDWYBSDBY": "The key that uniquely identifies the application",
        "ziDCJYH": "Automatically create Users",
        "kaiQH": "After opening",
        "diSFYHDYCSQDLJTS": "When third-party Users are authorized to log in to the system for the first time",
        "moRJRSQBDYM": "No need to enter the authorization binding page",
        "jiTMRCJYGXYHYZBD": "The system defaults to creating a new User and binding it to it",
        "yongHMQZ": "User Name Prefix",
        "qingSRYHMQZ": "Please enter the Username prefix",
        "moRMM": "Default password",
        "qingSRMRMM": "Please enter the default password",
        "cuoSSR": "Minimum input",
        "qingSRSM": "Please enter a description",
        "baoC": "Save",
        "chuSHBS": "Initialize form",
        "qingQCS": "Request parameters",
        "keXZ": "Optional values",
        "shouQXX": "Authorization information",
        "jiBXX": "Basic information",
        "xinX": "information",
        "tongDXDZ": "Redirect Address",
        "qiaHDMY": "Client Key",
        "zhuXM": "Attribute Name",
        "miY": "secret key",
        "tongDX": "redirect",
        "jiaoSLB": "Role List",
        "buMLB": "Department List",
        "shiPQY": "Is it enabled",
        "tongYSDDLJC": "Unified single sign on integration",
        "shanDDLPZ": "Single Sign On Configuration",
        "yongHXXXK": "User Information Interface",
        "yongHZXZDXX": "User attribute field information",
        "youX": "Mailbox",
        "dianH": "telephone",
        "weiXSDDLPZ": "WeChat Single Sign On Configuration",
        "dingDSDDLPZ": "DingTalk Single Sign On Configuration",
        "shiPZDCJPSYH": "Automatically create platform Users",
        "qianZ": "prefix",
        "ziDCJPSYHSJSLB": "List of roles when automatically creating platform Users",
        "ziDCJPSYHSBMLB": "List of departments when automatically creating platform Users",
        "xieRFSJX": "Access method mirror",
        "tiDMBSY": "Folding panel usage",
        "zuZLB": "Organizational List",
        "qingQTHCSBCYZ": "Request header and parameters must be filled in for verification",
        "qingSRWZDQQT": "Please enter the complete request header",
        "qingSRWZDQQCS": "Please enter the complete request parameters",
        "xieRFSDXX": "Options for access methods",
        "xinCS": "When adding",
        "qiHYYLX": "Switching application types",
        "qingKGYZDDZ": "Clear the values of common fields",
        "houDFHD": "Backend returned",
        "huo": "and",
        "zhong": "in",
        "zhuanW": "Convert to",
        "zhaXXQ": "Query details",
        "fuZCSZD": "Assign initial field",
        "jieJBJGBXRFSBCDWT": "Resolve the issue of editors changing access methods and reporting errors",
        "huoQJSLB": "Get Role List",
        "huoQZZLB": "Get organization list",
        "tianJJS": "Add Role",
        "shanSDTDCS": "Delete excess parameters",
        "zhiXZL": "Only selected",
        "fuWHSDDL": "Services and Single Sign On",
        "yuZZXZLSDDL": "Or only single sign on was selected",
        "peiZSDDLRTSPZ": "Single sign on configuration requires simultaneous configuration",
        "qiaFD": "Customer service end",
        "duWYY": "Independent application",
        "ruGW": "To be changed to",
        "zhuanGHD": "Send to backend",
        "baoCCG": "Save successful",
        "ruLCXJYCGJZYBLBZ": "If this verification is successful and in the failure list",
        "zeCCLBZYS": "Remove from this list",
        "fanZZJS": "On the contrary, add",
        "qingSZ": "Please upload",
        "luoSDTP": "Format image",
        "tuPTXBXXX": "The image size must be smaller than",
        "shangZYB": "Upload failed",
        "qingSHZS": "Please try again later",
        "yanZ": "validate",
        "heFX": "Legitimacy",
        "buSZQD": "Not correct",
        "gaiZDWBCZD": "This field is mandatory",
        "ciCBLRYYZ": "The table here needs to be verified",
        "weiZDYZMFTG": "Unable to pass field validation",
        "gaiW": "Change to",
        "meiYXDCL": "Each item has been filled in",
        "yuZMXDMYC": "Or maybe none of the items were filled in",
        "yunWYZTG": "All verified as passed",
        "piZYWWTG": "Otherwise, it will be considered as failure",
        "ruLSXCXCZ": "If it is a new row operation",
        "ruoXCHHCXXYY": "If a new page appears after adding",
        "zeTZDCXDYY": "Then jump to the latest page",
        "ruLSSSXCZ": "If it is a delete row operation",
        "ruoSSDXSBYDCHYX": "If the deleted line is the last line on this page",
        "juBYBSDYY": "And this page is not the first page",
        "zeTZDSYY": "Then jump to the previous page",
        "renZPZ": "Authentication configuration",
        "qianMFS": "Signature method",
        "jiTMC": "System Name",
        "qingSRJTMC": "Please enter the system name",
        "zhuTS": "Theme color",
        "boS": "white",
        "heiS": "black",
        "gaoD": "Gaode",
        "peiZHPSKDYGDDT": "After configuration, the platform can call Gaode Map",
        "qingSRGD": "Please enter Gaode",
        "jiTHSFWD": "System backend access",
        "qianDSZFWQ": "Front end server",
        "qianDBLDFWDK": "Front end exposed service ports",
        "jiT": "system",
        "dianJXG": "Click to modify",
        "tuiJCC": "Recommended size",
        "zhiC": "support",
        "liuLQYQ": "Browser tab",
        "liuLQ": "browser",
        "yeZXZDTPYS": "Image elements displayed on the page",
        "deLBJT": "Login background image",
        "siNDTP": "Images within",
        "jianYCC": "Suggested size",
        "anZPYSRSDDZ": "Install package or enter store address",
        "shangZ": "upload",
        "banBX": "Version number",
        "shangDDZ": "Store address",
        "luoSDWJ": "Format files",
        "shangZCG": "Upload successful",
        "jiTSZ": "System prompt",
        "jiTWZCW": "Unknown system error",
        "qingFKGGLY": "Please provide feedback to the administrator",
        "mingCBC": "Name is mandatory",
        "qingXZZTS": "Please select a theme color",
        "jiaZZT": "Load Status",
        "beiJTJZZT": "Background image loading status",
        "yeQJZZT": "Tab loading status",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "luoSJY": "format checks",
        "liuLQYQLSJY": "Browser tab format verification",
        "shangZGBZJ": "Upload change event",
        "beiJSZGBZJ": "Background upload change event",
        "beiJTSZYB": "Background image upload failed",
        "liuLQYQSZGBZJ": "Browser tab upload change event",
        "liuLQYQSZYB": "Browser tab upload failed",
        "jiBXXBS": "Basic Information Form",
        "tuPSZBS": "Image upload form",
        "queRDC": "Confirm export",
        "queD": "Confirm",
        "quX": "Cancel",
        "daoC": "Export",
        "xuX": "Index",
        "qiaHZH": "customer's account",
        "lianJDZ": "Contact address",
        "chuangJSJ": "Creation time",
        "gengXSJ": "Update time",
        "xiaoS": "search",
        "qiaHLB": "Customer List",
        "qingXZLX": "Please select the type",
        "yuZ": "perhaps",
        "lianXDZ": "Link address",
        "guanLDZ": "Manage addresses",
        "qingSRGLDZ": "Please enter the management address",
        "qingSRLXDZ": "Please enter the link address",
        "xuNY": "Virtual Domain",
        "qingSRXNY": "Please enter the virtual domain",
        "bianJSJY": "Edit Data Source",
        "xinCSJY": "Add data source",
        "qingSRZQD": "Please enter the correct",
        "guanLDZJY": "Manage address verification",
        "qingSRZQDGLDZ": "Please enter the correct management address",
        "lianXDZJY": "Link address verification",
        "qingSRZQDLXDZ": "Please enter the correct link address",
        "zanBZCGLGT": "Management function not currently supported",
        "guanL": "Administration",
        "qingXQYSJY": "Please enable the data source first",
        "queDY": "Are you sure you want to",
        "ma": "Is it",
        "qingXJY": "Please disable first",
        "zaiSS": "Delete again",
        "biaoLSL": "Table instance",
        "daKBJTC": "Open the editing pop-up window",
        "shuaXLB": "Refresh List",
        "shiY": "apply",
        "yongXJJYBJZSJHBZKDWT": "Used to solve the problem of not expanding data after asynchronous loading",
        "qingSRLX": "Please enter the type",
        "qingXZSPBTWK": "Please select whether it cannot be empty",
        "shi": "yes",
        "pi": "no",
        "xinCX": "Add Line",
        "buTSSZKT": "Cannot start with a number",
        "mingCZTYSZ": "The name can only consist of numbers",
        "ziW": "letter",
        "xiaHX": "Underline",
        "zhongHXZC": "Middle line composition",
        "liM": "Column names",
        "zhangZ": "length",
        "jingZ": "accuracy",
        "buTWK": "Cannot be empty",
        "qingPZSJYZD": "Please configure the data source fields",
        "shuJKZD": "Database Fields",
        "bangD": "binding",
        "zhiTFPY": "Can only be allocated with",
        "hongX": "sharing",
        "quanWDZCSJ": "Asset data for permissions",
        "piLPZ": "Batch configuration",
        "kai": "Open",
        "guan": "Close",
        "ziCQW": "Asset permissions",
        "ziCZXCS": "Number of asset consultations",
        "chanPFPHZDJRDSBZC": "Equipment assets automatically entered after product allocation",
        "diYCRYDSCP": "The first time you need to bring the product",
        "zhaX": "Query",
        "qingXGXSJ": "Please check the data first",
        "baoCS": "When saving",
        "guoLMYDQW": "Filter permissions that are not available",
        "fenPCPZCH": "After allocating product assets",
        "jinRSBZCFP": "Enter equipment asset allocation",
        "xuanZXD": "Selected items",
        "jiuXZXD": "Old selected items",
        "xuanZX": "Selected items",
        "liBDQKB": "A light copy of a list",
        "qiYPLSZ": "Enable batch settings",
        "qiangSGXDQWHPLSZDQWJXHB": "Merge selected permissions with batch set permissions",
        "bingYZQKXDQWJXBD": "And compare it with your optional permissions",
        "quJJZWDQXZDQW": "Take intersection as the currently selected permission",
        "jinYSDGX": "Disable individual check boxes",
        "quXPLSZ": "Cancel batch settings",
        "fangKZQQWDGXWZ": "Unleash the check restrictions on one's own permissions",
        "zhaKWBX": "View as mandatory",
        "quXGXSCF": "Trigger when unchecked",
        "naDQXXZDXD": "Obtain the unselected item",
        "qiangQXGXDXDQWTZ": "Reset the permissions of unchecked items",
        "liBQXQBXZ": "List Deselect All",
        "xuanZ": "Select",
        "ruoGXDKXQWZMYFXQW": "If the optional permissions for this item do not include sharing permissions",
        "zeBZCLHCZ": "No operations are supported",
        "gaiZCBZCHX": "This asset does not support sharing",
        "quXQX": "Deselect All",
        "huoQBZLSJ": "Obtain and organize data",
        "ziCQWBX": "Asset permission sorting",
        "ziCBX": "Asset sorting",
        "chanPDZTJXZHCL": "Convert the status of the product",
        "zhengLCSBHQSJ": "Organize parameters and obtain data",
        "feiSB": "Non equipment",
        "chanP": "Product",
        "buCZ": "Not present",
        "youQTZXCZ": "There are other query operations",
        "sheBYMSDDJZCFP": "Manually click on asset allocation on the device page",
        "yunSSCPDRD": "Delete all products brought in",
        "shangJZZ": "Superior organization",
        "qingXZSJZZ": "Please select a superior organization",
        "baiX": "Sort",
        "qingSRBX": "Please enter sorting",
        "zaiGDDSZTG": "Through the given tree",
        "piP": "matching",
        "qiangCSXDSYJDJY": "Disable all nodes under this tree",
        "biaoSXG": "Form related",
        "xinCZZZ": "Add sub organizations",
        "shangJZZXZGB": "Superior organization chooses to change",
        "dangQBJDZZBX": "The current edited organization sorting",
        "weiXZSJZZDCTBX": "To select the maximum sorting for the superior organization",
        "ruSJZZMYZZZ": "If the superior organization does not have self-organization",
        "zeMRW": "Then default to",
        "qingSRZZMC": "Please enter the organization name",
        "queDYSSM": "Are you sure you want to delete it",
        "zanMSJ": "There is currently no data available",
        "bianJTC": "Edit Popup",
        "xiaoSNY": "Search content",
        "shuJJZZT": "Data loading status",
        "yuanSJ": "Source data",
        "shuJD": "Data",
        "banB": "Version",
        "zhanZDSJ": "Displayed data",
        "dangQXZDX": "The currently selected item",
        "baoCYSJ": "Reporting source data",
        "qiangSXJGZHW": "Convert the tree structure to",
        "jieG": "structure",
        "diYCBYJXGL": "No need to filter for the first time",
        "qiangSXSZZHW": "Convert a tree array to",
        "xingS": "Form",
        "siBSXSCZ": "To facilitate filtering operations",
        "shanSBM": "Delete Department",
        "tanC": "pop-up notification",
        "jiSMRBXZ": "Calculate default sorting values",
        "weiZLBZCTDBXZ": "Sort the largest value in the sublist",
        "shiPJXFPCPXDJBSB": "Do you want to continue allocating specific equipment under the product",
        "ziCFP": "Asset allocation",
        "piLCZ": "Batch operations",
        "shiPPLJSBD": "Whether to unbind in bulk",
        "piLJB": "Batch unbinding",
        "piLBJ": "Batch editing",
        "shiPJSBD": "Unbind or not",
        "suoZCP": "Belonging products",
        "zhuCSJ": "Registration time",
        "liX": "Offline",
        "zaiX": "Online",
        "jieSBD": "Unbind",
        "huoQQWSJZD": "Obtain permission data dictionary",
        "huoQQWMC": "Obtain permission name",
        "quNSJ": "Remove data",
        "sheBZCFPTCCZLX": "Equipment asset allocation pop-up operation type",
        "shouDDJZCFPAN": "Manually click on the asset allocation button",
        "chanPZCFPH": "After product asset allocation",
        "ziDTCSBZCFP": "Automatically pop up device asset allocation",
        "qingGXRYBJDSJ": "Please check the data that needs to be edited",
        "qingGXRYJBDSJ": "Please check the data that needs to be unbound",
        "sheB": "Equipment",
        "qingXZYBDDYH": "Please select the User to bind",
        "xingM": "Name",
        "shouDXZGB": "Manually selecting changes",
        "fenYH": "Pagination meeting",
        "quXXZ": "Deselect",
        "bangDYH": "Bind Users",
        "xiaoSCS": "Search parameters",
        "biaoL": "Form",
        "jiCXX": "Basic information",
        "qingPZ": "Please configure",
        "peiZCG": "Configuration successful",
        "daoJSJ": "Expiration time",
        "bianM": "coding",
        "qingSRBM": "Please enter the code",
        "quanW": "authority",
        "qingXZQW": "Please select permissions",
        "gengX": "update",
        "caiSTB": "Menu icons",
        "buXZ": "Do not display",
        "ruYGL": "Need to filter",
        "qingSRQWMC": "Please enter the permission name",
        "quanWMC": "Permission Name",
        "quanWCZ": "Permission operation",
        "huoQQWLB": "Get permission list",
        "quanX": "Select All",
        "shanX": "Single Choice",
        "qingSZTB": "Please upload the icon",
        "dianJXZTB": "Click on the selection icon",
        "yeMDZ": "Page address",
        "qingSRYMDZ": "Please enter the page address",
        "ziF": "character",
        "qingZQCXDZ": "Please fill in the address correctly",
        "si": "with",
        "kaiT": "start",
        "qingSRTX": "Please enter a value greater than",
        "diZS": "Integer of",
        "quanWPZ": "Permission configuration",
        "shuJQWQZ": "Data permission control",
        "ciCSYMSJSDYDZCLX": "The asset type corresponding to the data on this menu page",
        "buZC": "Not supported",
        "jianXQZ": "Indirect control",
        "ciCSNDSJJXQTCSDSJQWQZ": "The data in this menu is based on the data permission control of other menus",
        "qingXZZCLX": "Please select asset type",
        "qingXZGLCS": "Please select the associated menu",
        "luoY": "route",
        "biaoS": "identification",
        "guanLCS": "Context menu",
        "ziCLX": "Asset type",
        "yuanBD": "original",
        "huoQCSXQ": "Get menu details",
        "huoQGLCS": "Get context menu",
        "huoQZCLX": "Obtain asset types",
        "bianJSBJYYBDBM": "Do not verify the original encoding during editing",
        "gaiBMTF": "The code is duplicated",
        "xinCHSXYM": "Refresh page after adding",
        "bianJZBRY": "Editing is not necessary",
        "caoZYB": "operation failed",
        "qingXXCCSJBXX": "Please add basic menu information first",
        "caiSDJBXX": "Basic information of the menu",
        "qiZPKLBLDSJ": "This includes data from tables",
        "biaoLXG": "Table related",
        "anNGL": "Button management",
        "caiSPZ": "Menu configuration",
        "xinCZCS": "Add submenu",
        "shiPSSGCS": "Do you want to delete this menu",
        "guoLFJCDCS": "Filter non integrated menus",
        "tiaoZZXQY": "Jump to the details page",
        "shanJKQHCSWXZ": "Click to switch menu not selected",
        "xuanZZT": "Selected status",
        "caoZFJCSS": "When operating the parent menu",
        "duiYZCSZTQMRYQTB": "The corresponding submenu status will be synchronized with it by default",
        "keSSDCZDZ": "Can be adjusted independently",
        "zhiCTZCSDZZZSX": "Support dragging and dropping menus to adjust display order",
        "baoCHDQJTCSSJQBFG": "After saving, the current system menu data will be overwritten",
        "queRCZ": "Confirm operation",
        "biaoJ": "sign",
        "tuoZCG": "Dragged successfully",
        "fuZC": "Only supported",
        "jiCS": "Level menu",
        "tuoZCGSGX": "Update on successful drag and drop",
        "chuSHCS": "Initialize menu",
        "buYKL": "Do not clone",
        "tongGYY": "By reference",
        "chuL": "handle",
        "chuLBX": "Process sorting",
        "genJQWGLCS": "Filter menus based on permissions",
        "heBSZ": "Merge arrays",
        "heBCS": "Merge Menu",
        "moRCS": "Default menu",
        "dangQJTCS": "Current system menu",
        "heBHDCS": "Merged menu",
        "jiLJTXZ": "Record system selection",
        "fangZTF": "Prevent duplication",
        "jiTSJXZD": "Selected by the system",
        "buTZC": "Cannot be taken from",
        "zhongTJ": "Add in",
        "moRCSSJZXJTCS": "Default menu and query system menu",
        "jiTXZD": "Selected by the system",
        "ruYWY": "Unique requirement",
        "zhaZFJ": "Find Parent",
        "ziJ": "Child level",
        "dangQWZDCS": "The current complete menu",
        "dangQDJD": "Currently clicked",
        "dangQ": "current",
        "diQBFJ": "All parents of",
        "diQBZJ": "All children of",
        "xuanZGT": "Select Features",
        "ziJMRCS": "Event default parameters",
        "chuLHD": "Processed",
        "tuoZGT": "Dragging function",
        "zhi": "value",
        "tuoZH": "After dragging",
        "zhaZCSCJ": "Find the deepest level",
        "ruYDMZDBS": "Need to match the identification in the code",
        "yiZ": "unanimous",
        "qingSRBS": "Please enter identification",
        "caoZQWLB": "Operation permission list",
        "tianJ": "Add",
        "jiaoYBSSPKY": "Verify if the identification is available",
        "caoZLX": "Operation type",
        "dangSSDGHWBYDCHYXS": "When the deleted item happens to be the last item on this page",
        "fanHDSYY": "Return to the previous page",
        "dangTJDXRYXJYYCTXZS": "When the added item requires a new page to be displayed",
        "tiaoZDCHYY": "Jump to the last page",
        "daoR": "Import",
        "daoRSJ": "Import data",
        "daoRCG": "Import successful",
        "daoRYB": "Import failed",
        "qingTS": "Please try again",
        "daoCSJ": "Export data",
        "quanWSJ": "Permission data",
        "daoCCG": "Export successful",
        "daoCCW": "Export error",
        "xiuGZT": "modify state",
        "qingQSJLX": "Request data type",
        "xiangYSJLX": "Response data type",
        "xieKMS": "Interface Description",
        "qingQZL": "Request Example",
        "xiangYZT": "Response status",
        "xiangYCS": "Response parameters",
        "cenSM": "Parameter Name",
        "cenSSM": "Parameter Description",
        "qingQLX": "Request type",
        "shiPBX": "Is it necessary to",
        "cenSLX": "Parameter type",
        "buS": "No, it's not",
        "zhongDLDHZBJXJS": "If the class is in, it will not be parsed",
        "zhiXJS": "End directly",
        "zhuangTM": "Status code",
        "cenSMC": "Parameter Name",
        "faS": "send",
        "gaiZDSBCZD": "This field is mandatory",
        "queDSS": "Confirm deletion",
        "cenSZ": "Parameter values",
        "ruLYHMCXCSJY": "If the User has not filled in the parameters and has",
        "diQKX": "In the case of",
        "geiYHZZQQZL": "Show Users request examples",
        "huoQMRCS": "Get default parameters",
        "chanSBHD": "Transforming",
        "xiang": "term",
        "dangQJDBLSJ": "Current node table data",
        "dangQBL": "Current Table",
        "yuanYXZD": "Previously selected",
        "shuDQBLZW": "Except for the current table",
        "gouXSDSJ": "Check the data above",
        "quXXZDSJX": "Deselect data items",
        "xinCXZDX": "Add selected items",
        "huanCDQBLHQTBLGBDSJ": "Cache data that has been changed in the current table and other tables",
        "xinCXZ": "Add Selected",
        "quXXZDSJ": "Unselected data",
        "yiSD": "Removed",
        "xinXZD": "Newly selected",
        "ciSS": "At this point",
        "qingXZ": "Please select",
        "xieK": "interface",
        "diSFXRSM": "Third party access instructions",
        "diSFPSXKQQJXSJQMDYFS": "Third party platform interface request based on data signature calling method",
        "shiYQMLJYQHDQQDWZXSJHFX": "Using signatures to verify the integrity and legality of client requests",
        "ninKSCKRXWD": "You can refer to the following document",
        "laiGC": "To construct",
        "xieKSDYDYDDSFPSXK": "Interface to call the corresponding third-party platform interface",
        "qianMZLSM": "Signature Example Explanation",
        "liangCFS": "Two ways",
        "faQQQDQMXXDRYFDQQTZ": "The signature information for initiating the request needs to be placed in the request header",
        "erBSQQB": "Instead of requesting the body",
        "qianMGZ": "Signature rules",
        "zhuY": "take care",
        "qianMSJCYFWQSJBTXCWFZSS": "The signature timestamp cannot differ from the server time by more than five minutes",
        "piZFWQQJJBCQQ": "Otherwise, the server will reject this request",
        "qiangCS": "Set parameters",
        "an": "according to",
        "baiXDD": "Sorting obtained",
        "shiYPXSJCSJMYDD": "Obtain using concatenated timestamps and keys",
        "deiD": "obtain",
        "qingQTZL": "Request header example",
        "xiangYJLZL": "Response result example",
        "zhiLSJ": "Example data",
        "zhiLSJLX": "Example data type",
        "fuWQYQLC": "Server signature verification process",
        "yanQSM": "Verification instructions",
        "shiYHQMXTDSF": "Using the same algorithm as the signature",
        "buRYDXYJLBX": "No need to sort the response results",
        "xieRSM": "Access instructions",
        "pingS": "platform",
        "jiX": "base on",
        "banBKF": "Version development",
        "yiL": "rely on",
        "qiangSX": "Add the following",
        "yiLJRD": "Dependency added to",
        "wenJZ": "In the file",
        "qiaHDDCSHHQQFS": "Client initialization and request methods",
        "qianMZLSJC": "Signature Example Timestamp",
        "fuWDXYJL": "Server response result",
        "xiangYT": "Response header",
        "yanQTG": "Verified and approved",
        "fuWQD": "Server's",
        "fangWBY": "Access key",
        "ruoLXBW": "If the type is not",
        "genJBTLXTJDDBTDGLSZ": "Add different types to obtain different filtering arrays",
        "shouY": "home page",
        "guoLTZZDXK": "Filter interfaces that can be displayed",
        "muS": "mode",
        "wei": "position",
        "shiBRYGL": "No filtering is required at this time",
        "guoLSZ": "Filter array",
        "wenD": "document",
        "fanH": "return",
        "diaoS": "debugging",
        "shiPZZ": "Whether to display",
        "qiangDXXSDSJZHWBLRYDXS": "Convert data in the form of objects to the form required for tables",
        "duiYYJ": "Corresponding to Level 1",
        "xiangGDL": "Related classes",
        "youZGXDX": "Items checked on the right",
        "youZYBGXDX": "The item originally checked on the right side",
        "gouXFSBHDX": "Check the items that have changed",
        "siDYD": "Corresponding to",
        "zuoW": "As a",
        "youZ": "Right side",
        "yingYGL": "Application Management",
        "zhaKCS": "view menu",
        "diSFSY": "Third party homepage",
        "qiangDYSBZHWBLSJFH": "Convert corresponding entities into table data and return",
        "shiBL": "Entity class",
        "shiBLMC": "Entity class name",
        "qiangZDXXSZZHW": "Convert field information array to",
        "daiM": "code",
        "duiYDSYSBL": "All corresponding entity classes",
        "ziDXXSZ": "Field information array",
        "zhuanHSZ": "Conversion depth",
        "peiZJTZC": "Configure system support",
        "fuQDFW": "The scope of empowerment",
        "guanLF": "Related parties",
        "qingXZGLF": "Please select related parties",
        "beiGLF": "Related parties",
        "qingXZBGLF": "Please select the affiliated party",
        "yanZBS": "Verification identification",
        "biaoSZTYSZ": "Identification can only be made up of numbers",
        "yanZBSWYX": "Verify identity uniqueness",
        "qingSRJSMC": "Please enter the role name",
        "qingZSXZYX": "Please select at least one item",
        "biaoT": "Meter header",
        "caiSQW": "Menu permissions",
        "shuJQW": "Data permissions",
        "gouXLYSJQWYTKDZQCJDSJQW": "Check any data permission to see the data permission you have created",
        "piLSZ": "Batch settings",
        "biaoLNY": "Table Content",
        "buZCSJQWPZ": "Data permission configuration not supported",
        "moRKZKQBSJ": "By default, all data can be viewed",
        "quanBSJ": "All data",
        "suoZZZJXJZZ": "Organization and subordinate organizations",
        "suoZZZ": "Organization",
        "ziQCJD": "Created by oneself",
        "tongZPLSZ": "Reset batch settings",
        "xiaMWBLNYBF": "The following is the table content section",
        "biaoLSJDPPHBB": "Flat version of table data",
        "qianKL": "Shallow cloning",
        "fangBJXDBLSJJXCZ": "Convenient operation on table data",
        "jianYQWDXGQK": "Modification of listening permissions",
        "chanSXGHFKGFZJ": "Generate modifications and provide feedback to the parent component",
        "shenKLBLSJDPPB": "Flat version of deep clone table data",
        "yinWHZYSGD": "Because some changes will be made",
        "gaiGDZYXFKGFZJ": "This change is only for feedback to the parent component",
        "benZJMRBH": "This component does not require any changes",
        "fangKGRZXSJXXDY": "Release personal center and message subscription",
        "ruLGXZCSZSJQW": "If this item supports setting data permissions",
        "zeDQJXSJQWDYS": "Then map its data permissions",
        "jieSHSSYXYSDYZX": "Delete source attributes used for mapping after completion",
        "tongSQSYXPQXZTDBJ": "Simultaneously clearing markers for semi all selection status",
        "moRXZGRZXXGSZ": "Default selection of personal center related settings",
        "biaoLSJPPH": "Flattening Table Data",
        "diYLXZDX": "Selected items in the first column",
        "xuanZDXZHFZJ": "The selected item is returned to the parent component",
        "panDSQX": "Judging as selecting all",
        "panQX": "Half all selection",
        "caiSQWGBZJ": "Menu permission change event",
        "chuFDX": "Triggered items",
        "shiPGBDYDCZQW": "Do you want to change the corresponding operation permissions",
        "panDSPRYDZCSJCZQWJXXZ": "Determine if it is necessary to select submenus and operation permissions",
        "gengXXZZT": "Update selected status",
        "gengXSJQW": "Update data permissions",
        "ruLDQSJQWSYZ": "If the current data permission already has a value",
        "juCSQWMYBXZYBPX": "And menu permissions are not selected or partially selected",
        "zeQKDYDSJQW": "Then clear the corresponding data permissions",
        "ruLDQSJQWMYZ": "If the current data permission has no value",
        "juCSQWYBXZYZSBPX": "And the menu permissions have been selected or partially selected",
        "zeQSJQWBWMRZ": "Change data permissions to default values",
        "gengXSCJDDZT": "Update the status of upper level nodes",
        "zhaoDDYDFJD": "Find the corresponding parent node",
        "panDGFJDDXZZTW": "Determine the selected status of the parent node as",
        "quanXZ": "Select All",
        "buFXZ": "Partial selection",
        "weiXZ": "Unchecked",
        "ruoGFJDBSGJD": "If the parent node is not the root node",
        "tongFCCZSCLQDGFJDDFJDZT": "Repeat this operation to determine the parent node status of the parent node",
        "gaiBTBJDZT": "Change the state of the head node",
        "gengXQW": "Update permissions",
        "caoZQWGBZJ": "Operation permission change event",
        "qiangSXJGDBLSJBPWYGPTSZ": "Flatten tree structured table data into a regular array",
        "shuJQWHC": "Data permission backfill",
        "genJSYQW": "Based on all permissions",
        "qu": "take",
        "bingJSJ": "Union data",
        "sheZZJDDZT": "Set the status of child nodes",
        "genJ": "according to",
        "di": "of",
        "zhuX": "attribute",
        "panDDYDQXZT": "Determine the corresponding select all status",
        "touBQXBSY": "Head selection not applicable",
        "muBDX": "Target object",
        "muBZX": "Target Properties",
        "ruLZXXYPQX": "If there are half all sub options selected",
        "zeDQJDZXWPQX": "Then the current node is directly semi selected",
        "quanWFP": "Permission allocation",
        "yongHGL": "User management",
        "jieB": "Unbind",
        "queRJB": "Confirm unbinding",
        "qingGXSJ": "Please check the data box",
        "shuaXBL": "Refresh Table",
        "qingSRXM": "Please enter your name",
        "queRMM": "Confirm password",
        "qingZCSRMM": "Please enter your password again",
        "shouJX": "Mobile phone number",
        "qingSRZQDSJX": "",
        "qingSRSJX": "",
        "qingSRZQDYX": "Please enter the correct email address",
        "qingSRYX": "Please enter your email address",
        "tongZMM": "reset password",
        "miMBTSX": "Password cannot be less than",
        "miMBXPHTXXYWHSZ": "The password must contain uppercase, lowercase, English, and numbers",
        "weiDMM": "Password for bit",
        "liangCMMSRBYZ": "The two password inputs are inconsistent",
        "yongHLX": "customer type"
    },
    "valueAdded": {
        "daoC": "Export",
        "shi": "yes",
        "pi": "no",
        "xuX": "Index",
        "yongHM": "User name",
        "chanPMC": "Product Name",
        "shePSS": "Number of configuration units",
        "shiPZDDY": "Whether to automatically subscribe",
        "shengXSJ": "entry-into-force time",
        "guoJSJ": "Expiration time",
        "xiaoS": "hour",
        "huiYQY": "Membership benefits",
        "xinCYHQ": "Add coupons",
        "quX": "Cancel",
        "queD": "Confirm",
        "jiTZDSC": "Automatically generated by the system",
        "kaiSJSSJSJ": "Start and end time",
        "qingXZKSSJ": "Please select the start time",
        "zengSJE": "Gift amount",
        "yuan": "element",
        "qingSRZSJE": "Please enter the gift amount",
        "qingSR": "Please enter",
        "youHQSL": "Number of coupons",
        "zhang": "Zhang",
        "qingSRYHQSL": "Please enter the number of coupons",
        "youXJ": "Validity period",
        "qingXZYXJ": "Please select an expiration date",
        "lingQHJS": "Calculate after receiving",
        "genSHD": "Follow the activity",
        "tian": "day",
        "tanCXG": "Pop up related",
        "caoZCG": "Operation successful",
        "xinC": "Create",
        "shanS": "Delete",
        "queDYSSM": "Are you sure you want to delete it",
        "tingZ": "cease",
        "kaiSSJ": "start time",
        "jieSSJ": "End time",
        "lingQHYXJ": "Validity period after collection",
        "siLQSJ": "Received data",
        "siSYSL": "Used quantity",
        "zhuangT": "State",
        "daiQD": "To be started",
        "jinXZ": "In progress",
        "siTZ": "Stopped",
        "siWC": "Completed",
        "chuangJSJ": "Creation time",
        "chuangJR": "Creator",
        "cuoHGXSJ": "Last update time",
        "cuoHGXR": "Last updated by",
        "caoZ": "Operation",
        "caoZYB": "operation failed",
        "daoR": "Import",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "daoRSJ": "Import data",
        "daoRCG": "Import successful",
        "daoRYB": "Import failed",
        "qingTS": "Please try again",
        "luoS": "format",
        "dingSXX": "Order information",
        "dingSS": "Number of orders",
        "jinE": "money",
        "dingS": "order form",
        "zong": "total",
        "daiFK": "Pending payment",
        "siFK": "Paid",
        "siGJ": "Expired",
        "zhiFCS": "Payment timeout",
        "dingSZX": "Order inquiry",
        "sanFDS": "Third party orders",
        "chanP": "Product",
        "zhiFJD": "Payment channels",
        "zhiFLX": "Payment type",
        "zhiFZT": "Payment status",
        "zhiFSJ": "Payment time",
        "dingYFWGJSJ": "Subscription service expiration time",
        "shiPKJFP": "Is an invoice issued",
        "geiDDRJ": "Given date",
        "dangQSJ": "current time",
        "jiSSJC": "Calculate time difference",
        "shanW": "unit",
        "haoM": "millisecond",
        "qiangSJCZHWLXZHYDJ": "Convert the time difference to how much longer is left until now",
        "tianS": "Days",
        "xiaoSS": "Hours",
        "fenZS": "Minutes",
        "miaoS": "Seconds",
        "shuCJL": "Output results",
        "fenZ": "grouping",
        "miao": "second",
        "huiYDS": "Membership orders",
        "gengXCPDJ": "Update product pricing",
        "qingSRCPMC": "Please enter the product name",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "qingSRXM": "Please enter your name",
        "chanPTP": "Product images",
        "qingSZCPTP": "Please upload product images",
        "jiFGZ": "Billing rules",
        "banB": "Version",
        "shuL": "Quantity",
        "shanJ": "Unit price",
        "ru": "month",
        "ban": "edition",
        "meiCJYS": "Every additional unit",
        "chanPMS": "Product Description",
        "shuRNYHAHCJTJCPMS": "After entering the content, press enter to add a product description",
        "queRSS": "confirm deletion",
        "qiSJFSL": "Starting billing quantity",
        "jieSJFSL": "End billing quantity",
        "chanPMSBTWK": "Product description cannot be empty",
        "qingSRNY": "Please enter the content",
        "xiangS": "Up",
        "xiangX": "down",
        "liSJL": "History",
        "guanB": "close",
        "caoZSJ": "Operation time",
        "caoZR": "Operator",
        "bianGNY": "Change content",
        "xiuG": "modify",
        "bianGLS": "Change History",
        "zhaX": "Query",
        "baoC": "Save",
        "quanWMC": "Permission Name",
        "shiPSFX": "Is there a charging item",
        "shiPSF": "Is there a fee",
        "qingXZQWX": "Please select a permission item",
        "baoCCG": "Save successful"
    },
    "gjsj": {
        "banBWJXQ": "Version file details",
        "shiYCP": "Applicable products",
        "linWMC": "Task Name",
        "gengXDJ": "Update level",
        "gengXNY": "Update content",
        "gengXFW": "Update scope",
        "guJBB": "Version hardware configuration",
        "ju": "And",
        "banBYJPZ": "Firmware version",
        "yu": "or",
        "gengXSL": "Update quantity",
        "jiHGXSBSL": "Plan to update the number of devices",
        "siGXSBSL": "Number of updated devices",
        "gengXPZ": "Update configuration",
        "sheB": "Equipment",
        "fenZMYHCZS": "When there is no User action in minutes",
        "ziDGX": "Automatic update",
        "sheBDDSJ": "Equipment local time",
        "shi": "Time",
        "jinY": "Disabled",
        "qiY": "Enable",
        "xiangQ": "Details",
        "linWX": "task number",
        "zhuangT": "State",
        "jiHGXSBS": "Plan to update the number of devices",
        "caoZSJ": "Operation time",
        "caoZR": "Operator",
        "caoZ": "Operation",
        "chanPXZ": "Product selection",
        "ziDGXPZ": "Automatically update configuration",
        "guJLXXZ": "Firmware type selection",
        "ruanJTBB": "Software major version",
        "banB": "Version",
        "banBZCDX": "Version supports multiple selections",
        "yingJPZZCDX": "Hardware configuration supports multiple selections",
        "xinCGJSJ": "New firmware upgrade",
        "yongHZDGX": "User initiated updates",
        "jinJGX": "Urgent update",
        "shengJ": "upgrade",
        "sheBKXSJ": "Device idle time",
        "fenZS": "Minutes",
        "biCBTWK": "Required fields cannot be empty",
        "sheZTBBSJQK": "Set up large version data situation",
        "zhiTXZCXBB": "Only the latest version can be selected",
        "huoQBBPZLMDYJZCBB": "Obtain the hardware support version in the version configuration",
        "cuoSYTNY": "At least one piece of content",
        "zuiDNR": "Up to two pieces of content",
        "qingXZZDGXPZ": "Please select automatic update configuration",
        "gengXNYPD": "Update content judgment",
        "qingXWSGXNY": "Please first improve and update the content",
        "juGJLXBTXT": "And the firmware types cannot be the same"
    },
    "bbbz": {
        "banBWJXQ": "Version file details",
        "shiYCP": "Applicable products",
        "banBLX": "Version type",
        "dangQCXZBB": "Current latest sub version",
        "zhiCYJBB": "Supports hardware versions",
        "caoZR": "Operator",
        "caoZSJ": "Operation time",
        "liSZBB": "Historical sub versions",
        "bianJ": "Edit",
        "xiangQ": "Details",
        "banBWJM": "Version FileName",
        "chanPMC": "Product Name",
        "ruanJTBB": "Software major version",
        "cuoXZBB": "Latest sub version",
        "zhiCYJPZ": "Support hardware configuration",
        "caoZ": "Operation",
        "chanP": "Product",
        "chanPXZ": "Product selection",
        "leiX": "Type",
        "shengJGJBB": "Upgrade firmware version",
        "xiuGLSRJBB": "Modify historical software versions",
        "cengJZZBB": "Subversion",
        "ziBBX": "Sub version number",
        "ziBBWJ": "Subversion file",
        "xinCBBWJ": "New version file added",
        "bianJDSHY": "When editing, use",
        "ziBBD": "Subversion",
        "ziBBWJDQM": "Signature of sub version files",
        "ziBBWJDTX": "Size of sub version files",
        "zhiCDYJBBLB": "List of supported hardware versions",
        "guJLX": "Firmware type",
        "biCBTWK": "Required fields cannot be empty",
        "ziBBXBC": "Sub version number is mandatory",
        "ziBBWJBZ": "Subversion files must be transmitted",
        "zhiCYJBBBX": "Support hardware version required",
        "bianJYM": "Edit Page",
        "bianJBBWJ": "Edit version file",
        "xiangQYM": "Details page",
        "genJCPGBBLXYZTBB": "Based on product and version type or major version",
        "huoQBBPZLMDYJZCBB": "Obtain the hardware support version in the version configuration"
    },
    "bbsj": {
        "qiY": "Enable",
        "tingY": "Deactivate",
        "bianJ": "Edit",
        "jinY": "Disabled",
        "chanPMC": "Product Name",
        "peiZLX": "Firmware type",
        "banBLX": "Version type",
        "yingJPZ": "Version number",
        "zhuangT": "State",
        "caoZSJ": "Operation time",
        "caoZR": "Operator",
        "caoZ": "Operation",
        "chanP": "Product",
        "chanPXZ": "Product selection",
        "leiX": "Type",
        "guJLX": "Firmware type",
        "xinCYJPZ": "Add hardware configuration",
        "siYYJPZ": "Existing version number",
        "xinCBBPZ": "New version configuration added",
        "ruanJ": "software",
        "yingJ": "hardware",
        "biCBTWK": "Required fields cannot be empty",
        "bianJBBPZ": "Edit version configuration",
        "huoQBBPZLMDYJZCBB": "Obtain the hardware support version in the version configuration"
    },

    "AMapComponent": {
        "qingPZGDDT": "Please configure the Gaode map",
        "luoX": "route",
        "dangQHJBZC": "The current environment does not support",
        "jiSSZ": "calculation speed",
        "shiPZDXZ": "Whether to display on top",
        "duiYDGJ": "Corresponding trajectory",
        "weiZ": "To be true",
        "sheZ": "set up",
        "wei": "by",
        "xianCCT": "Existing maximum",
        "weiJ": "False",
        "gouCCSZ": "In the construction parameters",
        "diFHZ": "Return value of"
    },
    "BadgeStatus": {
        "gaoJYS": "Alarm color",
        "zheGZBXPPXLZFCZDYG": "This value must match one of the following strings",
        "ziDY": "custom",
        "zhiYS": "Value Color"
    },
    "BatchDropdown": {
        "tongX": "Reselection",
        "piLCZ": "Batch operations",
        "ruYXZBLSJ": "Need to select table data",
        "houCFDZJ": "Post triggered events"
    },
    "CardBox": {
        "tuP": "Picture",
        "neiY": "content",
        "gouX": "Check the box",
        "zhuangT": "State",
        "anN": "button",
        "zhengC": "Normal"
    },
    "Empty": {
        "zanMSJ": "There is currently no data available"
    },
    "FileFormat": {
        "qingXZWJLS": "Please select a file format",
        "ziDQY": "Auto enable",
        "zuJSXBDDZ": "The value of bidirectional binding of components"
    },
    "Form": {
        "qingXZWJLS": "Please select a file format",
        "ziDQY": "Auto enable",
        "zuJSXBDDZ": "The value of bidirectional binding of components",
        "biaoSSJ": "Form Data",
        "biaoS": "Form",
        "genJZRDBS": "Based on the incoming form",
        "shengCBS": "Generate Form",
        "chuLMRZSJYSZ": "Process default and original values",
        "dangQZZ": "The current value is in",
        "zhong": "in",
        "chuL": "handle",
        "qingQ": "request",
        "tongZBS": "Reset Form",
        "yanZBSJBS": "Verify and submit the form",
        "gaiBSGZ": "Change individual values",
        "xiuGZGBSZ": "Modify the entire form value",
        "zuJMC": "Component Name",
        "zuJ": "assembly",
        "neiZZX": "Built in queries",
        "huiFG": "Will cover",
        "yinZ": "hide",
        "zhiBHJRD": "The value will not enter",
        "biaoSYZY": "Form Hidden Fields",
        "cuoDSR": "Maximum Input",
        "geZF": "Characters",
        "shuR": "input",
        "xuanZ": "choice",
        "qing": "please",
        "qingSRYWYZSZYZ": "Please enter English or numbers or",
        "yuZ": "perhaps",
        "cuoXFSR": "Min Input Only",
        "cuoTKSR": "Maximum Input",
        "weiZF": "Bit character"
    },
    "index.ts": {
        "xianZ": "display",
        "haiS": "still",
        "yongXBTFBL": "Used for different resolutions",
        "fenBL": "resolving power",
        "dingBZBZZ": "Top left slot",
        "qiaPNY": "Card content",
        "fenY": "paging",
        "qiHQPHBL": "Switch cards and tables",
        "shuaXSJ": "refresh data",
        "muSQH": "Mode switching",
        "jianYKZ": "Listening width",
        "jiSXZQPGS": "Calculate the number of displayed cards",
        "qingQSJ": "Request data",
        "panDRLSCHYYJCHYYWK": "Determine if it is the last page and the last page is empty",
        "jiuTZDQYY": "Just jump to the previous page",
        "chuSH": "initialization",
        "daoCFF": "Export method",
        "dingBYBZZ": "Top right slot",
        "siXZ": "Selected",
        "xiang": "term",
        "quXXZ": "Deselect",
        "di": "Section",
        "tiao": "strip",
        "zongH": "altogether"
    },
    "FRuleEditor": {
        "sheZZXGZ": "Set attribute rules",
        "zhuXFZ": "Attribute assignment",
        "qingDSFGZSYDZXJXFZ": "Please assign values to the attributes used in the above rules",
        "faSSJ": "send data",
        "tianJTM": "add entry",
        "yunXJL": "Running results",
        "kaiSYX": "Start running",
        "tingZYX": "Stop running",
        "qingK": "empty",
        "zhuX": "attribute",
        "dangQZ": "Current value",
        "shangYZ": "Previous value",
        "qingBJGZ": "Please edit the rule",
        "qingXSRBS": "Please enter the identification first",
        "xiaoSGJZ": "Search keywords",
        "qingXZSYZ": "Please select a usage value",
        "shiSZWKSHQSYYXZBQ": "Obtain the previous valid value to complete when the real-time value is empty",
        "shiSZBWKZSYSSZ": "If the real-time value is not empty, use the real-time value",
        "shiSZ": "Real time value",
        "shiSZDSYYXZ": "Previous valid value of real-time value",
        "tianJ": "Add",
        "shuJLX": "Data type",
        "shiPZD": "Is it read-only",
        "keXSZFW": "Writable numerical range"
    },
    "GeoComponent": {
        "zuoBDSQZJ": "Coordinate point picking component",
        "deLWZ": "geographical position",
        "queR": "Confirm",
        "quX": "Cancel",
        "shouDSRDZBD": "Manually entered coordinate points",
        "jingWZZFC": "Longitude and Latitude String",
        "deTTC": "map pop",
        "deTSQDZBD": "Coordinate points picked from the map",
        "deTJWZ": "Map longitude and latitude",
        "deTCSH": "Map initialization",
        "deTDJ": "Map click",
        "xuanZXSJL": "Select search results"
    },
    "InputSelect": {
        "qingXZSW": "Please select a unit"
    },
    "JIndicators": {
        "qingSR": "Please enter",
        "qingXZ": "Please select",
        "fanW": "range"
    },
    "Layout": {
        "paoH": "contain",
        "cenS": "parameter",
        "qingS": "clean up",
        "biaoJSD": "Mark as read",
        "weiDXX": "Unread messages",
        "dangQBJWSD": "Currently marked as read",
        "zhaKGD": "View more",
        "yinZYQXGDT": "Hide or Cancel Scrollbars",
        "geRZX": "Personal Center",
        "tuiCDL": "Logout"
    },
    "Metadata": {
        "peiZYS": "Configuration elements",
        "shuiM": "Explain",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "qingSRSM": "Please enter a description",
        "qingSR": "Please enter",
        "shi": "yes",
        "pi": "no",
        "cunCPZ": "Storage Configuration",
        "meiJXPZ": "Enumeration item configuration",
        "xinCMJX": "Add enumeration type",
        "peiZCS": "configuration parameter",
        "meiJZ": "enum",
        "qingSRMJZ": "Please enter the enumeration value",
        "zhiTYSZ": "Can only be represented by numbers",
        "ziW": "letter",
        "xiaHX": "Underline",
        "zhongHXZC": "Middle line composition",
        "biaoS": "identification",
        "qingSRBS": "Please enter identification",
        "meiJMC": "Enum names",
        "qingSRMJMC": "Please enter the enumeration name",
        "mingC": "Name",
        "qingSRMC": "Please enter a name",
        "tianJCS": "Add parameters",
        "zhiBZ": "Indicator value",
        "qingSRZBZ": "Please enter the indicator value",
        "tianJZB": "Add metrics",
        "guiZPZ": "Rule configuration",
        "congK": "window",
        "qingXZCK": "Please select a window",
        "juHHS": "Aggregate function",
        "qingXZJHHS": "Please select an aggregation function",
        "qingSRCKZZ": "Please enter the window length",
        "qingSRBZ": "Please enter the step size",
        "shiJCK": "time window",
        "ciSCK": "Number Window",
        "congKZZ": "Window length",
        "miao": "second",
        "ci": "second",
        "buZ": "step"
    },
    "MonacoEditor": {
        "daiMBJQ": "Code Editor",
        "zhuTS": "Theme color",
        "moRGL": "Default Highlighting",
        "heiS": "black",
        "gaoLHS": "High gloss black",
        "daiMLSH": "Code formatting",
        "guangBWZZRNY": "Insert content at cursor position",
        "zhuQHQGBWZ": "Previously obtained cursor position",
        "zhuHGBWZGB": "Afterwards, the cursor position changes",
        "sheZGBWZW": "Set cursor position to",
        "zhuQDWZ": "Previous position"
    },
    "NormalUpload": {
        "wenJSZ": "File upload",
        "xiaZMB": "Download template",
        "jinXZ": "In progress",
        "siWC": "Completed",
        "zongSL": "Total quantity",
        "zuJSXBDDZ": "The value of bidirectional binding of components",
        "sheBDRMB": "Device import template",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "yiB": "fail",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "qingXSZWJ": "Please upload the file first"
    },
    "Upload": {
        "wenJSZ": "File upload",
        "xiaZMB": "Download template",
        "jinXZ": "In progress",
        "siWC": "Completed",
        "zongSL": "Total quantity",
        "zuJSXBDDZ": "The value of bidirectional binding of components",
        "sheBDRMB": "Device import template",
        "qingSZ": "Please upload",
        "luoSWJ": "Format file",
        "yiB": "fail",
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator",
        "qingXSZWJ": "Please upload the file first",
        "dianJXG": "Click to modify",
        "shangZYB": "Upload failed",
        "qingSZZQLSDTP": "Please upload images in the correct format",
        "tuPTXBXXX": "The image size must be smaller than"
    },
    "PermissionButton": {
        "zanMQW": "No permission temporarily",
        "qingLJGLY": "Please contact the administrator"
    },
    "Player": {
        "shiPBF": "Video playback",
        "boF": "play",
        "zanT": "suspend",
        "zanTZT": "Pause state",
        "shiPBFGJL": "Video playback toolbar",
        "fenPZJ": "Split screen component",
        "gongJL": "toolbar",
        "shanP": "Single screen",
        "siFP": "Quad screen",
        "jiuFP": "Nine split screen",
        "quanP": "Full screen",
        "keBCFPPZJL": "Can save split screen configuration records",
        "fenPMC": "Split screen name",
        "qingSRMC": "Please enter a name",
        "cuoDKSR": "Up to input",
        "geZF": "Characters",
        "baoC": "Save",
        "zanMSJ": "There is currently no data available",
        "queRSS": "confirm deletion",
        "queR": "Confirm",
        "quX": "Cancel",
        "boFQ": "player",
        "shuaX": "Refresh",
        "qiangZQ": "controller",
        "dangQXZBFSP": "The currently selected broadcast video",
        "dangQCZDZ": "Current operation action",
        "fenPSL": "Number of split screens",
        "shiPCK": "Video Window",
        "dangQXZDCK": "The currently selected window",
        "liSJL": "History",
        "zhanZBCFC": "Display and Save Floating Window",
        "baoCBS": "Save Form",
        "quanPYS": "Full screen elements",
        "shuaXSP": "Refresh Video",
        "shiPLXBH": "Changes in video links",
        "gengXBFNY": "Update playback content",
        "dangQWZWFPCHYW": "The current position is the last position of the split screen",
        "dianJFPLSJL": "Click on split screen history",
        "huoQLSFP": "Get historical split screen",
        "shanSLSFP": "Delete historical split screen",
        "baoCFP": "Save split screen",
        "baoCCG": "Save successful",
        "baoCYB": "Save failed",
        "chuSH": "initialization",
        "gaiBFPSL": "Change the number of split screens",
        "quanPCZ": "Full screen operation",
        "dianJQZAN": "Click on the control button",
        "qiangZLX": "control type"
    },
    "RadioCard": {
        "shanXQP": "Single Choice Card",
        "shuiP": "level",
        "zhuiZ": "vertical",
        "shiPYXGYS": "Is there a small hook style available"
    },
    "RowPagination": {
        "biaoLXXC": "Table row addition",
        "jianSFYZJ": "Simple Paging Component"
    },
    "Search": {
        "zuJ": "assembly",
        "ruYJH": "Need to combine",
        "shiY": "apply",
        "zhuX": "belong to",
        "mingC": "Name",
        "shuiM": "Explain",
        "leiX": "Type",
        "moRZ": "Default value",
        "zhaXXLLB": "Query dropdown list",
        "zhaXMS": "Query mode",
        "zhaXZJWY": "Unique query component",
        "zhaXHDZJ": "Query callback events",
        "yongLTMMZXZDZ": "Used to rename query field values",
        "zhaXZZJLX": "Query Value Component Type",
        "huo": "and",
        "zuJXLZ": "Component dropdown value",
        "qiangZZXZDXLMRZ": "Control Query Field Dropdown Default Values",
        "moRW": "Default to",
        "jiMC": "Namely",
        "zhaXTJ": "query criteria",
        "chuLSGZX": "Process individual queries",
        "zhi": "value",
        "jiCYF": "Basic usage",
        "zhongPH": "Including",
        "zhuXCHCXZZXXLZ": "Properties will only appear in the query dropdown",
        "diZYZX": "The function of is to",
        "paoC": "Throw",
        "huiGJ": "Will be based on",
        "xiuGSJZ": "Modifying data",
        "diZ": "Value of",
        "diZYZXSZZXTJ": "The function of is to set query conditions",
        "xiangGTJCK": "Related conditions reference",
        "zhongD": "Middle",
        "shiJ": "Submit",
        "moR": "default",
        "yuZ": "perhaps",
        "bingJ": "also",
        "paoH": "contain",
        "buPH": "Excluding",
        "buZX": "Not belonging to"
    },
    "Table": {
        "xianZ": "display",
        "haiS": "still",
        "yongXBTFBL": "Used for different resolutions",
        "fenBL": "resolving power",
        "dingBZBZZ": "Top left slot",
        "qiaPNY": "Card content",
        "fenY": "paging",
        "qiHQPHBL": "Switch cards and tables",
        "shuaXSJ": "refresh data",
        "muSQH": "Mode switching",
        "jianYKZ": "Listening width",
        "jiSXZQPGS": "Calculate the number of displayed cards",
        "qingQSJ": "Request data",
        "panDRLSCHYYJCHYYWK": "Determine if it is the last page and the last page is empty",
        "jiuTZDQYY": "Just jump to the previous page",
        "chuSH": "initialization",
        "daoCFF": "Export method",
        "dingBYBZZ": "Top right slot",
        "siXZ": "Selected",
        "xiang": "term",
        "quXXZ": "Deselect",
        "di": "Section",
        "tiao": "strip",
        "zongH": "altogether"
    },
    "TitleComponent": {},
    "ValueItem": {
        "cenSLXSRZJ": "Parameter type input component",
        "qingSRLX": "Please enter the link",
        "daiMBJQTC": "Code Editor Popup",
        "bianJ": "Edit",
        "queR": "Confirm",
        "quX": "Cancel",
        "zuJSXBDDZ": "The value of bidirectional binding of components",
        "zuJLX": "Component type",
        "xiaLXZKXLSJ": "Dropdown selection box dropdown data",
        "wenJSZ": "File upload"
    }
}